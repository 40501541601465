import { useEffect, useState } from "react";
import IntegrationBuilderHeader from "../shared/IntegrationBuilderHeader";
import { IntegrationForIntegrationBuilder } from "../utils/Entities";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";
import useCreateOrPatchIntegration from "../hooks/useCreateOrPatchIntegration";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { Card } from "@merge-api/merge-javascript-shared";
import CardHeader from "../shared/CardHeader";
import TextFieldHeader from "../shared/TextFieldHeader";
import { DiffModelTypeEnum } from "../../../models/DiffModels";
import EditorLeavingGuard from "../../shared/unsaved-changes/EditorLeavingGuard";

interface APIEndpointsConfigurationProps {
  integrationID: string;
}

const WebAddressSchemaConfiguration = ({ integrationID }: APIEndpointsConfigurationProps) => {
  /* SETUP REQUEST BODY FOR EDITING CONFIGURATION FIEDLS */
  const {
    setOnSubmit,
    integration,
    isLoading,
    setCurrentStateForDiff,
    setNewStateForDiff,
    setModelTypeForDiff,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();
  const [requestBodyForIntegration, setRequestBodyForIntegration] = useState<
    IntegrationForIntegrationBuilder | undefined
  >();
  const { isLoadingCreateOrPatchIntegration, patchIntegration } = useCreateOrPatchIntegration({
    requestBodyForIntegration,
  });
  const [isRequiredFieldsCompleted, setIsRequiredFieldsCompleted] = useState<boolean>(false);

  /* SETUP BUTTON STATE */
  useIntegrationBuilderContext({
    submitButtonText: "Save configuration",
    canSubmit: isRequiredFieldsCompleted,
    isLoadingSubmit: isLoadingCreateOrPatchIntegration,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    shouldHideDiffModal: false,
  });

  /* USEEFFECT */
  /* This useEffect initializes all states, including the model state to be used in the diff modal.
   * When adding new fields/removing them, you'll have to add them to multiple places
   * to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    if (integration) {
      setRequestBodyForIntegration({
        base_api_url: integration.base_api_url,
      });
      setCurrentStateForDiff({
        base_api_url: integration.base_api_url,
      });
      setNewStateForDiff({
        base_api_url: integration.base_api_url,
      });
      setModelTypeForDiff(DiffModelTypeEnum.BASE_API_URL);
    } else {
      setCurrentStateForDiff({
        base_api_url: undefined,
      });
      setNewStateForDiff({
        base_api_url: undefined,
      });
    }
  }, [integration]);

  // Set submit button state based on current values
  useEffect(() => {
    setOnSubmit(() => patchIntegration(integrationID));
  }, [integrationID, requestBodyForIntegration]);

  // Update required state for request body changes
  useEffect(() => {
    setIsRequiredFieldsCompleted(
      requestBodyForIntegration?.base_api_url !== null &&
        requestBodyForIntegration?.base_api_url !== ""
    );
  }, [requestBodyForIntegration]);

  // Update new state
  useEffect(() => {
    setNewStateForDiff(requestBodyForIntegration);
  }, [requestBodyForIntegration]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div>
        <IntegrationBuilderHeader title="Configuration" showRequired />
        {isLoading ? (
          <EmptyStateWrapper isSpinner />
        ) : (
          <div className="flex flex-column space-y-6">
            <Card className="pt-4 px-5 pb-5 bg-white mb-6 min-w-fit">
              <CardHeader title="Configuration" />
              <div className="space-y-6">
                <TextFieldHeader
                  dataTestID="field-base-api-url"
                  title="Base API URL"
                  subtitle="Enter your API URL without the backslash '/' at the end."
                  learnMoreText="Include {DOMAIN} if there is a unique domain, include {PATH} if there is a unique path, and include {API-KEY} if there is an API key in the base API URL.
                Please note that if you include {DOMAIN}, {PATH}, or {API-KEY}, then you will have to add a step for each, and ask the end user to specify what these items are.
                
                Example: https://{API-KEY}:x@{DOMAIN}.fakehrplatform.com/api/gateway.php/{PATH}/v1."
                  placeholder="Example: https://api.atlassian.net"
                  value={requestBodyForIntegration?.base_api_url || ""}
                  onChange={(e) => {
                    setRequestBodyForIntegration((body) => {
                      return { ...body, base_api_url: e.target.value };
                    });
                  }}
                  required
                />
              </div>
            </Card>
          </div>
        )}
      </div>
    </EditorLeavingGuard>
  );
};

export default WebAddressSchemaConfiguration;
