import AccordionCard from "./AccordionCard";
import PermissionWrapper from "./PermissionWrapper";

interface Props {
  title: string;
  defaultExpanded?: boolean;
  hasPermissionToEdit?: boolean;
  children: React.ReactNode;
  dataTestID?: string;
}

const AccordionCardForSection = ({
  title,
  defaultExpanded,
  hasPermissionToEdit,
  children,
  dataTestID,
}: Props) => {
  return (
    <AccordionCard title={title} defaultExpanded={defaultExpanded} dataTestID={dataTestID}>
      <PermissionWrapper hasPermissionToEdit={hasPermissionToEdit ?? true}>
        {children}
      </PermissionWrapper>
    </AccordionCard>
  );
};

export default AccordionCardForSection;
