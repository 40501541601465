import { BlueprintRunBlueprintStep } from "../../../models/Blueprints";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import TypeaheadFormField from "./TypeaheadFormField";
import useBlueprintContext from "../context/useBlueprintContext";
import { HeaderPretitle } from "../../shared/text/MergeText";

const BlueprintEditorRightPanelRunBlueprintForm = () => {
  const { selectedStep } = useBlueprintContext();
  const { id, template } = selectedStep as BlueprintRunBlueprintStep;

  return (
    <>
      <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
      {Object.entries(template.parameter_schema.properties ?? {})
        .filter((v) => v[0] !== "model_object_id")
        .sort(function (a, b) {
          const keyA = a[0],
            keyB = b[0];

          if (keyA === "remote_data_path") return -1;
          if (keyB === "remote_data_path") return -1;
          return 0;
        })
        .map(([key, parameter]) => {
          return (
            <TypeaheadFormField
              key={key}
              title={parameter.title}
              subtitle={parameter.description}
              valueKey={key}
              parameterType={parameter.type}
            />
          );
        })}
      <hr />
      <HeaderPretitle className="mt-3">Blueprint Return Schema</HeaderPretitle>
      <JSONSchemaTreeDiagram stepID={id} jsonSchema={template.return_schema} />
    </>
  );
};

export default BlueprintEditorRightPanelRunBlueprintForm;
