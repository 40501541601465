import { BlueprintStepTemplate } from "../../../models/Blueprints";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelCommonModelLoopForm = ({ stepID, stepTemplate }: Props) => {
  return (
    <>
      <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
      <TypeaheadFormField
        key={"remote_id"}
        title={"Remote ID"}
        subtitle={"The Remote ID of objects to loop through."}
        valueKey={"remote_id"}
        parameterType={"uuid"}
      />
      <HeaderPretitle className="mt-3">Common Model Fields</HeaderPretitle>
      <JSONSchemaTreeDiagram stepID={stepID} jsonSchema={stepTemplate.return_schema} />
    </>
  );
};

export default BlueprintEditorRightPanelCommonModelLoopForm;
