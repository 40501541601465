import {
  ClientCredentialSource,
  GrantTypes,
  ParamsLocation,
} from "../../../../integrations/auth-config/AuthConfigShared";
import AccordionCardForSection from "../../../shared/AccordionCardForSection";
import { Row } from "../../../shared/postman-table/PostmanTableRow";
import { DateTimeFormatEnums, TokenExpiresInType } from "../../../utils/Entities";
import AuthorizationConfigurationCard from "./AuthorizationConfigurationCard";
import RefreshConfigurationCard from "./RefreshConfigurationCard";
import TokenURLAdvanced from "./TokenURLAdvanced";
import TokenURLRequestCard from "./TokenURLRequestCard";

interface OAuthGrantPageProps {
  grantType: GrantTypes;
  // auth url card states
  oAuthAuthorizeURL: string | undefined;
  setOAuthAuthorizeURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  redirectQueryParams: string[] | null;
  setRedirectQueryParams: React.Dispatch<React.SetStateAction<string[] | null>>;
  doesOAuthUsePKCE: boolean | undefined;
  setDoesOAuthUsePKCE: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  clientCredentialSourceChoice: ClientCredentialSource | undefined;
  setClientCredentialSourceChoice: React.Dispatch<
    React.SetStateAction<ClientCredentialSource | undefined>
  >;
  oAuthClientID: string | undefined;
  setOAuthClientID: React.Dispatch<React.SetStateAction<string | undefined>>;
  oAuthClientSecret: string | undefined;
  setOAuthClientSecret: React.Dispatch<React.SetStateAction<string | undefined>>;
  oAuthShouldFetchAccessToken: boolean | undefined;
  setOAuthShouldFetchAccessToken: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  oAuthAuthorizeURLParamKeysToExclude: string[] | null;
  setOAuthAuthorizeURLParamKeysToExclude: React.Dispatch<React.SetStateAction<string[] | null>>;

  // request card states
  tokenRequestURL: string | undefined;
  setTokenRequestURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  tokenURLRequestHeaders: Row[];
  setTokenURLRequestHeaders: React.Dispatch<React.SetStateAction<Row[]>>;
  tokenURLRequestParams: string[] | null;
  setTokenURLRequestParams: React.Dispatch<React.SetStateAction<string[] | null>>;
  requestParamType: ParamsLocation | undefined;
  setRequestParamType: React.Dispatch<React.SetStateAction<ParamsLocation | undefined>>;

  // response card states
  doesResponseIncludeExpiresInParam: boolean | undefined;
  setDoesResponseIncludeExpiresInParam: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  tokenExpiresInType: TokenExpiresInType | undefined;
  setTokenExpiresInType: React.Dispatch<React.SetStateAction<TokenExpiresInType | undefined>>;
  dateTimeFormat: DateTimeFormatEnums | undefined;
  setDateTimeFormat: React.Dispatch<React.SetStateAction<DateTimeFormatEnums | undefined>>;
  oAuthResponseTokenLifespanInSeconds: number | undefined;
  setOAuthResponseTokenLifespanInSeconds: React.Dispatch<React.SetStateAction<number | undefined>>;
  oAuthResponseTokenKeyPath: string[] | null;
  setOAuthResponseTokenKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
  oAuthResponseCreatedAtKeyPath: string[] | null;
  setOAuthResponseCreatedAtKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;

  // advanced token configuration states
  oAuthTokenURLHeaderKeysToExclude: string[] | null;
  setOAuthTokenURLHeaderKeysToExclude: React.Dispatch<React.SetStateAction<string[] | null>>;

  // refresh url card states
  shouldRefreshAfterInitialLink: boolean | undefined;
  setShouldRefreshAfterInitialLink: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  shouldUseTokenURLForRefresh: boolean | undefined;
  setShouldUseTokenURLForRefresh: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  doesRefreshIncludeExpiresIn: boolean | undefined;
  setDoesRefreshIncludeExpiresIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  oAuthRefreshURL: string | undefined;
  setOAuthRefreshURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  oAuthRefreshTokenLifespanInSeconds: number | undefined;
  setOAuthRefreshTokenLifespanInSeconds: React.Dispatch<React.SetStateAction<number | undefined>>;

  // advanced refresh url states
  refreshURLFieldsToExclude: string[] | null;
  setRefreshURLFieldsToExclude: React.Dispatch<React.SetStateAction<string[] | null>>;

  // permissioning
  hasPermissionToEdit: boolean;

  // integrationId, used for redirecting
  integrationId: string;
}

const OAuthGrantPage = ({
  grantType,
  oAuthAuthorizeURL,
  setOAuthAuthorizeURL,
  redirectQueryParams,
  setRedirectQueryParams,
  doesOAuthUsePKCE,
  setDoesOAuthUsePKCE,
  clientCredentialSourceChoice,
  setClientCredentialSourceChoice,
  oAuthClientID,
  setOAuthClientID,
  oAuthClientSecret,
  setOAuthClientSecret,
  tokenRequestURL,
  setTokenRequestURL,
  tokenURLRequestHeaders,
  setTokenURLRequestHeaders,
  tokenURLRequestParams,
  setTokenURLRequestParams,
  requestParamType,
  setRequestParamType,
  doesResponseIncludeExpiresInParam,
  setDoesResponseIncludeExpiresInParam,
  tokenExpiresInType,
  setTokenExpiresInType,
  dateTimeFormat,
  setDateTimeFormat,
  oAuthResponseTokenKeyPath,
  setOAuthResponseTokenKeyPath,
  oAuthResponseCreatedAtKeyPath,
  setOAuthResponseCreatedAtKeyPath,
  oAuthResponseTokenLifespanInSeconds,
  setOAuthResponseTokenLifespanInSeconds,
  shouldRefreshAfterInitialLink,
  setShouldRefreshAfterInitialLink,
  shouldUseTokenURLForRefresh,
  setShouldUseTokenURLForRefresh,
  doesRefreshIncludeExpiresIn,
  setDoesRefreshIncludeExpiresIn,
  oAuthRefreshURL,
  setOAuthRefreshURL,
  oAuthRefreshTokenLifespanInSeconds,
  setOAuthRefreshTokenLifespanInSeconds,
  oAuthShouldFetchAccessToken,
  setOAuthShouldFetchAccessToken,
  oAuthAuthorizeURLParamKeysToExclude,
  setOAuthAuthorizeURLParamKeysToExclude,
  oAuthTokenURLHeaderKeysToExclude,
  setOAuthTokenURLHeaderKeysToExclude,
  refreshURLFieldsToExclude,
  setRefreshURLFieldsToExclude,
  hasPermissionToEdit,
  integrationId,
}: OAuthGrantPageProps) => {
  return (
    <>
      {grantType !== GrantTypes.client_credentials && (
        <AuthorizationConfigurationCard
          oAuthAuthorizeURL={oAuthAuthorizeURL}
          setOAuthAuthorizeURL={setOAuthAuthorizeURL}
          redirectQueryParams={redirectQueryParams}
          setRedirectQueryParams={setRedirectQueryParams}
          doesOAuthUsePKCE={doesOAuthUsePKCE}
          setDoesOAuthUsePKCE={setDoesOAuthUsePKCE}
          clientCredentialSourceChoice={clientCredentialSourceChoice}
          setClientCredentialSourceChoice={setClientCredentialSourceChoice}
          oAuthClientID={oAuthClientID}
          setOAuthClientID={setOAuthClientID}
          oAuthClientSecret={oAuthClientSecret}
          setOAuthClientSecret={setOAuthClientSecret}
          oAuthShouldFetchAccessToken={oAuthShouldFetchAccessToken}
          setOAuthShouldFetchAccessToken={setOAuthShouldFetchAccessToken}
          oAuthAuthorizeURLParamKeysToExclude={oAuthAuthorizeURLParamKeysToExclude}
          setOAuthAuthorizeURLParamKeysToExclude={setOAuthAuthorizeURLParamKeysToExclude}
          hasPermissionToEdit={hasPermissionToEdit}
          integrationId={integrationId}
        />
      )}
      <AccordionCardForSection
        dataTestID="accordion-auth-config-token-configuration"
        title="Token configuration"
        defaultExpanded
        hasPermissionToEdit={hasPermissionToEdit}
      >
        <TokenURLRequestCard
          tokenRequestURL={tokenRequestURL}
          setTokenRequestURL={setTokenRequestURL}
          tokenURLRequestHeaders={tokenURLRequestHeaders}
          setTokenURLRequestHeaders={setTokenURLRequestHeaders}
          requestParamType={requestParamType}
          setRequestParamType={setRequestParamType}
        />
        <TokenURLAdvanced
          oAuthTokenURLHeaderKeysToExclude={oAuthTokenURLHeaderKeysToExclude}
          setOAuthTokenURLHeaderKeysToExclude={setOAuthTokenURLHeaderKeysToExclude}
          tokenURLRequestParams={tokenURLRequestParams}
          setTokenURLRequestParams={setTokenURLRequestParams}
          oAuthResponseTokenKeyPath={oAuthResponseTokenKeyPath}
          setOAuthResponseTokenKeyPath={setOAuthResponseTokenKeyPath}
        />
      </AccordionCardForSection>
      <RefreshConfigurationCard
        doesResponseIncludeExpiresInParam={doesResponseIncludeExpiresInParam}
        setDoesResponseIncludeExpiresInParam={setDoesResponseIncludeExpiresInParam}
        tokenExpiresInType={tokenExpiresInType}
        setTokenExpiresInType={setTokenExpiresInType}
        oAuthResponseCreatedAtKeyPath={oAuthResponseCreatedAtKeyPath}
        setOAuthResponseCreatedAtKeyPath={setOAuthResponseCreatedAtKeyPath}
        dateTimeFormat={dateTimeFormat}
        setDateTimeFormat={setDateTimeFormat}
        oAuthResponseTokenLifespanInSeconds={oAuthResponseTokenLifespanInSeconds}
        setOAuthResponseTokenLifespanInSeconds={setOAuthResponseTokenLifespanInSeconds}
        shouldRefreshAfterInitialLink={shouldRefreshAfterInitialLink}
        setShouldRefreshAfterInitialLink={setShouldRefreshAfterInitialLink}
        shouldUseTokenURLForRefresh={shouldUseTokenURLForRefresh}
        setShouldUseTokenURLForRefresh={setShouldUseTokenURLForRefresh}
        doesRefreshIncludeExpiresIn={doesRefreshIncludeExpiresIn}
        setDoesRefreshIncludeExpiresIn={setDoesRefreshIncludeExpiresIn}
        oAuthRefreshURL={oAuthRefreshURL}
        setOAuthRefreshURL={setOAuthRefreshURL}
        oAuthRefreshTokenLifespanInSeconds={oAuthRefreshTokenLifespanInSeconds}
        setOAuthRefreshTokenLifespanInSeconds={setOAuthRefreshTokenLifespanInSeconds}
        refreshURLFieldsToExclude={refreshURLFieldsToExclude}
        setRefreshURLFieldsToExclude={setRefreshURLFieldsToExclude}
        hasPermissionToEdit={hasPermissionToEdit}
      />
    </>
  );
};

export default OAuthGrantPage;
