import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelAddAvailableCustomFieldForm = () => {
  return (
    <form>
      <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
      <TypeaheadFormField
        title={"Name"}
        subtitle={
          "The name of the custom field, to be displayed in the linking flow. Note - not saved on the mapping itself."
        }
        includeUniqueIdentifierCheck={false}
        valueKey={"name"}
        parameterType={"array"}
      />
      <TypeaheadFormField
        title={"API Endpoint ID"}
        subtitle={
          "The ID of the API Endpoint which returns these custom fields in remote data. NOTE: This is not the api endpoint used to pull custom field metadata."
        }
        includeUniqueIdentifierCheck={false}
        valueKey={"api_endpoint_id"}
        parameterType={"string"}
      />
      <>
        <TypeaheadFormField
          title={"Custom Field Key Traversal Path"}
          subtitle={
            "The full traversal path of the custom field value, starting from the root of the remote data of the associated common model object."
          }
          includeUniqueIdentifierCheck={false}
          valueKey={"custom_field_key_traversal_path"}
          parameterType={"array"}
        />
      </>
    </form>
  );
};

export default BlueprintEditorRightPanelAddAvailableCustomFieldForm;
