import styled from "styled-components";
import { fetchWithAuth } from "../../api-client/api_client";
import { FIELD_NAME_TO_WARNING_TEXT, IntegrationChecklistData } from "../../constants";
import MergeModal from "../shared/MergeModal";

type Props = {
  show: boolean;
  onHide: () => void;
  onShow?: () => void;
  fieldName: keyof IntegrationChecklistData;
  integrationID: string;
};

const FlexStackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding-right: 24px;
  padding-left: 24px;
`;

const Text = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  text-align: left;
`;

const ConfirmButton = styled.button`
  display: flex;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: var(--Black, #000);
  color: white;
  font-size: 16px;
  cursor: pointer;
`;

const CancelButton = styled.div`
  margin-top: 16px;
  cursor: pointer;
  font-size: 16px;
`;

const MarkFieldUnsupportedModal = ({ show, onHide, fieldName, integrationID }: Props) => {
  const refreshPage = () => {
    window.location.reload();
  };

  const onResponse = () => {
    onHide();
    refreshPage();
  };

  const markAsUnsupported = () => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/integration-checklist/mark-unsupported`,
      method: "POST",
      body: {
        field_name: fieldName,
      },
      onResponse,
      onError: () => {},
    });
  };

  const modalBody = (
    <FlexStackContainer>
      <Text>
        Are you sure you want to mark <b>{FIELD_NAME_TO_WARNING_TEXT[fieldName]} </b> as
        unsupported?
      </Text>
      <ConfirmButton onClick={() => markAsUnsupported()}>Confirm</ConfirmButton>
      <CancelButton onClick={onHide}>Cancel</CancelButton>
    </FlexStackContainer>
  );

  return (
    <MergeModal
      dialogClassName="mark-unsupported-modal-width"
      title="Mark as unsupported"
      show={show}
      onHide={onHide}
      children={modalBody}
    />
  );
};
export default MarkFieldUnsupportedModal;
