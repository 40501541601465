import { useEffect, useState } from "react";
import {
  WebAddressSchemaIntegrationBuilder,
  WebAddressSchemaIntegrationBuilderBase,
} from "../../utils/Entities";
import EnterURL from "./EnterURL";
import SchemaConfiguration from "./SchemaConfiguration";
import { parseUrl } from "../utils/helpers";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import useCreateOrPatchWebAddressSchema from "../hooks/useCreateOrPatchWebAddressSchema";
import useDeleteWebAddressSchemas from "../hooks/useDeleteWebAddressSchema";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";
import EditorLeavingGuard from "../../../shared/unsaved-changes/EditorLeavingGuard";

const WEB_ADDRESS_SCHEMA_DELETE_TEXT = "Delete schema";

interface WebAddressSchemaSetupOptionsProps {
  selectedWebAddressSchema: WebAddressSchemaIntegrationBuilder | undefined;
  integrationID: string;
  setWebAddressSchemas: React.Dispatch<
    React.SetStateAction<WebAddressSchemaIntegrationBuilder[] | undefined>
  >;
  isNewWebAddressSchema: boolean;
}

const WebAddressSchemaSetupOptions = ({
  selectedWebAddressSchema,
  setWebAddressSchemas,
  integrationID,
  isNewWebAddressSchema,
}: WebAddressSchemaSetupOptionsProps) => {
  //state and consts derived from state
  const [scheme, _] = useState<string | undefined>(selectedWebAddressSchema?.scheme ?? "https");
  const [subdomain, setSubdomain] = useState<string | undefined>(
    selectedWebAddressSchema?.subdomain ?? undefined
  );
  const [secondLevelDomain, setSecondLevelDomain] = useState<string | undefined>(
    selectedWebAddressSchema?.second_level_domain ?? undefined
  );
  const [topLevelDomain, setTopLevelDomain] = useState<string | undefined>(
    selectedWebAddressSchema?.top_level_domain ?? undefined
  );
  const [description, setDescription] = useState<string | undefined>(
    selectedWebAddressSchema?.description ?? undefined
  );
  const constructedURL = selectedWebAddressSchema?.subdomain
    ? `${subdomain}.${secondLevelDomain}.${topLevelDomain}`
    : `${secondLevelDomain}.${topLevelDomain}`;
  const validDomain = secondLevelDomain && topLevelDomain; // determines if a url passed in exists
  const [fullURL, setFullURL] = useState<string | undefined>(
    validDomain ? constructedURL : undefined
  );
  const [sourceOfChange, setSourceOfChange] = useState<"fullURL" | "parts">("fullURL"); // state to determine where source of change is coming from

  // hooks

  // set states for subdomain, second level domain, and top level domain if fullURL is changed
  useEffect(() => {
    if (sourceOfChange !== "parts") {
      const { subdomain, secondLevelDomain, topLevelDomain } = parseUrl(fullURL);
      setSubdomain(subdomain || "");
      setSecondLevelDomain(secondLevelDomain || "");
      setTopLevelDomain(topLevelDomain || "");
    }
  }, [fullURL]);

  // set states for fullURL if subdomain, second level domain, or top level domain is changed
  useEffect(() => {
    if (sourceOfChange !== "fullURL") {
      const updatedConstructedURL = [subdomain, secondLevelDomain, topLevelDomain]
        .filter(Boolean)
        .join(".");
      setFullURL(updatedConstructedURL);
    }
  }, [subdomain, secondLevelDomain, topLevelDomain]);

  const {
    createWebAddressSchema,
    patchWebAddressSchema,
    isLoading,
  } = useCreateOrPatchWebAddressSchema({
    integrationID: integrationID,
    setWebAddressSchemas: setWebAddressSchemas,
    webAddressSchemaID: selectedWebAddressSchema?.id,
    scheme: scheme,
    subdomain: subdomain,
    secondLevelDomain: secondLevelDomain,
    topLevelDomain: topLevelDomain,
    description: description,
  });
  const { deleteWebAddressSchema, isLoadingDeleteWebAddressSchema } = useDeleteWebAddressSchemas({
    integrationID: integrationID,
    setWebAddressSchemas: setWebAddressSchemas,
    webAddressSchemaID: selectedWebAddressSchema?.id,
  });

  // context
  const webAddressSchemaOnSubmit = () => {
    isNewWebAddressSchema ? createWebAddressSchema() : patchWebAddressSchema();
  };

  // consts from derived state
  const webAddressSchemaCanSubmit = !!secondLevelDomain && !!topLevelDomain;
  const WEB_ADDRESS_SCHEMA_SUBMIT_TEXT = isNewWebAddressSchema ? "Create schema" : "Save schema";

  // context
  const {
    setOnSubmit,
    setOnDelete,
    setCurrentStateForDiff,
    setNewStateForDiff,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();
  useIntegrationBuilderContext({
    submitButtonText: WEB_ADDRESS_SCHEMA_SUBMIT_TEXT,
    canSubmit: webAddressSchemaCanSubmit,
    isLoadingSubmit: isLoading,
    deleteButtonText: WEB_ADDRESS_SCHEMA_DELETE_TEXT,
    isLoadingDelete: isLoadingDeleteWebAddressSchema,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: false,
    shouldRenderDeleteButton: !isNewWebAddressSchema,
    modelTypeForDiff: DiffModelTypeEnum.WEB_ADDRESS_SCHEMA,
    shouldHideDiffModal: isNewWebAddressSchema,
  });

  useEffect(() => {
    setOnSubmit(webAddressSchemaOnSubmit);
  }, [setOnSubmit, scheme, subdomain, secondLevelDomain, topLevelDomain, description]);

  useEffect(() => {
    setOnDelete(deleteWebAddressSchema);
  }, [setOnDelete, selectedWebAddressSchema]);

  /* This useEffect initializes the model state to be used in the diff modal.
   * When adding new fields/removing them, you'll have to add them to multiple places
   * to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    let webAddressSchemaBody: WebAddressSchemaIntegrationBuilderBase = {
      scheme: "https",
      subdomain: undefined,
      second_level_domain: "",
      top_level_domain: "",
      description: undefined,
    };
    if (selectedWebAddressSchema) {
      webAddressSchemaBody = {
        scheme: selectedWebAddressSchema.scheme ?? "https",
        subdomain: selectedWebAddressSchema.subdomain,
        second_level_domain: selectedWebAddressSchema.second_level_domain,
        top_level_domain: selectedWebAddressSchema.top_level_domain,
        description: selectedWebAddressSchema.description,
      };
    }
    setCurrentStateForDiff(webAddressSchemaBody);
    setNewStateForDiff(webAddressSchemaBody);
  }, [selectedWebAddressSchema]);

  useEffect(() => {
    setNewStateForDiff({
      scheme: scheme,
      subdomain: subdomain,
      second_level_domain: secondLevelDomain,
      top_level_domain: topLevelDomain,
      description: description,
    });
  }, [scheme, subdomain, secondLevelDomain, topLevelDomain, description]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div>
        <EnterURL
          selectedWebAddressSchema={selectedWebAddressSchema}
          fullURL={fullURL}
          setFullURL={setFullURL}
          setSourceOfChange={setSourceOfChange}
        />
        <SchemaConfiguration
          scheme={scheme}
          subdomain={subdomain}
          setSubdomain={setSubdomain}
          secondLevelDomain={secondLevelDomain}
          setSecondLevelDomain={setSecondLevelDomain}
          topLevelDomain={topLevelDomain}
          setTopLevelDomain={setTopLevelDomain}
          description={description}
          setDescription={setDescription}
          setSourceOfChange={setSourceOfChange}
        />
      </div>
    </EditorLeavingGuard>
  );
};

export default WebAddressSchemaSetupOptions;
