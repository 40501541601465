import { BlueprintStepTemplate } from "../../../models/Blueprints";
import CreateOrUpdateForm from "./CreateOrUpdateForm";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelCreateOrUpdateForm = ({ stepID, stepTemplate }: Props) => {
  return <CreateOrUpdateForm stepID={stepID} stepTemplate={stepTemplate} />;
};

export default BlueprintEditorRightPanelCreateOrUpdateForm;
