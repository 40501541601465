import { Alert } from "@merge-api/merge-javascript-shared";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { APIEndpointParameter } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import { UserFacingFilterDetails } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { RequestLocation } from "../../../../../autogenerated-types/AUTOGENERATED_NativePydantic_APIEndpointParameter";
import { APIEndpoint } from "../../../../integrations/versioned-components/types";
import {
  ComponentState,
  NextVersionState,
  VersionedComponentInfo,
} from "../../../../versioned-components/types";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import useSelectiveSyncFilterBuilderContext from "../../context/useSelectiveSyncFilterBuilderContext";
import { FilterLocationEnum } from "../../types";
import { shallowEqual } from "../../utils";
import APIEndpointsTypeahead from "./APIEndpointsTypeahead";
import useCreateOrUpdateAPIEndpointParameter from "./useCreateOrUpdateAPIEndpointParameter";

interface Props {
  userFacingDetails: UserFacingFilterDetails;
  apiEndpointParameter: APIEndpointParameter;
  isEditingExistingAPIEndpointParameter: boolean;
  setOnSubmit: React.Dispatch<React.SetStateAction<() => void>>;
  setIsModalOpen: (value: boolean) => void;
  addOrUpdateAPIEndpointParameter: (
    newAPIEndpointParameter: VersionedComponentInfo<APIEndpointParameter>
  ) => void;
}

const APIEndpointParameterForm = ({
  apiEndpointParameter,
  setOnSubmit,
  isEditingExistingAPIEndpointParameter,
  setIsModalOpen,
  addOrUpdateAPIEndpointParameter,
}: Props) => {
  const { integrationID } = useSelectiveSyncFilterBuilderContext();
  const apiEndpointParameterUnderConstruction: APIEndpointParameter = {
    ...apiEndpointParameter,
  };

  const {
    patchAPIEndpointParameter,
    createAndStageAPIEndpointParameter,
  } = useCreateOrUpdateAPIEndpointParameter();

  const [apiEndpointInfo, setAPIEndpointInfo] = useState<APIEndpoint | null>(
    apiEndpointParameterUnderConstruction?.api_endpoint_id ?? null
  );

  const [filterLocation, setFilterLocation] = useState<string>(
    apiEndpointParameterUnderConstruction?.request_location ?? ""
  );

  const [thirdPartyKeyName, setThirdPartyKeyName] = useState<string | null>(
    apiEndpointParameterUnderConstruction?.key_path?.[0] ?? null
  );

  const originalValues = {
    apiEndpointInfo,
    filterLocation,
    thirdPartyKeyName,
  };

  const hasChanges = () =>
    !shallowEqual(originalValues, {
      apiEndpointInfo: apiEndpointParameter?.api_endpoint_id,
      filterLocation: apiEndpointParameter?.request_location,
      thirdPartyKeyName: apiEndpointParameter?.key_path?.[0],
    });

  // This useEffect listens for any changes we make to the editable
  // api endpoint filter fields, and makes sure that the save button in
  // our modal will submit the correct information;
  const saveAPIEndpointParameterOnResponse = (madePatchRequest: boolean, id: string, data: any) => {
    const responseKey = isEditingExistingAPIEndpointParameter ? id : "component";

    if (madePatchRequest) {
      const versionedComponentInfo = data?.[responseKey];

      addOrUpdateAPIEndpointParameter(
        versionedComponentInfo as VersionedComponentInfo<APIEndpointParameter>
      );
    } else {
      const apiEndpointFilterResponse = data?.[responseKey];
      const versionedComponentInfo = {
        next_version: apiEndpointFilterResponse,
        next_version_state: NextVersionState.STAGED_WITH_CHANGES,
        published_version: null,
        component_state: ComponentState.NEW_COMPONENT_WITH_STAGED_VERSION,
      };

      addOrUpdateAPIEndpointParameter(
        versionedComponentInfo as VersionedComponentInfo<APIEndpointParameter>
      );
    }

    setIsModalOpen(false);
  };

  const saveAPIEndpointParameter = () => {
    const id = apiEndpointParameterUnderConstruction?.id
      ? apiEndpointParameterUnderConstruction?.id
      : uuidv4();

    const apiEndpointFilterPayload: APIEndpointParameter = {
      ...apiEndpointParameterUnderConstruction,
      id,
      request_location: filterLocation as RequestLocation,
      key_path: [thirdPartyKeyName ?? ""],
      api_endpoint_id: apiEndpointInfo as APIEndpoint,
    };

    apiEndpointFilterPayload.id = id;

    const requestPayload = {
      apiEndpointParameter: apiEndpointFilterPayload,
      integrationID,
      onResponse: (data: any) => {
        saveAPIEndpointParameterOnResponse(isEditingExistingAPIEndpointParameter, id, data);
      },
    };

    isEditingExistingAPIEndpointParameter
      ? patchAPIEndpointParameter(requestPayload)
      : createAndStageAPIEndpointParameter(requestPayload);
  };

  useEffect(() => {
    setOnSubmit(() => saveAPIEndpointParameter);
  }, [filterLocation, thirdPartyKeyName, apiEndpointInfo]);

  return (
    <div className="flex flex-col">
      {isEditingExistingAPIEndpointParameter && hasChanges() && (
        <Alert showWarningIcon={true} color="red" className="mb-6">
          Any changes made here will be reflected across all instances of this third-party API
          endpoint parameter
        </Alert>
      )}
      <APIEndpointsTypeahead
        className="mb-4"
        integrationID={integrationID}
        apiEndpointInfo={apiEndpointInfo}
        setAPIEndpointInfo={setAPIEndpointInfo}
      />
      <TypeaheadHeader
        className="mb-6"
        title="Filter Location"
        subtitle="This specifies where in our API requests we will be adding the parameter, e.g. as a query parameter, or a request body value, etc."
        value={filterLocation}
        options={Object.values(FilterLocationEnum).map((val) => val)}
        onChange={(_: any, location: any) => {
          setFilterLocation(location);
        }}
      />
      <TextFieldHeader
        className="mb-6"
        title="Third-party keyname"
        subtitle="The key name that will be used in the request to the third-party"
        value={thirdPartyKeyName ?? ""}
        onChange={(e) => setThirdPartyKeyName(e.target.value)}
      />
    </div>
  );
};
export default APIEndpointParameterForm;
