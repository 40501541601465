import React, { useEffect, useState, Suspense } from "react";
import { Button, Form } from "react-bootstrap";
import Editor from "react-simple-code-editor";
import {
  BlueprintCustomFunctionStep,
  BlueprintParameterValueConstant,
  BlueprintParameterValueReturnValue,
  BlueprintParameterValue,
  BlueprintParameterValueNestedParameterValues,
  BlueprintParameterValueType,
} from "../../../../models/Blueprints";
import useBlueprintContext from "../../context/useBlueprintContext";
import { BlueprintAvailableParameter } from "../../utils/BlueprintEditorUtils";
import max from "lodash/max";
import CustomFunctionTypeaheadFormField from "./CustomFunctionTypeaheadFormField";
import { HeaderPretitle } from "../../../shared/text/MergeText";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";

const { highlight, languages } = require("prismjs");

const LazyCodeBlock = React.lazy(() => import("./CustomFunctionsModal"));

const BlueprintEditorRightPanelCustomFunctionForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintCustomFunctionStep;
  const args = (step.parameter_values.arguments as BlueprintParameterValueNestedParameterValues)
    .nested_parameter_values;
  const code = (step.parameter_values.code as BlueprintParameterValueConstant).constant as string;
  const [unsavedCustomFunctionCode, setUnsavedCustomFunctionCode] = useState(code);
  const [isShowingCustomFunctionsModal, setIsShowingCustomFunctionsModal] = useState<boolean>(
    false
  );

  useEffect(() => {
    setUnsavedCustomFunctionCode(code);
  }, [selectedStep?.id]);

  const maxArgVal = max([...Object.keys(args).map((arg) => Number(arg.slice(1))), 0]) as number;

  const updateArgs = (newArgs: { [key: string]: BlueprintParameterValue | undefined }) =>
    updateStepParameterValue(step, "arguments", {
      nested_parameter_values: newArgs,
      value_type: BlueprintParameterValueType.nestedParameterValues,
    });

  const addArg = () =>
    updateArgs({
      ...args,
      ["x" + (maxArgVal + 1)]: {
        step_id: "",
        return_schema_path: [],
        request_return_value_path: [],
        value_type: BlueprintParameterValueType.returnValue,
      },
    });

  const updateExistingArg = (key: string, newValue: BlueprintParameterValueReturnValue | null) => {
    updateArgs({ ...args, [key]: newValue ?? undefined });
  };

  const deleteArg = (key: string) => {
    const { [key]: _, ...newArgs } = args;
    updateArgs(newArgs);
  };

  return (
    <>
      <Suspense fallback={<div />}>
        <LazyCodeBlock
          isShowingCustomFunctionsModal={isShowingCustomFunctionsModal}
          setIsShowingCustomFunctionsModal={setIsShowingCustomFunctionsModal}
        />
      </Suspense>

      <Form>
        <HeaderPretitle className="mt-3">Arguments</HeaderPretitle>
        {Object.entries(args).map(([key, value]) => (
          <div key={key}>
            <CustomFunctionTypeaheadFormField
              onChange={(options: BlueprintAvailableParameter[]) =>
                updateExistingArg(
                  key,
                  options[0]?.parameterValue as BlueprintParameterValueReturnValue | null
                )
              }
              currentParameterValue={value}
              onDelete={() => deleteArg(key)}
              title={key}
              subtitle={"Set the value for the argument."}
            />
          </div>
        ))}

        <Button className="mt-0 mb-3 btn-block" onClick={addArg}>
          Add New Argument
        </Button>
        <hr />
        <HeaderPretitle className="mt-9">Code</HeaderPretitle>
        <DeprecatedH4>
          {"def custom_function(" + Object.keys(args).join(",") + "): \n"}
        </DeprecatedH4>

        <Form.Text className="text-muted">Only Python will work for custom functions.</Form.Text>
        <Editor
          highlight={(code) => highlight(code, languages.markup, "markup")}
          value={unsavedCustomFunctionCode}
          onBlur={() => {
            updateStepParameterValue(step, "code", {
              constant: unsavedCustomFunctionCode,
              value_type: BlueprintParameterValueType.constant,
            });
          }}
          onValueChange={(newCode) => {
            setUnsavedCustomFunctionCode(newCode);
          }}
          padding={10}
          style={{
            border: "1px solid #d2ddec",
            borderRadius: 8,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            minHeight: "100px",
            overflow: "auto",
          }}
        />
        <Button
          variant="white"
          size="sm"
          className="btn-block mt-9"
          onClick={() => setIsShowingCustomFunctionsModal(true)}
        >
          View Custom Functions <span className="fe fe-maximize-2 ml-1.5" />
        </Button>
      </Form>
    </>
  );
};

export default BlueprintEditorRightPanelCustomFunctionForm;
