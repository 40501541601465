import {
  AddStepRelation,
  Blueprint,
  BlueprintGhostStepType,
  BlueprintIfElseStep,
  BlueprintStep,
  BlueprintStepOrGhostStepOrTriggerOrScraper,
  BlueprintStepPath,
  BlueprintStepType,
  BlueprintTrigger,
  LEFT_ALIGN_COOKIE_KEY,
} from "../../../models/Blueprints";
import {
  TraversalPath,
  getParentStepForSubstep,
  getPredecessorStepForStep,
  getStepForStepID,
  getAllStepIDsInBranch,
  getStepTraversalPathForStepID,
  isStepTypeWithPaths,
} from "./BlueprintEditorUtils";
import isEmpty from "lodash/isEmpty";
import {
  ScraperVersion,
  ScraperGhostStep,
  ScraperStep,
  ScraperStepPath,
  ScraperStepType,
} from "../../scraper/types";
import { CanvasConfigurationBase, CanvasStepPlacement } from "../../scraper/utils/ScraperUtils";
import Cookies from "universal-cookie";

const cookies = new Cookies();
var shouldLeftAlign = cookies.get(LEFT_ALIGN_COOKIE_KEY) ?? false;

// false if any path of a given step is a non-empty array, true otherwise
export const areAllStepPathsEmpty = (step: BlueprintStep | ScraperStep): boolean =>
  Object.values(step?.paths ?? []).every((path) => isEmpty(path));

// for a given step, finds the path in paths with the longest length, factoring in subpaths inside paths.
// used to determine the YIndex for the successor step.
export const getMaxPathYIndexHeightForSubstepsOfStep = (
  step: BlueprintStep | ScraperStep
): number => {
  if (!isStepTypeWithPaths(step) || step.hasCollapsedSubsteps) {
    // no substeps possible
    return 0;
  } else if (areAllStepPathsEmpty(step)) {
    // account for ghost step.
    return 1;
  } else {
    // find the max of the heights of each path of step
    if (shouldLeftAlign) {
      return Object.values(step?.paths ?? [])
        .map((path) => getYIndexHeightForPath(path))
        .reduce((a: number, b: number) => a + b, 0);
    }
    return Math.max(
      ...Object.values(step?.paths ?? []).map((path) => getYIndexHeightForPath(path))
    );
  }
};

// Finds the total height of a path and all its descendants by summing the heights of each step's subtrees
// (plus the steps themselves) - each element in the array must appear below all descendants of previous steps in the array
export const getYIndexHeightForPath = (path: BlueprintStepPath): number => {
  return Math.max(
    path
      .map((step) => getMaxPathYIndexHeightForSubstepsOfStep(step))
      .reduce((a: number, b: number) => a + b + 1, 0),
    1
  );
};

// Returns the y index of a given step, i.e. what level (where the first step is at level 0) the step should
// show up at.
export const getYIndexForStep = (
  blueprintOrScraper: Blueprint | ScraperVersion,
  step: BlueprintStep | ScraperStep
): number => {
  const stepID = step.id;
  if (blueprintOrScraper.steps?.[0].id === stepID) {
    return 0;
  } else {
    const path = getStepTraversalPathForStepID(blueprintOrScraper, stepID) as TraversalPath;

    if (!path) {
      return 0;
    }
    const childIndex = path[path.length - 1] as number;

    if (childIndex === 0) {
      // first step in substep array, should be immediately after its parent
      const parentStep = getParentStepForSubstep(blueprintOrScraper, step) as BlueprintStep;

      if (shouldLeftAlign) {
        if ("template" in parentStep && parentStep.template.step_type == BlueprintStepType.IfElse) {
          const ifElseStep = parentStep as BlueprintIfElseStep;
          var trueCount = 0;
          // var falseCount = 0;
          var isFalsePath = false;
          Object.entries(ifElseStep.paths ?? []).forEach(([pathKey, path]) => {
            if (pathKey === "true") {
              trueCount = path.length;
            } else if (pathKey === "false") {
              if (path.includes(step as BlueprintStep)) {
                isFalsePath = true;
              }
            }
          });

          // False path
          if (isFalsePath) {
            return getYIndexForStep(blueprintOrScraper, parentStep) + trueCount + 1;
          }
        }
      }

      return getYIndexForStep(blueprintOrScraper, parentStep) + 1;
    } else {
      //should be immediately after all branches of the predecessor step in an array conclude
      const predecessorStep = getPredecessorStepForStep(blueprintOrScraper, step) as BlueprintStep;
      return (
        getYIndexForStep(blueprintOrScraper, predecessorStep) +
        getMaxPathYIndexHeightForSubstepsOfStep(predecessorStep) +
        1
      );
    }
  }
};

// Returns the x index offset of a given step relative to its parent, which is entirely determined by
// the parent step type and the child step path key.
export const getParentXOffsetForPathKey = (
  parentStep: BlueprintStep | ScraperStep,
  pathKey: string
): number => {
  if (parentStep.hasCollapsedSubsteps) {
    return 0;
  }
  if ("template" in parentStep) {
    switch (parentStep.template.step_type) {
      case BlueprintStepType.ArrayLoop:
      case BlueprintStepType.WhileLoop:
      case BlueprintStepType.BatchAPIRespnse:
      case BlueprintStepType.APIRequestLoop:
      case BlueprintStepType.DateRangeLoop:
      case BlueprintStepType.CommonModelLoop:
      case BlueprintStepType.ConcurrentRequestLoop:
      case BlueprintStepType.TraverseTree:
        // loop will show up to the right of the parent step
        if (shouldLeftAlign) {
          return 1.5;
        }
        return 1;
      case BlueprintStepType.IfElse:
        // TODO - an actual spacing algorithmm
        // true statements to the left, false to the right
        if (shouldLeftAlign) {
          return 1.5;
        }
        return pathKey === "true" ? -2 : 2;

      case BlueprintStepType.Switch:
        // display branches in sorted key order
        const sortedKeys = Object.keys(parentStep.paths ?? {}).sort();
        const pos = sortedKeys.indexOf(pathKey);
        const numKeys = sortedKeys.length;
        return (pos - Math.floor(numKeys / 2)) * 2;
      default:
        return 0;
    }
  } else {
    switch (parentStep.step_type) {
      case ScraperStepType.IF_ELSE:
        // TODO - an actual spacing algorithm
        // true statements to the left, false to the right
        if (shouldLeftAlign) {
          return 1;
        }
        return pathKey === "true" ? -2 : 2;
      case ScraperStepType.CONDITIONAL_ACTION:
      case ScraperStepType.GET_USER_INPUT:
      case ScraperStepType.QUERY_SELECTOR_LOOP:
      case ScraperStepType.ARRAY_LOOP:
      case ScraperStepType.EXPECT_DOWNLOAD_CSV:
        return 1;
      default:
        return 0;
    }
  }
};

// Returns the x index of a given step, i.e. what column (where the first step is at column 0) the step should
// show up at. Steps in the same array/path should be in the same column.
export const getXIndexForStep = (
  blueprintOrScraper: Blueprint | ScraperVersion,
  step: BlueprintStep | ScraperStep
): number => {
  let currentIndex = 0;
  let currentObject: any = blueprintOrScraper;
  const path = getStepTraversalPathForStepID(
    blueprintOrScraper as Blueprint,
    step.id
  ) as TraversalPath;

  path.forEach((p, index) => {
    if (p === "paths") {
      const step = currentObject as BlueprintStep | ScraperStep;
      const pathKey = path[index + 1] as string;
      currentIndex += getParentXOffsetForPathKey(step, pathKey);
    }
    currentObject = currentObject[p];
  });
  return currentIndex;
};

export const getGhostStepID = (
  relatedStepID: string,
  newStepRelation: AddStepRelation,
  pathKey?: string
): string => relatedStepID + ".ghost." + newStepRelation + (pathKey ? "." + pathKey : "");

export const getGhostStep = (
  relatedStepID: string,
  newStepRelation: AddStepRelation,
  pathKey?: string
): BlueprintGhostStepType => ({
  id: getGhostStepID(relatedStepID, newStepRelation, pathKey),
  template: "ghost",
  newStepRelation,
  relatedStepID,
  pathKey,
});

export enum StepPlacementType {
  EXISTING = "EXISTING",
  GHOST = "GHOST",
  TRIGGER = "TRIGGER",
  SCRAPER = "SCRAPER",
}

export interface BlueprintCanvasStepPlacementBase {
  xIndex: number;
  yIndex: number;
  id?: string;
}

export interface BlueprintCanvasExistingStepPlacement extends BlueprintCanvasStepPlacementBase {
  step: BlueprintStep;
  stepPlacementType: StepPlacementType.EXISTING;
}

export interface BlueprintCanvasGhostStepPlacement extends BlueprintCanvasStepPlacementBase {
  step: BlueprintGhostStepType;
  stepPlacementType: StepPlacementType.GHOST;
}
export interface BlueprintCanvasTriggerStepPlacement extends BlueprintCanvasStepPlacementBase {
  trigger: BlueprintTrigger;
  stepPlacementType: StepPlacementType.TRIGGER;
}

export interface BlueprintCanvasScraperStepPlacement extends BlueprintCanvasStepPlacementBase {
  scraper: ScraperVersion;
  stepPlacementType: StepPlacementType.SCRAPER;
}

export type BlueprintCanvasStepPlacement =
  | BlueprintCanvasExistingStepPlacement
  | BlueprintCanvasGhostStepPlacement
  | BlueprintCanvasTriggerStepPlacement
  | BlueprintCanvasScraperStepPlacement;

export type BlueprintCanvasConfiguration = {
  totalX: number;
  stepPlacements: { [stepID: string]: BlueprintCanvasStepPlacement };
  nodeIDtoParentNodeIDMap: { [stepID: string]: string | undefined };
  nodeIDtoPredecessorNodeIDMap: { [key: string]: string | undefined };
};

export const getBlueprintCanvasConfiguration = (
  blueprint: Blueprint,
  selectedStep: undefined | BlueprintStepOrGhostStepOrTriggerOrScraper,
  blueprintTrigger: undefined | BlueprintTrigger
): BlueprintCanvasConfiguration =>
  getCanvasConfigurationBase(
    blueprint,
    selectedStep,
    blueprintTrigger
  ) as BlueprintCanvasConfiguration;

// Returns the X,Y indices of all steps, as well as the X,Y dimensions of the canvas.
export const getCanvasConfigurationBase = (
  blueprintOrScraper: Blueprint | ScraperVersion,
  selectedStep:
    | undefined
    | BlueprintStepOrGhostStepOrTriggerOrScraper
    | ScraperStep
    | ScraperGhostStep,
  blueprintTrigger: undefined | BlueprintTrigger
): BlueprintCanvasConfiguration | CanvasConfigurationBase => {
  let minX = 0;
  let maxX = 0;
  let maxY = 0;

  const nodeIDtoParentNodeIDMap: { [key: string]: string | undefined } = {};
  const nodeIDtoPredecessorNodeIDMap: { [key: string]: string | undefined } = {};
  const stepPlacements: {
    [stepID: string]: BlueprintCanvasStepPlacement | CanvasStepPlacement;
  } = {};
  let ghostYIndex: number | null = null;
  shouldLeftAlign = cookies.get(LEFT_ALIGN_COOKIE_KEY) ?? false;
  if (
    selectedStep &&
    "template" in selectedStep &&
    selectedStep.template === "ghost" &&
    [AddStepRelation.SIBLING_AFTER, AddStepRelation.SIBLING_BEFORE].includes(
      selectedStep.newStepRelation
    )
  ) {
    const relatedStep = getStepForStepID(
      blueprintOrScraper,
      selectedStep.relatedStepID
    ) as BlueprintStep;
    const relatedStepPath =
      getStepTraversalPathForStepID(blueprintOrScraper, selectedStep.relatedStepID) ?? [];
    const ghostArrayIndex =
      (relatedStepPath[relatedStepPath.length - 1] as number) +
      (selectedStep.newStepRelation === AddStepRelation.SIBLING_AFTER ? 1 : 0);

    if (ghostArrayIndex === 0) {
      if (relatedStepPath[relatedStepPath.length - 2] === "steps") {
        ghostYIndex = 0;
      } else {
        ghostYIndex =
          getYIndexForStep(
            blueprintOrScraper,
            getParentStepForSubstep(blueprintOrScraper, relatedStep) as BlueprintStep
          ) + 1;
      }
    } else {
      const predecessorStep =
        selectedStep.newStepRelation === AddStepRelation.SIBLING_BEFORE
          ? (getPredecessorStepForStep(blueprintOrScraper, relatedStep) as BlueprintStep)
          : relatedStep;
      ghostYIndex =
        getYIndexForStep(blueprintOrScraper, predecessorStep) +
        getMaxPathYIndexHeightForSubstepsOfStep(predecessorStep) +
        1;
    }

    stepPlacements[selectedStep.id] = {
      step: selectedStep,
      stepPlacementType: StepPlacementType.GHOST,
      xIndex: getXIndexForStep(blueprintOrScraper, relatedStep),
      yIndex: ghostYIndex,
    };
  }

  const shiftNodeChildren = (path: BlueprintStepPath | ScraperStepPath, delta: number) => {
    path.forEach((step: BlueprintStep | ScraperStep) => {
      // Increment xIndex of each step
      stepPlacements[step.id].xIndex += delta;
      Object.entries(step.paths ?? []).forEach(([pathKey, path]) => {
        // Base case, we hit an empty path and shift ghost step child
        if (path.length === 0) {
          const ghostStepID = getGhostStepID(step.id, AddStepRelation.CHILD, pathKey);
          if (ghostStepID in stepPlacements) stepPlacements[ghostStepID].xIndex += delta;
        } else shiftNodeChildren(path ?? [], delta);
      });
    });
  };

  const configurationHelper = (
    path: BlueprintStepPath | ScraperStepPath,
    xIndex: number,
    yIndex: number,
    parentNodeID?: string
  ): { maxYFromPath: number; maxXFromPath: number; minXFromPath: number } => {
    let maxYFromPath = yIndex;
    let nextYIndex = yIndex;
    let localMaxXFromPath = xIndex;
    let localMinXFromPath = xIndex;
    let previousStep: string | undefined = undefined;
    path.forEach((step: BlueprintStep | ScraperStep) => {
      stepPlacements[step.id] = {
        xIndex,
        yIndex: nextYIndex,
        step,
        stepPlacementType: StepPlacementType.EXISTING,
      } as BlueprintCanvasScraperStepPlacement | BlueprintCanvasStepPlacement;
      minX = Math.min(minX, xIndex);
      maxX = Math.max(maxX, xIndex);
      maxYFromPath = Math.max(maxYFromPath, nextYIndex);
      nodeIDtoParentNodeIDMap[step.id] = parentNodeID;
      nodeIDtoPredecessorNodeIDMap[step.id] = previousStep;

      if (step.hasCollapsedSubsteps) {
        // all descendants should have same step placements as parent
        const descendants = getAllStepIDsInBranch(step as BlueprintStep);
        descendants.forEach((stepId: string) => {
          stepPlacements[stepId] = {
            xIndex,
            yIndex: nextYIndex,
            step,
            stepPlacementType: StepPlacementType.EXISTING,
          } as BlueprintCanvasScraperStepPlacement | BlueprintCanvasStepPlacement;
        });
        nextYIndex += 1;
        return;
      }

      let leftChildMaxX: number | null = null;
      let rightChildMinX: number | null = null;

      Object.entries(step.paths ?? []).forEach(([pathKey, path]) => {
        const pathXOffset = getParentXOffsetForPathKey(step, pathKey);
        if (path.length === 0) {
          const ghostXIndex = xIndex + pathXOffset;
          const ghostYIndex = nextYIndex + 1;
          const ghostStepID = getGhostStepID(step.id, AddStepRelation.CHILD, pathKey);
          stepPlacements[ghostStepID] = {
            step: getGhostStep(step.id, AddStepRelation.CHILD, pathKey),
            stepPlacementType: StepPlacementType.GHOST,
            xIndex: ghostXIndex,
            yIndex: shouldLeftAlign
              ? pathKey === "true"
                ? ghostYIndex
                : ghostYIndex + 1
              : ghostYIndex,
          };
          minX = Math.min(minX, ghostXIndex);
          maxX = Math.max(maxX, ghostXIndex);

          if (pathXOffset < 0) {
            leftChildMaxX = Math.max(ghostXIndex, pathXOffset);
          }

          if (pathXOffset > 0) {
            rightChildMinX = Math.min(ghostXIndex, pathXOffset);
          }

          localMinXFromPath = Math.min(localMinXFromPath, ghostXIndex);
          localMaxXFromPath = Math.max(localMaxXFromPath, ghostXIndex);
          if (shouldLeftAlign) {
            maxYFromPath = Math.max(
              maxYFromPath,
              ghostYIndex + (Object.entries(step.paths ?? []).length - 1)
            );
          } else {
            maxYFromPath = Math.max(maxYFromPath, ghostYIndex);
          }
        } else {
          var truePathLength = 0;
          if (shouldLeftAlign && pathKey === "false") {
            Object.entries(step.paths ?? []).forEach(([pathKey, path]) => {
              if (pathKey === "true") {
                truePathLength = getYIndexHeightForPath(path);
              }
            });
          }

          const {
            maxYFromPath: pathMaxYIndex,
            maxXFromPath: pathMaxX,
            minXFromPath: pathMinX,
          } = configurationHelper(
            path,
            xIndex + pathXOffset,
            nextYIndex + 1 + truePathLength,
            step.id
          );

          maxYFromPath = Math.max(maxYFromPath, pathMaxYIndex);

          localMinXFromPath = Math.min(localMinXFromPath, pathMinX);
          localMaxXFromPath = Math.max(localMaxXFromPath, pathMaxX);

          // We determine whether a child node (represented by step paths) are a
          // left or right child by the pathXOffset
          if (pathXOffset < 0) {
            leftChildMaxX = Math.max(pathMaxX, pathXOffset);
          }

          if (pathXOffset > 0) {
            rightChildMinX = Math.min(pathMinX, pathXOffset);
          }
        }

        // Once we have recursed through a node's children, we check if there is a left && right child
        // If there is, and the left child would overlap with the right child, we shift the subtree until they don't
        if (leftChildMaxX != null && rightChildMinX != null) {
          if (leftChildMaxX + 1 >= rightChildMinX - 1 && path.length > 0) {
            const delta = Math.abs(leftChildMaxX - rightChildMinX) + 1;
            const weightedDelta = delta * (pathXOffset > 0 ? 1 : -1);

            shiftNodeChildren(path, weightedDelta);

            localMinXFromPath += weightedDelta;
            localMaxXFromPath += weightedDelta;

            rightChildMinX += weightedDelta;
            leftChildMaxX += weightedDelta;
          }
        }
      });

      nextYIndex = maxYFromPath + 1;
      previousStep = step.id;
    });

    maxY = Math.max(maxY, maxYFromPath);

    return { maxYFromPath, maxXFromPath: localMaxXFromPath, minXFromPath: localMinXFromPath };
  };

  configurationHelper(blueprintOrScraper.steps, 0, 0);

  if (isEmpty(blueprintOrScraper.steps)) {
    stepPlacements[getGhostStepID("", AddStepRelation.EMPTY_BLUEPRINT, "")] = {
      step: getGhostStep("", AddStepRelation.EMPTY_BLUEPRINT, ""),
      stepPlacementType: StepPlacementType.GHOST,
      xIndex: 0,
      yIndex: 0,
    };
  }

  // normalize to lowest x index at 0
  Object.keys(stepPlacements).map((key) => {
    if (
      ghostYIndex != null &&
      stepPlacements[key].yIndex >= ghostYIndex &&
      key != selectedStep?.id
    ) {
      stepPlacements[key].yIndex += 1;
    }
  });

  // Shift steps down and insert trigger
  if (blueprintTrigger) {
    const blueprint = blueprintOrScraper as Blueprint;
    Object.keys(stepPlacements).map((key) => {
      stepPlacements[key].yIndex += blueprint.scraper ? 2 : 1;
    });

    stepPlacements["trigger"] = {
      trigger: blueprintTrigger,
      stepPlacementType: StepPlacementType.TRIGGER,
      xIndex: 0,
      yIndex: 0,
    };

    if (blueprint.scraper) {
      stepPlacements["scraper"] = {
        scraper: blueprint.scraper,
        stepPlacementType: StepPlacementType.SCRAPER,
        xIndex: 0,
        yIndex: 1,
      };
    }
  }

  // normalize to lowest x index at 0
  Object.keys(stepPlacements).map((key) => {
    stepPlacements[key].xIndex -= minX;
  });

  return {
    totalX: maxX - minX,
    stepPlacements: stepPlacements as { [stepID: string]: CanvasStepPlacement },
    nodeIDtoParentNodeIDMap,
    nodeIDtoPredecessorNodeIDMap,
  };
};
