export enum ComponentDiffType {
  ADDED = "ADDED",
  DELETED = "DELTED",
  UPDATED = "UPDATED",
}

export type OptionalModelData = null | Record<string, string>;

export type StagedComponent = {
  old_model_data: OptionalModelData;
  new_model_data: OptionalModelData;
  component_id: string;
  component_version_id: string;
  name: string;
  diff_type: ComponentDiffType;
  comment: string | null;
};

export type PublishModuleInfo = {
  has_changes: boolean;
  can_publish: boolean;
  staged_changes: Record<string, StagedComponent[]>;
};

export type BlueprintAdvancedConfigurationsType = {
  reset_timestamps_on_publish: boolean;
};

export type PublishIntegrationVersionRequestBodyType = {
  ticket: string | undefined;
  description: string | undefined;
  blueprint_advanced_configurations:
    | Record<string, BlueprintAdvancedConfigurationsType>
    | undefined;
  override_ticket?: string;
  override_comment?: string;
};

export enum PublishModalScreenType {
  CONTEXT = "CONTEXT",
  CONFIRMATION = "CONFIRMATION",
  CONFIGURATION = "CONFIGURATION",
}

export enum BlueprintValidatorResultOptions {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  FAILURE = "FAILURE",
}

export enum BlueprintValidatorRuleResultOptions {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum BlueprintValidatorRuleResultType {
  WARNING = "WARNING",
  BLOCKER = "BLOCKER",
}

export enum ValidatorRules {
  BPV_RULE_STALE_PARAMETERS = "BPV_RULE_STALE_PARAMETERS",
  BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED = "BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED",
  BPV_RULE_VALID_CUSTOM_FUNCTION_SYNTAX = "BPV_RULE_VALID_CUSTOM_FUNCTION_SYNTAX",
  BPV_RULE_MAPPING_TEST_COVERAGE = "BPV_RULE_MAPPING_TEST_COVERAGE",
  BPV_RULE_PAGINATION_SETUP = "BPV_RULE_PAGINATION_SETUP",
  BPV_RULE_INTEGRATION_CHECKLIST = "BPV_RULE_INTEGRATION_CHECKLIST",
  BPV_RULE_INITIALIZE_WRITTEN_COMMON_MODEL_IN_CREATE_BP = "BPV_RULE_INITIALIZE_WRITTEN_COMMON_MODEL_IN_CREATE_BP",
  BPV_RULE_N_PLUS_ONE_API_REQUEST = "BPV_RULE_N_PLUS_ONE_API_REQUEST",
}

export enum BlueprintValidatorRules {
  BPV_RULE_STALE_PARAMETERS = "Stale parameters",
  BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED = "Unique indentifier",
  BPV_RULE_VALID_CUSTOM_FUNCTION_SYNTAX = "Custom function syntax",
  BPV_RULE_MAPPING_TEST_COVERAGE = "Mapping test coverage",
  BPV_RULE_PAGINATION_SETUP = "Pagination setup",
  BPV_RULE_INTEGRATION_CHECKLIST = "Integration Checklist",
}

export type BlueprintValidatorRuleResult = {
  result: BlueprintValidatorRuleResultOptions;
  result_type: BlueprintValidatorRuleResultType;
  rule: string;
  exception_message: string;
  additional_information: any;
  error_message: string;
};

export type BlueprintValidatorResult = {
  id: string;
  result: BlueprintValidatorResultOptions;
  blocking_rules: any;
  warning_rules: any;
  rule_results: Array<BlueprintValidatorRuleResult>;
};

export enum IntegrationComponentNames {
  MAPPING_TEST = "MappingTest",
  BLUEPRINT = "Blueprint",
  SCRAPER = "Scraper",
  API_ENDPOINT_FILTER = "APIEndpointParameter",
  SELECTIVE_SYNC_FILTER_SCHEMA = "SelectiveSyncFilterSchema",
}

export const mapDisplayIntegrationComponentName = {
  [IntegrationComponentNames.MAPPING_TEST]: "mapping test",
  [IntegrationComponentNames.BLUEPRINT]: "Blueprint",
  [IntegrationComponentNames.SCRAPER]: "Scraper",
  [IntegrationComponentNames.API_ENDPOINT_FILTER]: "API Endpoint Filter",
  [IntegrationComponentNames.SELECTIVE_SYNC_FILTER_SCHEMA]: "Selective Sync Filter Schema",
};

export type BlueprintVersionUniqueIdentifierFormatInformation = {
  unique_identifier_formats_by_blueprint_version_common_model_step_id: {
    // common model ID
    [key: string]: {
      // blueprint version ID
      [key: string]: {
        // step id: [fields used to construct uid]
        [key: string]: string[];
      };
    };
  };
  // Holds the names for the blueprint version IDs
  blueprint_version_id_to_name: { [key: string]: string };
};
