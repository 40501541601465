import { Link } from "@merge-api/merge-javascript-shared";
import HelpAccordionBlock from "../../../shared/HelpAccordionBlock";
import HelpAccordionCard from "../../../shared/HelpAccordionCard";
import BlueprintCardAPIRequestFooterEditableCodeSection from "../../../../shared/blueprint-card/BlueprintCardAPIRequestFooterEditableCodeSection";

interface HelperAccordionProps {
  className?: string;
}

const GenshiTemplatingHelperAccordion = ({ className }: HelperAccordionProps) => {
  const genshiExample = `<APBILLITEMS>
  <APBILLITEM py:for="item in line_items">
      <ACCOUNTNO>\${item["glaccountno"]}</ACCOUNTNO>
      <TRX_AMOUNT>\${item["amount"]}</TRX_AMOUNT>
      <TAXENTRIES py:if="item.get('tax_id')">
          <TAXENTRY>
              <DETAILID>\${item["tax_id"]}</DETAILID>
          </TAXENTRY>
      </TAXENTRIES>
  </APBILLITEM>
</APBILLITEMS>`;

  return (
    <HelpAccordionCard
      title="Additional information for using the Genshi templating engine for generating and processing XML request body formats"
      className={className}
      children={
        <div className="flex flex-col space-y-4">
          <HelpAccordionBlock
            title="What is Genshi?"
            body={
              <div>
                Genshi is a powerful Python-based templating engine designed specifically for
                generating and processing XML documents, including XHTML. It allows you to create
                templates that combine static XML structures with dynamic content generated from
                Python expressions and logic. You can learn more by reading the documentation{" "}
                <Link target="_blank" href="https://genshi.readthedocs.io/en/latest/xml-templates/">
                  here
                </Link>
                .
              </div>
            }
          />
          <HelpAccordionBlock
            title="How Does Genshi Work?"
            body={
              <div>
                Genshi works by combining an XML template with a context object containing your
                data. The template includes placeholders that are replaced with actual values from
                the context during rendering. Additionally, Genshi supports conditional statements
                and loops directly within the XML structure, allowing you to include or exclude
                elements based on your data.
                <br />
                <br />
                For example, you can use <code>py:if</code> and <code>py:for</code> together to
                create a dynamic list of items with optional elements:
                <br />
                <br />
                <BlueprintCardAPIRequestFooterEditableCodeSection
                  body={genshiExample}
                  alwaysEditable={false}
                  editable={false}
                />
                In this example, the <code>py:for</code> loop generates an{" "}
                <code>&lt;APBILLITEM&gt;</code> element for each item in the <code>line_items</code>{" "}
                list. If an item has a <code>tax_id</code>, the <code>&lt;TAXENTRIES&gt;</code>{" "}
                block is included; otherwise, it is omitted.
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default GenshiTemplatingHelperAccordion;
