import { useState, useEffect, useRef, createRef, useMemo } from "react";
import BlueprintStepCard from "./BlueprintStepCard";
import useBlueprintContext from "../context/useBlueprintContext";
import BlueprintGhostStepCard from "./BlueprintGhostStepCard";
import {
  BlueprintCanvasConfiguration,
  getBlueprintCanvasConfiguration,
  getMaxPathYIndexHeightForSubstepsOfStep,
  getXIndexForStep,
  getYIndexForStep,
  StepPlacementType,
} from "../utils/BlueprintCanvasUtils";
import { useWindowSize } from "../../shared/hooks/useWindowSize";
import BlueprintTriggerCard from "./BlueprintTriggerCard";
import BlueprintScraperCard from "./BlueprintScraperCard";
import {
  canStepHaveMoreThanTwoPaths,
  getAllTerminalStepsFromStep,
  getNumberOfPathsForStep,
  getPathKeyForStep,
  getPredecessorStepForStep,
  checkConditionForTraversalPath,
  getEnforceStepTraversalPathForStepID,
  getAllCollapsedStepIDs,
  getParentStepToIDList,
} from "../utils/BlueprintEditorUtils";
import StepNote from "../../../models/StepNote";
import { useCookies } from "react-cookie";
import { LEFT_ALIGN_COOKIE_KEY } from "../../../models/Blueprints";

const CARD_X_STEP = 180;
const CARD_Y_STEP = 150;

const BlueprintCanvas = () => {
  const {
    blueprint,
    blueprintTrigger,
    selectedStep,
    setSelectedStep,
    selectedSteps,
    selectedStepLog,
    setSelectedStepLog,
  } = useBlueprintContext();
  const [canvasConfiguration, setCanvasConfiguration] = useState<BlueprintCanvasConfiguration>(
    getBlueprintCanvasConfiguration(blueprint, selectedStep, blueprintTrigger)
  );

  // Set initial selected step ID
  // Used for 1) highlighting parents & descendants, 2) highlighting step if query param passed in URL
  let initialSelectedStepID = selectedStep?.id ?? "";
  const parentStepToIDList = useMemo(() => getParentStepToIDList(blueprint?.steps), [
    blueprint?.steps,
  ]);
  const selectedStepDescendants = useMemo(() => parentStepToIDList[initialSelectedStepID] ?? [], [
    initialSelectedStepID,
    parentStepToIDList,
  ]);

  const collapsedSteps = getAllCollapsedStepIDs(blueprint);
  const canvasRef = useRef<HTMLDivElement>(null);

  /* ---- BEGIN - AUTO-SCROLLING TO STEPS ---- */
  // Initialize step refs, to enable auto-scrolling to a step
  const stepRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  // Set step refs on initial page load
  useEffect(() => {
    Object.keys(canvasConfiguration.stepPlacements).forEach((stepID) => {
      stepRefs.current[stepID] = stepRefs.current[stepID] || createRef();
    });
  }, []);

  // Function to scroll to a specific ref based on query param "step"
  const scrollToStep = (stepID: string) => {
    if (!!stepRefs?.current?.[stepID]?.current) {
      stepRefs.current[stepID]?.current?.scrollIntoView({ behavior: "smooth" });
      const matchingStepPlacement = canvasConfiguration.stepPlacements?.[stepID];
      if (matchingStepPlacement) {
        switch (matchingStepPlacement.stepPlacementType) {
          case StepPlacementType.EXISTING:
          case StepPlacementType.GHOST:
            setSelectedStep(matchingStepPlacement.step);
            initialSelectedStepID = matchingStepPlacement.step.id;
            break;
          case StepPlacementType.TRIGGER:
            setSelectedStep(matchingStepPlacement.trigger);
            initialSelectedStepID = "";
            break;
          case StepPlacementType.SCRAPER:
            setSelectedStep(matchingStepPlacement.scraper);
            initialSelectedStepID = matchingStepPlacement.scraper.id;
            break;
        }
      }
    }
  };

  // Listen for query param on initial page load
  useEffect(() => {
    const handleQueryParamChange = () => {
      // Wait for 1 second before scrolling to allow for DOM updates
      setTimeout(() => {
        const params = new URLSearchParams(window.location.search);
        const stepToFocus = params.get("step");
        if (stepToFocus) {
          scrollToStep(stepToFocus);
        }
      }, 1000);
    };
    handleQueryParamChange();
    window.addEventListener("querychange", handleQueryParamChange);
    return () => {
      window.removeEventListener("querychange", handleQueryParamChange);
    };
  }, []);

  // Listen for changes in selectedStepLog, so that we can auto-scroll to it
  useEffect(() => {
    if (selectedStepLog) {
      scrollToStep(selectedStepLog.step_id);
    }
  }, [selectedStepLog]);

  /* ---- END - AUTO-SCROLLING TO STEPS ---- */

  /* ---- BEGIN - TEMPORARILY HANDLE UN-SELECTING STEP LOG ---- */
  // TODO: Remove when step log in canvas is implemented - https://app.asana.com/0/1205644398660644/1208127423472822
  // Listen for changes in selected step, so that we can un-select selected step log
  useEffect(() => {
    if (selectedStep) {
      if (selectedStep.id !== selectedStepLog?.step_id) {
        setSelectedStepLog(undefined);
      }
    } else {
      setSelectedStepLog(undefined);
    }
  }, [selectedStep]);
  /* ---- END - TEMPORARILY HANDLE UN-SELECTING STEP LOG ---- */

  const [cookies] = useCookies([LEFT_ALIGN_COOKIE_KEY]);

  const { width } = useWindowSize();
  // This positions the blueprint symmetrically in the canvas view.

  const cardXStart = cookies.leftAlignSteps
    ? 40
    : Math.max(((width ?? 0) - 1050 - canvasConfiguration.totalX * CARD_X_STEP) / 2, 25);

  // Recalculate positioning whenever blueprint or selected step updates.
  useEffect(() => {
    const newConfig = getBlueprintCanvasConfiguration(blueprint, selectedStep, blueprintTrigger);
    setCanvasConfiguration(newConfig);
  }, [blueprint, selectedStep, blueprintTrigger, cookies.leftAlignSteps]);

  const stepNotes: { [stepId: string]: string } = {};
  blueprint.step_notes.forEach(
    (substep: StepNote) =>
      !collapsedSteps.includes(substep.step_id) && (stepNotes[substep.step_id] = substep.text)
  );

  return (
    <div className="blueprint-canvas" ref={canvasRef}>
      <div>
        {Object.entries(canvasConfiguration.stepPlacements).map(([stepID, stepPlacement]) => {
          // DRAW ARROWS
          //@ts-ignore This is a step except for triggers and scrapers but TS does not know this
          const currentStep = stepPlacement?.step;
          const XArrowStart = cardXStart + stepPlacement.xIndex * CARD_X_STEP + 175;
          const YArrowStart = stepPlacement.yIndex * CARD_Y_STEP + 14;
          const YArrowArcStart = YArrowStart - 25;
          const controlPointX = XArrowStart;
          const controlPointY = YArrowStart - 35;
          const YArrowArcEnd = YArrowStart - 45;

          // No arrows needed for undefined or ghost steps
          if (
            currentStep === undefined ||
            currentStep?.id === null ||
            currentStep?.template == "ghost"
          ) {
            return <></>;
          }

          const parentID = canvasConfiguration.nodeIDtoParentNodeIDMap[stepID];
          //@ts-ignore This is a step except for triggers and scrapers but TS does not know this
          const parentStep = parentID ? canvasConfiguration.stepPlacements[parentID].step : null;
          // TODO: Dont search the whole tree again, just grab id + step from nodeIDtoPredecessorNodeIDMap
          const predecessorStep = getPredecessorStepForStep(blueprint, currentStep);

          const parentPath = (
            getEnforceStepTraversalPathForStepID(blueprint, currentStep.id) ?? [""]
          ).slice(0, -1);

          if (
            checkConditionForTraversalPath(blueprint, parentPath, (step) => {
              return Boolean(step.hasCollapsedSubsteps);
            })
          ) {
            return <></>;
          }

          if (predecessorStep === null && parentStep !== null && parentID) {
            // child
            const XOffSetFromParent =
              stepPlacement.xIndex - canvasConfiguration.stepPlacements[parentID].xIndex;

            const XArrowArcEnd = XArrowStart + (XOffSetFromParent > 0 ? -10 : 10);
            const XArrowArcStart =
              XArrowStart - XOffSetFromParent * 180 + (XOffSetFromParent > 0 ? 10 : -10);
            const controlPointXX = XArrowStart - XOffSetFromParent * 180;

            const numberOfPathsForParent = getNumberOfPathsForStep(parentStep);

            return (
              <div style={{ position: "absolute" }} key={stepID}>
                <div
                  style={{
                    position: "absolute",
                    left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                    top: `${stepPlacement.yIndex * CARD_Y_STEP - 35}px`,
                    transition: "all 0.5s",
                  }}
                >
                  <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="card-bottom"
                      d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                      fill="#C9D0DA"
                    ></path>
                  </svg>
                </div>
                {XOffSetFromParent === 0 ? (
                  <svg
                    width={canvasRef.current?.scrollWidth}
                    height={canvasRef.current?.scrollHeight}
                  >
                    <line // vertical line
                      x1={XArrowStart}
                      y1={YArrowStart}
                      x2={XArrowStart}
                      y2={YArrowStart - 73}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></line>
                  </svg>
                ) : (
                  <svg
                    width={canvasRef.current?.scrollWidth}
                    height={canvasRef.current?.scrollHeight}
                  >
                    <line // small vertical line from currentStep to horizontal line
                      x1={XArrowStart}
                      y1={YArrowStart}
                      x2={XArrowStart}
                      y2={YArrowArcStart}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                      style={{ zIndex: 0 }}
                    ></line>
                    <path
                      d={
                        "M" +
                        XArrowStart +
                        " " +
                        YArrowArcStart +
                        " " +
                        "Q" +
                        " " +
                        controlPointX +
                        " " +
                        controlPointY +
                        " " +
                        XArrowArcEnd +
                        " " +
                        controlPointY
                      }
                      fill="none"
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></path>
                    <line // horizontal line
                      x1={XArrowArcEnd}
                      y1={controlPointY}
                      x2={XArrowArcStart}
                      y2={controlPointY}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                      style={{ zIndex: 0 }}
                    />
                    <path
                      d={
                        "M" +
                        XArrowArcStart +
                        " " +
                        controlPointY +
                        " " +
                        "Q" +
                        controlPointXX +
                        " " +
                        controlPointY +
                        " " +
                        controlPointXX +
                        " " +
                        YArrowArcEnd
                      }
                      fill="none"
                      stroke="#C9D0DA"
                      strokeWidth="2"
                    ></path>
                    <line // small vertical line from parentStep to horizonal line
                      x1={controlPointXX}
                      y1={YArrowArcEnd}
                      x2={controlPointXX}
                      y2={YArrowStart - 73}
                      stroke="#C9D0DA"
                      strokeWidth="2"
                      style={{ zIndex: 0 }}
                    />
                    {cookies.leftAlignSteps && (
                      <line // Center align dotted lines along the path of children
                        x1={XArrowStart - CARD_X_STEP * 1.5}
                        y1={(getYIndexForStep(blueprint, parentStep) + 2) * CARD_Y_STEP - 18}
                        x2={XArrowStart - CARD_X_STEP * 1.5}
                        y2={
                          (getYIndexForStep(blueprint, parentStep) + 2) * CARD_Y_STEP +
                          getMaxPathYIndexHeightForSubstepsOfStep(parentStep) * CARD_Y_STEP -
                          18
                        }
                        stroke="#dde2e8"
                        strokeWidth="2px"
                        strokeDasharray={"4,8"}
                        strokeLinecap="round"
                      ></line>
                    )}
                  </svg>
                )}

                {numberOfPathsForParent > 2 ||
                (numberOfPathsForParent > 0 && canStepHaveMoreThanTwoPaths(parentStep)) ? (
                  <div
                    style={{
                      position: "absolute",
                      left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP + 141}px`,
                      top: `${stepPlacement.yIndex * CARD_Y_STEP - 17}px`,
                      transition: "all 0.5s",
                    }}
                  >
                    <button className="pathkey-button">
                      {getPathKeyForStep(parentStep, currentStep)}
                    </button>
                  </div>
                ) : numberOfPathsForParent > 1 ? (
                  <div
                    style={{
                      position: "absolute",
                      left:
                        XOffSetFromParent < 0
                          ? `${cardXStart + stepPlacement.xIndex * CARD_X_STEP + 283}px`
                          : `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                      top: `${stepPlacement.yIndex * CARD_Y_STEP - 32}px`,
                      transition: "all 0.5s",
                    }}
                  >
                    <button className="pathkey-button">
                      <b>{getPathKeyForStep(parentStep, currentStep)}</b>
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            );
          }

          if (predecessorStep !== null && predecessorStep.paths !== undefined) {
            const terminalSteps = getAllTerminalStepsFromStep(blueprint, predecessorStep);
            let XOffSet;
            let YOffSet;

            return Object.entries(terminalSteps ?? {}).map(([_, terminalStep]) => {
              XOffSet =
                getXIndexForStep(blueprint, currentStep) -
                getXIndexForStep(blueprint, terminalStep);
              YOffSet =
                getYIndexForStep(blueprint, currentStep) -
                getYIndexForStep(blueprint, terminalStep);

              const XArrowArcEnd = XArrowStart + (XOffSet > 0 ? -10 : 10);
              const XArrowArcStart = XArrowStart - XOffSet * 180 + (XOffSet > 0 ? 10 : -10);
              const controlPointXX = XArrowStart - XOffSet * 180;

              return (
                <div
                  style={{ position: "absolute" }}
                  key={predecessorStep.id + stepID + terminalStep.id}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                      top: `${stepPlacement.yIndex * CARD_Y_STEP - 35}px`,
                      transition: "all 0.5s",
                    }}
                  >
                    <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        className="card-bottom"
                        d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                        fill="#C9D0DA"
                      ></path>
                    </svg>
                  </div>
                  {XOffSet === 0 ? (
                    <svg
                      width={canvasRef.current?.scrollWidth}
                      height={canvasRef.current?.scrollHeight}
                    >
                      <line // vertical line
                        x1={XArrowStart}
                        y1={YArrowStart}
                        x2={XArrowStart}
                        y2={YArrowStart - 73}
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      ></line>
                    </svg>
                  ) : (
                    <svg
                      width={canvasRef.current?.scrollWidth}
                      height={canvasRef.current?.scrollHeight}
                    >
                      <line // small vertical line from currentStep to horizontal line
                        x1={XArrowStart}
                        y1={YArrowStart}
                        x2={XArrowStart}
                        y2={YArrowArcStart}
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      ></line>
                      <path
                        d={
                          "M" +
                          XArrowStart +
                          " " +
                          YArrowArcStart +
                          " " +
                          "Q" +
                          controlPointX +
                          " " +
                          controlPointY +
                          " " +
                          XArrowArcEnd +
                          " " +
                          controlPointY
                        }
                        fill="none"
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      ></path>
                      <line // horizontal line
                        x1={XArrowArcEnd}
                        y1={controlPointY}
                        x2={XArrowArcStart}
                        y2={controlPointY}
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      />
                      <path
                        d={
                          "M" +
                          XArrowArcStart +
                          " " +
                          controlPointY +
                          " " +
                          "Q" +
                          controlPointXX +
                          " " +
                          controlPointY +
                          " " +
                          controlPointXX +
                          " " +
                          YArrowArcEnd
                        }
                        fill="none"
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      ></path>
                      <line // vertical line from parentStep to horizonal line
                        x1={controlPointXX}
                        y1={YArrowArcEnd}
                        x2={controlPointXX}
                        y2={YArrowStart - YOffSet * 73 - (YOffSet - 1) * 77}
                        stroke="#C9D0DA"
                        strokeWidth="2"
                      />
                    </svg>
                  )}
                </div>
              );
            });
          }

          if (predecessorStep != null || (predecessorStep == null && parentStep == null)) {
            return (
              <div style={{ position: "absolute" }} key={stepID}>
                <div
                  style={{
                    position: "absolute",
                    left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                    top: `${stepPlacement.yIndex * CARD_Y_STEP - 35}px`,
                    transition: "all 0.5s",
                  }}
                >
                  <svg width="360" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="card-bottom"
                      d="M 175 50 L 180.773503 40 L 169.226497 40 L 175 50"
                      fill="#C9D0DA"
                    ></path>
                  </svg>
                </div>
                <svg
                  width={canvasRef.current?.scrollWidth}
                  height={canvasRef.current?.scrollHeight}
                >
                  <line // vertical line
                    x1={XArrowStart}
                    y1={YArrowStart}
                    x2={XArrowStart}
                    y2={YArrowStart - 73}
                    stroke="#C9D0DA"
                    strokeWidth="2"
                  ></line>
                </svg>
              </div>
            );
          }
          return <></>;
        })}
      </div>

      <div>
        {Object.entries(canvasConfiguration.stepPlacements).map(([stepID, stepPlacement]) => {
          return (
            <div
              ref={stepRefs.current[stepID]}
              key={stepID}
              style={{
                position: "absolute",
                left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
                top: `${
                  stepPlacement.yIndex * CARD_Y_STEP +
                  (stepPlacement.stepPlacementType === StepPlacementType.EXISTING ? 0 : 40)
                }px`,
                transition: "all 0.5s",
              }}
            >
              {stepPlacement.stepPlacementType === StepPlacementType.TRIGGER ? (
                <BlueprintTriggerCard
                  trigger={stepPlacement.trigger}
                  operationType={blueprint.operation_type}
                  triggerType={blueprint.trigger_type}
                />
              ) : stepPlacement.stepPlacementType === StepPlacementType.SCRAPER ? (
                <BlueprintScraperCard scraper={stepPlacement.scraper} />
              ) : stepPlacement.stepPlacementType === StepPlacementType.GHOST ? (
                <BlueprintGhostStepCard ghostStep={stepPlacement.step} />
              ) : (
                <BlueprintStepCard
                  isHighlighted={
                    initialSelectedStepID === stepPlacement.step.id ||
                    selectedStep === stepPlacement.step ||
                    (selectedSteps ?? []).includes(stepPlacement.step)
                  }
                  isDescendantofSelectedStep={selectedStepDescendants.includes(
                    stepPlacement.step.id
                  )}
                  step={stepPlacement.step}
                  stepNote={stepNotes[stepID]}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlueprintCanvas;
