import { useState } from "react";
import AccordionCard from "../../shared/AccordionCard";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { IntegrationForIntegrationBuilder } from "../../utils/Entities";
import NotesForCustomersPreviewModal from "./NotesHTMLPreview";

interface Props {
  requestBodyForIntegration: IntegrationForIntegrationBuilder | undefined;
  setRequestBodyForIntegration: React.Dispatch<
    React.SetStateAction<IntegrationForIntegrationBuilder | undefined>
  >;
}

const DashboardConfigurationSection = ({
  requestBodyForIntegration,
  setRequestBodyForIntegration,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <AccordionCard title="Dashboard" badgeNumber={1}>
      {requestBodyForIntegration && (
        <div className="space-y-6">
          <TextFieldHeader
            title="Helpful notes for customers"
            subtitle="Notes in HTML to show customers regarding this integration. Commonly used to show instructions on how to sign up for a demo account. This appears to the customer on the configuration page."
            autosize={true}
            value={requestBodyForIntegration.notes_for_customers || ""}
            onChange={(event) =>
              setRequestBodyForIntegration((body) => {
                return {
                  ...body,
                  notes_for_customers: event.target.value.length > 0 ? event.target.value : null,
                };
              })
            }
            placeholder='Example: <li>How to get a sandbox: Sign up <a href="https://linear.app/signup" target="_blank">here!</a></li>'
            bottomLinkText={
              requestBodyForIntegration.notes_for_customers ? "Preview HTML" : undefined
            }
            bottomLinkOnClick={() => setIsModalOpen(true)}
          />
          {isModalOpen && (
            <NotesForCustomersPreviewModal
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              htmlText={requestBodyForIntegration.notes_for_customers || ""}
            />
          )}
        </div>
      )}
    </AccordionCard>
  );
};

export default DashboardConfigurationSection;
