import { RateLimitConfigurationIntegrationBuilder } from "../../../../utils/Entities";
import { Badge, Text } from "@merge-api/merge-javascript-shared";

interface HeaderCellProps {
  rateLimitConfiguration: RateLimitConfigurationIntegrationBuilder;
  onClick: React.MouseEventHandler<HTMLTableCellElement> | undefined;
}

const HeaderCell = ({ rateLimitConfiguration, onClick }: HeaderCellProps) => {
  const shouldSetMaxCount = rateLimitConfiguration?.should_set_limit_max_count_via_headers;
  const shouldUpdateCountLeft =
    rateLimitConfiguration?.should_update_rate_limit_count_left_via_headers;
  const shouldUpdateCurrentCount =
    rateLimitConfiguration?.should_update_rate_limit_current_count_via_headers;
  const shouldUpdateResetTimestampKeyPathViaHeaders =
    !!rateLimitConfiguration?.response_header_rate_limit_reset_timestamp_key_path &&
    rateLimitConfiguration?.response_header_rate_limit_reset_timestamp_key_path.length > 0;
  const hasHeaderBooleans =
    shouldSetMaxCount ||
    shouldUpdateCountLeft ||
    shouldUpdateCurrentCount ||
    shouldUpdateResetTimestampKeyPathViaHeaders;
  return (
    <td onClick={onClick} className="space-x-2">
      {!hasHeaderBooleans && (
        <Text as="div" className="text-gray-40 text-base font-medium">
          —
        </Text>
      )}
      {shouldSetMaxCount && (
        <Badge size="sm" color="gray">
          Max count
        </Badge>
      )}
      {shouldUpdateCurrentCount && (
        <Badge size="sm" color="gray">
          Current count
        </Badge>
      )}
      {shouldUpdateCountLeft && (
        <Badge size="sm" color="gray">
          Count left
        </Badge>
      )}
      {shouldUpdateResetTimestampKeyPathViaHeaders && (
        <Badge size="sm" color="gray">
          Reset timestamp
        </Badge>
      )}
    </td>
  );
};
export default HeaderCell;
