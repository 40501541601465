import { Typeahead } from "@merge-api/merge-javascript-shared";
import SortButton from "./SortButton";
import {
  TypeaheadComparatorClusterOption,
  TypeaheadComparatorEnum,
} from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import LoadingText from "../shared/LoadingText";
import { SortTypeEnum } from "../utils/constants";
import {
  BlueprintExtendedOperationType,
  BlueprintVersionPublishState,
} from "../../../../models/Blueprints";
import {
  BlueprintSearchFilterTypeaheadEnum,
  MapBlueprintSearchFilterTypeaheadDisplayName,
} from "../../../../models/blueprint-search/BlueprintSearchInputModels";

interface Props {
  isLoadingAvailableFilters: boolean;
  availableFilters: TypeaheadComparatorClusterOption[];
  selectedFilters: TypeaheadComparatorClusterOption[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<TypeaheadComparatorClusterOption[]>>;
  sortType: SortTypeEnum;
  setSortType: (sortType: SortTypeEnum) => void;
}

const BlueprintsSearchFilters = ({
  isLoadingAvailableFilters,
  availableFilters,
  selectedFilters,
  setSelectedFilters,
  sortType,
  setSortType,
}: Props) => {
  // We purposely define this map here instead of in constants
  // There's some weird behavior updating this map, even if using de-referenced values
  const MapChipValueToComparatorClusterOption: Record<string, TypeaheadComparatorClusterOption> = {
    FETCH: {
      value: BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE,
      label:
        MapBlueprintSearchFilterTypeaheadDisplayName[
          BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE
        ],
      comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
      target: {
        targetOptions: [
          {
            value: BlueprintExtendedOperationType.FETCH,
            label: BlueprintExtendedOperationType.FETCH,
          },
        ],
      },
    },
    CREATE: {
      value: BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE,
      label:
        MapBlueprintSearchFilterTypeaheadDisplayName[
          BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE
        ],
      comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
      target: {
        targetOptions: [
          {
            value: BlueprintExtendedOperationType.CREATE,
            label: BlueprintExtendedOperationType.CREATE,
          },
        ],
      },
    },
    EDIT: {
      value: BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE,
      label:
        MapBlueprintSearchFilterTypeaheadDisplayName[
          BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE
        ],
      comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
      target: {
        targetOptions: [
          {
            value: BlueprintExtendedOperationType.EDIT,
            label: BlueprintExtendedOperationType.EDIT,
          },
        ],
      },
    },
    WEBHOOK: {
      value: BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE,
      label:
        MapBlueprintSearchFilterTypeaheadDisplayName[
          BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE
        ],
      comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
      target: {
        targetOptions: [
          {
            value: BlueprintExtendedOperationType.FETCH_WEBHOOK,
            label: BlueprintExtendedOperationType.FETCH_WEBHOOK,
          },
        ],
      },
    },
    FUNCTIONAL: {
      value: BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE,
      label:
        MapBlueprintSearchFilterTypeaheadDisplayName[
          BlueprintSearchFilterTypeaheadEnum.OPERATION_TYPE
        ],
      comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
      target: {
        targetOptions: [
          {
            value: BlueprintExtendedOperationType.FUNCTIONAL,
            label: BlueprintExtendedOperationType.FUNCTIONAL,
          },
        ],
      },
    },
    PUBLISHED: {
      value: BlueprintSearchFilterTypeaheadEnum.PUBLISHED_STATUS,
      label:
        MapBlueprintSearchFilterTypeaheadDisplayName[
          BlueprintSearchFilterTypeaheadEnum.PUBLISHED_STATUS
        ],
      comparatorOptions: [TypeaheadComparatorEnum.EQUALS],
      target: {
        targetOptions: [
          {
            value: BlueprintVersionPublishState.Published,
            label: BlueprintVersionPublishState.Published,
          },
        ],
      },
    },
  };

  return (
    <div className="flex flex-col space-y-6">
      {isLoadingAvailableFilters ? (
        <LoadingText text="Loading search bar ..." />
      ) : (
        <Typeahead
          isComparatorClusters={true}
          options={availableFilters}
          placeholder="Add filters..."
          value={selectedFilters}
          blurOnSelect={true}
          onChange={(_, value) => {
            if (value) setSelectedFilters(value);
          }}
          footerProps={{
            footerChipsTitle: "Quick filters",
            mapChipsToOptions: MapChipValueToComparatorClusterOption,
            footerPostfix: <SortButton sortType={sortType} setSortType={setSortType} />,
          }}
        />
      )}
    </div>
  );
};

export default BlueprintsSearchFilters;
