import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import {
  BlueprintParameterValue,
  BlueprintParameterValueType,
  BlueprintParameterValueProcedureArray,
  BlueprintStepType,
} from "../../../models/Blueprints";
import { COMPARATOR_CHOICES } from "../../../components/blueprint-editor/right-panel/BlueprintEditorRightPanelIfElseForm";
import MergeCodeBlock from "../MergeCodeBlock";
import { parseParameterValue } from "../utils/SharedComponentUtils";
import { getShortenedText } from "../utils/SharedComponentUtils";
import { firstLetterUpperCase } from "../../../utils";
import { palette } from "../../../styles/theme/colors";
import ExpandableCodePreview from "./ExpandableCodePreview";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

const MAX_CODE_LENGTH = 38;
const MAX_PARAM_LENGTH = 38;

interface Props {
  title: string;
  subtitle: string | JSX.Element;
  step: Record<string, BlueprintParameterValue>;
  shortenedTitle: string | JSX.Element;
  stepType?: BlueprintStepType | string;
}

const ParamName = styled.div`
  width: 38px;
  font-size: 9px;
  padding: 0px 5px;
  font-family: var(--font-family-monospace);
  font-weight: 600;
  color: #939aa5;
`;

const ParamContainer = styled(Row)`
  width: 275px !important;
  height: 28px;
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 0.375rem !important;
  margin-bottom: 0.1875rem !important;
`;

const Params = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  width: 275px !important;
  flex-direction: row;
  align-content: space-evenly;
  gap: 18px;
`;

const DataTransformContainer = styled(Row)`
  width: 275px !important;
  height: 34px;
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 0.375rem !important;
  margin-bottom: 0.1875rem !important;
`;

const CodeBlock = styled(Col)`
  width: 275px !important;
  border-radius: 5px;
  background: rgb(1, 22, 39);
  color: #60819c;
  height: 28px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Field = styled.div`
  font-size: 11px;
  whitespace: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ParamField = styled.div`
  font-size: 11px;
  whitespace: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 215px;
  padding: 3px;
  text-align: right;
`;

const DataTransformField = styled.div`
  font-size: 11px;
  whitespace: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 37px;
`;

const Variable = styled.span`
  color: ${palette.blue};
  fontfamily: '"Fira code", "Fira Mono", monospace';
`;

const StepCodePreview = ({ title, subtitle, shortenedTitle, step, stepType }: Props) => {
  const code = parseParamValuefromStep(step, stepType);
  const [shortenedIfElse, _] = getShortenedText(code, MAX_CODE_LENGTH);
  return (
    <div>
      <Row>
        <p className="mb-0" style={{ fontSize: "14px", fontWeight: 500 }}>
          {subtitle}
        </p>
      </Row>
      <Row>
        {shortenedTitle === title ? (
          <DeprecatedH4 className="small text-muted text-truncate text-sm mb-[0.65rem]">
            {shortenedTitle}
          </DeprecatedH4>
        ) : (
          <OverlayTrigger
            overlay={
              <Tooltip className="step-card-full-name-tooltip" id="tooltip">
                {title}
              </Tooltip>
            }
            placement="top"
          >
            <DeprecatedH4 className="small text-muted mb-0 text-truncate">
              {shortenedTitle}
            </DeprecatedH4>
          </OverlayTrigger>
        )}
      </Row>
      {step.array || (step.value && stepType != BlueprintStepType.SetVariable) ? (
        <ParamContainer>
          <Params>
            <div style={{ width: "50px !important" }}>
              <ParamName>Param</ParamName>
            </div>
            <div style={{ width: "200px !important" }}>
              <ParamField>{getShortenedText(code, MAX_PARAM_LENGTH)[0]}</ParamField>
            </div>
          </Params>
        </ParamContainer>
      ) : stepType == BlueprintStepType.IfElse ? (
        <Row className="mb-1">
          <OverlayTrigger
            overlay={
              <Tooltip className="step-card-full-name-tooltip" id="tooltip">
                {code}
              </Tooltip>
            }
            placement="top"
          >
            <CodeBlock>
              <MergeCodeBlock
                textToCopy={code}
                language={"python"}
                shouldExcludeCopyOption={true}
                customStyle={{ lineHeight: "1px", padding: "13px 1px", fontSize: "10px" }}
              >
                {shortenedIfElse}
              </MergeCodeBlock>
            </CodeBlock>
          </OverlayTrigger>
        </Row>
      ) : stepType === BlueprintStepType.SetVariable && step.key && step.value_type ? (
        <Row className="mb-1">
          <Col>
            <ParamContainer>
              <Col>
                <Field>
                  {" "}
                  Set{" "}
                  <Variable>
                    {parseParameterValue(step.key as BlueprintParameterValue)}
                  </Variable> to{" "}
                  <Variable>
                    {parseParameterValue(
                      (step.value ?? step.value_type) as BlueprintParameterValue
                    )}
                  </Variable>
                </Field>
              </Col>
            </ParamContainer>
          </Col>
        </Row>
      ) : stepType === BlueprintStepType.DataTransform && step.procedures ? (
        <Row className="mb-1">
          <Col>
            <DataTransformContainer>
              <Col>
                {parseProcedureArray(step.procedures as BlueprintParameterValueProcedureArray)}
              </Col>
            </DataTransformContainer>
          </Col>
        </Row>
      ) : (
        stepType === BlueprintStepType.CustomFunction && (
          <Row className="mb-1">
            <ExpandableCodePreview code={parseParameterValue(step.code)}>
              {parseParameterValue(step.code)}
            </ExpandableCodePreview>
          </Row>
        )
      )}
    </div>
  );
};

const isIfElseComplete = (step: Record<string, BlueprintParameterValue>): boolean => {
  if (
    step.statements &&
    step.statements.value_type === BlueprintParameterValueType.statementArray &&
    step.statements.statement_array.length > 0
  ) {
    // New Template
    if (!step.logical_operator) {
      return false;
    }
    for (let i = 0; i < step.statements.statement_array.length; i++) {
      let statement = step.statements.statement_array[i];
      if (!(statement.val1 && statement.val2 && statement.comparator)) {
        return false;
      }
    }
    // All statements are fully populated
    return true;
  } else if (step.value1 && step.value2 && step.comparator) {
    // Old Template backwards compatability
    return true;
  }
  return false;
};

const parseParamValuefromStep = (
  step: Record<string, BlueprintParameterValue>,
  stepType: BlueprintStepType | string | undefined
) => {
  if (step.array) return parseParameterValue(step.array as BlueprintParameterValue);
  if (step.value) return parseParameterValue(step.value as BlueprintParameterValue);
  if (stepType === BlueprintStepType.IfElse && isIfElseComplete(step)) return parseIfElseText(step);
  else return null;
};

const parseIfElseText = (step: Record<string, BlueprintParameterValue>) => {
  var code = `if `;

  if (
    step.statements &&
    step.statements.value_type === BlueprintParameterValueType.statementArray
  ) {
    code += `${firstLetterUpperCase(
      parseParameterValue(step.logical_operator as BlueprintParameterValue)
    )} of: `;
    step.statements.statement_array.forEach((statement) => {
      const comparatorObj = COMPARATOR_CHOICES.find(
        (x) => x.id === parseParameterValue(statement.comparator as BlueprintParameterValue)
      );
      const compSymbol = comparatorObj ? comparatorObj.name : "?";
      code += `${parseParameterValue(
        statement.val1 as BlueprintParameterValue
      )} ${compSymbol} ${parseParameterValue(statement.val2 as BlueprintParameterValue)}, `;
    });

    return code;
  }
  const comparatorObj = COMPARATOR_CHOICES.find(
    (x) => x.id === parseParameterValue(step.comparator as BlueprintParameterValue)
  );
  const compSymbol = comparatorObj ? comparatorObj.name : "?";
  code += `${parseParameterValue(
    step.value1 as BlueprintParameterValue
  )} ${compSymbol} ${parseParameterValue(step.value2 as BlueprintParameterValue)}`;

  return code;
};

const parseProcedureArray = (array: BlueprintParameterValueProcedureArray) => {
  const a = array.procedure_array[0];

  if (a && a.parameter_values.key && a.parameter_values.object) {
    const key = parseParameterValue(a.parameter_values.key as BlueprintParameterValue);
    const value = a.parameter_values.value
      ? parseParameterValue(a.parameter_values.value as BlueprintParameterValue)
      : null;
    const obj = parseParameterValue(a.parameter_values.object as BlueprintParameterValue);
    const text = (
      <DataTransformField>
        {" "}
        Add{" "}
        <Variable>
          &#123;{key} {value ? `, ${value}` : null}&#125;
        </Variable>{" "}
        to <Variable>{obj}</Variable>{" "}
      </DataTransformField>
    );
    return text;
  } else if (a && a.parameter_values.array && a.parameter_values.value) {
    const array = parseParameterValue(a.parameter_values.array as BlueprintParameterValue);
    const value = a.parameter_values.value
      ? parseParameterValue(a.parameter_values.value as BlueprintParameterValue)
      : null;
    const text = (
      <DataTransformField>
        {" "}
        Add <Variable>{value}</Variable> to <Variable>{array}</Variable>{" "}
      </DataTransformField>
    );
    return text;
  }
  return null;
};

export default StepCodePreview;
