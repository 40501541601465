import { Text } from "@merge-api/merge-javascript-shared";
import MergeTable from "../../../../shared/MergeTable";
import { SelectiveSyncFilterSchemaAPIResponse } from "../../../../versioned-components/types";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import IntegrationBuilderEmptyState from "../../../shared/IntegrationBuilderEmptyState";
import SelectiveSyncFiltersTableRow from "./SelectiveSyncFiltersTableRow";

interface Props {
  selectiveSyncFilterSchemas: SelectiveSyncFilterSchemaAPIResponse;
  integrationID: string;
}

const SelectiveSyncFiltersTable = ({ selectiveSyncFilterSchemas, integrationID }: Props) => {
  const {} = useIntegrationBuilderContext({
    shouldRenderStageButton: false,
    shouldRenderSubmitButton: false,
    shouldRenderNavigationButtons: true,
  });

  const TABLE_HEADERS = (
    <>
      <th scope="col">Model</th>
      <th scope="col">Field</th>
      <th scope="col">Operators</th>
      <th scope="col">Last modified</th>
      <th scope="col">Status</th>
    </>
  );

  const EmptyTableContent = () => (
    <IntegrationBuilderEmptyState
      title="No Selective Sync filters"
      subtitle={
        <div>
          Click <Text variant="title-sm">+ Filter</Text> in the top right to manually add one
        </div>
      }
      numberOfColumns={5}
    />
  );

  const ROWS = (
    <>
      {selectiveSyncFilterSchemas && Object.entries(selectiveSyncFilterSchemas).length > 0 ? (
        Object.entries(selectiveSyncFilterSchemas).map(([id, schema]) => {
          return (
            <SelectiveSyncFiltersTableRow
              id={id}
              selectiveSyncFilterSchema={schema}
              integrationID={integrationID}
            />
          );
        })
      ) : (
        <EmptyTableContent />
      )}
    </>
  );

  return (
    <MergeTable
      hover={true}
      hasMarginBottom={false}
      hasMorePaddingOnFirstElement
      className="mb-6"
      header={TABLE_HEADERS}
      content={ROWS}
    />
  );
};

export default SelectiveSyncFiltersTable;
