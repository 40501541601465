import { Button } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import { BlueprintVersionPublishState } from "../../../models/Blueprints";
import { navigateToBlueprintEditor } from "../../../router/RouterUtils";
import useBlueprintContext from "../../blueprint-editor/context/useBlueprintContext";
import { IntegrationEditorLeftPanelVerticalTab } from "./IntegrationEditorLeftPanel";

type Props = {
  verticalTabs: IntegrationEditorLeftPanelVerticalTab[];
};

const NavbarContainer = styled.div`
  border-top: 1px solid #eaeef3;
  border-bottom: 1px solid #eaeef3;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
`;

const IntegrationEditorLeftPanelVerticalNavbar = ({ verticalTabs }: Props) => {
  const { blueprint, blueprintVersions } = useBlueprintContext();
  const history = useHistory();
  const integrationID = blueprint.integration.id;
  const currentVersionID = blueprint.version.id;

  const latestPublishedVersion = blueprintVersions.find(
    (bp) => bp.publish_state === BlueprintVersionPublishState.Published
  );

  function viewBlueprintVersion(blueprint_version_id: string) {
    navigateToBlueprintEditor(history, integrationID, blueprint_version_id);
  }

  return (
    <NavbarContainer>
      <ul className="flex-column sub-navbar-nav navbar-nav py-6">
        {verticalTabs.map(({ href, Icon, title }) => (
          <li key={title} className="nav-item">
            <NavLink to={href} className="nav-link sub-nav-link d-flex align-items-center blue-nav">
              <Icon size={"16px"} />
              <div className="ml-1.5">{title} </div>
            </NavLink>
          </li>
        ))}
      </ul>
      {latestPublishedVersion && currentVersionID !== latestPublishedVersion.id && (
        <Button
          variant="success"
          size="sm"
          className="mb-3"
          onClick={() => viewBlueprintVersion(latestPublishedVersion.id!)}
        >
          <div className="d-flex justify-content-center">Go to Latest Published Version</div>
        </Button>
      )}
    </NavbarContainer>
  );
};

export default IntegrationEditorLeftPanelVerticalNavbar;
