import React, { useEffect } from "react";
import IntegrationBuilderContext, {
  IntegrationBuilderContextState,
} from "./IntegrationBuilderContext";
import { DiffModelTypeEnum } from "../../../models/DiffModels";

interface ContextUpdateParams {
  // submission button props
  onSubmit?: () => void;
  onStage?: () => void;
  canSubmit?: boolean;
  submitButtonText?: string;
  isLoadingSubmit?: boolean;
  shouldRenderSubmitButton?: boolean;
  modelTypeForDiff?: DiffModelTypeEnum | undefined;
  shouldHideDiffModal?: boolean;
  // navigation button props
  shouldRenderNavigationButtons?: boolean;
  // delete button props
  onDelete?: () => void;
  deleteButtonText?: string;
  isLoadingDelete?: boolean;
  shouldRenderDeleteButton?: boolean;
  isGatedByPermissioning?: boolean;
  hasPermissionToEdit?: boolean;
  shouldRenderStageButton?: boolean;
  stageButtonText?: string;
}

const useIntegrationBuilderContext = (
  updates?: ContextUpdateParams
): IntegrationBuilderContextState => {
  const context = React.useContext(IntegrationBuilderContext);

  if (!context) {
    throw new Error(
      "useIntegrationBuilderContext must be used within IntegrationBuilderContextProvider"
    );
  }

  const {
    setCanSubmit,
    setSubmitButtonText,
    setIsLoadingSubmit,
    setOnSubmit,
    setShouldRenderSubmitButton,
    setShouldRenderNavigationButtons,
    setOnDelete,
    setIsLoadingDelete,
    setDeleteButtonText,
    setShouldRenderDeleteButton,
    setModelTypeForDiff,
    setShouldHideDiffModal,
    setIsGatedByPermissioning,
    setHasPermissionToEdit,
    setShouldRenderStageButton,
    setOnStage,
    setStageButtonText,
  } = context;

  // state updates, if they're passed into the hook
  useEffect(() => {
    if (updates) {
      // submission button props
      if (updates.canSubmit !== undefined) setCanSubmit(updates.canSubmit);
      if (updates.submitButtonText !== undefined) setSubmitButtonText(updates.submitButtonText);
      if (updates.isLoadingSubmit !== undefined) setIsLoadingSubmit(updates.isLoadingSubmit);
      if (updates.onSubmit !== undefined) setOnSubmit(updates.onSubmit);
      if (updates.shouldRenderSubmitButton !== undefined)
        setShouldRenderSubmitButton(updates.shouldRenderSubmitButton);
      if (updates.modelTypeForDiff) setModelTypeForDiff(updates.modelTypeForDiff);
      if (updates.shouldHideDiffModal !== undefined)
        setShouldHideDiffModal(updates.shouldHideDiffModal);

      // navigation button props
      if (updates.shouldRenderNavigationButtons !== undefined)
        setShouldRenderNavigationButtons(updates.shouldRenderNavigationButtons);

      // delete button props
      if (updates.onDelete !== undefined) setOnDelete(updates.onDelete);
      if (updates.deleteButtonText !== undefined) setDeleteButtonText(updates.deleteButtonText);
      if (updates.isLoadingDelete !== undefined) setIsLoadingDelete(updates.isLoadingDelete);
      if (updates.shouldRenderDeleteButton !== undefined)
        setShouldRenderDeleteButton(updates.shouldRenderDeleteButton);

      // stage button props
      if (updates.onStage !== undefined) setOnStage(updates.onStage);
      if (updates.stageButtonText !== undefined) setStageButtonText(updates.stageButtonText);
      if (updates.shouldRenderStageButton !== undefined)
        setShouldRenderStageButton(updates.shouldRenderStageButton);
      // default to not rendering the stage button
      else setShouldRenderStageButton(false);

      /**
       * Don't check permissions if "isGatedByPermissioning" is false for the model
       * This means the model doesn't have support for permissioning yet
       * Any permissions should be false by default, to more likely prevent security leaks.
       */
      if (updates.isGatedByPermissioning !== undefined) {
        setIsGatedByPermissioning(updates.isGatedByPermissioning);
        if (updates.hasPermissionToEdit !== undefined) {
          setHasPermissionToEdit(updates.hasPermissionToEdit);
        } else {
          setHasPermissionToEdit(false);
        }
      } else {
        setIsGatedByPermissioning(false);
        setHasPermissionToEdit(true);
      }
    }
  }, [
    updates,
    setCanSubmit,
    setSubmitButtonText,
    setIsLoadingSubmit,
    setOnSubmit,
    setShouldRenderSubmitButton,
    setShouldRenderNavigationButtons,
    setShouldRenderStageButton,
    setStageButtonText,
  ]);

  return context;
};

export default useIntegrationBuilderContext;
