import clsx from "clsx";
import { toast } from "react-toastify";

// constants
const baseClassName = "rounded-md text-white font-sans font-medium p-5 text-md";

export const showSuccessToast = (message: string) => {
  const className = "bg-emerald-60";
  toast.success(message, { position: "bottom-right", className: clsx(baseClassName, className) });
};

export const showErrorToast = (message: string) => {
  const className = "bg-red-60";
  toast.success(message, { position: "bottom-right", className: clsx(baseClassName, className) });
};

export const showWarningToast = (message: string) => {
  const className = "bg-yellow-50";
  toast.success(message, { position: "bottom-right", className: clsx(baseClassName, className) });
};

export const showInfoToast = (message: string) => {
  const className = "bg-blue-60";
  toast.success(message, { position: "bottom-right", className: clsx(baseClassName, className) });
};
