import clsx from "clsx";
import { Button, ButtonVariant, Checkbox, TextField } from "@merge-api/merge-javascript-shared";
import { Minus, Plus } from "lucide-react";

export type Row = {
  active: boolean;
  key: string;
  value: string;
  isDefault?: boolean; // used in cases where the value is always 'default value'
  isPermanent?: boolean; // used in cases where the row should never be removed
};

type PostmanTableRowProps = {
  className?: string;
  row?: Row;
  onNewLineClick?: () => void;
  onRowRemove?: () => void;
  onRowChange?: (key: "key" | "value" | "active", value: string | boolean) => void;
  disabled?: boolean;
  keyPlaceholder?: string;
  valuePlaceholder?: string;
  keysDisabled?: boolean;
  userInputDisabled?: boolean;

  // if last row, will render a plus icon to insert a new row below
  isLastRow?: boolean;
  // if isUserInput, will render a minus icon to remove a row
  isUserInput?: boolean;
  // Disables adding new rows
  addRowDisabled?: boolean;
};

const PostmanTableRow = ({
  className,
  row,
  isLastRow,
  onNewLineClick,
  onRowRemove,
  onRowChange,
  disabled,
  keysDisabled,
  userInputDisabled,
  isUserInput = true,
  addRowDisabled,
  keyPlaceholder,
  valuePlaceholder,
}: PostmanTableRowProps) => {
  return (
    <div
      className={clsx(
        className,
        "flex flex-row border-t-[0.5px] border-t-gray-20 border-solid min-w-[500px]"
      )}
    >
      {/* Checkbox/Plus Icon */}
      {!disabled && !userInputDisabled && (
        <div className="w-12 flex items-center justify-center shrink-0">
          {isLastRow && !addRowDisabled ? (
            <Button size="sm" variant={ButtonVariant.TextBlack} onClick={onNewLineClick}>
              <Plus size={16} />
            </Button>
          ) : (
            !row?.isPermanent &&
            (isUserInput ? (
              <Button size="sm" variant={ButtonVariant.TextBlack} onClick={onRowRemove}>
                <Minus size={16} />
              </Button>
            ) : (
              <Checkbox
                variant="secondary"
                disabled={disabled}
                checked={row?.active}
                onChange={(checked) => onRowChange && onRowChange("active", checked)}
                className="my-2.5 mx-4"
              />
            ))
          )}
        </div>
      )}

      {/* Key */}
      <div
        className={clsx(
          !userInputDisabled && "border-l-[0.5px] border-l-gray-20",
          isLastRow ? "pt-0.5 px-0.5 pb-[3px]" : "p-0.5"
        )}
      >
        <div className="w-64 flex h-full">
          <TextField
            variant="inlined-code"
            placeholder={keyPlaceholder || "Enter key..."}
            value={row?.key}
            onChange={(e) => onRowChange && !row?.isPermanent && onRowChange("key", e.target.value)}
            className={clsx(
              "min-w-64 w-64 grow-0 pr-[1px]",
              disabled && "pointer-events-none",
              keysDisabled && "pointer-events-none"
            )}
            inputClassName={clsx(
              !row?.active && "text-black text-opacity-[.35]",
              disabled && "pointer-events-none",
              keysDisabled && "pointer-events-none text-black text-opacity-[.65]",
              row?.isPermanent && "pointer-events-none text-black"
            )}
            disabled={disabled}
          />
        </div>
      </div>

      {/* Value */}
      <div
        className={clsx(
          "border-l-[0.5px] border-l-gray-20 w-full",
          isLastRow ? "pt-0.5 px-0.5 pb-[3px]" : "p-0.5"
        )}
      >
        <div className="flex w-full h-full">
          <TextField
            variant="inlined-code"
            placeholder={valuePlaceholder || "Enter value..."}
            className={clsx(
              "w-full pr-[1px]",
              disabled && "pointer-events-none",
              row?.isDefault && "pointer-events-none"
            )}
            value={row?.value}
            onChange={(e) => onRowChange && !row?.isDefault && onRowChange("value", e.target.value)}
            inputClassName={clsx(
              !row?.active && "text-black text-opacity-[.35] w-full",
              disabled && "pointer-events-none",
              row?.isDefault && "pointer-events-none text-gray-40 text-opacity-[.65]"
            )}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default PostmanTableRow;
