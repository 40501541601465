import {
  BlueprintFileUrlToFileStep,
  BlueprintParameterValueType,
  BlueprintStepTemplate,
} from "../../../models/Blueprints";
import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import DropdownFormField from "./DropdownFormField";
import TypeaheadFormField from "./TypeaheadFormField";

interface Props {
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelFileUrlToFileForm = ({ stepTemplate }: Props) => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintFileUrlToFileStep;

  const currentComparatorValue =
    step.parameter_values?.["use_authentication"]?.value_type ===
    BlueprintParameterValueType.constant
      ? step.parameter_values?.["use_authentication"]?.constant
      : null;

  const CHOICES = [
    { name: "True", id: "True" },
    { name: "False", id: "False" },
  ];

  return (
    <>
      <DropdownFormField
        choices={CHOICES}
        currentValue={currentComparatorValue}
        placeholder={"Select to enable Authentication"}
        title={"Authentication"}
        subtitle={"Enable authentication on the file download"}
        onChange={(e) =>
          updateStepParameterValue(step, "use_authentication", {
            constant: e.target.value,
            value_type: BlueprintParameterValueType.constant,
          })
        }
      />

      <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
      {Object.entries(stepTemplate.parameter_schema ?? {}).map(([key, parameter]) => {
        return (
          <TypeaheadFormField
            key={key}
            title={parameter.title ?? key}
            valueKey={key}
            parameterType={parameter.type}
          />
        );
      })}
    </>
  );
};

export default BlueprintEditorRightPanelFileUrlToFileForm;
