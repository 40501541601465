import { Button, ButtonVariant, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import {
  Blueprint,
  BlueprintOperationType,
  BlueprintTestPayload,
  JSONObjectSchema,
  ReportFile,
} from "../../../../models/Blueprints";
import BlueprintEditorLeftPanelSavePayloadModal from "../BlueprintEditorLeftPanelSavePayloadModal";
import Editor from "react-simple-code-editor";
import Dropdown from "../../right-panel/Dropdown";

const { highlight, languages } = require("prismjs");

interface Props {
  blueprint: Blueprint;
  hasInputParameters: boolean;
  isShowingSavePayloadModal: boolean;
  setIsShowingSavePayloadModal: (x: boolean) => void;
  blueprintTestPayloads: BlueprintTestPayload[];
  setBlueprintTestPayloads: React.Dispatch<React.SetStateAction<BlueprintTestPayload[]>>;
  generatedBlueprintTestPayloads: BlueprintTestPayload[];
  setGeneratedBlueprintTestPayloads: React.Dispatch<React.SetStateAction<BlueprintTestPayload[]>>;
  isSelectedTestPayloadGenerated: boolean;
  selectedTestPayload: undefined | BlueprintTestPayload;
  setSelectedTestPayload: (s: undefined | BlueprintTestPayload) => void;
  globalVarsAsString: string | undefined;
  setGlobalVarsAsString: (x: string) => void;
  selectedTestLinkedAccount: undefined | string;
  selectedTestCommonModel: undefined | string;
  updatedParameterSchema: JSONObjectSchema | undefined;
  updateToLatestInputParameterSchema: () => void;
  shouldDisableGeneratePayload: boolean;
  isLoadingGeneratedPayload: boolean;
  setIsLoadingGeneratedPayload: (x: boolean) => void;
  generateWritePayload: (
    common_model_object_id: string | null,
    linked_account_id: string | null
  ) => void;
  loadSelectedTestPayload: (id: string) => void;
  setIsShowingConfirmDeletePayloadModal: (x: boolean) => void;
  areInputParametersValid: boolean;
  reportFileID: string | undefined;
  setReportFileID: (x: string | undefined) => void;
  reportFiles: Array<ReportFile>;
}

/**
 * Renders payload fields for running Blueprints with input parameters
 * ie.: Running a CREATE Blueprint with payload
 */
const BlueprintLeftPanelConsolePayload = ({
  blueprint,
  hasInputParameters,
  isShowingSavePayloadModal,
  setIsShowingSavePayloadModal,
  blueprintTestPayloads,
  setBlueprintTestPayloads,
  generatedBlueprintTestPayloads,
  setGeneratedBlueprintTestPayloads,
  isSelectedTestPayloadGenerated,
  selectedTestPayload,
  setSelectedTestPayload,
  globalVarsAsString,
  setGlobalVarsAsString,
  selectedTestCommonModel,
  selectedTestLinkedAccount,
  updatedParameterSchema,
  updateToLatestInputParameterSchema,
  shouldDisableGeneratePayload,
  isLoadingGeneratedPayload,
  setIsLoadingGeneratedPayload,
  generateWritePayload,
  loadSelectedTestPayload,
  setIsShowingConfirmDeletePayloadModal,
  areInputParametersValid,
  reportFileID,
  setReportFileID,
  reportFiles,
}: Props) => {
  // If a Blueprint doesn't have input parameters, or is not a valid type, we do not render payload fields
  if (!hasInputParameters || blueprint.operation_type === BlueprintOperationType.PROXY) {
    return null;
  }

  const inputParametersSubtitle = blueprint?.report_template_id
    ? "Upload new test Report files or select which Report file to use in the test run."
    : "For writes, pass in valid JSON representing nested common model. If webhook, nest under 'webhook_payload' key. This will shade red if JSON is formatted incorrectly.";

  return (
    <>
      <BlueprintEditorLeftPanelSavePayloadModal
        show={isShowingSavePayloadModal}
        onHide={() => setIsShowingSavePayloadModal(false)}
        blueprintTestPayloads={blueprintTestPayloads}
        generatedBlueprintTestPayloads={generatedBlueprintTestPayloads}
        isSelectedTestPayloadGenerated={isSelectedTestPayloadGenerated}
        setGeneratedBlueprintTestPayloads={setGeneratedBlueprintTestPayloads}
        setBlueprintTestPayloads={setBlueprintTestPayloads}
        selectedTestPayload={selectedTestPayload}
        setSelectedTestPayload={setSelectedTestPayload}
        globalVarsAsString={globalVarsAsString}
        selectedTestLinkedAccount={selectedTestLinkedAccount}
        selectedTestCommonModel={selectedTestCommonModel}
      />
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-1">
          <Text variant="h6">Input Parameters</Text>
          <Text variant="sm">{inputParametersSubtitle}</Text>
        </div>
        {!blueprint.report_template_id ? (
          <div className="flex flex-col space-y-2">
            {updatedParameterSchema && (
              <Button
                size="sm"
                variant={ButtonVariant.SecondaryBlue}
                fullWidth={true}
                onClick={updateToLatestInputParameterSchema}
              >
                Update to latest input parameter schema
              </Button>
            )}
            <Tooltip
              title={
                shouldDisableGeneratePayload
                  ? ""
                  : "Select a linked account and save your blueprint to generate a test payload."
              }
              placement="right-end"
              className="w-full"
            >
              <Button
                loading={isLoadingGeneratedPayload}
                size="sm"
                fullWidth
                variant={ButtonVariant.SecondaryBlue}
                disabled={shouldDisableGeneratePayload}
                onClick={() => {
                  generateWritePayload(
                    selectedTestCommonModel ?? null,
                    selectedTestLinkedAccount ?? null
                  );
                  setIsLoadingGeneratedPayload(true);
                }}
              >
                Generate payload
              </Button>
            </Tooltip>
            <Dropdown
              placeholder="Select payload"
              onChange={(e: any) => loadSelectedTestPayload(e.target.value)}
              currentValue={selectedTestPayload?.id}
              choices={blueprintTestPayloads}
            />
            <Editor
              highlight={(code) => highlight(code, languages.js)}
              value={globalVarsAsString ?? ""}
              onValueChange={(code) => {
                setGlobalVarsAsString(code);
              }}
              padding={10}
              style={{
                backgroundColor: areInputParametersValid ? "white" : "#FFE0E0",
                border: "1px solid #d2ddec",
                borderRadius: 8,
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                minHeight: "100px",
                overflow: "auto",
              }}
            />
            <Button
              size="sm"
              fullWidth={true}
              variant={ButtonVariant.SecondaryBlue}
              onClick={() => setIsShowingSavePayloadModal(true)}
            >
              Save payload
            </Button>
            {selectedTestPayload && !isSelectedTestPayloadGenerated && (
              <Button
                size="sm"
                variant={ButtonVariant.DangerText}
                fullWidth={true}
                onClick={() => {
                  setIsShowingConfirmDeletePayloadModal(true);
                }}
              >
                Delete payload
              </Button>
            )}
          </div>
        ) : (
          <Dropdown
            placeholder="Select Report"
            onChange={(e: any) => setReportFileID(e.target.value)}
            currentValue={reportFileID}
            choices={(reportFiles ?? [])
              .filter((file) => file.linked_account_id == selectedTestLinkedAccount)
              .map((file) => {
                return { id: file.id, name: file.name ?? file.filename };
              })}
          />
        )}
      </div>
    </>
  );
};

export default BlueprintLeftPanelConsolePayload;
