import { AdminAPICategory } from "@merge-api/merge-javascript-shared";
import { ScraperDataType } from "../components/scraper/types";
import { IntegrationChecklistData, INTEGRATION_CATEGORY_STATUSES } from "../constants";
import {
  BlueprintOperationType,
  BlueprintTriggerType,
  BlueprintVersionPublishState,
  BlueprintStatus,
  JSONSchema,
} from "./Blueprints";

export enum UserType {
  admin = "ADMIN",
  member = "MEMBER",
}

export interface Organization {
  id: string;
  name: string;
  logo: string;
  auto_enable_new_integrations: boolean;
}

export interface PendingInvite {
  id: string;
  email: string;
  organization: Organization;
}

export interface User {
  is_active?: boolean;
  id: string;
  uuid: string;
  name: string;
  email: string;
  avatar: string;
  organization: Organization;
  type: UserType;
  is_staff: boolean;
  is_demo: boolean;
  is_two_factor_enabled: boolean;
}

export interface OrganizationConfigStatusField {
  completed: boolean;
  description: string;
  field_name: string;
}

export interface IntegrationCategoryStatusInfo {
  integration: string;
  category: APICategory;
  status: INTEGRATION_CATEGORY_STATUSES;
}

export interface ReportTemplate {
  id: string;
  integration: string;
  name: string;
  filename_pattern: string;
  is_filename_pattern_regex: boolean;
  created_at: string;
  required_fields: string[];
  return_schema: Record<string, any>;
}

export interface CreateOrUpdateReportTemplateProps {
  id?: string;
  integration_id: string;
  name: string;
  filename_pattern: string;
  is_filename_pattern_regex: boolean;
  required_fields: string[];
  return_schema: Record<string, any>;
}

export interface WebhookReceiverEventType {
  id: string;
  event: string;
  sample_json: Record<string, any>;
  integration?: string | null;
  header_schema: Record<string, any>;
  body_path?: (string | null)[] | null;
  should_authenticate: boolean;
  created_at: string;
}

export interface Integration {
  id: string;
  name: string;
  image: string;
  square_image?: string;
  coverage: string;
  base_api_url: string;
  api_documentation_url: string;
  modified_at: string;
  action_types: Partial<Record<BlueprintOperationType, Array<string>>>;
  last_modified_blueprint_at: string;
  number_of_linked_accounts: number | string;
  number_of_staged_tests: number;
  has_published_setup_steps: boolean;
  has_pagination_configurations: boolean;
  active: boolean;
  categories: Array<AdminAPICategory>;
  is_enabled?: boolean;
  organization_config_status: OrganizationConfigStatusField[];
  timestamp_format: string;
  api_request_retry_delay_seconds: number | string;
  notes_for_customers: string;
  required_organization_config_fields?: { [key: string]: string }[];
  additional_auth_field_keys?: string[];
  additional_header_fields?: string;
  auth_type: string;
  basic_auth_key_header_format?: string;
  basic_auth_key_token_exchange_access_token_lifespan_in_seconds?: number | string;
  basic_auth_key_token_exchange_header_format?: string;
  basic_auth_key_token_exchange_request_data_format?: string;
  basic_auth_key_token_exchange_response_auth_key_path?: string[];
  basic_auth_key_token_exchange_url?: string;
  oauth_access_token_lifespan_in_seconds?: number | string;
  oauth_authorize_url?: string;
  oauth_authorize_url_additional_redirect_uri_query_param_keys?: string[];
  oauth_authorize_url_param_keys_to_exclude?: string[];
  oauth_client_credential_source?: string;
  oauth_client_id?: string;
  oauth_client_secret?: string;
  oauth_grant_type?: string;
  oauth_params_scopes?: string;
  oauth_refresh_fields_to_exclude?: string[];
  oauth_response_token_key_path?: string[];
  oauth_token_url?: string;
  oauth_token_url_header_format?: string;
  oauth_token_url_params_location?: string;
  black_image?: string;
  body_text?: string;
  color?: string;
  color_image?: string;
  dark_grey_image?: string;
  header_text?: string;
  light_grey_image?: string;
  omit_logo_from_landing_page?: boolean;
  skip_landing_page_generation?: boolean;
  social_banner?: string;
  white_image?: string;
  categories_status_info?: Array<IntegrationCategoryStatusInfo>;
  integration_checklist: IntegrationChecklistData | undefined;
  report_templates: Array<ReportTemplate> | null;
  webhook_receiver_event_types: Array<WebhookReceiverEventType> | null;
  test_coverage?: IntegrationTestCoverage | null;
  number_of_active_production_linked_accounts?: number;
}

export interface IntegrationTestCoverage {
  read_coverage_percent: number | null;
  write_coverage_percent: number | null;
  total_coverage_percent: number | null;
  last_calculation_timestamp: number | null;
}

export interface OrganizationCategoryIntegration {
  id: string;
  organization: Organization;
  organization_config_status: OrganizationConfigStatusField[];
  category: string;
  integration: Integration;
  is_enabled: boolean;
  display_order: number | undefined;
}

export interface LinkedAccount {
  id: string;
  integration: Integration;
  created_at: string;
  completed_at: string;
  organization: Organization;
  end_user: EndUser;
  category: APICategory;
  is_sandbox_account: boolean;
  status?: LinkedAccountStatus;
  integration_builder_unmatched_credentials?: string;
}

export enum LinkedAccountStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  RELINK_NEEDED = "RELINK_NEEDED",
}

export const mapDisplayableLinkedAccountStatus = {
  [LinkedAccountStatus.COMPLETE]: "Active",
  [LinkedAccountStatus.INCOMPLETE]: "Incomplete",
  [LinkedAccountStatus.RELINK_NEEDED]: "Relink",
};

export interface EndUser {
  id: string;
  organization_name: string;
  email_address: string;
  organization_logo: string;
  origin_id: string;
}

export interface Webhook {
  id: string;
  event: string;
  target: string;
}

export interface CustomIntegration {
  id: string;
  name: string;
  image: ImageBitmap;
  active: boolean;
  category: string;
}

export interface SetupStep {
  id: string;
  step_number: number;
  title: string;
  description: string;
}

export interface CommonModelMappingStatus {
  common_model: string;
  completion: number;
  coverage: number;
}

export interface CommonModel {
  id: string;
  coverage: number;
  completion: number;
  mappingStatus: string;
  modified_at: string;
  name: string;
  reviewStatus: string;
  mappings: Array<CommonModelMappingStatus>;
}

export interface BlueprintMeta {
  id: string;
  name: string;
  human_name: string;
  blueprint_id: string;
  publish_state: BlueprintVersionPublishState;
  blueprint_overview_publish_state: BlueprintVersionPublishState;
  user: User | null;
  created_at: string;
  modified_at: string;
  comment: string;
  mapping_statuses: CommonModelMappingStatus[];
  trigger_type: BlueprintTriggerType;
  operation_type: BlueprintOperationType;
  written_common_model?: string;
  has_scraper: boolean;
  scraper_data_type?: ScraperDataType;
  endpoints?: APIEndpoint[];
  meta_blueprint_id?: string;
  status: BlueprintStatus;
  blueprint_overview_test_coverage?: BlueprintVersionTestCoverage;
}

export interface BlueprintVersionTestCoverage {
  coverage_percent: number | null;
  last_calculation_timestamp: number | null;
}

export enum APIEndpointMethod {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
  PUT = "PUT",
  OPTIONS = "OPTIONS",
  HEAD = "HEAD",
}

export enum TimestampParamLocation {
  QUERY_PARAM = "QUERY_PARAM",
  BODY = "BODY",
}

export enum APIRequestType {
  REST = "REST",
  SOAP = "SOAP",
  GRAPHQL = "GRAPHQL",
}

export enum APIRequestFormatSubtype {
  REST_JSON = "REST_JSON",
  SOAP_XML = "SOAP_XML",
  GRAPHQL_JSON = "GRAPHQL_JSON",
}

export enum APIResponseType {
  JSON = "JSON",
  IMAGE = "IMAGE",
  BYTES = "BYTES",
  XML = "XML",
  NUMBER = "NUMBER",
}

export enum ResponseBodyAdditionalParsingParser {
  JSON = "JSON",
  XML = "XML",
}

export enum APIRequestFormat {
  REQUEST_FORMAT_JSON = "REQUEST_FORMAT_JSON",
  REQUEST_FORMAT_MULTIPART_FORM = "REQUEST_FORMAT_MULTIPART_FORM",
  REQUEST_FORMAT_OCTET_STREAM = "REQUEST_FORMAT_OCTET_STREAM",
}

export interface ResponseBodyAdditionalParsingRule {
  path: string;
  parser: ResponseBodyAdditionalParsingParser;
}
export type ResponseBodyAdditionalParsing = ResponseBodyAdditionalParsingRule[];

export interface APIEndpoint {
  id: string;
  name: string;
  method: string;
  path: string;
  base_api_url_override: string;
  is_paginated: boolean;
  is_proxy: boolean;
  is_live_request: boolean;
  array_keys: JSONSchema;
  body_schema: JSONSchema;
  request_format: APIRequestFormat;
  header_schema: JSONSchema;
  path_param_schema: JSONSchema;
  pagination_configuration?: PaginationConfiguration;
  query_param_schema: JSONSchema;
  return_schema: JSONSchema;
  timestamp_param_key: string;
  timestamp_param_location: string;
  request_type: string;
  response_type: APIResponseType;
  response_body_additional_parsing: ResponseBodyAdditionalParsing;
  file_schema: JSONSchema;
  soap_request_body_format: string;
  soap_request_param_schema: JSONSchema;
  soap_request_use_body_templating_engine: boolean;
  request_body_base_config: string;
  request_body_param_schema: JSONSchema;
  request_body_format_subtype: APIRequestFormatSubtype;
  endpoint_modification_blueprint: string;
  disable_endpoint_modification: boolean;
}

export interface FileParameterData {
  param_key: string;
  filename: string;
  base64_data: any;
}

export enum PaginationType {
  CURSOR = "CURSOR",
  OFFSET = "OFFSET",
  PAGES = "PAGES",
}

export enum PaginationReturnLocation {
  HEADER = "HEADER",
  BODY = "BODY",
  JSON = "JSON",
}

export enum PaginationReturnValue {
  QUERY_PARAM = "QUERY_PARAM",
  URL = "URL",
  PATH_PARAM = "PATH_PARAM",
  BODY_PARAM = "BODY_PARAM",
}

export interface PaginationConfiguration {
  id: string;
  api_endpoint: APIEndpoint;
  integration_id: string;
  pagination_type: PaginationType;
  return_value_key: string;
  return_value_parameter: string;
  return_value_location: string;
  return_body_has_next_key: string;
  input_query_parameter_key: string;
  return_value_type: PaginationReturnValue;
  timestamp_param_key: string;
  request_body_parameter_key: string;
  request_path_parameter_key: string;
  request_query_parameter_key: string;
  response_pagination_value_location: string;
  response_pagination_value_type: string;
  response_max_items_key_path: string[];
  item_initial_value: number | string;
  item_limit_amount: number | string;
  item_limit_param_key: string;
  response_body_array_key_path: string[];
  response_pagination_value_key_path: string[];
  response_pagination_header_parameter: string;
  response_has_next_bool_key_path: string[];
}

export interface Comment {
  id: string;
  user: User;
  body: string;
  created_at: string;
}

export enum APICategory {
  hris = "hris",
  ats = "ats",
  accounting = "accounting",
  ticketing = "ticketing",
  crm = "crm",
  mktg = "mktg",
  filestorage = "filestorage",
  datawarehouse = "datawarehouse",
}

export interface CommonModelInfo {
  id: string;
  remote_id: string;
  name: string;
}

export interface IntegrationCommonModelConfig {
  category: string;
  model: string;
  unique_id_templates: IntegrationCommonModelTemplate[];
}

export enum IntegrationCommonModelTemplateStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DEPRECATED = "DEPRECATED",
}

export interface IntegrationCommonModelTemplate {
  id: string;
  is_default: boolean;
  status: string;
  support_md5_hashing: boolean;
  template_name: string;
  unique_id_fields: IntegrationCommonModelTemplateField[];
  eligible_fields: IntegrationCommonModelTemplateField[];
  category: string;
  model: string;
  non_common_model_transformations: string[];
}

export interface IntegrationCommonModelTemplateField {
  id: string | null | undefined;
  field_name: string;
  is_common_model_field: boolean | undefined;
  use_unparsed_value: boolean | undefined;
  should_allow_nulls: boolean | undefined;
  transformation: string | null | undefined;
  description: string;
  field_type: string;
}

export interface IntegrationCommonModelContentType {
  id: number;
  category: string;
  common_model_name: string;
}

export interface IntegrationCommonModelAndFields {
  id: string;
  category: string;
  model_name: string;
  model_fields: string[];
}

export enum AuthType {
  BASIC_AUTH = "BASIC",
  OAUTH2 = "OAUTH2",
  OAUTH1 = "OAUTH1",
  BASIC_AUTH_WITH_TOKEN_EXCHANGE = "BASIC_WITH_TOKEN_EXCHANGE",
  AUTH_TYPE_SCRAPER = "SCRAPER",
  AUTH_TYPE_SFTP = "SFTP",
}

export enum RequestMockFilterOptions {
  ALL_REQUESTS = "All requests",
  USED_REQUESTS = "Used requests",
  UNUSED_REQUESTS = "Unused requests",
}
