import { SetStateAction, useEffect } from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import { Chips } from "@merge-api/merge-javascript-shared";
import CommonModelDisplay from "../shared/CommonModelDisplay";
import RequiredFieldAsterisk from "../../../shared/RequiredFieldAsterisk";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import {
  UserFacingFilterDetails,
  UserFacingFilterType,
} from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import SelectiveSyncFilterFilterTypeCommonModelFieldKeyContainer from "./SelectiveSyncFilterFilterTypeCommonModelFieldKeyContainer";

interface TestProps {
  integrationFilterTypes: { [id: string]: UserFacingFilterType };
  currentCommonModelID: string;
  setCurrentCommonModelID: (value: SetStateAction<string>) => void;
  isExistingCommonModelField: boolean | null;
  setIsExistingCommonModelField: (value: SetStateAction<boolean | null>) => void;
  isEditingExistingS2FilterSchema: boolean;
  commonModelField: string | null;
  setCommonModelField: (value: SetStateAction<string | null>) => void;
  setFilterTypeID: (filterTypeID: string) => void;
  setRemoteKeyName: (remoteKeyName: string) => void;
  filterDetails: UserFacingFilterDetails | null;
  setIsMissingRequiredFields: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserFacingFilterForm = ({
  integrationFilterTypes,
  currentCommonModelID,
  setCurrentCommonModelID,
  isExistingCommonModelField,
  setIsExistingCommonModelField,
  isEditingExistingS2FilterSchema,
  commonModelField,
  setCommonModelField,
  setFilterTypeID,
  setRemoteKeyName,
  setIsMissingRequiredFields,
  filterDetails,
}: TestProps) => {
  const handleButtonClick = (value: boolean | string | number) => {
    setCommonModelField(null);
    setIsExistingCommonModelField(value as boolean);
  };

  const filterTypeCommonModelIds = (filterTypes: Array<UserFacingFilterType>) => {
    return Array.from(
      new Set(filterTypes.map((filterType) => filterType.common_model_class_id))
    ).sort();
  };

  const isCommonModelFieldButtonOptions = [
    {
      value: true,
      text: "Yes",
      disabled: isEditingExistingS2FilterSchema,
      selected: isExistingCommonModelField == true,
      onClick: handleButtonClick,
    },
    {
      value: false,
      text: "No",
      disabled: isEditingExistingS2FilterSchema,
      selected: isExistingCommonModelField == false,
      onClick: handleButtonClick,
    },
  ];

  useEffect(() => {
    setIsMissingRequiredFields(
      !(!!filterDetails?.remote_key_name && !!filterDetails?.filter_type_id?.id)
    );
  }, [filterDetails]);

  const renderDropdownItem = (id: string) => <CommonModelDisplay commonModelID={id} />;

  return (
    <>
      <TypeaheadHeader
        title="Select Common Model"
        subtitle="The common model that the selective sync filter will be applied to"
        options={filterTypeCommonModelIds(Object.values(integrationFilterTypes))}
        value={currentCommonModelID}
        required={!currentCommonModelID}
        renderOption={(modelID) => renderDropdownItem(modelID ?? "")}
        className="mb-6 mt-2"
        disabled={isEditingExistingS2FilterSchema}
        onChange={(_, modelID) => {
          setCurrentCommonModelID(modelID as SetStateAction<string>);
          setCommonModelField(null);
        }}
      />
      <Text variant="h6">
        Is this filter going to be applied on an existing Common Model field?{" "}
        {!(isExistingCommonModelField != null) && <RequiredFieldAsterisk />}
      </Text>
      <Text variant="sm" className="text-gray-70">
        This indicates if the filter will be applied to a field that exists on our common models,
        e.g. Employee.last_name, or if it’s filtering on a 3rd party field, like Workday’s
        integration_system_id
      </Text>
      <Chips options={isCommonModelFieldButtonOptions} className="mb-6 mt-2" />
      {isExistingCommonModelField != null && (
        <SelectiveSyncFilterFilterTypeCommonModelFieldKeyContainer
          integrationFilterTypes={integrationFilterTypes}
          isExistingCommonModelField={isExistingCommonModelField}
          currentCommonModelID={currentCommonModelID}
          filterCommonModelField={commonModelField}
          isEditingExistingS2FilterSchema={isEditingExistingS2FilterSchema}
          setFilterTypeID={setFilterTypeID}
        />
      )}
      <div className="flex flex-col">
        <TextFieldHeader
          title="Remote key name"
          subtitle="The key name that will be shown to end users"
          required={!filterDetails?.remote_key_name}
          value={filterDetails?.remote_key_name ?? ""}
          disabled={isEditingExistingS2FilterSchema}
          className="mt-4"
          onChange={(newEvent) => {
            const value = newEvent.target.value;
            setRemoteKeyName(value);
          }}
        />
      </div>
    </>
  );
};

export default UserFacingFilterForm;
