import SubLeftNavigationContainer from "../../shared/layout/SubLeftNavigationContainer";
import { Redirect, Route, Switch, match } from "react-router-dom";
import { getPublishModulePath } from "../../../router/RouterUtils";
import IntegrationPublishModuleSubtabWithValidator from "./IntegrationPublishModuleSubtab";

type Props = { integrationID: string; match: match };

const IntegrationPublishRequestTab = ({ integrationID }: Props) => {
  return (
    <SubLeftNavigationContainer
      subtabs={[{ label: "Publishing", destination: getPublishModulePath(integrationID) }]}
    >
      <Switch>
        <Route
          exact
          path={getPublishModulePath(integrationID)}
          component={() => (
            <IntegrationPublishModuleSubtabWithValidator integrationID={integrationID} />
          )}
        />
        <Redirect to={getPublishModulePath(integrationID)} />
      </Switch>
    </SubLeftNavigationContainer>
  );
};

export default IntegrationPublishRequestTab;
