/*
 * Sample values to build frontend
 */

import { ReactNode } from "react";
import { AuthType, ResponseBodyAdditionalParsingParser } from "../../../models/Entities";
import {
  ClientCredentialSource,
  GrantTypes,
  ParamsLocation,
} from "../../integrations/auth-config/AuthConfigShared";
import { IntegrationChecklistData } from "../../../constants";
import { AdminAPICategory } from "@merge-api/merge-javascript-shared";

export enum BuilderStatus {
  NOT_COMPLETED = "NOT_COMPLETED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export interface Section {
  sectionIdentifier: string;
  previousSectionIdentifier: string | null;
  nextSectionIdentifier: string | null;
  icon?: ReactNode;
  name: string;
  state: BuilderStatus;
  path: string;
  disabled: boolean;
  subSections?: Section[];
}

/*
 * Navigation section values
 */

export const ADD_NEW_LINKED_ACCOUNT = "Add new Linked Account";

/*
 * Credential selection enum values during linked account selection process
 */

export enum LinkedAccountCredentialSelection {
  API_KEY = "API_KEY",
  SUBDOMAIN = "SUBDOMAIN",
  CLIENT_ID = "CLIENT_ID",
  CLIENT_SECRET = "CLIENT_SECRET",
  OTHER = "OTHER",
}

export interface IntegrationBuilderUnmatchedCredentails {
  type: LinkedAccountCredentialSelection;
  value: string;
  description: string;
}

/*
 * General enum values for our three types of auth
 */

export enum GeneralAuthType {
  API = "API",
  SCRAPER = "SCRAPER",
  SFTP = "SFTP",
}

/*
 * Enums that define whether for an OAuth token `expires_in` refer to to the time the token is fetched
 * or the time the token is created
 */

export enum TokenExpiresInType {
  FETCHED = "FETCHED",
  CREATED = "CREATED",
}

/*
 * Enums that define a datetime selection
 */

export enum DateTimeFormatEnums {
  ISO_8601 = "ISO_FORMAT",
  UNIX = "MILLISECONDS",
}

export enum LinkingFlowStepTypeEnums {
  STEP_TYPE_USERNAME_PASSWORD = "STEP_TYPE_USERNAME_PASSWORD",
  STEP_TYPE_CUSTOM_DOMAIN = "STEP_TYPE_CUSTOM_DOMAIN",
  STEP_TYPE_CUSTOM_PATH = "STEP_TYPE_CUSTOM_PATH",
  STEP_TYPE_API_KEY = "STEP_TYPE_API_KEY",
  STEP_TYPE_SERVICE_ACCOUNT = "STEP_TYPE_SERVICE_ACCOUNT",
  STEP_TYPE_OAUTH = "STEP_TYPE_OAUTH",
  STEP_TYPE_ADDITIONAL_AUTH = "STEP_TYPE_ADDITIONAL_AUTH",
  STEP_TYPE_SSL_CERT_KEY = "STEP_TYPE_SSL_CERT_KEY",
  STEP_TYPE_OAUTH_CLIENT_CREDENTIALS = "STEP_TYPE_OAUTH_CLIENT_CREDENTIALS",
  STEP_TYPE_OAUTH_REFRESH_TOKEN = "STEP_TYPE_OAUTH_REFRESH_TOKEN",
  STEP_TYPE_OVERRIDE_OAUTH_TOKEN_URL_AND_REFRESH_TOKEN = "STEP_TYPE_OVERRIDE_OAUTH_TOKEN_URL_AND_REFRESH_TOKEN",
  STEP_TYPE_TEXT_ONLY = "STEP_TYPE_TEXT_ONLY",
  STEP_TYPE_MFA_CODE = "STEP_TYPE_MFA_CODE",
  STEP_TYPE_SECURITY_QUESTION = "STEP_TYPE_SECURITY_QUESTION",
  STEP_TYPE_OVERRIDE_BASE_API_URL = "STEP_TYPE_OVERRIDE_BASE_API_URL",
  STEP_TYPE_ORG_CONSENT = "STEP_TYPE_ORG_CONSENT",
  STEP_TYPE_ORG_PURCHASE = "STEP_TYPE_ORG_PURCHASE",
  STEP_TYPE_CHOICE = "STEP_TYPE_CHOICE",
  STEP_TYPE_TRELLO_OAUTH1 = "STEP_TYPE_TRELLO_OAUTH1",
  STEP_TYPE_SFTP_CREDENTIALS_V2 = "STEP_TYPE_SFTP_CREDENTIALS_V2",
  STEP_TYPE_SFTP_REQUIRED_FIELDS = "STEP_TYPE_SFTP_REQUIRED_FIELDS",
  STEP_TYPE_DEPRECATION_NOTICE = "STEP_TYPE_DEPRECATION_NOTICE",
  STEP_TYPE_WHITELIST_IP_ADDRESS = "STEP_TYPE_WHITELIST_IP_ADDRESS",
  STEP_TYPE_SFTP_OR_CSV = "STEP_TYPE_SFTP_OR_CSV",
  STEP_TYPE_URL_PARSER = "STEP_TYPE_URL_PARSER",
}

export const LinkingFlowStepTypeDisplayNameMap: { [key: string]: string } = {
  STEP_TYPE_USERNAME_PASSWORD: "Username & password",
  STEP_TYPE_CUSTOM_DOMAIN: "Custom domain",
  STEP_TYPE_CUSTOM_PATH: "Custom path",
  STEP_TYPE_API_KEY: "API key",
  STEP_TYPE_SERVICE_ACCOUNT: "Service account",
  STEP_TYPE_OAUTH: "OAuth",
  STEP_TYPE_ADDITIONAL_AUTH: "Additional auth",
  STEP_TYPE_SSL_CERT_KEY: "SSL Certificate Key",
  STEP_TYPE_OAUTH_CLIENT_CREDENTIALS: "OAuth client credentials",
  STEP_TYPE_OAUTH_REFRESH_TOKEN: "OAuth refresh token",
  STEP_TYPE_OVERRIDE_OAUTH_TOKEN_URL_AND_REFRESH_TOKEN:
    "Override OAuth token URL and refresh token",
  STEP_TYPE_TEXT_ONLY: "Text only",
  STEP_TYPE_MFA_CODE: "MFA code",
  STEP_TYPE_SECURITY_QUESTION: "Security question",
  STEP_TYPE_OVERRIDE_BASE_API_URL: "Override base API URL",
  STEP_TYPE_ORG_CONSENT: "Org consent",
  STEP_TYPE_ORG_PURCHASE: "Org purchase",
  STEP_TYPE_CHOICE: "Choice",
  STEP_TYPE_TRELLO_OAUTH1: "Trello OAuth1",
  STEP_TYPE_SFTP_CREDENTIALS_V2: "SFTP credentials",
  STEP_TYPE_SFTP_REQUIRED_FIELDS: "SFTP required fields",
  STEP_TYPE_DEPRECATION_NOTICE: "Deprecation notice",
  STEP_TYPE_WHITELIST_IP_ADDRESS: "Whitelist IP address",
  STEP_TYPE_SFTP_OR_CSV: "SFTP or CSV",
  STEP_TYPE_URL_PARSER: "URL parser",
};

export const LinkingFlowStepTypeDisplayNameReverseMap: {
  [key: string]: LinkingFlowStepTypeEnums;
} = {
  "Username & password": LinkingFlowStepTypeEnums.STEP_TYPE_USERNAME_PASSWORD,
  "Custom domain": LinkingFlowStepTypeEnums.STEP_TYPE_CUSTOM_DOMAIN,
  "Custom path": LinkingFlowStepTypeEnums.STEP_TYPE_CUSTOM_PATH,
  "API key": LinkingFlowStepTypeEnums.STEP_TYPE_API_KEY,
  "Service account": LinkingFlowStepTypeEnums.STEP_TYPE_SERVICE_ACCOUNT,
  OAuth: LinkingFlowStepTypeEnums.STEP_TYPE_OAUTH,
  "Additional auth": LinkingFlowStepTypeEnums.STEP_TYPE_ADDITIONAL_AUTH,
  "SSL Certificate Key": LinkingFlowStepTypeEnums.STEP_TYPE_SSL_CERT_KEY,
  "OAuth client credentials": LinkingFlowStepTypeEnums.STEP_TYPE_OAUTH_CLIENT_CREDENTIALS,
  "OAuth refresh token": LinkingFlowStepTypeEnums.STEP_TYPE_OAUTH_REFRESH_TOKEN,
  "Override OAuth token URL and refresh token":
    LinkingFlowStepTypeEnums.STEP_TYPE_OVERRIDE_OAUTH_TOKEN_URL_AND_REFRESH_TOKEN,
  "Text only": LinkingFlowStepTypeEnums.STEP_TYPE_TEXT_ONLY,
  "MFA code": LinkingFlowStepTypeEnums.STEP_TYPE_MFA_CODE,
  "Security question": LinkingFlowStepTypeEnums.STEP_TYPE_SECURITY_QUESTION,
  "Override base API URL": LinkingFlowStepTypeEnums.STEP_TYPE_OVERRIDE_BASE_API_URL,
  "Org consent": LinkingFlowStepTypeEnums.STEP_TYPE_ORG_CONSENT,
  "Org purchase": LinkingFlowStepTypeEnums.STEP_TYPE_ORG_PURCHASE,
  Choice: LinkingFlowStepTypeEnums.STEP_TYPE_CHOICE,
  "Trello OAuth1": LinkingFlowStepTypeEnums.STEP_TYPE_TRELLO_OAUTH1,
  "SFTP credentials": LinkingFlowStepTypeEnums.STEP_TYPE_SFTP_CREDENTIALS_V2,
  "SFTP required fields": LinkingFlowStepTypeEnums.STEP_TYPE_SFTP_REQUIRED_FIELDS,
  "Deprecation notice": LinkingFlowStepTypeEnums.STEP_TYPE_DEPRECATION_NOTICE,
  "Whitelist IP address": LinkingFlowStepTypeEnums.STEP_TYPE_WHITELIST_IP_ADDRESS,
  "SFTP or CSV": LinkingFlowStepTypeEnums.STEP_TYPE_SFTP_OR_CSV,
  "URL parser": LinkingFlowStepTypeEnums.STEP_TYPE_URL_PARSER,
};

export enum LinkingFlowStepPostActionTypeEnums {
  POST_ACTION_TYPE_ADP_READ_CREDENTIALS = "POST_ACTION_TYPE_ADP_READ_CREDENTIALS",
  POST_ACTION_TYPE_COMEET_ENCODE_JWT_TOKEN = "POST_ACTION_TYPE_COMEET_ENCODE_JWT_TOKEN",
  POST_ACTION_TYPE_XERO_FETCH_TENANT_ID = "POST_ACTION_TYPE_XERO_FETCH_TENANT_ID",
  POST_ACTION_TYPE_QBO_ADD_REALM_ID_TO_URL = "POST_ACTION_TYPE_QBO_ADD_REALM_ID_TO_URL",
  POST_ACTION_TYPE_SAP_GENERATE_ASSERTION = "POST_ACTION_TYPE_SAP_GENERATE_ASSERTION",
  POST_ACTION_TYPE_BAMBOO_HR_OPEN_ID_LOGIN = "POST_ACTION_TYPE_BAMBOO_HR_OPEN_ID_LOGIN",
  POST_ACTION_TYPE_ADP_EXTRACT_ORG_ID = "POST_ACTION_TYPE_ADP_EXTRACT_ORG_ID",
}

export enum LinkingFlowStepPathTypeEnums {
  PATH_TYPE_MANUAL_LINKING = "MANUAL_LINKING",
  PATH_TYPE_PARTNERSHIP = "PARTNERSHIP",
  PATH_TYPE_SCRAPER_MFA = "SCRAPER_MFA",
  PATH_TYPE_SCRAPER_SECURITY_QUESTION = "SCRAPER_SECURITY_QUESTION",
  PATH_TYPE_SCRAPER = "SCRAPER",
}

export enum LinkChoiceStepOptionFormFieldValueEnums {
  BASE_URL_CONFIG_OVERRIDE_KEY = "baseURL",
  CUSTOM_DOMAIN_CONFIG_OVERRIDE_KEY = "customDomain",
  CUSTOM_PATH_CONFIG_OVERRIDE_KEY = "customPath",
  OAUTH_TOKEN_URL_OVERRIDE_KEY = "overrideOAuthTokenURL",
  OAUTH_AUTHORIZE_URL_OVERRIDE_KEY = "overrideOAuthAuthorizeURL",
  BASIC_AUTH_TOKEN_EXCHANGE_URL_OVERRIDE_KEY = "overrideBasicAuthKeyTokenExchangeURL",
}

export const LinkChoiceStepOptionFormFieldDisplayNameMap: {
  [key: string | LinkChoiceStepOptionFormFieldValueEnums]: string;
} = {
  [LinkChoiceStepOptionFormFieldValueEnums.BASE_URL_CONFIG_OVERRIDE_KEY]: "Base URL",
  [LinkChoiceStepOptionFormFieldValueEnums.CUSTOM_DOMAIN_CONFIG_OVERRIDE_KEY]: "Custom domain",
  [LinkChoiceStepOptionFormFieldValueEnums.CUSTOM_PATH_CONFIG_OVERRIDE_KEY]: "Custom path",
  [LinkChoiceStepOptionFormFieldValueEnums.OAUTH_TOKEN_URL_OVERRIDE_KEY]:
    "Override OAuth token URL",
  [LinkChoiceStepOptionFormFieldValueEnums.OAUTH_AUTHORIZE_URL_OVERRIDE_KEY]:
    "Override OAuth authorize URL",
  [LinkChoiceStepOptionFormFieldValueEnums.BASIC_AUTH_TOKEN_EXCHANGE_URL_OVERRIDE_KEY]:
    "Override basic auth key token exchange URL",
};

export const LinkChoiceStepOptionFormFieldDisplayNameReverseMap: {
  [key: string]: LinkChoiceStepOptionFormFieldValueEnums;
} = {
  "Base URL": LinkChoiceStepOptionFormFieldValueEnums.BASE_URL_CONFIG_OVERRIDE_KEY,
  "Custom domain": LinkChoiceStepOptionFormFieldValueEnums.CUSTOM_DOMAIN_CONFIG_OVERRIDE_KEY,
  "Custom path": LinkChoiceStepOptionFormFieldValueEnums.CUSTOM_PATH_CONFIG_OVERRIDE_KEY,
  "Override OAuth token URL": LinkChoiceStepOptionFormFieldValueEnums.OAUTH_TOKEN_URL_OVERRIDE_KEY,
  "Override OAuth authorize URL":
    LinkChoiceStepOptionFormFieldValueEnums.OAUTH_AUTHORIZE_URL_OVERRIDE_KEY,
  "Override basic auth key token exchange URL":
    LinkChoiceStepOptionFormFieldValueEnums.BASIC_AUTH_TOKEN_EXCHANGE_URL_OVERRIDE_KEY,
};

export enum LinkChoiceStepOptionAccountTypeEnums {
  END_USER_PRODUCTION_ACCOUNT = "END_USER_PRODUCTION_ACCOUNT",
  END_USER_SANDBOX_ACCOUNT = "END_USER_SANDBOX_ACCOUNT",
}

export enum IntegrationSetupChecklistItemIconEnums {
  INTEGRATION_SETUP_CHECKLIST_ITEM_ICON_WIFI = "WIFI",
  INTEGRATION_SETUP_CHECKLIST_ITEM_ICON_FILE_TEXT = "FILE_TEXT",
  INTEGRATION_SETUP_CHECKLIST_ITEM_ICON_CLIPBOARD_EDIT = "CLIPBOARD_EDIT",
}

export const ValidStepTypesForInputConfigurationFields = [
  LinkingFlowStepTypeEnums.STEP_TYPE_ADDITIONAL_AUTH,
  LinkingFlowStepTypeEnums.STEP_TYPE_CUSTOM_DOMAIN,
  LinkingFlowStepTypeEnums.STEP_TYPE_OVERRIDE_BASE_API_URL,
];

export const ValidStepTypesForConfirmMessageField = [
  LinkingFlowStepTypeEnums.STEP_TYPE_ORG_CONSENT,
  LinkingFlowStepTypeEnums.STEP_TYPE_SERVICE_ACCOUNT,
];

export const ValidStepTypesForIntegrationSetupChecklistItems = [
  LinkingFlowStepTypeEnums.STEP_TYPE_TEXT_ONLY,
];

/*
 * Integration for Integration Builder
 */

export interface IntegrationForIntegrationBuilder {
  /* --- Base fields --- */
  id?: string;
  name?: string;
  categories?: Array<AdminAPICategory>;
  base_api_url?: string;
  api_documentation_url?: string;
  /* --- Marketing fields --- */
  color?: string;
  header_text?: string;
  body_text?: string;
  abbreviated_name?: string | null;
  image?: string;
  color_image?: string;
  dark_grey_image?: string;
  square_image?: string;
  social_banner?: string;
  white_image?: string;
  black_image?: string;
  light_grey_image?: string;
  /* --- Customer-facing fields --- */
  help_center_auth_guide_url?: string | null;
  api_endpoints_to_documentation_urls?: { [key: string]: any };
  notes_for_customers?: string | null;
  /* --- Link configuration fields --- */
  required_organization_config_fields?: Object | null;
  optional_organization_config_fields?: Object | null;
  show_admin_needed_screen?: boolean;
  /* --- Fields that affect blueprint runs --- */
  is_soap_integration?: boolean;
  is_multi_subsidiary?: boolean;
  should_use_scraper_proxy?: boolean;
  extra_scraper_headers?: string | null;
  webhook_setup_guide_url?: string | null;
  webhook_listener_instructions?: string | null;
  timestamp_format?: string;
  retain_authorization_header_on_redirect?: boolean;
  override_request_timeout_seconds?: number;
  api_request_retry_delay_seconds?: number;
  require_ssl_certificates?: boolean;
  convert_body_data_to_string?: boolean;
  convert_strings_to_numbers?: boolean;
  integration_checklist?: IntegrationChecklistData | undefined;
}

export interface IntegrationLogos {
  image?: File;
  square_image?: File;
  color_image?: File;
  white_image?: File;
  light_grey_image?: File;
  dark_grey_image?: File;
  black_image?: File;
  social_banner?: File;
}

/*
 * AuthConfigMeta but for Integration Builder
 */

export interface AuthConfigIntegrationBuilder {
  id: string;
  does_user_have_edit_permission?: boolean;
  name: string;
  auth_type: AuthType;
  is_published: boolean;
  additional_header_fields: string;
  is_default_for_auth_type: boolean;
  // the following are for BASIC auth
  basic_auth_key_header_format: string;
  additional_auth_field_keys: string[];
  basic_auth_key_token_exchange_request_data_convert_to_string: boolean;

  // the following are for BASIC AUTH WITH TOKEN EXCHANGE
  basic_auth_key_token_exchange_url: string;
  basic_auth_key_token_exchange_header_format: string;
  basic_auth_key_token_exchange_request_data_format: string;
  basic_auth_key_token_exchange_response_auth_key_path: string[];
  basic_auth_key_token_exchange_access_token_lifespan_in_seconds: number;
  basic_auth_key_token_access_token_lifespan_key_path: string[];

  // OAuth
  oauth_authorize_url: string;
  oauth_token_url: string;
  oauth_client_id: string;
  oauth_client_secret: string;
  oauth_refresh_url: string;
  oauth_token_url_params_location: ParamsLocation;
  oauth_response_token_key_path: string[];
  oauth_grant_type: GrantTypes;
  oauth_client_credential_source: ClientCredentialSource;
  oauth_access_token_lifespan_in_seconds: number;
  oauth_access_token_expiration_key_path: string;
  oauth_refresh_token_lifespan_in_seconds: number;
  oauth_params_scopes: string;
  oauth_authorize_url_additional_redirect_uri_query_param_keys: string[];
  oauth_authorize_url_param_keys_to_exclude: string[];
  oauth_token_url_header_format: string;
  oauth_token_url_header_keys_to_exclude: string[];
  oauth_request_fields_to_exclude: string[] | null;
  oauth_should_refresh_access_token: boolean;
  oauth_should_fetch_access_token: boolean;
  oauth_use_pkce: boolean;
  oauth_response_created_at_key_path: string[];
  oauth_response_created_datetime_format: DateTimeFormatEnums;
  oauth_refresh_fields_to_exclude: string[];
  oauth_set_soap_body_header_to_null: boolean;

  // Scraper
  scraper_session_lifespan_in_seconds: number;
  service_account_field_keys: string[];

  // SFTP
  should_end_user_provide_public_key: boolean;
  should_end_user_provide_pgp_public_key: boolean;
  is_manual_upload_only_sftp: boolean;
}

/*
 * WebAddressSchema for Integration Builder
 */

export interface WebAddressSchemaIntegrationBuilder extends WebAddressSchemaIntegrationBuilderBase {
  id: string;
}

export interface WebAddressSchemaIntegrationBuilderBase {
  scheme: string;
  subdomain?: string;
  second_level_domain: string;
  top_level_domain: string;
  description?: string;
}

/*
 * API Endpoint for Integration Builder
 */
export enum APIEndpointPreviewStatus {
  PROCESSING = "PROCESSING",
  PENDING = "PENDING",
}

export interface APIEndpointBodyParsingConfiguration {
  key_path: string[];
  format: ResponseBodyAdditionalParsingParser;
}

export interface APIEndpointRestRequestConfigIntegrationBuilder {
  body_schema: Object;
  content_type: string;
}

export interface APIEndpointSoapRequestConfigIntegrationBuilder {
  request_body_format: string;
  request_body_param_schema: Object;
  does_request_use_body_templating_engine: boolean;
}

export interface APIEndpointGraphQLRequestConfigIntegrationBuilder {
  request_body_base_config: string;
  request_body_param_schema: Object;
}

export interface APIEndpointTimestampFilterConfigIntegrationBuilder {
  param_key: string;
  param_location: string | null;
  format_override: string | null;
  param_prefix: string | null;
  param_suffix: string | null;
  buffer_hours: number | null;
  default_override: string | null;
}

export interface APIEndpointRequestConfigIntegrationBuilder {
  method: string;
  path: string;
  base_url_override: string;
  path_param_schema: Object;
  query_param_schema: Object;
  header_schema: Object;
  timestamp_filter_configuration: APIEndpointTimestampFilterConfigIntegrationBuilder | null;
  request_type: string;
  file_schema: Object;
  rest_request_configuration: APIEndpointRestRequestConfigIntegrationBuilder | null;
  soap_request_configuration: APIEndpointSoapRequestConfigIntegrationBuilder | null;
  graphql_request_configuration: APIEndpointGraphQLRequestConfigIntegrationBuilder | null;
  url_encoded_query_params_format: Object | null;
  url_encoded_query_param_schema: Object;
  should_filter_empty_values_from_body_data: boolean;
  should_accept_merge_with_json_request_body: boolean;
  should_accept_raw_json_request_body: boolean;
  should_override_integration_convert_strings_to_numbers: boolean | null;
  should_override_integration_convert_body_data_to_string: boolean | null;
}

export interface APIEndpointPaginationConfigurationInformation {
  pagination_configuration_id: string | null;
  pagination_response_body_array_key_path_override: string[] | null;
}

export interface APIEndpointResponseConfigIntegrationBuilder {
  response_type: string;
  response_schema: string | Object; // For now, needs to also be string to support JSONSchemaFieldHeader. Otherwise will crash when editing.
  allowed_error_codes: string[] | null;
  additional_response_body_parsing: APIEndpointBodyParsingConfiguration[] | null;
  sync_cursor_key_path: string[] | null;
}

export interface APIEndpointIntegrationBuilder {
  id?: string | null;
  integration_id: string;
  name: string;
  api_protocol: string;
  endpoint_type: string | null;
  request_configuration: APIEndpointRequestConfigIntegrationBuilder;
  response_configuration: APIEndpointResponseConfigIntegrationBuilder;
  pagination_configuration_information: APIEndpointPaginationConfigurationInformation | null;
  is_endpoint_modification_disabled: boolean;
  rate_limit_configurations?: RateLimitConfigurationIntegrationBuilder[];
}

export interface APIEndpointPreviewIntegrationBuilder {
  id?: string | null;
  method: string;
  path: string;
  status: string;
  name: string;
  description: string;
  autogen_runs?: IntegrationBuilderAutogenRun[];
}

/**
 * Rate Limit Configuration for Integration Builder
 */

export enum RateLimitResetTimestampFormat {
  TIMESTAMP = "RESET_TIMESTAMP_FORMAT_TIMESTAMP",
  TTL = "RESET_TIMESTAMP_FORMAT_TTL",
}

export enum RateLimitType {
  REQUEST_FREQUENCY = "INTEGRATION_RATE_LIMIT_TYPE_REQUEST_FREQUENCY",
  MODEL_COUNT = "INTEGRATION_RATE_LIMIT_TYPE_MODEL_COUNT_MAXIMUM",
  COMPLEXITY_COUNT = "INTEGRATION_RATE_LIMIT_TYPE_COMPLEXITY_COUNT_MAXIMUM",
  CONNECTION_COUNT = "INTEGRATION_RATE_LIMIT_TYPE_CONNECTION_COUNT_MAXIMUM",
  FAILED_REQUEST_COUNT = "INTEGRATION_RATE_LIMIT_TYPE_FAILED_REQUESTS_COUNT",
}

export enum RateLimitTimePeriod {
  END_OF_MONTH = "end of month",
  MONTHS = "months",
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes",
  SECONDS = "seconds",
}

export interface RateLimitConfigurationIntegrationBuilder {
  rate_limit_identifier?: string;
  temp_id?: string;
  id?: string;
  is_active: boolean;
  api_endpoint?: string; // TODO: Remove after reconfiguring other rate limit endpoints that still use this as the ID field https://app.asana.com/0/1206958832426002/1207807506261551/f
  api_endpoint_id?: string;
  api_endpoint_name?: string;
  rate_limit_type: RateLimitType;
  rate_limit_time_period: RateLimitTimePeriod;
  rate_limit_time_value?: number;
  default_rate_limit_max_count?: number | null;
  default_rate_limit_max_points?: number | null;
  default_rate_limit_threshold?: number;
  response_header_max_count_key_path?: string[] | null;
  should_set_limit_max_count_via_headers: boolean;
  response_header_rate_limit_current_count_key_path?: string[] | null;
  response_header_rate_limit_reset_timestamp_key_path?: string[] | null;
  reset_expiration_format?: RateLimitResetTimestampFormat | null;
  should_update_rate_limit_current_count_via_headers: boolean;
  response_header_rate_limit_count_left_key_path?: string[] | null;
  should_update_rate_limit_count_left_via_headers: boolean;
  aggressive_rate_limit_threshold?: number | null;
  default_increment: number;
  maximum_retry_count?: number | null;
  retry_backoff_factor?: number | null;
}

/**
 * Pagination Configurations for Integration Builder
 */
export interface PaginationConfigurationIntegrationBuilder {
  id?: string;
  api_endpoints?: APIEndpointIntegrationBuilder[] | null;
  is_default_for_integration: boolean;
  name: string;
  pagination_type: string;
  description?: string | null;
  request_information: PaginationConfigurationRequestInformation;
  response_information: PaginationConfigurationResponseInformation;
  session_information: PaginationConfigurationSessionInformation;
  sort_information: PaginationConfigurationSortInformation;
}

export interface PaginationConfigurationRequestInformation {
  pagination_value_information: PaginationValueInformation;
  items_per_request_information: PaginationItemsPerRequestInformation | null;
  page_size_information: PaginationPageSizeInformation | null;
}

export interface PaginationPageSizeInformation {
  page_size: number;
  page_size_request_location: string | null;
  page_size_key_path: string[] | null;
}

export interface PaginationItemsPerRequestInformation {
  items_per_request: number;
  items_per_request_location: string | null;
  items_per_request_location_key: string | null;
}

export interface PaginationValueInformation {
  pagination_value_request_location: string;
  pagination_value_request_location_key_path: string[] | null;
  initial_pagination_value: number | null;
}

export interface PaginationConfigurationResponseInformation {
  paginated_results_array_key_path: string[] | null;
  pointer_for_next_request_information: PointerForNextRequestInformation;
  end_of_pagination_information: EndOfPaginationInformation;
}

export interface EndOfPaginationInformation {
  end_of_pagination_type: string;
  max_items_information: EndOfPaginationMaxItemsInformation | null;
  break_value_information: EndOfPaginationBreakValueInformation | null;
}

export interface EndOfPaginationMaxItemsInformation {
  response_max_items_value_location: string | null;
  response_max_items_key_path: string[];
}

export interface EndOfPaginationBreakValueInformation {
  break_value_type: string;
  break_value_response_body_key_path: string[];
  specific_value_to_break_on: any | null;
  override_infinite_loop_condition_key_path: string[] | null;
}

export interface PointerForNextRequestInformation {
  response_location: string | null;
  response_body_key_path: string[] | null;
}

export interface PaginationConfigurationSessionInformation {
  is_session_pagination: boolean;
  session_id_param_name: string | null;
  read_more_request_header_overrides: Object | null;
  read_more_paginated_request_body_base_config: string | null;
  read_more_response_body_array_key_path: string[] | null;
  response_session_id_key_path: string[] | null;
  read_more_response_max_items_key_path: string[] | null;
}

export interface PaginationConfigurationSortInformation {
  is_sort_assisted_pagination: boolean;
  sort_field_filter_request_location: string | null;
  sort_field_filter_request_key_path: string[] | null;
  sort_field_filter_request_format: Object | null;
  sort_field_filter_response_key_path: string[] | null;
}

export interface IntegrationBuilderAutogenRun {
  id: string;
  task_id?: string;
  exception_message?: string;
  start_time?: string;
  end_time?: string;
  integration_id: string;
  status: string;
  notification_status: string;
  run_type: string;
  model_id?: string | null;
}

export interface EndpointCollectionRun {
  id: string;
  api_documentation_url: string;
  endpoint_preview_count?: number;
  status: string;
  notification_status: string;
  visited_urls?: string[];
  exception_message?: string;
}

export interface GetEndpointCollectionRunResponse {
  collection_run?: EndpointCollectionRun;
}

/*
 * Linking Flow Steps for Integration Builder
 */
export interface LinkingFlowStepIntegrationBuilder {
  id?: string;
  step_type: LinkingFlowStepTypeEnums;
  step_post_action_type?: LinkingFlowStepPostActionTypeEnums;
  step_identifier?: string;
  step_number: number;
  title?: string;
  description?: string;
  input_prefix?: string;
  input_suffix?: string;
  input_placeholder?: string;
  input_validation_regex?: string;
  input_validation_error?: string;
  additional_auth_display_names?: Record<string, string>;
  help_guide_url?: string;
  confirm_message?: string;
  button_text?: string;
  integration_setup_checklist_item_id: string | null;
  sftp_credential_field_keys?: string[];
}

export interface LinkingFlowStepPathIntegrationBuilder {
  id?: string;
  path_type: LinkingFlowStepPathTypeEnums;
  auth_configuration_id: string;
  is_active: boolean;
  custom_auth_choice_text?: string;
  checklist_title?: string;
  steps: LinkingFlowStepIntegrationBuilder[];
}

export interface LinkingFlowStepPathResponseBody {
  next: string | null;
  previous: string | null;
  results: LinkingFlowStepPathIntegrationBuilder[];
}

export interface LinkingFlowStepPathTableRowsIntegrationBuilder {
  id: string;
  auth_config_id: string;
  auth_config_name: string;
  step_path_id: string | undefined;
  path_type: string | undefined;
  is_active: boolean | undefined;
}

export interface LinkChoiceStepOptionFormFieldValue {
  id?: string;
  field_name: LinkChoiceStepOptionFormFieldValueEnums;
  field_value?: string;
}

export interface LinkChoiceStepOption {
  id?: string;
  name: string;
  account_type: LinkChoiceStepOptionAccountTypeEnums;
  auth_configuration_override_id: string | null;
  form_field_values: LinkChoiceStepOptionFormFieldValue[];
}

export interface LinkChoiceStepOptionResponseBody {
  next: string | null;
  previous: string | null;
  results: LinkChoiceStepOption[];
}

export interface IntegrationSetupChecklistItem {
  id: string;
  title: string;
  icon: IntegrationSetupChecklistItemIconEnums;
  order_in_checklist: number;
}

export interface TestLinkTokenResponseData {
  link_token: string;
}

export enum AutogenerationRunStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESS_MODEL_CREATED = "SUCCESS_MODEL_CREATED",
  SUCCESS_NO_ACTION = "SUCCESS_NO_ACTION",
  FAILURE = "FAILURE",
  ABORTED = "ABORTED",
}

export enum EndpointCollectionRunStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  ABORTED = "ABORTED",
}

export enum EndpointCollectionRunNotificationStatus {
  PENDING = "PENDING",
  DISMISSED = "DISMISSED",
}

export const RETRYABLE_AUTOGENERATION_RUN_STATUSES: string[] = [
  AutogenerationRunStatus.FAILURE,
  AutogenerationRunStatus.ABORTED,
  AutogenerationRunStatus.SUCCESS_NO_ACTION,
];

export const FINISHED_AUTOGENERATION_RUN_STATUSES: string[] = [
  AutogenerationRunStatus.SUCCESS_MODEL_CREATED,
  AutogenerationRunStatus.SUCCESS_NO_ACTION,
  AutogenerationRunStatus.FAILURE,
  AutogenerationRunStatus.ABORTED,
];

export const FAILURE_AUTOGENERATION_RUN_STATUSES: string[] = [
  AutogenerationRunStatus.FAILURE,
  AutogenerationRunStatus.ABORTED,
];

export enum AutogenerationRunNotificationStatus {
  PENDING = "PENDING",
  DISMISSED = "DISMISSED",
  RETRIED = "RETRIED",
}

export enum AutogenerationRunType {
  ENDPOINT_COLLECTION = "ENDPOINT_COLLECTION",
  API_ENDPOINT = "API_ENDPOINT",
  PAGINATION_CONFIGURATION = "PAGINATION_CONFIGURATION",
  RATE_LIMIT = "RATE_LIMIT",
}
