import { BPRStepIOLog } from "../../../../../models/Blueprints";
import useBlueprintContext from "../../../context/useBlueprintContext";
import StepIOLogsTableCard from "./StepIOLogsTableCard";

interface Props {
  stepIOLogs: BPRStepIOLog[];
}

/**
 * Renders table of step I/O logs
 * @param {BPRStepIOLog[]} stepIOLogs - An array of step I/O logs to be rendered
 */
const StepIOLogsTable = ({ stepIOLogs }: Props) => {
  const { setSelectedStepLog, selectedStepLog } = useBlueprintContext();

  return (
    <div className="flex flex-col space-y-2">
      {stepIOLogs.map((stepIOLog, index) => {
        const isSelected = selectedStepLog?.index_in_raw_logs === index;
        return (
          <StepIOLogsTableCard
            index={index}
            stepIOLog={stepIOLog}
            setSelectedStepLog={setSelectedStepLog}
            isSelected={isSelected}
          />
        );
      })}
    </div>
  );
};

export default StepIOLogsTable;
