import { HTTPMethodBadge } from "./MergeBadges";
import MergeCodeBlock from "./MergeCodeBlock";
import { Badge } from "react-bootstrap";
import { isValidJSON } from "../../utils";
import DeprecatedH2 from "../deprecated/DeprecatedH2";
import DeprecatedH4 from "../deprecated/DeprecatedH4";
import { Accordion, Link, Text } from "@merge-api/merge-javascript-shared";
import { ArrowUpRight } from "lucide-react";
import { useState } from "react";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../models/DiffModels";
import DiffModal from "./diff-view/DiffModal";

export interface TestRunResponse {
  status_code: number;
  body?: string;
  headers?: { [key: string]: string };
  request: {
    url: string;
    body?: string;
    method: string;
    headers: { [key: string]: string };
  };
}

export interface TestSelectiveSyncFilterProps {}

interface Props {
  response: TestRunResponse;
  responseWithoutSelectiveSyncFilters?: TestRunResponse | undefined;
}

const TestRunResponseBlock = ({ response, responseWithoutSelectiveSyncFilters }: Props) => {
  const [isShowingDiffModal, setIsShowingDiffModal] = useState<boolean>(false);
  const requestHeaders = JSON.stringify(response?.request.headers, null, 2);

  let requestBody: string = response?.request.body ?? "{}";
  let stringifiedRequestBody: string;
  try {
    stringifiedRequestBody =
      requestBody !== undefined && isValidJSON(requestBody)
        ? JSON.stringify(JSON.parse(requestBody), null, 2)
        : requestBody || "Empty";
  } catch (error) {
    stringifiedRequestBody = "Error when parsing JSON";
  }

  let responseBody: string = response?.body ?? "{}";
  let stringifiedResponseBody: string;
  try {
    stringifiedResponseBody =
      responseBody !== undefined && isValidJSON(responseBody)
        ? JSON.stringify(JSON.parse(responseBody), null, 2)
        : responseBody || "Empty";
  } catch (error) {
    stringifiedResponseBody = "Error when parsing JSON";
  }

  const responseHeaders = JSON.stringify(response?.headers, null, 2);

  return (
    <>
      {isShowingDiffModal && responseWithoutSelectiveSyncFilters && (
        <DiffModal
          isModalOpen={isShowingDiffModal}
          setIsModalOpen={setIsShowingDiffModal}
          diffModalType={DiffModalTypeEnum.COMPARE}
          modelType={DiffModelTypeEnum.DEFAULT}
          newState={JSON.parse(responseWithoutSelectiveSyncFilters?.body ?? "{}")}
          newStateTitle={`Response without Selective Sync Filter`}
          currentState={JSON.parse(responseBody) ?? {}}
          currentStateTitle={`Response with Selective Sync Filter applied`}
        />
      )}
      <hr />
      <DeprecatedH2>Request Details</DeprecatedH2>
      <div className="flex flex-row items-center justify-between">
        <div>
          <HTTPMethodBadge method={response.request.method} />
          <code className="ml-1.5">{response.request.url}</code>
        </div>
        {!!responseWithoutSelectiveSyncFilters ? (
          <Link>
            <div className="flex flex-row items-center" onClick={() => setIsShowingDiffModal(true)}>
              <Text variant="h6">Compare without filter</Text>
              <ArrowUpRight size={16} />
            </div>
          </Link>
        ) : (
          <></>
        )}
      </div>

      <DeprecatedH4 className="mt-9">Request Headers:</DeprecatedH4>
      <MergeCodeBlock textToCopy={requestHeaders}>{requestHeaders}</MergeCodeBlock>

      <DeprecatedH4 className="mt-3">Request Body:</DeprecatedH4>
      <MergeCodeBlock textToCopy={stringifiedRequestBody}>{stringifiedRequestBody}</MergeCodeBlock>

      <hr />
      <DeprecatedH2>Response Details</DeprecatedH2>
      <DeprecatedH4 className="mt-5">
        Response Status:
        <Badge variant="primary" className="ml-1.5 font-weight-bold">
          {response.status_code}
        </Badge>
      </DeprecatedH4>
      <Accordion
        chevronOrientation="right"
        title={<Text className="mt-3">Response Headers:</Text>}
        variant="none"
      >
        {
          <MergeCodeBlock
            showLineNumbers={true}
            customStyle={{
              lineHeight: "4px",
            }}
            textToCopy={responseHeaders}
          >
            {responseHeaders}
          </MergeCodeBlock>
        }
      </Accordion>
      <DeprecatedH4 className="mt-3">Response Body:</DeprecatedH4>
      {
        <MergeCodeBlock
          showLineNumbers={true}
          customStyle={{
            lineHeight: "4px",
          }}
          textToCopy={stringifiedResponseBody}
        >
          {stringifiedResponseBody}
        </MergeCodeBlock>
      }
    </>
  );
};

export default TestRunResponseBlock;
