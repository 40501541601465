import { LinkingFlowStepPathIntegrationBuilder } from "../../../utils/Entities";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import { AuthType } from "../../../../../models/Entities";
import { useContext } from "react";
import IntegrationBuilderContext from "../../../context/IntegrationBuilderContext";
import { LinkingFlowStepPathAuthChoiceNamePlaceholder } from "../../utils/helpers";
import AccordionCard from "../../../shared/AccordionCard";

interface Props {
  authConfigType: AuthType | undefined;
  requestedStepPath: LinkingFlowStepPathIntegrationBuilder;
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >;
}

const AdvancedStepPathFields = ({
  authConfigType,
  requestedStepPath,
  setRequestedStepPath,
}: Props) => {
  // Get integration name for steps
  const { integration } = useContext(IntegrationBuilderContext);

  return (
    <AccordionCard
      dataTestID="acccordion-step-path-advanced"
      chevronSize={16}
      chevronOrientation="right"
      onChange={function noRefCheck() {}}
      title="Advanced"
      badgeNumber={authConfigType == AuthType.AUTH_TYPE_SFTP ? 2 : 1}
      variant="outline"
      titleVariant="h6"
    >
      <div className="space-y-6">
        <TextFieldHeader
          dataTestID="field-step-path-auth-choice-name"
          title="Auth choice name"
          subtitle="Overrides the default choice name for this step path. Often not useful unless the default choice name is not descriptive enough for this integration."
          placeholder={LinkingFlowStepPathAuthChoiceNamePlaceholder(
            authConfigType,
            integration?.name
          )}
          value={requestedStepPath.custom_auth_choice_text}
          onChange={(event) => {
            setRequestedStepPath({
              ...requestedStepPath,
              custom_auth_choice_text: event.target.value,
            });
          }}
        />
        {authConfigType === AuthType.AUTH_TYPE_SFTP && (
          <TextFieldHeader
            dataTestID="field-step-path-checklist-title"
            title="Checklist title"
            subtitle="Title to render on the checklist step path page. Useful only for SFTP authentication."
            placeholder="Example: Schedule reports via SFTP."
            value={requestedStepPath.checklist_title}
            onChange={(event) => {
              setRequestedStepPath({ ...requestedStepPath, checklist_title: event.target.value });
            }}
          />
        )}
      </div>
    </AccordionCard>
  );
};

export default AdvancedStepPathFields;
