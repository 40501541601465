import {
  BlueprintAddParamToEndpointStep,
  BlueprintParameterValueConstant,
  BlueprintParameterValueType,
} from "../../../models/Blueprints";
import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import DropdownFormField from "./DropdownFormField";
import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelAddParamToEndpointForm = () => {
  const { selectedStep, updateStepParameterValue } = useBlueprintContext();
  const step = selectedStep as BlueprintAddParamToEndpointStep;

  return (
    <>
      <form>
        <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
        <DropdownFormField
          choices={[
            { name: "HEADER", id: "header" },
            { name: "QUERY", id: "query" },
            { name: "BODY", id: "body" },
          ]}
          currentValue={
            (step.parameter_values.key_type as BlueprintParameterValueConstant)?.constant
          }
          placeholder={"Select a key type"}
          title={"Assert Type"}
          subtitle={"The type of key. Modify the header, query, or body parameters."}
          onChange={(e) =>
            updateStepParameterValue(step, "key_type", {
              constant: e.target.value,
              value_type: BlueprintParameterValueType.constant,
            })
          }
        />
        <TypeaheadFormField
          title={"key_name"}
          subtitle={"The key name of the endpoint modification."}
          valueKey={"key_name"}
          parameterType={"string"}
        />
        <TypeaheadFormField
          title={"key_value"}
          subtitle={"The key value of the endpoint modification"}
          valueKey={"key_value"}
          parameterType={"string"}
        />
      </form>
    </>
  );
};

export default BlueprintEditorRightPanelAddParamToEndpointForm;
