import { Button, ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import { ChevronLeft, ChevronRight, Lock, Trash2 } from "lucide-react";
import { useContext, useState } from "react";
import IntegrationBuilderContext from "../context/IntegrationBuilderContext";
import DiffModal from "../../shared/diff-view/DiffModal";

interface IntegrationBuilderNavigationButtonsProps {
  onPrevious: () => void;
  onNext: () => void;
  disablePrevious: boolean;
  disableNext: boolean;
  isDisabled: boolean;
}

const IntegrationBuilderBottomNavigation: React.FC<IntegrationBuilderNavigationButtonsProps> = ({
  onPrevious,
  onNext,
  disablePrevious,
  disableNext,
  isDisabled,
}) => {
  // context
  const {
    canSubmit,
    onSubmit,
    isLoadingSubmit,
    submitButtonText,
    shouldRenderSubmitButton,
    shouldRenderNavigationButtons,
    onDelete,
    isLoadingDelete,
    deleteButtonText,
    shouldRenderDeleteButton,
    stageButtonText,
    canStage,
    shouldRenderStageButton,
    isLoadingStage,
    onStage,
    currentStateForDiff,
    newStateForDiff,
    modelTypeForDiff,
    shouldHideDiffModal,
    isGatedByPermissioning,
    hasPermissionToEdit,
  } = useContext(IntegrationBuilderContext);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);

  const confirmDelete = () => {
    onDelete();
    setIsDeleteDialogOpen(false);
  };

  const canEdit = !isGatedByPermissioning || (isGatedByPermissioning && hasPermissionToEdit);

  return (
    <div className="flex items-center justify-between px-6 py-4 border-t border border-l-0 border-b-0 border-r-0 min-h-[69px] bg-white">
      <div className="flex-1">
        {!canEdit && (
          <div className="flex flex-row space-x-2 px-3 py-2 items-center rounded-md bg-yellow-0 w-fit">
            <Lock size={16} className="text-black" />
            <Text variant="md" className="text-black">
              You do not have permission to edit this component
            </Text>
          </div>
        )}
        {canEdit && shouldRenderSubmitButton && (
          <Button
            size="sm"
            color="blue"
            onClick={() => {
              // if the diff modal is hidden, submit the form directly
              if (shouldHideDiffModal) {
                onSubmit();
              } else {
                setIsSaveDialogOpen(true);
              }
            }}
            disabled={!canSubmit}
            loading={isLoadingSubmit}
          >
            <div data-testid="button-integration-builder-bottom-nav-submit">{submitButtonText}</div>
          </Button>
        )}
      </div>
      {isSaveDialogOpen && currentStateForDiff && newStateForDiff && modelTypeForDiff && (
        <DiffModal
          currentState={currentStateForDiff}
          newState={newStateForDiff}
          modelType={modelTypeForDiff}
          isModalOpen={isSaveDialogOpen}
          setIsModalOpen={setIsSaveDialogOpen}
          onPrimaryButtonClick={onSubmit}
          primaryButtonLoading={isLoadingSubmit}
        />
      )}
      <div className="flex flex-1 justify-center">
        {shouldRenderNavigationButtons && (
          <div className="flex justify-center">
            <Button
              size="sm"
              variant={ButtonVariant.SecondaryBlue}
              className="flex flex-row items-center mr-3 w-[105px]"
              onClick={onPrevious}
              disabled={disablePrevious || isDisabled}
            >
              <ChevronLeft className="mr-1.5" size={16} /> Previous
            </Button>
            <Button
              size="sm"
              variant={ButtonVariant.SecondaryBlue}
              className="flex flex-row items-center w-[105px]"
              onClick={onNext}
              disabled={disableNext || isDisabled}
            >
              Next <ChevronRight className="ml-1.5" size={16} />
            </Button>
          </div>
        )}
      </div>
      <div className="flex-1 flex justify-end">
        {canEdit && shouldRenderDeleteButton && (
          <>
            <Button
              size="sm"
              variant={ButtonVariant.DangerFilled}
              loading={isLoadingDelete}
              onClick={() => setIsDeleteDialogOpen(true)}
              leftIcon={<Trash2 size={16} />}
            >
              {deleteButtonText}
            </Button>
            <Dialog
              title={deleteButtonText}
              primaryButtonVariant={ButtonVariant.DangerFilled}
              primaryButtonText={deleteButtonText}
              onPrimaryButtonClick={confirmDelete}
              open={isDeleteDialogOpen}
              onClose={() => setIsDeleteDialogOpen(false)}
              variant="sm"
              disableCloseOnBackdropClick={false}
            >
              This action cannot be undone
            </Dialog>
          </>
        )}
      </div>
      {canEdit && shouldRenderStageButton && (
        <div className="flex-1 flex justify-end">
          <Button
            size="sm"
            color="blueSecondary"
            loading={isLoadingStage}
            onClick={onStage}
            disabled={!canStage}
          >
            {stageButtonText}
          </Button>
        </div>
      )}
    </div>
  );
};
export default IntegrationBuilderBottomNavigation;
