import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { Plus, WandSparkles } from "lucide-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TableRowLoading from "../../../../../components/integration-builder/shared/TableRowLoading";
import { navigateToIntegrationBuilderCreateRateLimitConfiguration } from "../../../../../router/RouterUtils";
import MergeFlagWrapper from "../../../../shared/MergeFlagWrapper";
import MergeTable from "../../../../shared/MergeTable";
import TableSkeletonLoader from "../../../../shared/TableSkeletonLoader";
import { MergeFlagFeature } from "../../../../shared/hooks/useMergeFlag";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import IntegrationBuilderEmptyState from "../../../shared/IntegrationBuilderEmptyState";
import IntegrationBuilderHeader from "../../../shared/IntegrationBuilderHeader";
import { RateLimitConfigurationIntegrationBuilder } from "../../../utils/Entities";
import RateLimitExplainAlert from "../shared/RateLimitExplainAlert";
import RateLimitConfigurationTableRow from "./RateLimitConfigurationTableRow";
import useAutogenerateRateLimit from "../../hooks/useAutogenerateRateLimit";
import usePollRateLimitConfigurationsSummary from "../../hooks/usePollRateLimitConfigurationsSummary";
// import AutogeneratedConfigurationCard from "src/components/integration-builder/shared/autogenerated-configuration-card/AutogeneratedConfigurationCard";

interface RateLimitConfigurationTableProps {
  integrationID: string;
  isLoadingRateLimitConfigurations: boolean;
  setIsLoadingRateLimitConfigurations: React.Dispatch<React.SetStateAction<boolean>>;
  rateLimitConfigurations: RateLimitConfigurationIntegrationBuilder[] | undefined;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
}

const RateLimitConfigurationTable = ({
  integrationID,
  isLoadingRateLimitConfigurations,
  setIsLoadingRateLimitConfigurations,
  rateLimitConfigurations,
  setRateLimitConfigurations,
}: RateLimitConfigurationTableProps) => {
  const [
    isAutogeneratingRateLimitConfiguration,
    setIsAutogeneratingRateLimitConfiguration,
  ] = useState(false);

  // hooks
  const history = useHistory();

  const {
    pollRateLimitConfigurations,
    stopPollingRateLimitConfigurations,
  } = usePollRateLimitConfigurationsSummary({
    setRateLimitConfigurations,
    setIsLoadingRateLimitConfigurations,
    setIsAutogeneratingRateLimitConfiguration,
  });

  // poll for rate limit configurations when component mounts
  // and stop polling when component unmounts
  useEffect(() => {
    pollRateLimitConfigurations(integrationID);
    return () => {
      stopPollingRateLimitConfigurations();
    };
  }, []);

  // context
  useIntegrationBuilderContext({
    canSubmit: false,
    shouldRenderSubmitButton: false,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
  });

  const { autogenerateRateLimit } = useAutogenerateRateLimit({
    integrationID,
  });

  return (
    <div>
      <IntegrationBuilderHeader
        title="Rate limits"
        button={
          <Button
            size="md"
            leftIcon={<Plus size={16} />}
            onClick={() => {
              navigateToIntegrationBuilderCreateRateLimitConfiguration(history, integrationID);
            }}
          >
            Rate limit
          </Button>
        }
        secondaryButton={
          <MergeFlagWrapper
            integrationId={integrationID}
            feature={MergeFlagFeature.MERGE_FLAG_RATE_LIMIT_GENERATOR}
          >
            <Button
              variant={
                isAutogeneratingRateLimitConfiguration
                  ? ButtonVariant.SecondaryGray
                  : ButtonVariant.SecondaryBlue
              }
              size="md"
              loading={isAutogeneratingRateLimitConfiguration}
              leftIcon={
                !isAutogeneratingRateLimitConfiguration ? <WandSparkles size={16} /> : undefined
              }
              onClick={() => {
                setIsAutogeneratingRateLimitConfiguration(true);
                autogenerateRateLimit();
              }}
            >
              {isAutogeneratingRateLimitConfiguration
                ? "Generating default configuration"
                : "Generate default configuration"}
            </Button>
          </MergeFlagWrapper>
        }
      />
      <RateLimitExplainAlert integrationID={integrationID} className="mb-6" />
      {/* <AutogeneratedConfigurationCard
        className="my-6"
        autogen_runs={[]}
        setShouldHideCard={() => {}}
      /> */}
      <MergeTable
        hover={rateLimitConfigurations && rateLimitConfigurations.length > 0}
        hasMarginBottom={false}
        hasMorePaddingOnFirstElement
        header={
          <>
            <th scope="col">Type</th>
            <th scope="col">Rate limit</th>
            <th scope="col">Threshold</th>
            <th scope="col">Updated via headers</th>
            <th scope="col">Endpoint</th>
            <th scope="col">Status</th>
          </>
        }
        content={
          isLoadingRateLimitConfigurations ? (
            <TableSkeletonLoader cols={6} rows={3} size="large" />
          ) : (
            <>
              {(rateLimitConfigurations && rateLimitConfigurations.length > 0) ||
              isAutogeneratingRateLimitConfiguration ? (
                <>
                  {isAutogeneratingRateLimitConfiguration && (
                    <TableRowLoading numCols={6} title="Generating default configuration..." />
                  )}
                  {rateLimitConfigurations?.map((rateLimitConfig) => (
                    <RateLimitConfigurationTableRow
                      key={rateLimitConfig.id}
                      rateLimitConfiguration={rateLimitConfig}
                      integrationID={integrationID}
                      setRateLimitConfigurations={setRateLimitConfigurations}
                    />
                  ))}
                </>
              ) : (
                <IntegrationBuilderEmptyState
                  title="No rate limits"
                  subtitle={
                    <div>
                      Click <Text variant="title-sm">+ Rate limit</Text> in the top right to
                      manually add one
                    </div>
                  }
                  numberOfColumns={6}
                />
              )}
            </>
          )
        }
      />
    </div>
  );
};
export default RateLimitConfigurationTable;
