import { useParams } from "react-router-dom";
import { RateLimitConfigurationIntegrationBuilder } from "../../utils/Entities";
import RateLimitConfigurationHeader from "./shared/RateLimitConfigurationHeader";
import RateLimitExplainAlert from "./shared/RateLimitExplainAlert";
import RateLimitConfigurationSetupOptions from "./RateLimitConfigurationSetupOptions";
import { useContext, useEffect } from "react";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import RateLimitConfigurationContext from "../context/RateLimitConfigurationContext";
import useCreateOrPatchRateLimitConfiguration from "../hooks/useCreateOrPatchRateLimitConfiguration";
import useDeleteRateLimitConfiguration from "../hooks/useDeleteRateLimitConfiguration";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";
import { transformRateLimitConfigurationDataForDiffModal } from "../utils/helpers";
import EditorLeavingGuard from "../../../shared/unsaved-changes/EditorLeavingGuard";

type RouteParams = {
  rateLimitConfigurationID: string | undefined;
};

interface RateLimitConfigurationSetupProps {
  integrationID: string;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  isLoadingRateLimitConfigurations: boolean;
  selectedRateLimitConfiguration: RateLimitConfigurationIntegrationBuilder | undefined;
}

const RATE_LIMIT_CONFIGURATION_SUBMIT_TEXT = "Save rate limit";
const RATE_LIMIT_CONFIGURATION_DELETE_TEXT = "Delete rate limit";

const BaseRateLimitConfigurationSetup = ({
  integrationID,
  setRateLimitConfigurations,
  isLoadingRateLimitConfigurations,
  selectedRateLimitConfiguration,
}: RateLimitConfigurationSetupProps) => {
  const { rateLimitConfigurationID } = useParams<RouteParams>();

  // hooks for deleting, creating, or patching rate limit configurations
  const {
    deleteRateLimitConfiguration,
    isDeletingRateLimitConfigurationLoading,
  } = useDeleteRateLimitConfiguration({
    integrationID,
    setRateLimitConfigurations,
  });

  const {
    createRateLimitConfiguration,
    patchRateLimitConfiguration,
    isLoadingSubmit,
  } = useCreateOrPatchRateLimitConfiguration({
    integrationID,
    setRateLimitConfigurations,
  });

  // const derived from state
  const isNewRateLimitConfiguration = !!!selectedRateLimitConfiguration;

  // context
  const {
    setOnSubmit,
    setOnDelete,
    setCanSubmit,
    setNewStateForDiff,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();

  const { formRateLimitConfigurationData, canSubmitRateLimitConfiguration } = useContext(
    RateLimitConfigurationContext
  );

  useIntegrationBuilderContext({
    submitButtonText: RATE_LIMIT_CONFIGURATION_SUBMIT_TEXT,
    canSubmit: canSubmitRateLimitConfiguration,
    isLoadingSubmit: isLoadingSubmit,
    isLoadingDelete: isDeletingRateLimitConfigurationLoading,
    deleteButtonText: RATE_LIMIT_CONFIGURATION_DELETE_TEXT,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: false,
    shouldRenderDeleteButton: !isNewRateLimitConfiguration,
    modelTypeForDiff: DiffModelTypeEnum.RATE_LIMIT_CONFIGURATION,
    shouldHideDiffModal: false,
  });

  // hooks for setting onSubmit and onDelete
  const rateLimitConfigurationOnSubmit = () => {
    const rateLimitConfigurationData = formRateLimitConfigurationData();
    if (!rateLimitConfigurationData) {
      return;
    }

    isNewRateLimitConfiguration
      ? createRateLimitConfiguration(rateLimitConfigurationData)
      : patchRateLimitConfiguration(rateLimitConfigurationData);
  };

  // handles onSubmit function
  useEffect(() => {
    setOnSubmit(rateLimitConfigurationOnSubmit);
  }, [setOnSubmit, formRateLimitConfigurationData]);

  // Specifies whether we can submit the form
  useEffect(() => {
    setCanSubmit(canSubmitRateLimitConfiguration);
  }, [setCanSubmit, canSubmitRateLimitConfiguration]);

  // handles onDelete function
  useEffect(() => {
    setOnDelete(() => deleteRateLimitConfiguration(selectedRateLimitConfiguration?.id));
  }, [setOnDelete, selectedRateLimitConfiguration]);

  // Update diff state for diff modal
  useEffect(() => {
    const rateLimitConfigData = formRateLimitConfigurationData();
    if (rateLimitConfigData) {
      const rateLimitConfigDataWithHelperDiffFields = transformRateLimitConfigurationDataForDiffModal(
        rateLimitConfigData
      );
      setNewStateForDiff(rateLimitConfigDataWithHelperDiffFields);
    }
  }, [formRateLimitConfigurationData]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div>
        <RateLimitConfigurationHeader
          integrationID={integrationID}
          isNewRateLimitConfiguration={!rateLimitConfigurationID}
        />
        <RateLimitExplainAlert integrationID={integrationID} />
        {!isLoadingRateLimitConfigurations && <RateLimitConfigurationSetupOptions />}
      </div>
    </EditorLeavingGuard>
  );
};

export default BaseRateLimitConfigurationSetup;
