import { isEmpty } from "lodash";
import { BlueprintStep, BlueprintStepTemplate } from "../../../models/Blueprints";
import { HTTPMethod } from "../../../models/HTTPMethods";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import { HeaderPretitle } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import InputFormField from "./InputFormField";
import TypeaheadFormField from "./TypeaheadFormField";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelConcurrentRequestDataForm = ({ stepID, stepTemplate }: Props) => {
  const { selectedStep, updateStepMockResponseBody } = useBlueprintContext();
  const step = selectedStep as BlueprintStep;

  return (
    <>
      {!isEmpty(stepTemplate.parameter_schema) &&
        !isEmpty(stepTemplate.parameter_schema?.properties) && (
          <>
            <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>

            {Object.entries(stepTemplate.parameter_schema?.properties ?? {}).map(
              ([key, parameter]) => {
                return (
                  <TypeaheadFormField
                    key={key}
                    title={parameter.title ?? key}
                    valueKey={key}
                    parameterType={parameter.type}
                  />
                );
              }
            )}
            <hr />
          </>
        )}

      <HeaderPretitle className="mt-3">Response</HeaderPretitle>
      <JSONSchemaTreeDiagram stepID={stepID} jsonSchema={stepTemplate.return_schema} />
      {!isEmpty(stepTemplate.return_schema) &&
        stepTemplate.return_schema?.type === "object" &&
        stepTemplate.metadata["method"] !== HTTPMethod.GET && (
          <>
            <HeaderPretitle className="mt-3">Mock Response Fields For Test Runs</HeaderPretitle>
            {Object.entries(stepTemplate.return_schema?.properties ?? {}).map(([field, _]) => {
              return (
                <InputFormField
                  key={field}
                  title={field}
                  currentValue={
                    step.mock_response_body !== undefined ? step.mock_response_body[field] : ""
                  }
                  onChange={(value) => updateStepMockResponseBody(step, field, value)}
                />
              );
            })}
            <hr />
          </>
        )}
    </>
  );
};

export default BlueprintEditorRightPanelConcurrentRequestDataForm;
