import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BlueprintOperationType } from "../../../models/Blueprints";
import { BlueprintMeta, Integration } from "../../../models/Entities";
import Editor from "react-simple-code-editor";
import {
  MappingTestBodyParameters,
  MappingTestBodyParameterSchema,
  MappingTestExistingCommonModels,
  MappingTestExpectedResult,
  MappingTestBlueprintsMetadata,
} from "../../../models/MappingTests";
import { isUpdateExistingModelBlueprintOperation } from "../../blueprint-editor/utils/BlueprintEditorUtils";
import BlueprintInputPayloadBody from "../../shared/blueprint-card/BlueprintInputPayloadBody";
import { EditableText } from "../../shared/EditableText";
import OverviewSection from "../../shared/layout/OverviewSection";
import MergeText, { TextType } from "../../shared/text/MergeText";
import { showErrorToast } from "../../shared/Toasts";
import useMappingTestContext from "../context/useMappingTestContext";
import { patchMappingTestName } from "../utils/MappingTestFetchUtils";
import MappingTestBodyParametersSection from "./MappingTestBodyParametersSection";
import MappingTestExistingCommonModelNameSection from "./MappingTestExistingCommonModelNameSection";
import { isValidJSON, validateTimeInput } from "../../../utils";
import { Checkbox, Select, Text, TextField, Tooltip } from "@merge-api/merge-javascript-shared";
import { getReadableNameForExpectedResult } from "../utils/MappingTestBuildingUtils";
import { Info } from "lucide-react";

const { highlight, languages } = require("prismjs");

type Props = {
  leftPanelAvailableRelationLookupDict: { [commonModelID: string]: Array<string> };
  blueprints: BlueprintMeta[];
  bodyParameters: MappingTestBodyParameters;
  setBodyParameters: (bodyParameters: MappingTestBodyParameters) => void;
  bodyParameterSchema: MappingTestBodyParameterSchema | null;
  existingCommonModels: MappingTestExistingCommonModels | undefined;
  setExistingCommonModels: (existingCommonModels: MappingTestExistingCommonModels) => void;
  existingCommonModelName: string | undefined;
  setExistingCommonModelName: (existingCommonModelName: string) => void;
  integration: Integration;
  linkedAccountEndUserName: string;
  linkedAccountID: string;
  navigateToFetchTest: undefined | (() => void);
  expectedResult: MappingTestExpectedResult | undefined;
  isMappingTestInitialized: boolean;
  operationType: BlueprintOperationType;
  writtenOrUpdatedCommonModel: string | undefined | null;
  webhookReceiverEventTypeID: string | null;
  blueprintsMetadata: MappingTestBlueprintsMetadata;
  mappingTestID: string;
  updateMappingTestBPMetadata: (blueprintID: string, newMetaData: any) => void;
  maxLoopIterations: undefined | number;
  setMaxLoopIterations: (val: number | undefined) => void;
  shouldMatchOnRequestHeaders: boolean;
  setShouldMatchOnRequestHeaders: (val: boolean) => void;
  maxPageIterations: undefined | number;
  setMaxPageIterations: (val: number | undefined) => void;
  mappingTestFreezeTime: undefined | string;
  setMappingTestFreezeTime: (val: string | undefined) => void;
  isReadTestCollection: boolean;
  nestedWritesMapForMappingTestModelArray: string[];
  commonModelName?: string;
};

const MappingTestV2EditorLeftPanelOverviewSubtab = ({
  leftPanelAvailableRelationLookupDict,
  bodyParameterSchema,
  nestedWritesMapForMappingTestModelArray,
  bodyParameters,
  setBodyParameters,
  existingCommonModels,
  setExistingCommonModels,
  existingCommonModelName,
  setExistingCommonModelName,
  integration,
  linkedAccountEndUserName,
  linkedAccountID,
  navigateToFetchTest,
  expectedResult,
  operationType,
  webhookReceiverEventTypeID,
  writtenOrUpdatedCommonModel,
  blueprints,
  blueprintsMetadata,
  mappingTestID,
  updateMappingTestBPMetadata,
  maxLoopIterations,
  setMaxLoopIterations,
  maxPageIterations,
  setMaxPageIterations,
  mappingTestFreezeTime,
  setMappingTestFreezeTime,
  isReadTestCollection,
  commonModelName,
  shouldMatchOnRequestHeaders,
  setShouldMatchOnRequestHeaders,
}: Props) => {
  const { mappingTestName, setMappingTestName } = useMappingTestContext();
  const [dateTimeInput, setDateTimeInput] = useState<string>(mappingTestFreezeTime ?? "");
  const [existingCommonModelsAsString, setExistingCommonModelsAsString] = useState<string | null>(
    existingCommonModels ? JSON.stringify(existingCommonModels, null, 2) : null
  );
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const isMappingTestWithExistingCommonModels = true;
  const areExistingCommonModelsValid =
    !existingCommonModelsAsString ||
    (existingCommonModelsAsString && isValidJSON(existingCommonModelsAsString));

  useEffect(() => {
    if (existingCommonModelsAsString && isValidJSON(existingCommonModelsAsString)) {
      setExistingCommonModels(JSON.parse(existingCommonModelsAsString));
    }
  }, [existingCommonModelsAsString]);

  const updateMappingTestName = (newName: string) => {
    const patchNameInput = {
      mappingTestID,
      newName,
      onResponse: (data: { name: string }) => {
        setMappingTestName(data.name);
      },
      onError: () => {
        showErrorToast("Failed to update mapping test name");
      },
    };
    patchMappingTestName(patchNameInput);
  };

  const EXPECTED_RESULT_OPTIONS: MappingTestExpectedResult[] = [
    ...Object.values(MappingTestExpectedResult),
  ];

  const handleFrozenTimeInputChange = (value: string) => {
    setMappingTestFreezeTime(value);
    setDateTimeInput(value);
    const { isValid, errorMsg } = validateTimeInput(value);
    setErrorText(isValid ? undefined : errorMsg);
  };

  return (
    <>
      <div className="mt-3">
        <OverviewSection
          title="Mapping Test Name"
          overviewBodyTextClassName="w-100"
          content={
            <EditableText
              originalText={mappingTestName}
              onSave={(newName) => updateMappingTestName(newName)}
            />
          }
        />
        <div className="d-flex align-items-center justify-content-center">
          {navigateToFetchTest && (
            <Button onClick={navigateToFetchTest} size="sm" variant="secondary">
              Open Fetch Test
            </Button>
          )}
        </div>
        <DescriptionSection
          title={"Linked Account"}
          subtitle={`${linkedAccountEndUserName} (${linkedAccountID})`}
        />
        <TextField
          className="my-5"
          variant="bordered"
          type="text"
          label={
            <div>
              <Text as="div" className="text-black font-semibold mb-2">
                Frozen time
              </Text>
              <Text as="div" variant="sm" className="text-gray-70 mb-2">
                Specify a frozen time value to use when executing your blueprint. This will set a
                static time reference for any date range loops or custom functions.
              </Text>
            </div>
          }
          placeholder="Enter frozen time value"
          value={dateTimeInput}
          onChange={(e: any) => {
            handleFrozenTimeInputChange(e.target.value);
          }}
          error={!!errorText}
          errorText={errorText}
        />
        <TextField
          variant="bordered"
          type="number"
          label={
            <div>
              <Text as="div" className="text-black font-semibold mb-2">
                Max loop iterations
              </Text>
              <Text as="div" variant="sm" className="text-gray-70 mb-2">
                The maximum number of items to loop over in any loop before breaking early
              </Text>
            </div>
          }
          placeholder="Enter the total max loop iterations"
          value={maxLoopIterations}
          onChange={(e) =>
            setMaxLoopIterations(e.target.value !== "" ? parseInt(e.target.value, 10) : undefined)
          }
        />
        <TextField
          className="mt-5"
          variant="bordered"
          type="number"
          label={
            <div>
              <Text as="div" className="text-black font-semibold mb-2">
                Max page iterations
              </Text>
              <Text as="div" variant="sm" className="text-gray-70 mb-2">
                The maximum number of pages to request in any APIRequestLoop step before not
                fetching any more pages
              </Text>
            </div>
          }
          placeholder="Enter the total max loop iterations"
          value={maxPageIterations}
          onChange={(e) =>
            setMaxPageIterations(e.target.value !== "" ? parseInt(e.target.value, 10) : undefined)
          }
        />
        <Checkbox
          className="mt-5"
          label={
            <div className="flex flex-row space-x-2 items-center">
              <Text>Match headers on request mocks</Text>
              <Tooltip title="Set this to true if you want mapping tests to match headers on request mocks. This will match request mocks on both authentication headers and request headers. For the vast majority of integrations, this is not necessary">
                <Info size={12} className="text-black" />
              </Tooltip>
            </div>
          }
          checked={shouldMatchOnRequestHeaders}
          onChange={() => setShouldMatchOnRequestHeaders(!shouldMatchOnRequestHeaders)}
        />
        {expectedResult && (
          <div className="mt-5">
            <Text as="div" className="text-black font-semibold mb-2">
              Expected result
            </Text>
            <Select
              disabled
              clearable={false}
              value={expectedResult}
              options={EXPECTED_RESULT_OPTIONS}
              getOptionLabel={(option) => getReadableNameForExpectedResult(option)}
            />
          </div>
        )}
        {isMappingTestWithExistingCommonModels &&
          webhookReceiverEventTypeID == null &&
          !isReadTestCollection && (
            <div className="my-5">
              <Text as="div" className="text-black font-semibold mb-2">
                Pre-existing common models
              </Text>
              <Text as="div" variant="sm" className="text-gray-70 mb-2">
                A snapshot of common models that are used in this blueprint, prior to the blueprint
                being run. If this field is filled out, the test will use these common models rather
                than the ones from the associated FETCH test. It is recommended to use the common
                models from the associated FETCH test to avoid errors.
              </Text>
              <Editor
                highlight={(code) => highlight(code, languages.js)}
                value={existingCommonModelsAsString ?? ""}
                onValueChange={(newVal) => {
                  setExistingCommonModelsAsString(newVal);
                }}
                padding={10}
                style={{
                  backgroundColor: areExistingCommonModelsValid ? "white" : "#FFE0E0",
                  border: "1px solid #d2ddec",
                  borderRadius: 8,
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  minHeight: "100px",
                  overflow: "auto",
                }}
              />
            </div>
          )}
        {isUpdateExistingModelBlueprintOperation(operationType) && writtenOrUpdatedCommonModel && (
          <MappingTestExistingCommonModelNameSection
            availableModelNames={
              leftPanelAvailableRelationLookupDict?.[writtenOrUpdatedCommonModel] ?? []
            }
            existingCommonModelName={existingCommonModelName}
            setExistingCommonModelName={setExistingCommonModelName}
          />
        )}
        <MappingTestBodyParametersSection
          nestedWritesMapForMappingTestModelArray={nestedWritesMapForMappingTestModelArray}
          availableRelationLookupDict={leftPanelAvailableRelationLookupDict}
          bodyParameterSchema={bodyParameterSchema}
          bodyParameters={bodyParameters}
          setBodyParameters={setBodyParameters}
          commonModelName={commonModelName}
        />
        {webhookReceiverEventTypeID !== null && (
          <BlueprintInputPayloadBody
            blueprint={blueprints[0]}
            integrationID={integration.id}
            linkedAccountID={linkedAccountID}
            blueprintMetadata={blueprintsMetadata?.[blueprints[0]?.blueprint_id] ?? {}}
            updateMappingTestBPMetadata={updateMappingTestBPMetadata}
          />
        )}
      </div>
    </>
  );
};

const DescriptionSection = ({ title, subtitle }: { title: string; subtitle: string }) => (
  <>
    <div className="mb-1.5">
      <MergeText isBold size="12px" type={TextType.MUTED}>
        {title}
      </MergeText>
    </div>
    <div className="mb-1.5">
      <MergeText isBold size="14px">
        {subtitle}
      </MergeText>
    </div>
  </>
);

export default MappingTestV2EditorLeftPanelOverviewSubtab;
