import { ButtonVariant, Card, Dropdown, MenuItem, Text } from "@merge-api/merge-javascript-shared";
import {
  ParameterMappingDetails,
  UserFacingFilterDetails,
  ValueTransformation,
  VersionedComponentInfo,
} from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import IntegrationBuilderEmptyState from "../../../shared/IntegrationBuilderEmptyState";
import { ConditionOperator, CONDITION_OPERATORS } from "../../types";
import ParameterMappingOperatorContainer from "./ParameterMappingOperatorContainer";
import { Plus } from "lucide-react";

type Props = {
  parameterMappingDetails: ParameterMappingDetails | undefined;
  addFilterOperator: (newOperator: ConditionOperator) => void;
  removeFilterOperator: (newOperator: ConditionOperator) => void;
  userFacingDetails: UserFacingFilterDetails | null;
  addOrUpdateAPIEndpointParameter: (
    newAPIEndpointParameter: VersionedComponentInfo,
    operator: string
  ) => void;
  updateValueTransformation: (
    newValueTransformation: ValueTransformation | null,
    operator: string,
    index: number
  ) => void;
  removeParameterMappingDetails: (parameterMappingDetailsID: string, operator: string) => void;
  setAllowManualFilterMappingInBlueprints: (newValue: boolean, operator: string) => void;
};

const ParameterMappingDetailsContainer = ({
  parameterMappingDetails,
  userFacingDetails,
  addFilterOperator,
  removeFilterOperator,
  addOrUpdateAPIEndpointParameter,
  removeParameterMappingDetails,
  updateValueTransformation,
  setAllowManualFilterMappingInBlueprints,
}: Props) => {
  const existingOperators = Object.keys(parameterMappingDetails ?? {});
  return (
    <div className="mb-6">
      <div className="flex flex-row mb-4 w-full justify-between align-items-center">
        <Text variant="h5">Operators and endpoint mapping logic</Text>
        <Dropdown
          ButtonProps={{
            leftIcon: <Plus size={16} />,
            variant: ButtonVariant.SecondaryBlue,
            children: "Operator",
            disabled: !!!(
              userFacingDetails?.filter_type_id?.id && userFacingDetails?.remote_key_name
            ),
          }}
        >
          {/* Dont give users the option to add an operator we already have */}
          {Object.entries(CONDITION_OPERATORS)
            .filter(([key, _]) => !existingOperators.includes(key))
            .map(([key, val]) => (
              <MenuItem onClick={() => addFilterOperator(key as ConditionOperator)}>{val}</MenuItem>
            ))}
        </Dropdown>
      </div>
      <div>
        {existingOperators.length > 0 ? (
          Object.entries(parameterMappingDetails ?? {}).map(([operator, mappings]) => (
            <ParameterMappingOperatorContainer
              operator={operator as ConditionOperator}
              mappingsForOperator={mappings}
              removeFilterOperator={removeFilterOperator}
              userFacingDetails={userFacingDetails}
              addOrUpdateAPIEndpointParameter={addOrUpdateAPIEndpointParameter}
              removeParameterMappingDetails={removeParameterMappingDetails}
              updateValueTransformation={updateValueTransformation}
              setAllowManualFilterMappingInBlueprints={setAllowManualFilterMappingInBlueprints}
            />
          ))
        ) : (
          <Card variant="outline" className="flex flex-row justify-center">
            <IntegrationBuilderEmptyState
              title="No filter operators"
              subtitle={
                <div>
                  Click <Text variant="title-sm">+ Operator</Text> above to manually add a new
                  operator to apply filters for
                </div>
              }
              numberOfColumns={6}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default ParameterMappingDetailsContainer;
