import { TextField, Typeahead } from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { UserFacingFilterType } from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import {
  AbstractCondition,
  AbstractConditionValue,
  ConditionOperator,
  CONDITION_OPERATORS,
} from "../../../types";

interface Props {
  condition: AbstractCondition;
  filterType: UserFacingFilterType | null;
  index: number;
  updateOrRemoveAbstractConditionAtIndex: (
    index: number,
    updatedCondition: AbstractCondition,
    removeBlock?: boolean
  ) => void;
}

const APIRequestTesterAppliedCondition = ({
  condition,
  filterType,
  index,
  updateOrRemoveAbstractConditionAtIndex,
}: Props) => {
  const [operator, setOperator] = useState<string>(condition?.operator ?? "");
  const [value, setValue] = useState<AbstractConditionValue>(condition?.value);

  const updateConditionValue = (newVal: string) => {
    setValue({
      value: newVal,
      typed_value: newVal,
    });
  };

  const commonModelName = (filterType?.common_model_class_id ?? ".").split(".")[1];

  useEffect(() => {
    const updatedCondition = {
      ...condition,
      value,
      operator,
    };
    updateOrRemoveAbstractConditionAtIndex(index, updatedCondition);
  }, [operator, value]);

  const removeAbstractCondition = () => {
    updateOrRemoveAbstractConditionAtIndex(index, condition, true);
  };

  return (
    <div className="bg-gray-0 p-2 rounded-md mb-2">
      <div className="flex flex-row mb-1 justify-between w-full">
        <>{`${commonModelName} ${filterType?.filter_name}`}</>
        <div className="cursor-pointer mr-2">
          <X size={12} onClick={() => removeAbstractCondition()} />
        </div>
      </div>
      <Typeahead
        className="bg-white rounded-t-lg rounded-b-none border-[0.5px] border-gray-30"
        options={Object.entries(CONDITION_OPERATORS).map(([key, _]) => key)}
        value={operator}
        blurOnSelect={true}
        renderOption={(key: any) => <div>{CONDITION_OPERATORS[key as ConditionOperator]}</div>}
        onChange={(_: any, val: any) => setOperator(val)}
        filterOptions={(options: any, { inputValue }: any) => {
          return options.filter((option: string) => {
            const inputValueToLowerCase = inputValue.toLowerCase();
            return option.toLowerCase().includes(inputValueToLowerCase);
          });
        }}
      />
      <TextField
        inputWrapperClassName="rounded-md"
        className="bg-white rounded-t-none rounded-b-lg border-x-[0.5px] border-b-[0.5px] border-gray-30"
        value={value?.value}
        onChange={(e) => updateConditionValue(e.target.value)}
      />
    </div>
  );
};

export default APIRequestTesterAppliedCondition;
