import { GeneralAuthType } from "../../../utils/Entities";
import { AuthType } from "../../../../../models/Entities";
import { authTypeMapping } from "../../../utils/helpers";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface ConfigurationTypeButtonProps {
  generalAuthConfigType: string | undefined;
  setGeneralAuthConfigType: React.Dispatch<React.SetStateAction<GeneralAuthType | undefined>>;
  setActualAuthType: React.Dispatch<React.SetStateAction<AuthType | undefined>>;
}

const ConfigurationTypeButtons = ({
  generalAuthConfigType,
  setGeneralAuthConfigType,
  setActualAuthType,
}: ConfigurationTypeButtonProps) => {
  const handleButtonClick = (value: GeneralAuthType) => {
    setGeneralAuthConfigType(value);
    setActualAuthType(authTypeMapping[value]);
  };

  const options = [
    {
      value: GeneralAuthType.API,
      text: "API",
      disabled: false,
      selected: generalAuthConfigType === GeneralAuthType.API,
      onClick: handleButtonClick,
    },
    {
      value: GeneralAuthType.SCRAPER,
      text: "Scraper",
      disabled: false,
      selected: generalAuthConfigType === GeneralAuthType.SCRAPER,
      onClick: handleButtonClick,
    },
    {
      value: GeneralAuthType.SFTP,
      text: "SFTP",
      disabled: false,
      selected: generalAuthConfigType === GeneralAuthType.SFTP,
      onClick: handleButtonClick,
    },
  ];
  return (
    <MultiSelectHeader
      dataTestID="field-auth-config-integration-type"
      title="Integration type"
      subtitle="Select what type of integration configuration you'd like to create"
      options={options}
      required
      className="mt-6"
    />
  );
};

export default ConfigurationTypeButtons;
