import { useContext } from "react";
import AccordionCard from "../../shared/AccordionCard";
import RateLimitConfigurationContext from "../context/RateLimitConfigurationContext";
import BinaryChoiceButtons from "../../authentication/components/shared/BinaryChoiceButtons";
import LineIndent from "../../authentication/components/shared/LineIndent";
import KeyPathFieldHeader from "../../shared/KeyPathFieldHeader";
import ResetTimestampFormatButtons from "./buttons/ResetTimestampFormatButtons";

interface RateLimitHeadersProps {
  inlineSetup: boolean;
}

const RateLimitHeaders = ({ inlineSetup }: RateLimitHeadersProps) => {
  // context
  const {
    shouldSetLimitMaxCountViaHeaders,
    setShouldSetLimitMaxCountViaHeaders,
    responseHeaderMaxCountKeyPath,
    setResponseHeaderMaxCountKeyPath,
    shouldUpdateRateLimitCurrentCountViaHeaders,
    setShouldUpdateRateLimitCurrentCountViaHeaders,
    responseHeaderRateLimitCurrentCountKeyPath,
    setResponseHeaderRateLimitCurrentCountKeyPath,
    shouldUpdateRateLimitCountLeftViaHeaders,
    setShouldUpdateRateLimitCountLeftViaHeaders,
    responseHeaderRateLimitCountLeftKeyPath,
    setResponseHeaderRateLimitCountLeftKeyPath,
    shouldUpdateResetTimestampKeyPathViaHeaders,
    setShouldUpdateResetTimestampKeyPathViaHeaders,
    responseHeaderResetTimestampKeyPath,
    setResponseHeaderResetTimestampKeyPath,
    resetExpirationFormat,
    setResetExpirationFormat,
  } = useContext(RateLimitConfigurationContext);

  // calculate the total number of options based on selections
  const totalBadgeNumber =
    (shouldUpdateRateLimitCurrentCountViaHeaders ? 2 : 1) +
    (shouldSetLimitMaxCountViaHeaders ? 2 : 1) +
    (shouldUpdateRateLimitCountLeftViaHeaders ? 2 : 1) +
    (shouldUpdateResetTimestampKeyPathViaHeaders ? 3 : 1); // Note this counts for 3 if true

  return (
    <AccordionCard
      dataTestID="accordion-rate-limit-config-update-via-headers"
      title="Updated via headers"
      defaultExpanded={true}
      titleVariant={inlineSetup ? "h6" : "h5"}
      variant={inlineSetup ? "outline" : "shadow"}
      badgeNumber={inlineSetup ? totalBadgeNumber : undefined}
    >
      <BinaryChoiceButtons
        dataTestID="field-rate-limit-config-should-set-limit-max-count-via-headers"
        title="Should the max count be pulled from response headers?"
        subtitle="Certain third-party integrations offer variable rate limits based on the connected customer. If such a unique limit is indicated in the headers, our system can adjust the max count limit value accordingly."
        binaryChoice={shouldSetLimitMaxCountViaHeaders}
        setBinaryChoice={setShouldSetLimitMaxCountViaHeaders}
        required
      />
      {shouldSetLimitMaxCountViaHeaders === true && (
        <LineIndent className="mt-6">
          <KeyPathFieldHeader
            dataTestID="field-rate-limit-config-response-header-max-count-key"
            title="Response header max count key"
            subtitle="Define the specific header key to extract the returned rate limit value. Type the name of the key below and press Enter."
            placeholder="Add key name..."
            required
            keyPath={responseHeaderMaxCountKeyPath}
            onKeyPathChange={setResponseHeaderMaxCountKeyPath}
            hasSource={false}
            showArrow={false}
          />
        </LineIndent>
      )}
      <BinaryChoiceButtons
        className="mt-6"
        dataTestID="field-rate-limit-config-should-update-rate-limit-current-count-via-headers"
        title="Should the current count be pulled from response headers?"
        subtitle="Certain third-party services provide the current count against a rate limit within the response headers. In these cases, we can rely on this count instead of the custom model count logic embedded in the runner."
        binaryChoice={shouldUpdateRateLimitCurrentCountViaHeaders}
        setBinaryChoice={setShouldUpdateRateLimitCurrentCountViaHeaders}
        required
      />
      {shouldUpdateRateLimitCurrentCountViaHeaders === true && (
        <LineIndent className="mt-6">
          <KeyPathFieldHeader
            dataTestID="field-rate-limit-config-response-header-rate-limit-current-count-key"
            title="Response header rate limit current count key"
            subtitle="Define the string path that points to the header location where the rate limit count can be retrieved. Type the name of the key below and press Enter."
            placeholder="Add key name..."
            required
            keyPath={responseHeaderRateLimitCurrentCountKeyPath}
            onKeyPathChange={setResponseHeaderRateLimitCurrentCountKeyPath}
            hasSource={false}
            showArrow={false}
          />
        </LineIndent>
      )}
      <BinaryChoiceButtons
        className="mt-6"
        dataTestID="field-rate-limit-config-should-update-rate-limit-count-left-via-headers"
        title="Should the current count left be pulled from response headers?"
        subtitle="Certain third-party services provide the count left against a rate limit within the response headers. In these cases, we can rely on this count instead of the custom model count logic embedded in the runner."
        binaryChoice={shouldUpdateRateLimitCountLeftViaHeaders}
        setBinaryChoice={setShouldUpdateRateLimitCountLeftViaHeaders}
        required
      />
      {shouldUpdateRateLimitCountLeftViaHeaders === true && (
        <LineIndent className="mt-6">
          <KeyPathFieldHeader
            dataTestID="field-rate-limit-config-response-header-rate-limit-count-left-key"
            title="Response header rate limit current count left key"
            subtitle="Define the string path that points to the header location where the rate limit count left can be retrieved. Type the name of the key below and press Enter."
            placeholder="Add key name..."
            required
            keyPath={responseHeaderRateLimitCountLeftKeyPath}
            onKeyPathChange={setResponseHeaderRateLimitCountLeftKeyPath}
            hasSource={false}
            showArrow={false}
          />
        </LineIndent>
      )}
      <BinaryChoiceButtons
        className="mt-6"
        dataTestID="field-rate-limit-config-should-update-reset-timestamp-key-path-via-headers"
        title="Should the rate limit reset timestamp be pulled from response headers?"
        subtitle="Certain third-party services provide the timestamp against a rate limit within the response headers. In these cases, we can rely on this count instead of the custom model count logic embedded in the runner."
        binaryChoice={shouldUpdateResetTimestampKeyPathViaHeaders}
        setBinaryChoice={setShouldUpdateResetTimestampKeyPathViaHeaders}
        required
      />
      {shouldUpdateResetTimestampKeyPathViaHeaders === true && (
        <LineIndent className="mt-6">
          <KeyPathFieldHeader
            dataTestID="field-rate-limit-config-response-header-rate-limit-reset-timestamp-key"
            title="Response header rate limit reset timestamp key"
            subtitle="The specified string path points to the header location where the rate limit reset timestamp value can be retrieved. Type the name of the key below and press Enter."
            placeholder="Add key name..."
            required
            keyPath={responseHeaderResetTimestampKeyPath}
            onKeyPathChange={setResponseHeaderResetTimestampKeyPath}
            hasSource={false}
            showArrow={false}
          />
          <ResetTimestampFormatButtons
            className="mt-6"
            resetExpirationFormat={resetExpirationFormat}
            setResetExpirationFormat={setResetExpirationFormat}
          />
        </LineIndent>
      )}
    </AccordionCard>
  );
};

export default RateLimitHeaders;
