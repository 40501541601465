import { BlueprintStepType } from "../../../../../models/Blueprints";
import { DiffState, DiffStateField } from "../../../../../models/DiffModels";
import { convertValueToString, findKeys } from "../helpers";
import { TemplateParentProps, addTemplateField, getValue } from "./helpers";

const apiEndpointStepTypes = [
  BlueprintStepType.APIRequest,
  BlueprintStepType.APIRequestLive,
  BlueprintStepType.APIRequestLoop,
  BlueprintStepType.APIRequestProxy,
];

export const generateDiffStateForStepTemplate = (
  currentStep: { [key: string]: any },
  newStep: { [key: string]: any },
  isRenderNewAsEmpty: boolean = false,
  isRenderCurrentAsEmpty: boolean = false
): DiffState => {
  let diffStateFields: DiffStateField[] = [];

  // Normalize "template" fields
  const normalizedCurrentTemplate = normalizeTemplate(currentStep);
  const normalizedNewTemplate = normalizeTemplate(newStep);
  const parentProps: TemplateParentProps = {
    diffStateFields: diffStateFields,
    currentState: normalizedCurrentTemplate,
    newState: normalizedNewTemplate,
    isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
    isRenderNewAsEmpty: isRenderNewAsEmpty,
  };

  // Create diff fields
  diffStateFields = addTemplateField("Name", parentProps);
  diffStateFields = addTemplateField("Step type", parentProps);
  if (
    normalizedCurrentTemplate.hasOwnProperty("Concurrency enabled") ||
    normalizedNewTemplate.hasOwnProperty("Concurrency enabled")
  ) {
    diffStateFields = addTemplateField("Concurrency enabled", parentProps);
  }
  if (
    normalizedCurrentTemplate.hasOwnProperty("Pagination timestamps enabled") ||
    normalizedNewTemplate.hasOwnProperty("Pagination timestamps enabled")
  ) {
    diffStateFields = addTemplateField("Pagination timestamps enabled", parentProps);
  }

  const isAPIEndpointInCurrent = "API Endpoint" in normalizedCurrentTemplate;
  const isAPIEndpointInNew = "API Endpoint" in normalizedNewTemplate;
  if (isAPIEndpointInCurrent || isAPIEndpointInNew) {
    const apiEndpointChildDiffStateFields = generateAPIEndpointTemplateDiffStateFields({
      currentData: normalizedCurrentTemplate["API Endpoint"] ?? {},
      newData: normalizedNewTemplate["API Endpoint"] ?? {},
      isRenderNewAsEmpty: isRenderNewAsEmpty,
      isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
    });
    diffStateFields = addTemplateField(
      "API endpoint",
      parentProps,
      apiEndpointChildDiffStateFields
    );
  }
  return diffStateFields;
};

const normalizeTemplate = (step: { [key: string]: any }): { [key: string]: any } => {
  const template: { [key: string]: any } = getValue(step, "template");
  let normalizedTemplate: { [key: string]: any } = {};
  if (template) {
    normalizedTemplate["Name"] = getValue(template, "name");
    normalizedTemplate["Step type"] = getValue(template, "step_type");
    if (getValue(template, "can_run_concurrently") === true) {
      normalizedTemplate["Concurrency enabled"] = getValue(step, "run_concurrently").toString();
    }
    if (getValue(step, "use_pagination_timestamp") === true) {
      normalizedTemplate["Pagination timestamps enabled"] = getValue(
        step,
        "use_pagination_timestamp"
      ).toString();
    }
    const metadata = getValue(template, "metadata");
    if (
      apiEndpointStepTypes.includes(normalizedTemplate["Step type"]) &&
      metadata &&
      typeof metadata === "object"
    ) {
      let apiEndpointMetadata: { [key: string]: any } = {};
      Object.keys(metadata).forEach(
        (key) => (apiEndpointMetadata[key] = convertValueToString(metadata[key]))
      );
      normalizedTemplate["API Endpoint"] = apiEndpointMetadata;
    }
  }
  return normalizedTemplate;
};

interface GeneratedAPIEndpointTemplateDiffStateFieldsProps {
  currentData: { [key: string]: any };
  newData: { [key: string]: any };
  isRenderNewAsEmpty: boolean;
  isRenderCurrentAsEmpty: boolean;
}

const generateAPIEndpointTemplateDiffStateFields = ({
  currentData,
  newData,
  isRenderNewAsEmpty = false,
  isRenderCurrentAsEmpty = false,
}: GeneratedAPIEndpointTemplateDiffStateFieldsProps): DiffStateField[] => {
  // Detect new & deleted fields.
  // Compare existing fields.
  // We don't care about order here.

  let diffStateFields: DiffStateField[] = [];
  let parentProps: TemplateParentProps = {
    diffStateFields: diffStateFields,
    currentState: currentData,
    newState: newData,
    isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
    isRenderNewAsEmpty: isRenderNewAsEmpty,
  };
  const currentKeys: string[] = Object.keys(currentData);
  const newKeys: string[] = Object.keys(newData);

  const existingKeys: string[] = findKeys(currentKeys, newKeys, true);
  const addedKeys: string[] = findKeys(newKeys, currentKeys, false);
  const deletedKeys: string[] = findKeys(currentKeys, newKeys, false);

  const allKeys: string[] = existingKeys.concat(addedKeys, deletedKeys);
  allKeys.forEach((key) => addTemplateField(key, parentProps));

  return diffStateFields;
};
