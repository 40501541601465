import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BreakValueType,
  EndOfPaginationType,
  PaginationType,
  PaginationValueRequestLocation,
  PointerForNextRequestResponseLocation,
} from "../../../../models/PaginationConfigurationModels";
import {
  PaginationConfigurationIntegrationBuilder,
  PaginationConfigurationResponseInformation,
  PaginationConfigurationSessionInformation,
  PaginationConfigurationSortInformation,
  PaginationPageSizeInformation,
} from "../../utils/Entities";
import PaginationConfigurationContext from "./PaginationConfigurationContext";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";

interface Props {
  children: JSX.Element;
  selectedPaginationConfiguration: PaginationConfigurationIntegrationBuilder | undefined;
  integrationID: string;
}

const PaginationConfigurationContextProvider = ({
  children,
  selectedPaginationConfiguration,
  integrationID,
}: Props) => {
  const endpoints = selectedPaginationConfiguration?.api_endpoints || null;

  const {
    setCurrentStateForDiff,
    setNewStateForDiff,
    modelTypeForDiff,
  } = useIntegrationBuilderContext();

  // Initialize functions
  const initialName = selectedPaginationConfiguration?.name || "";
  const initialDescription = selectedPaginationConfiguration?.description || "";
  const initialPaginationType = selectedPaginationConfiguration?.pagination_type || "";
  const initialIsDefaultForIntegration =
    selectedPaginationConfiguration?.is_default_for_integration || false;
  const initialPaginationValueRequestLocation =
    selectedPaginationConfiguration?.request_information?.pagination_value_information
      .pagination_value_request_location || "";
  const initialPaginationValueRequestLocationKeyPath =
    selectedPaginationConfiguration?.request_information?.pagination_value_information
      .pagination_value_request_location_key_path || null;
  const initialInitialPaginationValue =
    selectedPaginationConfiguration?.request_information?.pagination_value_information
      .initial_pagination_value || 0;
  const initialPageSize =
    selectedPaginationConfiguration?.request_information?.page_size_information?.page_size || null;
  const initialPageSizeRequestLocation =
    selectedPaginationConfiguration?.request_information?.page_size_information
      ?.page_size_request_location || null;
  const initialPageSizeKeyPath =
    selectedPaginationConfiguration?.request_information?.page_size_information
      ?.page_size_key_path || null;
  const initialPaginatedResultsArrayKeyPath =
    selectedPaginationConfiguration?.response_information?.paginated_results_array_key_path || null;
  const initialPointerForNextRequestResponseLocation =
    selectedPaginationConfiguration?.response_information?.pointer_for_next_request_information
      ?.response_location || null;
  const initialPointerForNextRequestResponseBodyKeyPath =
    selectedPaginationConfiguration?.response_information?.pointer_for_next_request_information
      .response_body_key_path || null;
  const initialEndOfPaginationType =
    selectedPaginationConfiguration?.response_information?.end_of_pagination_information
      .end_of_pagination_type || EndOfPaginationType.EMPTY_ARRAY;
  const initialMaxItemsValueLocation =
    selectedPaginationConfiguration?.response_information?.end_of_pagination_information
      .max_items_information?.response_max_items_value_location || null;
  const initialMaxItemsValueKeyPath =
    selectedPaginationConfiguration?.response_information?.end_of_pagination_information
      .max_items_information?.response_max_items_key_path || null;
  const initialBreakValueType =
    selectedPaginationConfiguration?.response_information?.end_of_pagination_information
      .break_value_information?.break_value_type || "";
  const initialBreakValueResponseBodyKeyPath =
    selectedPaginationConfiguration?.response_information?.end_of_pagination_information
      .break_value_information?.break_value_response_body_key_path || [];
  const initialSpecificValueToBreakOn =
    selectedPaginationConfiguration?.response_information?.end_of_pagination_information
      .break_value_information?.specific_value_to_break_on || null;
  const initialOverrideInfiniteLoopConditionKeyPath =
    selectedPaginationConfiguration?.response_information?.end_of_pagination_information
      .break_value_information?.override_infinite_loop_condition_key_path || null;
  const initialIsSessionPagination =
    selectedPaginationConfiguration?.session_information?.is_session_pagination || false;
  const initialSessionIDParamName =
    selectedPaginationConfiguration?.session_information?.session_id_param_name || null;
  const initialReadMoreRequestHeaderOverrides =
    selectedPaginationConfiguration?.session_information?.read_more_request_header_overrides ||
    null;
  const initialReadMorePaginatedRequestBodyBaseConfig =
    selectedPaginationConfiguration?.session_information
      ?.read_more_paginated_request_body_base_config || null;
  const initialReadMoreResponseBodyArrayKeyPath =
    selectedPaginationConfiguration?.session_information?.read_more_response_body_array_key_path ||
    null;
  const initialResponseSessionIDKeyPath =
    selectedPaginationConfiguration?.session_information?.response_session_id_key_path || null;
  const initialReadMoreResponseMaxItemsKeyPath =
    selectedPaginationConfiguration?.session_information?.read_more_response_max_items_key_path ||
    null;
  const initialIsSortAssistedPagination =
    selectedPaginationConfiguration?.sort_information?.is_sort_assisted_pagination || false;
  const initialSortFieldFilterRequestLocation =
    selectedPaginationConfiguration?.sort_information?.sort_field_filter_request_location || null;
  const initialSortFieldFilterRequestKeyPath =
    selectedPaginationConfiguration?.sort_information?.sort_field_filter_request_key_path || null;
  const initialSortFieldFilterRequestFormat =
    selectedPaginationConfiguration?.sort_information?.sort_field_filter_request_format || null;
  const initialSortFieldFilterResponseKeyPath =
    selectedPaginationConfiguration?.sort_information?.sort_field_filter_response_key_path || null;

  /**
   * Basic pagination configuration data
   */
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const [paginationType, setPaginationType] = useState(initialPaginationType);
  const [isDefaultForIntegration, setIsDefaultForIntegration] = useState(
    initialIsDefaultForIntegration
  );

  /**
   * Pagination configuration request information
   */
  const [paginationValueRequestLocation, setPaginationValueRequestLocation] = useState(
    initialPaginationValueRequestLocation
  );
  const [
    paginationValueRequestLocationKeyPath,
    setPaginationValueRequestLocationKeyPath,
  ] = useState(initialPaginationValueRequestLocationKeyPath);
  const [initialPaginationValue, setInitialPaginationValue] = useState<number | null>(
    initialInitialPaginationValue
  );
  // PaginationPageSizeInformation
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [pageSizeRequestLocation, setPageSizeRequestLocation] = useState(
    initialPageSizeRequestLocation
  );
  const [pageSizeKeyPath, setPageSizeKeyPath] = useState(initialPageSizeKeyPath);

  /**
   * Pagination configuration response information
   */
  const [paginatedResultsArrayKeyPath, setPaginatedResultsArrayKeyPath] = useState(
    initialPaginatedResultsArrayKeyPath
  );
  // PointerForNextRequestInformation
  const [
    pointerForNextRequestResponseLocation,
    setPointerForNextRequestResponseLocation,
  ] = useState<string | null>(initialPointerForNextRequestResponseLocation);
  const [
    pointerForNextRequestResponseBodyKeyPath,
    setPointerForNextRequestResponseBodyKeyPath,
  ] = useState(initialPointerForNextRequestResponseBodyKeyPath);
  // EndOfPaginationInformation
  const [endOfPaginationType, setEndOfPaginationType] = useState(initialEndOfPaginationType);
  // --- EndOfPaginationMaxItemsInformation
  const [maxItemsValueLocation, setMaxItemsValueLocation] = useState(initialMaxItemsValueLocation);
  const [maxItemsValueKeyPath, setMaxItemsValueKeyPath] = useState(initialMaxItemsValueKeyPath);
  // --- EndOfPaginationBreakValueInformation
  const [breakValueType, setBreakValueType] = useState(initialBreakValueType);
  const [breakValueResponseBodyKeyPath, setBreakValueResponseBodyKeyPath] = useState<
    string[] | null
  >(initialBreakValueResponseBodyKeyPath);
  const [specificValueToBreakOn, setSpecificValueToBreakOn] = useState(
    initialSpecificValueToBreakOn
  );
  const [overrideInfiniteLoopConditionKeyPath, setOverrideInfiniteLoopConditionKeyPath] = useState(
    initialOverrideInfiniteLoopConditionKeyPath
  );

  /**
   * Pagination configuration session information
   */
  const [isSessionPagination, setIsSessionPagination] = useState(initialIsSessionPagination);
  const [sessionIDParamName, setSessionIDParamName] = useState(initialSessionIDParamName);
  const [readMoreRequestHeaderOverrides, setReadMoreRequestHeaderOverrides] = useState(
    initialReadMoreRequestHeaderOverrides
  );
  const [
    readMorePaginatedRequestBodyBaseConfig,
    setReadMorePaginatedRequestBodyBaseConfig,
  ] = useState(initialReadMorePaginatedRequestBodyBaseConfig);
  const [readMoreResponseBodyArrayKeyPath, setReadMoreResponseBodyArrayKeyPath] = useState(
    initialReadMoreResponseBodyArrayKeyPath
  );
  const [responseSessionIDKeyPath, setResponseSessionIDKeyPath] = useState(
    initialResponseSessionIDKeyPath
  );
  const [readMoreResponseMaxItemsKeyPath, setReadMoreResponseMaxItemsKeyPath] = useState(
    initialReadMoreResponseMaxItemsKeyPath
  );

  /**
   * Pagination configuration sort information
   */
  const [isSortAssistedPagination, setIsSortAssistedPagination] = useState(
    initialIsSortAssistedPagination
  );
  const [sortFieldFilterRequestLocation, setSortFieldFilterRequestLocation] = useState(
    initialSortFieldFilterRequestLocation
  );
  const [sortFieldFilterRequestKeyPath, setSortFieldFilterRequestKeyPath] = useState(
    initialSortFieldFilterRequestKeyPath
  );
  const [sortFieldFilterRequestFormat, setSortFieldFilterRequestFormat] = useState(
    initialSortFieldFilterRequestFormat
  );
  const [sortFieldFilterResponseKeyPath, setSortFieldFilterResponseKeyPath] = useState(
    initialSortFieldFilterResponseKeyPath
  );

  // Set states based on selected pagination configuration
  // This is required for page reloads
  useEffect(() => {
    setName(initialName);
    setDescription(initialDescription);
    setPaginationType(initialPaginationType);
    setIsDefaultForIntegration(initialIsDefaultForIntegration);
    setPaginationValueRequestLocation(initialPaginationValueRequestLocation);
    setPaginationValueRequestLocationKeyPath(initialPaginationValueRequestLocationKeyPath);
    setInitialPaginationValue(initialInitialPaginationValue);
    setPageSize(initialPageSize);
    setPageSizeRequestLocation(initialPageSizeRequestLocation);
    setPageSizeKeyPath(initialPageSizeKeyPath);
    setPaginatedResultsArrayKeyPath(initialPaginatedResultsArrayKeyPath);
    setPointerForNextRequestResponseLocation(initialPointerForNextRequestResponseLocation);
    setPointerForNextRequestResponseBodyKeyPath(initialPointerForNextRequestResponseBodyKeyPath);
    setEndOfPaginationType(initialEndOfPaginationType);
    setMaxItemsValueLocation(initialMaxItemsValueLocation);
    setMaxItemsValueKeyPath(initialMaxItemsValueKeyPath);
    setBreakValueType(initialBreakValueType);
    setBreakValueResponseBodyKeyPath(initialBreakValueResponseBodyKeyPath);
    setSpecificValueToBreakOn(initialSpecificValueToBreakOn);
    setOverrideInfiniteLoopConditionKeyPath(initialOverrideInfiniteLoopConditionKeyPath);
    setIsSessionPagination(initialIsSessionPagination);
    setSessionIDParamName(initialSessionIDParamName);
    setReadMoreRequestHeaderOverrides(initialReadMoreRequestHeaderOverrides);
    setReadMorePaginatedRequestBodyBaseConfig(initialReadMorePaginatedRequestBodyBaseConfig);
    setReadMoreResponseBodyArrayKeyPath(initialReadMoreResponseBodyArrayKeyPath);
    setResponseSessionIDKeyPath(initialResponseSessionIDKeyPath);
    setReadMoreResponseMaxItemsKeyPath(initialReadMoreResponseMaxItemsKeyPath);
    setIsSortAssistedPagination(initialIsSortAssistedPagination);
    setSortFieldFilterRequestLocation(initialSortFieldFilterRequestLocation);
    setSortFieldFilterRequestKeyPath(initialSortFieldFilterRequestKeyPath);
    setSortFieldFilterRequestFormat(initialSortFieldFilterRequestFormat);
    setSortFieldFilterResponseKeyPath(initialSortFieldFilterResponseKeyPath);
  }, [selectedPaginationConfiguration]);

  /* This useEffect initializes the model state to be used in the diff modal.
   * When adding/removing fields, you must update helpers-pag-config-diff.ts
   * to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    const responseInformation: PaginationConfigurationResponseInformation = {
      paginated_results_array_key_path: initialPaginatedResultsArrayKeyPath,
      pointer_for_next_request_information: {
        response_location: initialPointerForNextRequestResponseLocation || null,
        response_body_key_path: initialPointerForNextRequestResponseBodyKeyPath || null,
      },
      end_of_pagination_information: {
        end_of_pagination_type: initialEndOfPaginationType,
        max_items_information:
          initialEndOfPaginationType === EndOfPaginationType.MAX_ITEMS
            ? {
                response_max_items_value_location: initialMaxItemsValueLocation || null,
                response_max_items_key_path: initialMaxItemsValueKeyPath || [],
              }
            : null,
        break_value_information:
          endOfPaginationType === EndOfPaginationType.BREAK_VALUE
            ? {
                break_value_type: initialBreakValueType,
                break_value_response_body_key_path: initialBreakValueResponseBodyKeyPath || [],
                specific_value_to_break_on: initialSpecificValueToBreakOn,
                override_infinite_loop_condition_key_path: initialOverrideInfiniteLoopConditionKeyPath,
              }
            : null,
      },
    };

    const pageSizeInformation: PaginationPageSizeInformation = {
      page_size: initialPageSize || 0,
      page_size_request_location: initialPageSizeRequestLocation,
      page_size_key_path: initialPageSizeKeyPath || [],
    };

    const sessionInformation: PaginationConfigurationSessionInformation = initialIsSessionPagination
      ? {
          is_session_pagination: true,
          session_id_param_name: initialSessionIDParamName,
          read_more_request_header_overrides: initialReadMoreRequestHeaderOverrides,
          read_more_paginated_request_body_base_config: initialReadMorePaginatedRequestBodyBaseConfig,
          read_more_response_body_array_key_path: initialReadMoreResponseBodyArrayKeyPath,
          response_session_id_key_path: initialResponseSessionIDKeyPath,
          read_more_response_max_items_key_path: initialReadMoreResponseMaxItemsKeyPath,
        }
      : {
          is_session_pagination: false,
          session_id_param_name: null,
          read_more_request_header_overrides: null,
          read_more_paginated_request_body_base_config: null,
          read_more_response_body_array_key_path: null,
          response_session_id_key_path: null,
          read_more_response_max_items_key_path: null,
        };

    const sortInformation: PaginationConfigurationSortInformation = initialIsSortAssistedPagination
      ? {
          is_sort_assisted_pagination: true,
          sort_field_filter_request_location: initialSortFieldFilterRequestLocation,
          sort_field_filter_request_key_path: initialSortFieldFilterRequestKeyPath || [],
          sort_field_filter_request_format: initialSortFieldFilterRequestFormat,
          sort_field_filter_response_key_path: initialSortFieldFilterResponseKeyPath || [],
        }
      : {
          is_sort_assisted_pagination: false,
          sort_field_filter_request_location: null,
          sort_field_filter_request_key_path: null,
          sort_field_filter_request_format: null,
          sort_field_filter_response_key_path: null,
        };

    const initialDiffData: PaginationConfigurationIntegrationBuilder = {
      id: selectedPaginationConfiguration?.id,
      name: initialName,
      description: initialDescription,
      pagination_type: initialPaginationType,
      is_default_for_integration: initialIsDefaultForIntegration,
      request_information: {
        pagination_value_information: {
          pagination_value_request_location: initialPaginationValueRequestLocation,
          pagination_value_request_location_key_path: initialPaginationValueRequestLocationKeyPath,
          initial_pagination_value: initialInitialPaginationValue,
        },
        items_per_request_information: null,
        page_size_information: pageSizeInformation,
      },
      response_information: responseInformation,
      session_information: sessionInformation,
      sort_information: sortInformation,
    };

    if (modelTypeForDiff === DiffModelTypeEnum.API_ENDPOINT) {
      setCurrentStateForDiff((diff: { [key: string]: any }) => {
        return { ...diff, pagination_configuration: initialDiffData };
      });
      setNewStateForDiff((diff: { [key: string]: any }) => {
        return { ...diff, pagination_configuration: initialDiffData };
      });
    } else {
      setCurrentStateForDiff(initialDiffData);
      setNewStateForDiff(initialDiffData);
    }
  }, [modelTypeForDiff, selectedPaginationConfiguration]);

  const formPaginationConfigurationData = useCallback(() => {
    const responseInformation: PaginationConfigurationResponseInformation = {
      paginated_results_array_key_path: paginatedResultsArrayKeyPath,
      pointer_for_next_request_information: {
        response_location: pointerForNextRequestResponseLocation || null,
        response_body_key_path: pointerForNextRequestResponseBodyKeyPath || null,
      },
      end_of_pagination_information: {
        end_of_pagination_type: endOfPaginationType,
        max_items_information:
          endOfPaginationType === EndOfPaginationType.MAX_ITEMS
            ? {
                response_max_items_value_location: maxItemsValueLocation || null,
                response_max_items_key_path: maxItemsValueKeyPath || [],
              }
            : null,
        break_value_information:
          endOfPaginationType === EndOfPaginationType.BREAK_VALUE
            ? {
                break_value_type: breakValueType,
                break_value_response_body_key_path: breakValueResponseBodyKeyPath || [],
                specific_value_to_break_on: specificValueToBreakOn,
                override_infinite_loop_condition_key_path: overrideInfiniteLoopConditionKeyPath,
              }
            : null,
      },
    };

    const pageSizeInformation: PaginationPageSizeInformation = {
      page_size: pageSize || 0,
      page_size_request_location: pageSizeRequestLocation,
      page_size_key_path: pageSizeKeyPath || [],
    };

    const sessionInformation: PaginationConfigurationSessionInformation = isSessionPagination
      ? {
          is_session_pagination: true,
          session_id_param_name: sessionIDParamName,
          read_more_request_header_overrides: readMoreRequestHeaderOverrides,
          read_more_paginated_request_body_base_config: readMorePaginatedRequestBodyBaseConfig,
          read_more_response_body_array_key_path: readMoreResponseBodyArrayKeyPath,
          response_session_id_key_path: responseSessionIDKeyPath,
          read_more_response_max_items_key_path: readMoreResponseMaxItemsKeyPath,
        }
      : {
          is_session_pagination: false,
          session_id_param_name: null,
          read_more_request_header_overrides: null,
          read_more_paginated_request_body_base_config: null,
          read_more_response_body_array_key_path: null,
          response_session_id_key_path: null,
          read_more_response_max_items_key_path: null,
        };

    const sortInformation: PaginationConfigurationSortInformation = isSortAssistedPagination
      ? {
          is_sort_assisted_pagination: true,
          sort_field_filter_request_location: sortFieldFilterRequestLocation,
          sort_field_filter_request_key_path: sortFieldFilterRequestKeyPath || [],
          sort_field_filter_request_format: sortFieldFilterRequestFormat,
          sort_field_filter_response_key_path: sortFieldFilterResponseKeyPath || [],
        }
      : {
          is_sort_assisted_pagination: false,
          sort_field_filter_request_location: null,
          sort_field_filter_request_key_path: null,
          sort_field_filter_request_format: null,
          sort_field_filter_response_key_path: null,
        };

    const data: PaginationConfigurationIntegrationBuilder = {
      id: selectedPaginationConfiguration?.id,
      name,
      description,
      pagination_type: paginationType,
      is_default_for_integration: isDefaultForIntegration,
      request_information: {
        pagination_value_information: {
          pagination_value_request_location: paginationValueRequestLocation,
          pagination_value_request_location_key_path: paginationValueRequestLocationKeyPath,
          initial_pagination_value: initialPaginationValue,
        },
        items_per_request_information: null,
        page_size_information: pageSizeInformation,
      },
      response_information: responseInformation,
      session_information: sessionInformation,
      sort_information: sortInformation,
    };
    return data;
  }, [
    name,
    description,
    paginationType,
    isDefaultForIntegration,
    paginationValueRequestLocation,
    paginationValueRequestLocationKeyPath,
    initialPaginationValue,
    paginatedResultsArrayKeyPath,
    pointerForNextRequestResponseLocation,
    pointerForNextRequestResponseBodyKeyPath,
    endOfPaginationType,
    maxItemsValueLocation,
    maxItemsValueKeyPath,
    breakValueType,
    breakValueResponseBodyKeyPath,
    specificValueToBreakOn,
    overrideInfiniteLoopConditionKeyPath,
    pageSize,
    pageSizeRequestLocation,
    pageSizeKeyPath,
    isSessionPagination,
    sessionIDParamName,
    readMoreRequestHeaderOverrides,
    readMorePaginatedRequestBodyBaseConfig,
    readMoreResponseBodyArrayKeyPath,
    responseSessionIDKeyPath,
    readMoreResponseMaxItemsKeyPath,
    isSortAssistedPagination,
    sortFieldFilterRequestLocation,
    sortFieldFilterRequestKeyPath,
    sortFieldFilterRequestFormat,
    sortFieldFilterResponseKeyPath,
  ]);

  /* This useEffect updates the model state to be used in the diff modal.
   */
  useEffect(() => {
    const paginationConfigData = formPaginationConfigurationData();
    if (paginationConfigData && modelTypeForDiff !== DiffModelTypeEnum.API_ENDPOINT) {
      setNewStateForDiff(paginationConfigData);
    }
  }, [modelTypeForDiff, formPaginationConfigurationData]);

  const canSubmitPaginationConfiguration = useMemo(() => {
    // const isEmptyArray = (array?: any[] | null): boolean => !!(array && array.length > 0 && array[0].length > 0);
    const isValidString = (value: string | null): boolean => value !== null && value !== "";
    const isValidArray = (array: string[] | null): boolean =>
      array !== null && array.length > 0 && array[0].length > 0;

    // BASIC PAGINATION CONFIGURATION VALIDATION
    const isBasicConfigurationValid = isValidString(name) && isValidString(paginationType);

    if (!isBasicConfigurationValid) {
      return false;
    }

    // REQUEST INFORMATION VALIDATION
    const isBasicRequestInformationValid =
      isValidString(paginationValueRequestLocation) || isSessionPagination;
    const isKeyPathNeeded = [
      PaginationValueRequestLocation.QUERY_PARAM,
      PaginationValueRequestLocation.PATH_PARAM,
      PaginationValueRequestLocation.BODY_PARAM,
    ].includes(paginationValueRequestLocation as PaginationValueRequestLocation);
    const isKeyPathValid =
      !isKeyPathNeeded ||
      isValidArray(paginationValueRequestLocationKeyPath) ||
      isSessionPagination;
    const isRequestInformationValid = isBasicRequestInformationValid && isKeyPathValid;

    if (!isRequestInformationValid) {
      return false;
    }

    // RESPONSE INFORMATION VALIDATION
    // key path must be specified always
    // pointer for next request response location must be specified when using URL or PATH
    const isBasicResponseInformationValid =
      isValidString(pointerForNextRequestResponseLocation) ||
      (paginationValueRequestLocation !== PaginationValueRequestLocation.URL &&
        paginationValueRequestLocation !== PaginationValueRequestLocation.PATH);
    const isPointerForNextValid =
      pointerForNextRequestResponseLocation !==
        PointerForNextRequestResponseLocation.RESPONSE_BODY ||
      isValidArray(pointerForNextRequestResponseBodyKeyPath);
    // page size validation - if pagination type is offset, page size is needed
    const isPageSizeInfoValid =
      paginationType !== PaginationType.OFFSET ||
      (initialPaginationValue !== null &&
        pageSize !== null &&
        isValidString(pageSizeRequestLocation) &&
        isValidArray(pageSizeKeyPath));
    const isResponseInformationValid =
      isBasicResponseInformationValid && isPointerForNextValid && isPageSizeInfoValid;

    if (!isResponseInformationValid) {
      return false;
    }

    // END OF PAGINATION VALIDATION
    const isMaxItemsValid =
      endOfPaginationType !== EndOfPaginationType.MAX_ITEMS ||
      (isValidString(maxItemsValueLocation) && isValidArray(maxItemsValueKeyPath));
    const isBreakValueValid =
      endOfPaginationType !== EndOfPaginationType.BREAK_VALUE ||
      (isValidString(breakValueType) &&
        isValidArray(breakValueResponseBodyKeyPath) &&
        (breakValueType !== BreakValueType.BREAK_WHEN_SPECIFIC_VALUE ||
          isValidString(specificValueToBreakOn)));
    const isEndOfPaginationValid = isMaxItemsValid && isBreakValueValid;

    if (!isEndOfPaginationValid) {
      return false;
    }

    // SESSION AND SORT VALIDATION
    const isSessionPaginationValid =
      !isSessionPagination ||
      (isValidString(sessionIDParamName) && isValidArray(responseSessionIDKeyPath));
    const isSortAssistedPaginationValid =
      !isSortAssistedPagination ||
      (isValidString(sortFieldFilterRequestLocation) &&
        isValidArray(sortFieldFilterRequestKeyPath) &&
        isValidArray(sortFieldFilterResponseKeyPath));
    if (!isSessionPaginationValid || !isSortAssistedPaginationValid) {
      return false;
    }

    // if you made it this far, all validations passed :)
    return true;
  }, [
    name,
    paginationType,
    paginationValueRequestLocation,
    paginationValueRequestLocationKeyPath,
    pointerForNextRequestResponseLocation,
    pointerForNextRequestResponseBodyKeyPath,
    initialPaginationValue,
    pageSize,
    pageSizeRequestLocation,
    pageSizeKeyPath,
    endOfPaginationType,
    maxItemsValueLocation,
    maxItemsValueKeyPath,
    breakValueType,
    breakValueResponseBodyKeyPath,
    specificValueToBreakOn,
    isSessionPagination,
    sessionIDParamName,
    responseSessionIDKeyPath,
    isSortAssistedPagination,
    sortFieldFilterRequestLocation,
    sortFieldFilterRequestKeyPath,
    sortFieldFilterResponseKeyPath,
  ]);

  return (
    <PaginationConfigurationContext.Provider
      value={{
        integrationID,
        endpoints,

        // Basic pagination configuration information
        name,
        setName,
        description,
        setDescription,
        paginationType,
        setPaginationType,
        isDefaultForIntegration,
        setIsDefaultForIntegration,

        // Pagination configuration request information
        paginationValueRequestLocation,
        setPaginationValueRequestLocation,
        paginationValueRequestLocationKeyPath,
        setPaginationValueRequestLocationKeyPath,
        initialPaginationValue,
        setInitialPaginationValue,
        // PaginationPageSizeInformation
        pageSize,
        setPageSize,
        pageSizeRequestLocation,
        setPageSizeRequestLocation,
        pageSizeKeyPath,
        setPageSizeKeyPath,

        // Pagination configuration response information
        paginatedResultsArrayKeyPath,
        setPaginatedResultsArrayKeyPath,
        // PointerForNextRequestInformation
        pointerForNextRequestResponseLocation,
        setPointerForNextRequestResponseLocation,
        pointerForNextRequestResponseBodyKeyPath,
        setPointerForNextRequestResponseBodyKeyPath,
        // EndOfPaginationInformation
        endOfPaginationType,
        setEndOfPaginationType,
        // --- EndOfPaginationMaxItemsInformation
        maxItemsValueLocation,
        setMaxItemsValueLocation,
        maxItemsValueKeyPath,
        setMaxItemsValueKeyPath,
        // --- EndOfPaginationBreakValueInformation
        breakValueType,
        setBreakValueType,
        breakValueResponseBodyKeyPath,
        setBreakValueResponseBodyKeyPath,
        specificValueToBreakOn,
        setSpecificValueToBreakOn,
        overrideInfiniteLoopConditionKeyPath,
        setOverrideInfiniteLoopConditionKeyPath,

        // Pagination configuration session information
        isSessionPagination,
        setIsSessionPagination,
        sessionIDParamName,
        setSessionIDParamName,
        readMoreRequestHeaderOverrides,
        setReadMoreRequestHeaderOverrides,
        readMorePaginatedRequestBodyBaseConfig,
        setReadMorePaginatedRequestBodyBaseConfig,
        readMoreResponseBodyArrayKeyPath,
        setReadMoreResponseBodyArrayKeyPath,
        responseSessionIDKeyPath,
        setResponseSessionIDKeyPath,
        readMoreResponseMaxItemsKeyPath,
        setReadMoreResponseMaxItemsKeyPath,

        // Pagination configuration sort information
        isSortAssistedPagination,
        setIsSortAssistedPagination,
        sortFieldFilterRequestLocation,
        setSortFieldFilterRequestLocation,
        sortFieldFilterRequestKeyPath,
        setSortFieldFilterRequestKeyPath,
        sortFieldFilterRequestFormat,
        setSortFieldFilterRequestFormat,
        sortFieldFilterResponseKeyPath,
        setSortFieldFilterResponseKeyPath,

        // Form builder for pagination configuration data
        formPaginationConfigurationData,

        // Can submit pagination configuration
        canSubmitPaginationConfiguration,
      }}
    >
      {children}
    </PaginationConfigurationContext.Provider>
  );
};

export default PaginationConfigurationContextProvider;
