import "react-bootstrap-typeahead/css/Typeahead.css";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeprecatedBadge } from "../../shared/MergeBadges";
import { SmallTextMutedParagraph } from "../../shared/text/MergeText";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

export interface FormFieldCommonProps {
  subtitle?: string | JSX.Element;
  title?: string;
  example?: string;
  valueType?: string;
  deprecated?: boolean;
  choices?: any;
  className?: string;
  onBlur?: () => void;
  relationModel?: string;
  titleChildren?: string | JSX.Element;
  dataTestID?: string;
}

interface Props extends FormFieldCommonProps {
  children: JSX.Element | (JSX.Element | false | undefined)[];
}

function FormField({
  children,
  dataTestID,
  example,
  subtitle,
  title,
  valueType,
  deprecated,
  choices,
  className,
  onBlur,
  titleChildren,
}: Props) {
  return (
    <div className={className || "form-group mb-9"} onBlur={onBlur}>
      <div data-testid={dataTestID} className="d-flex align-items-center text-align-center mb-1.5">
        {title && <DeprecatedH4 className="mb-0">{title}</DeprecatedH4>}
        {valueType && (
          <Badge variant="soft-secondary" className="ml-1.5">
            {valueType}
          </Badge>
        )}
        {deprecated && <DeprecatedBadge />}
        {titleChildren}
      </div>
      {subtitle && (
        <SmallTextMutedParagraph>
          {subtitle}
          {choices && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 0 }}
              overlay={
                <Tooltip id="choices-mapping-tooltip">
                  If you are hard-coding a value, include one of the following:
                  {choices.map((choice: string) => " " + choice)}
                </Tooltip>
              }
            >
              <i className="ml-1.5 text-muted text-right fe fe-info float-right" />
            </OverlayTrigger>
          )}
        </SmallTextMutedParagraph>
      )}
      {example && <SmallTextMutedParagraph>{example}</SmallTextMutedParagraph>}
      {children}
    </div>
  );
}

export default FormField;
