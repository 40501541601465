import { DropResult } from "@hello-pangea/dnd";
import { AuthType } from "../../../../models/Entities";
import { Row } from "../../shared/postman-table/PostmanTableRow";
import {
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepPathTypeEnums,
  LinkChoiceStepOptionFormFieldValueEnums,
  LinkChoiceStepOption,
  LinkingFlowStepTypeEnums,
  AuthConfigIntegrationBuilder,
  LinkingFlowStepIntegrationBuilder,
  LinkChoiceStepOptionAccountTypeEnums,
  LinkingFlowStepPathTableRowsIntegrationBuilder,
} from "../../utils/Entities";
import cloneDeep from "lodash/cloneDeep";

const scraperPathTypes = [
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_MFA,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_SECURITY_QUESTION,
];

export function generateStepPathTableRows(
  authConfigs: AuthConfigIntegrationBuilder[],
  stepPaths: LinkingFlowStepPathIntegrationBuilder[]
) {
  let copyAuthConfigs = cloneDeep(authConfigs);
  let rows: LinkingFlowStepPathTableRowsIntegrationBuilder[] = [];
  let stepPath: LinkingFlowStepPathIntegrationBuilder | undefined = undefined;

  copyAuthConfigs.forEach((authConfig) => {
    if (authConfig.auth_type === AuthType.AUTH_TYPE_SCRAPER) {
      scraperPathTypes.forEach((pathType) => {
        stepPath = stepPaths.find(
          (path) => path.auth_configuration_id === authConfig.id && path.path_type === pathType
        );
        rows.push({
          id: authConfig.id + pathType,
          auth_config_id: authConfig.id,
          auth_config_name: authConfig.name,
          step_path_id: stepPath?.id,
          path_type: pathType,
          is_active: stepPath?.is_active,
        });
      });
    } else {
      stepPath = stepPaths.find((path) => path.auth_configuration_id === authConfig.id);
      rows.push({
        id: authConfig.id,
        auth_config_id: authConfig.id,
        auth_config_name: authConfig.name,
        step_path_id: stepPath?.id,
        path_type: stepPath?.path_type,
        is_active: stepPath?.is_active,
      });
    }
  });

  return rows;
}

export const sortStepPathTableRows = (rows: LinkingFlowStepPathTableRowsIntegrationBuilder[]) => {
  let copyOfRows = rows;
  copyOfRows.sort((a, b) => {
    let aExists = a.step_path_id ? true : false;
    let bExists = b.step_path_id ? true : false;
    return aExists === bExists ? 0 : aExists ? -1 : 1;
  });
  return copyOfRows;
};

export const formattedPathTypeName = (pathType: string) => {
  let pathTypeWithoutUnderscores = pathType.replace(/_/g, " ");
  let reformattedPathType = `${pathTypeWithoutUnderscores
    .slice(0, 1)
    .toUpperCase()}${pathTypeWithoutUnderscores.slice(1).toLowerCase()}`;
  if (reformattedPathType.includes(" mfa")) {
    reformattedPathType = reformattedPathType.replace(" mfa", " MFA");
  }
  return reformattedPathType;
};

export function convertQueryValueToEnumKey<T extends { [key: string]: string }>(
  queryParamKey: string,
  enumType: T
): T[keyof T] | undefined {
  const queryValue = new URLSearchParams(window.location.search).get(queryParamKey);
  return queryValue
    ? enumType[Object.keys(enumType).find((key) => enumType[key] === queryValue) as keyof T]
    : undefined;
}

/* Helpers for Additional auth Postman table */

export const convertAdditionalAuthNamesToRows = (
  authConfigs: AuthConfigIntegrationBuilder[] | undefined,
  requestedStepPathAuthConfigID: string,
  requestedStep: LinkingFlowStepIntegrationBuilder
) => {
  let authConfig = authConfigs?.find(
    (authConfig) => authConfig.id === requestedStepPathAuthConfigID
  );
  let rows: Row[] = [];
  authConfig?.additional_auth_field_keys?.forEach((field_key: string) => {
    rows.push({
      active: true,
      key: field_key,
      value:
        requestedStep.additional_auth_display_names !== undefined &&
        field_key in requestedStep.additional_auth_display_names
          ? requestedStep.additional_auth_display_names[field_key]
          : "",
    });
  });
  return rows;
};

export const convertAdditionalAuthRowsToNames = (additionalAuthDisplayNameRows: Row[]) => {
  let names: Record<string, string> = {};
  additionalAuthDisplayNameRows.forEach((row: Row) => {
    if (row.value) {
      names[row.key] = row.value;
    }
  });
  return names;
};

/* HELPER FUNCTIONS FOR ADD/DELETE/UPDATE STEPS */

export function addStep(
  requestedStepPath: LinkingFlowStepPathIntegrationBuilder,
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >
) {
  let newStep: LinkingFlowStepIntegrationBuilder = {
    step_type: LinkingFlowStepTypeEnums.STEP_TYPE_OAUTH,
    step_number:
      requestedStepPath.steps.length > 0
        ? requestedStepPath.steps[requestedStepPath.steps.length - 1].step_number + 1
        : 1,
    integration_setup_checklist_item_id: null,
  };
  setRequestedStepPath({
    ...requestedStepPath,
    steps: [...requestedStepPath.steps, newStep],
  });
}

export function deleteStep(
  stepIndex: number,
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >
) {
  setRequestedStepPath((oldRequestedStepPath) => {
    return oldRequestedStepPath
      ? {
          ...oldRequestedStepPath,
          steps: [
            ...oldRequestedStepPath.steps.slice(0, stepIndex),
            ...oldRequestedStepPath.steps.slice(stepIndex + 1),
          ],
        }
      : undefined;
  });
}

export function updateStep(
  stepIndex: number,
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >,
  keyValuePairs: Record<string, any>
) {
  const shouldOverrideEntireStep = "step_type" in keyValuePairs;
  setRequestedStepPath((oldRequestedStepPath) => {
    if (oldRequestedStepPath) {
      const newStep: LinkingFlowStepIntegrationBuilder = shouldOverrideEntireStep
        ? {
            step_type: keyValuePairs["step_type"],
            step_number: oldRequestedStepPath.steps[stepIndex].step_number,
            integration_setup_checklist_item_id: null,
          }
        : { ...oldRequestedStepPath.steps[stepIndex], ...keyValuePairs };
      return {
        ...oldRequestedStepPath,
        steps: [
          ...oldRequestedStepPath.steps.slice(0, stepIndex),
          newStep,
          ...oldRequestedStepPath.steps.slice(stepIndex + 1),
        ],
      };
    } else {
      return;
    }
  });
}

/* Functions for re-ordering steps */
const reorderSteps = (
  steps: LinkingFlowStepIntegrationBuilder[],
  startIndex: number,
  endIndex: number
) => {
  const result: LinkingFlowStepIntegrationBuilder[] = [...steps];
  // Re-order steps
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // Re-assign step_numbers
  result.forEach((step, index) => (step.step_number = index + 1));
  return result;
};

export function onDragEnd(
  result: DropResult,
  requestedStepPath: LinkingFlowStepPathIntegrationBuilder,
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >
) {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  const reorderedSteps: LinkingFlowStepIntegrationBuilder[] = reorderSteps(
    requestedStepPath.steps,
    result.source.index,
    result.destination.index
  );
  setRequestedStepPath({
    ...requestedStepPath,
    steps: reorderedSteps,
  });
}

/* HELPER FUNCTIONS FOR ADD/DELETE/UPDATE LINK CHOICE STEP OPTIONS */

export function addLinkChoiceStepOption(
  setRequestedLinkChoiceStepOptions: React.Dispatch<React.SetStateAction<LinkChoiceStepOption[]>>
) {
  setRequestedLinkChoiceStepOptions((oldRequestedLinkChoiceStepOption) => {
    return [
      ...oldRequestedLinkChoiceStepOption,
      {
        name: "",
        account_type: LinkChoiceStepOptionAccountTypeEnums.END_USER_PRODUCTION_ACCOUNT,
        auth_configuration_override_id: null,
        form_field_values: [],
      },
    ];
  });
}

export function deleteLinkChoiceStepOption(
  index: number,
  setRequestedLinkChoiceStepOptions: React.Dispatch<React.SetStateAction<LinkChoiceStepOption[]>>
) {
  setRequestedLinkChoiceStepOptions((oldRequestedLinkChoiceStepOption) => {
    return oldRequestedLinkChoiceStepOption
      ? [
          ...oldRequestedLinkChoiceStepOption.slice(0, index),
          ...oldRequestedLinkChoiceStepOption.slice(index + 1),
        ]
      : [];
  });
}

export function updateLinkChoiceStepOption(
  index: number,
  setRequestedLinkChoiceStepOptions: React.Dispatch<React.SetStateAction<LinkChoiceStepOption[]>>,
  keyValuePairs: Record<string, any>
) {
  setRequestedLinkChoiceStepOptions((oldRequestedLinkChoiceStepOption) => {
    return oldRequestedLinkChoiceStepOption
      ? [
          ...oldRequestedLinkChoiceStepOption.slice(0, index),
          { ...oldRequestedLinkChoiceStepOption[index], ...keyValuePairs },
          ...oldRequestedLinkChoiceStepOption.slice(index + 1),
        ]
      : [];
  });
}

/* HELPER FUNCTIONS FOR ADD/DELETE/UPDATE FORM FIELD VALUE */

export function addFormFieldValue(
  updateLinkChoiceStepOption: (index: number, keyValuePairs: Record<string, any>) => void,
  indexStepOption: number,
  requestedLinkChoiceStepOption: LinkChoiceStepOption
) {
  updateLinkChoiceStepOption(indexStepOption, {
    form_field_values: [
      ...requestedLinkChoiceStepOption.form_field_values,
      {
        field_name: LinkChoiceStepOptionFormFieldValueEnums.BASE_URL_CONFIG_OVERRIDE_KEY,
      },
    ],
  });
}

export function deleteFormFieldValue(
  updateLinkChoiceStepOption: (index: number, keyValuePairs: Record<string, any>) => void,
  requestedLinkChoiceStepOption: LinkChoiceStepOption,
  indexStepOption: number,
  indexFormFieldValue: number
) {
  updateLinkChoiceStepOption(indexStepOption, {
    form_field_values: [
      ...requestedLinkChoiceStepOption.form_field_values.slice(0, indexFormFieldValue),
      ...requestedLinkChoiceStepOption.form_field_values.slice(indexFormFieldValue + 1),
    ],
  });
}

export function updateFormFieldValue(
  updateLinkChoiceStepOption: (index: number, keyValuePairs: Record<string, any>) => void,
  requestedLinkChoiceStepOption: LinkChoiceStepOption,
  indexStepOption: number,
  indexFormFieldValue: number,
  keyValuePairs: Record<string, any>
) {
  updateLinkChoiceStepOption(indexStepOption, {
    form_field_values: [
      ...requestedLinkChoiceStepOption.form_field_values.slice(0, indexFormFieldValue),
      {
        ...requestedLinkChoiceStepOption.form_field_values[indexFormFieldValue],
        ...keyValuePairs,
      },
      ...requestedLinkChoiceStepOption.form_field_values.slice(indexFormFieldValue + 1),
    ],
  });
}

/* Placeholders for linking flow steps */

export const LinkingFlowStepTitlePlaceholder = (
  linkingFlowStepType: LinkingFlowStepTypeEnums,
  integrationName?: string
) => {
  switch (linkingFlowStepType) {
    case LinkingFlowStepTypeEnums.STEP_TYPE_ADDITIONAL_AUTH:
      return "Enter additional information";
    case LinkingFlowStepTypeEnums.STEP_TYPE_URL_PARSER:
    case LinkingFlowStepTypeEnums.STEP_TYPE_OVERRIDE_BASE_API_URL:
      return `Enter your ${integrationName} URL`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_CUSTOM_DOMAIN:
      return `Enter your ${integrationName} subdomain`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_API_KEY:
      return `Enter your ${integrationName} API key`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_CUSTOM_PATH:
      return `Enter your ${integrationName} URL path`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_MFA_CODE:
      return `Enter your ${integrationName} 2FA code`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_CHOICE:
      return "Select...";
    case LinkingFlowStepTypeEnums.STEP_TYPE_OAUTH:
    case LinkingFlowStepTypeEnums.STEP_TYPE_TRELLO_OAUTH1:
      return `Authorize using ${integrationName}'s website`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_OAUTH_CLIENT_CREDENTIALS:
      return `Enter your ${integrationName} OAuth credentials`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_OAUTH_REFRESH_TOKEN:
      return `Enter your ${integrationName} OAuth refresh token`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_ORG_CONSENT:
    case LinkingFlowStepTypeEnums.STEP_TYPE_ORG_PURCHASE:
    case LinkingFlowStepTypeEnums.STEP_TYPE_TEXT_ONLY:
      return `Let's set up your integration with ${integrationName}`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_OVERRIDE_OAUTH_TOKEN_URL_AND_REFRESH_TOKEN:
      return `Enter your ${integrationName} OAuth Token URL and Refresh Token`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_SECURITY_QUESTION:
      return `Provide the answer to your security question`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_SERVICE_ACCOUNT:
      return `Set up a service account for your ${integrationName} instance and enter the account's email address`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_SSL_CERT_KEY:
      return `Upload your ${integrationName} organization's SSL certificate and key`;
    case LinkingFlowStepTypeEnums.STEP_TYPE_USERNAME_PASSWORD:
      return `Enter your ${integrationName} credentials`;
    default:
      return "none";
  }
};

export const LinkingFlowStepPathAuthChoiceNamePlaceholder = (
  authConfigType?: AuthType,
  integrationName?: string
) => {
  switch (authConfigType) {
    case AuthType.OAUTH2:
      return `Default: I want to authenticate through ${integrationName}'s website.`;
    case AuthType.AUTH_TYPE_SCRAPER:
      return `Default: I want to use my username and password.`;
    default:
      return `Example: Use scheduled reports (SFTP).`;
  }
};
