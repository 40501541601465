import classNames from "classnames";
import { APICategory, APIEndpointMethod } from "../../models/Entities";
import {
  BlueprintOperationType,
  BlueprintVersionPublishState,
  BlueprintStatus,
} from "../../models/Blueprints";
import styled from "styled-components";
import { apiCategoryFromString } from "../../models/Helpers";
import MergeText, { TextType } from "./text/MergeText";
import { palette } from "../../styles/theme/colors";
import { Badge } from "@merge-api/merge-javascript-shared";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";

type ResponseStatusBadgeProps = {
  responseCode: number;
  className?: string;
};

export const ResponseStatusBadge = ({ responseCode, className }: ResponseStatusBadgeProps) => {
  return (
    <Badge
      color={responseCode < 300 ? "teal" : responseCode < 400 ? "amber" : "red"}
      size="sm"
      className={className}
    >
      {responseCode}
    </Badge>
  );
};

type StatusBadgeProps = {
  status: string | JSX.Element;
  className?: string;
};

export const StatusBadge = ({ status, className }: StatusBadgeProps) => {
  return (
    <>
      {(() => {
        switch (status) {
          case "Not Started":
            return (
              <span className={classNames("badge badge-soft-dark", className)}>Not Started</span>
            );
          case "In Progress":
            return (
              <span className={classNames("badge badge-soft-warning", className)}>In Progress</span>
            );
          case "Complete":
            return (
              <span className={classNames("badge badge-soft-success", className)}>Complete</span>
            );
          default:
            return <span className={classNames("badge badge-light", className)}>{status}</span>;
        }
      })()}
    </>
  );
};

type BlueprintStatusBadgeProps = {
  status: BlueprintVersionPublishState | BlueprintStatus;
};

export const BlueprintStatusBadge = ({ status }: BlueprintStatusBadgeProps) => {
  let style = null;
  let statusText = "";
  switch (status) {
    case BlueprintStatus.Archived:
      style = TextType.MUTED2;
      statusText = "Archived";
      break;
    case BlueprintStatus.Active:
      style = TextType.SUCCESS;
      statusText = "Active";
      break;
    case BlueprintVersionPublishState.Draft:
      style = TextType.MUTED;
      statusText = "Draft";
      break;
    case BlueprintVersionPublishState.Published:
      style = TextType.SUCCESS;
      statusText = "Published";
      break;
    case BlueprintVersionPublishState.Staged:
      style = TextType.WARNING;
      statusText = "Staged";
      break;
    case BlueprintVersionPublishState.Unpublished:
      style = TextType.BLUE;
      statusText = "Unpublished";
      break;
  }

  return (
    <MergeText isBold type={style}>
      {statusText}
    </MergeText>
  );
};

export const getBlueprintSearchStatusBadgeColor = (
  publishedStatus: BlueprintVersionPublishState
): BaseColor => {
  switch (publishedStatus) {
    case BlueprintVersionPublishState.Published:
      return "teal";
    case BlueprintVersionPublishState.Unpublished:
      return "gray";
    case BlueprintVersionPublishState.Draft:
      return "yellow";
    case BlueprintVersionPublishState.Staged:
      return "orange";
    default:
      return "gray";
  }
};

type BlueprintRunnerTestRunStatusBadgeProps = {
  exitCode?: number;
  isQueued?: boolean;
  isRunning?: boolean;
  isLoading?: boolean;
  className?: string;
};

export const BlueprintRunnerTestRunStatusBadge = ({
  exitCode,
  isQueued,
  isRunning,
  isLoading,
}: BlueprintRunnerTestRunStatusBadgeProps) => {
  const color = isLoading
    ? "gray"
    : isQueued
    ? "gray"
    : isRunning
    ? "blue"
    : exitCode === 200
    ? "teal"
    : "red";

  const text = isLoading
    ? "Loading"
    : isQueued
    ? "Queued"
    : isRunning
    ? "Running"
    : exitCode === 200
    ? "Success"
    : "Error";

  return (
    <Badge size="lg" color={color}>
      {text}
    </Badge>
  );
};

type LinkedAccountsStatusBadgeProps = {
  status: string | JSX.Element;
};

export const LinkedAccountsStatusBadge = ({ status }: LinkedAccountsStatusBadgeProps) => {
  return (
    <>
      {(() => {
        switch (status) {
          case "Complete":
            return <span className="badge badge-soft-success">Complete</span>;
          default:
            return <span className="badge badge-soft-warning">{status}</span>;
        }
      })()}
    </>
  );
};

type HTTPMethodBadgeProps = {
  method: APIEndpointMethod | string;
};

export const HTTPMethodBadge = ({ method }: HTTPMethodBadgeProps) => {
  let style = "light";
  switch (method) {
    case APIEndpointMethod.GET:
      style = "primary";
      break;
    case APIEndpointMethod.POST:
      style = "success";
      break;
    case APIEndpointMethod.PUT:
      style = "info";
      break;
    case APIEndpointMethod.DELETE:
      style = "danger";
      break;
    case APIEndpointMethod.PATCH:
      style = "dark";
      break;
    case APIEndpointMethod.OPTIONS:
      style = "secondary";
      break;
    case APIEndpointMethod.HEAD:
      style = "light";
      break;
  }

  return (
    <span className={`badge badge-${style} http-method-badge`}>
      <b>{method}</b>
    </span>
  );
};

type HTTPMethodBadgeUnfilledProps = {
  method: APIEndpointMethod | string;
};
const HTTPMethodBadgeUnfilledSpan = styled.span<HTTPMethodBadgeUnfilledProps>`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => {
    switch (props.method) {
      case APIEndpointMethod.GET:
        return "var(--blue40)";
      case APIEndpointMethod.POST:
        return "#00b187";
      case APIEndpointMethod.PATCH:
        return "var(--yellow50)";
      case APIEndpointMethod.DELETE:
        return "#ea0524";
      case APIEndpointMethod.PUT:
        return "var(--indigo50)";
      default:
        return palette.black;
    }
  }};
`;
export const HTTPMethodBadgeUnfilled = ({ method }: HTTPMethodBadgeUnfilledProps) => (
  <HTTPMethodBadgeUnfilledSpan method={method}>{method}</HTTPMethodBadgeUnfilledSpan>
);

type CommonModelBadgeProps = {
  commonModel: string;
};

export const CommonModelBadge = ({ commonModel }: CommonModelBadgeProps) => {
  let style = "light";
  const category = apiCategoryFromString(commonModel.split(".")[0]);
  if (category) {
    switch (category) {
      case APICategory.hris:
        style = "soft-secondary";
        break;
      case APICategory.ats:
        style = "soft-success";
        break;
      case APICategory.accounting:
        style = "soft-danger";
        break;
      case APICategory.ticketing:
        style = "orange";
        break;
      case APICategory.crm:
        style = "soft-info";
        break;
      case APICategory.mktg:
        style = "soft-light";
        break;
      case APICategory.filestorage:
        style = "soft-light";
        break;
      case APICategory.datawarehouse:
        style = "badge-gray";
        break;
      default:
        // If this fails to compile, add the category above
        ((_: never) => {})(category);
    }
  }
  return <span className={`badge badge-${style} mr-1.5`}>{commonModel}</span>;
};

type APIBadgeProps = {
  endpoint: string;
};

export const APIEndpointBadge = ({ endpoint }: APIBadgeProps) => {
  return <span className={`badge badge-soft-primary mr-1.5`}>{endpoint}</span>;
};

type OperationTypeBadgeProps = { operationType: BlueprintOperationType };
export const OperationTypeBadge = ({ operationType }: OperationTypeBadgeProps) => {
  return <span className="badge badge-light">{operationType}</span>;
};

export const BlueprintTypeBadge = ({
  blueprintType,
}: {
  blueprintType: "Common Model Scraper" | "API" | "Auth Scraper";
}) => {
  let style = "light";
  switch (blueprintType) {
    case "API":
      style = "soft-primary";
      break;
    case "Common Model Scraper":
      style = "soft-secondary";
      break;
    case "Auth Scraper":
      style = "soft-warning";
      break;
  }
  return <span className={`badge badge-${style} mr-1.5`}>{blueprintType}</span>;
};

const DeprecatedBadgeStyle = styled.span.attrs({ className: "badge ml-1.5" })`
  color: #ca8f00;
  background-color: #fff9e6;
`;

export const DeprecatedBadge = () => <DeprecatedBadgeStyle>Deprecated</DeprecatedBadgeStyle>;

type CategoryBadgeProps = { category: string };
export const CategoryBadge = ({ category }: CategoryBadgeProps) => {
  return <span className="badge badge-light">{category.toUpperCase()}</span>;
};
