import clsx from "clsx";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
  INTEGRATION_BUILDER_PATH,
  INTEGRATION_BUILDER_PATH_INITIALIZATION,
  getIntegrationBuilderAISearchPathForId,
  getIntegrationBuilderAPIEndpointPathForId,
  getIntegrationBuilderAPIEndpointsConfigurationPathForId,
  getIntegrationBuilderAuthenticationConfigurationPathForId,
  getIntegrationBuilderCustomerFacingFieldsPathForId,
  getIntegrationBuilderInitializationPathForId,
  getIntegrationBuilderLinkingFlowStepsPathConfigurationForId,
  getIntegrationBuilderLinkingFlowStepsPathForId,
  getIntegrationBuilderPaginationConfigurationsForId,
  getIntegrationBuilderRateLimitConfigurationPathForId,
  getIntegrationBuilderWebAddressSchemaConfigurationPathForId,
  getIntegrationBuilderWebAddressSchemaForId,
  getIntegrationBuilderSelectiveSyncFilterBuilder,
} from "../../router/RouterUtils";
import AISearch from "./ai-search/AISearch";
import APIEndpoints from "./api-endpoint-setup/APIEndpoints";
import APIEndpointsConfiguration from "./api-endpoints-configuration/APIEndpointsConfiguration";
import AuthenticationConfiguration from "./authentication/AuthenticationConfiguration";
import useIntegrationBuilderContext from "./context/useIntegrationBuilderContext";
import CustomerFacingFields from "./customer-facing-fields/CustomerFacingFields";
import IntegrationInitialization from "./initialization/IntegrationInitialization";
import IntegrationBuilderLeftNavBar from "./left-nav-bar/IntegrationBuilderLeftNavBar";
import LinkingFlowStepPathsConfiguration from "./linking-flow-steps-configuration/LinkingFlowStepPathsConfiguration";
import LinkingFlowStepPaths from "./linking-flow-steps/LinkingFlowStepPaths";
import PaginationConfigurations from "./pagination-configuration-setup/PaginationConfigurations";
import RateLimitConfiguration from "./rate-limits/RateLimitConfiguration";
import SelectiveSyncFilters from "./selective-sync-filters/components/SelectiveSyncFilters";
import IntegrationBuilderBottomNavigation from "./shared/IntegrationBuilderBottomNavigation";
import { Section } from "./utils/Entities";
import { flattenSections } from "./utils/helpers";
import useIntegrationBuilderSections from "./utils/useIntegrationBuilderSections";
import WebAddressSchemaConfiguration from "./web-address-schema-configuration/WebAddressSchemaConfiguration";
import WebAddressSchema from "./web-address-schema/WebAddressSchema";

interface IntegrationBuilderWrapperProps {
  isDisabled: boolean;
  integrationID: string | undefined;
}

const IntegrationBuilderWrapper = ({
  isDisabled,
  integrationID,
}: IntegrationBuilderWrapperProps) => {
  /* STATES TO HANDLE NAVIGATION */
  const history = useHistory();
  const sections = useIntegrationBuilderSections(integrationID);
  const flattenedSections = flattenSections(sections);
  const [currentSection, setCurrentSection] = useState<Section | undefined>();
  const { isRightPanelOpen } = useIntegrationBuilderContext();

  /* HANDLES NAVIGATION */
  useEffect(() => {
    const foundSection = flattenedSections.find(
      (section) => section.path === history.location.pathname
    );
    if (foundSection) {
      setCurrentSection(foundSection);
    }
  }, [history.location.pathname]);

  const handlePrevious = () => {
    const previousSection = flattenedSections.find(
      (section) => section.sectionIdentifier === currentSection?.previousSectionIdentifier
    );
    if (previousSection) {
      history.push(previousSection.path);
    }
  };

  const handleNext = () => {
    const nextSection = flattenedSections.find(
      (section) => section.sectionIdentifier === currentSection?.nextSectionIdentifier
    );
    if (nextSection) {
      history.push(nextSection.path);
    }
  };

  const handleNavigation = (path: string) => {
    const newSection = flattenedSections.find((section) => section.path === path);
    if (newSection) {
      history.push(path);
    }
  };

  // handlers for opening right panel on API endpoints form
  const [shouldMountPanel, setShouldMountPanel] = useState(true);
  const [isOpening, setIsOpening] = useState(isRightPanelOpen);
  useEffect(() => {
    if (isRightPanelOpen) {
      setTimeout(() => setIsOpening(true), 5);
      setShouldMountPanel(true);
    } else {
      setIsOpening(false);
      setTimeout(() => {
        setShouldMountPanel(false);
      }, 500);
    }
  }, [isRightPanelOpen]);

  return (
    <div className="flex h-screen w-full">
      {/* Navigation and tab selection */}
      <IntegrationBuilderLeftNavBar
        integrationID={integrationID}
        sections={sections}
        onNavigate={handleNavigation}
      />

      {/* Rendering of individual pages  */}

      <div className="flex flex-col w-full">
        <div className="flex-grow overflow-auto relative">
          <div className={clsx("bg-[#FCFDFF] h-full", !isRightPanelOpen && "my-10 ml-10")}>
            <div className={`${isRightPanelOpen ? "mr-4" : "mr-10"} transition-margin h-full`}>
              {integrationID ? (
                <Switch>
                  <Route
                    path={getIntegrationBuilderInitializationPathForId(integrationID)}
                    render={() => <IntegrationInitialization integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderAISearchPathForId(integrationID)}
                    render={() => <AISearch integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderWebAddressSchemaForId(integrationID)}
                    render={() => <WebAddressSchema integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderWebAddressSchemaConfigurationPathForId(
                      integrationID
                    )}
                    render={() => <WebAddressSchemaConfiguration integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderAuthenticationConfigurationPathForId(integrationID)}
                    render={() => <AuthenticationConfiguration integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderLinkingFlowStepsPathForId(integrationID)}
                    render={() => <LinkingFlowStepPaths integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderLinkingFlowStepsPathConfigurationForId(
                      integrationID
                    )}
                    render={() => (
                      <LinkingFlowStepPathsConfiguration integrationID={integrationID} />
                    )}
                  />
                  <Route
                    path={getIntegrationBuilderPaginationConfigurationsForId(integrationID)}
                    render={() => <PaginationConfigurations integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderRateLimitConfigurationPathForId(integrationID)}
                    render={() => <RateLimitConfiguration integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderAPIEndpointPathForId(integrationID)}
                    render={() => <APIEndpoints integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderAPIEndpointsConfigurationPathForId(integrationID)}
                    render={() => <APIEndpointsConfiguration integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderCustomerFacingFieldsPathForId(integrationID)}
                    render={() => <CustomerFacingFields integrationID={integrationID} />}
                  />
                  <Route
                    path={getIntegrationBuilderSelectiveSyncFilterBuilder(integrationID)}
                    render={() => <SelectiveSyncFilters integrationID={integrationID} />}
                  />
                  <Redirect
                    from={INTEGRATION_BUILDER_PATH}
                    to={getIntegrationBuilderInitializationPathForId(integrationID)}
                  />
                </Switch>
              ) : (
                <Switch>
                  <Route
                    path={INTEGRATION_BUILDER_PATH_INITIALIZATION}
                    render={() => <IntegrationInitialization integrationID={integrationID} />}
                  />
                  <Redirect
                    from={INTEGRATION_BUILDER_PATH}
                    to={INTEGRATION_BUILDER_PATH_INITIALIZATION}
                  />
                </Switch>
              )}
            </div>
          </div>
        </div>
        <IntegrationBuilderBottomNavigation
          isDisabled={isDisabled}
          onPrevious={handlePrevious}
          onNext={handleNext}
          disablePrevious={!currentSection?.previousSectionIdentifier}
          disableNext={!currentSection?.nextSectionIdentifier}
        />
      </div>
      {(isRightPanelOpen || shouldMountPanel) && (
        <div
          id="integration-builder-right-panel"
          className={`h-screen ${
            isOpening ? "w-[516px]" : "w-0"
          } transition-width duration-500 ease-in-out transform`}
        />
      )}
    </div>
  );
};

export default IntegrationBuilderWrapper;
