import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelGetOrCreateByRemoteIDForm = () => {
  return (
    <>
      <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
      <TypeaheadFormField
        key={"remote_id"}
        title={"Remote ID"}
        subtitle={"The third-party API ID of the matching object."}
        valueKey={"remote_id"}
        parameterType={"string"}
      />
    </>
  );
};

export default BlueprintEditorRightPanelGetOrCreateByRemoteIDForm;
