import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, match, Route, Redirect } from "react-router-dom";
import "../../styles/css/blueprint_editor.css";
import {
  BlueprintStepTemplate,
  Blueprint,
  BlueprintStep,
  AddStepRelation,
  BlueprintRunnerExecutionResponse,
  BlueprintSwitchStep,
  BlueprintStepTemplateBase,
  BlueprintStepType,
  BlueprintTrigger,
  BlueprintStepOrGhostStepOrTriggerOrScraper,
  BlueprintWithTrigger,
  BlueprintTriggerIntervalUnit,
  BlueprintTriggerSpeed,
  BlueprintTriggerSchedule,
  JSONObjectSchema,
  StepRelations,
  BlueprintGenericStepTemplate,
  BlueprintVersion,
  ReportFile,
  BlueprintParameterValue,
  BlueprintVersionStaleParametersInfo,
  BlueprintIfElseStep,
  BlueprintSelectedStepLog,
} from "../../models/Blueprints";
import BlueprintContextProvider from "./context/BlueprintContextProvider";
import BlueprintEditorView from "./BlueprintEditorView";
import {
  addNewCopiedStep,
  addNewStepFromTemplate,
  deleteStep,
  deleteSteps,
  collapseSubsteps,
  modifySwitchOption,
  ModifySwitchOptionType,
  renameBlueprintStep,
  setHumanNameForBlueprint,
  setParameterSchemaForBlueprint,
  setReturnSchemaForBlueprint,
  updateBlueprintForNewMockResponseBody,
  updateBlueprintForNewParameterValue,
  updateBlueprintForNewParameterValues,
  updateBlueprintForNewStepTemplate,
  updateBlueprintStepConcurrency,
  updateUsePaginationTimestamp,
  updateClosesPaginationSequence,
  setStepNoteForBlueprint,
  initializeStepRelationMap,
  addNewGenericStepFromTemplate,
  getBlueprintStepForStepID,
  deleteIfElseStepAndMoveChildrenUp,
} from "./utils/BlueprintEditorUtils";
import {
  fetchBlueprintVersionForIntegration,
  fetchBlueprintVersions,
  fetchGenericStepTemplates,
  fetchReportFilesByBlueprintVersionID,
  fetchStaleParametersByBlueprintVersionID,
  fetchStepTemplates,
} from "./utils/BlueprintEditorAPIClient";
import { fetchTemplateConfigs } from "../integrations/utils/IntegrationsAPIClient";

import EditorLeavingGuard from "../shared/unsaved-changes/EditorLeavingGuard";
import useBlueprintReducer from "./useBlueprintReducer";
import isEqual from "lodash/isEqual";
import { GlobalHotKeys } from "react-hotkeys";
import { BLUEPRINT_EDITOR_PATH, getBlueprintEditorPath } from "../../router/RouterUtils";
import EmptyStateWrapper from "../shared/layout/EmptyStateWrapper";
import { showErrorToast } from "../shared/Toasts";
import { IntegrationCommonModelConfig } from "../../models/Entities";
import { BLUEPRINT_OPERATION_TYPES_WITH_SCHEDULES } from "../../constants";
import useDocumentTitle from "../shared/useDocumentTitle";

type RouteParams = {
  integrationID: string;
  blueprintVersionID: string;
};

type Props = {
  match: match;
};

function BlueprintEditor({ match }: Props): JSX.Element {
  const { integrationID, blueprintVersionID } = useParams<RouteParams>();
  const [originalBlueprint, setOriginalBlueprint] = useState<Blueprint | undefined>();
  const [blueprintTrigger, setBlueprintTrigger] = useState<BlueprintTrigger | undefined>();
  const [
    blueprintRunnerExecutionResponse,
    setBlueprintRunnerExecutionResponse,
  ] = useState<null | BlueprintRunnerExecutionResponse>(null);
  const [selectedStep, setSelectedStep] = useState<BlueprintStepOrGhostStepOrTriggerOrScraper>();
  const [selectedSteps, setSelectedSteps] = useState<
    Array<BlueprintStepOrGhostStepOrTriggerOrScraper>
  >();
  const [copiedSteps, setCopiedSteps] = useState<
    Array<BlueprintStepOrGhostStepOrTriggerOrScraper>
  >();
  const [stepTemplates, setStepTemplates] = useState<BlueprintStepTemplate[]>([]);
  const [genericStepTemplates, setGenericStepTemplates] = useState<BlueprintGenericStepTemplate[]>(
    []
  );
  const [reportFiles, setReportFiles] = useState<Array<ReportFile>>([]);
  const [
    staleParameters,
    setStaleParameters,
  ] = useState<BlueprintVersionStaleParametersInfo | null>(null);
  const [copiedStep, setCopiedStep] = useState<BlueprintStep>();
  const [doesBlueprintHaveUnsavedChanges, setDoesBlueprintHaveUnsavedChanges] = useState<boolean>(
    false
  );
  const [blueprintState, blueprintActions] = useBlueprintReducer(undefined);
  const [availableTemplateConfigs, setAvailableTemplateConfigs] = useState<
    IntegrationCommonModelConfig[]
  >([]);
  const [stepRelationMap, setStepRelationMap] = useState<Map<string, StepRelations>>(new Map());
  const [blueprintVersions, setBlueprintVersions] = useState<BlueprintVersion[]>([]);
  const [isShowingStepCoverage, setIsShowingStepCoverage] = useState<boolean>(false);
  const [selectedStepLog, setSelectedStepLog] = useState<BlueprintSelectedStepLog | undefined>();

  const blueprint = blueprintState as Blueprint | undefined;
  const blueprintName = blueprint?.name;

  useDocumentTitle(blueprint?.human_name ?? blueprint?.name, [
    blueprint?.name,
    blueprint?.human_name,
  ]);

  const applyDefaultSchedules = (blueprint: Blueprint, schedule: BlueprintTrigger) => {
    const shouldBeScheduled = BLUEPRINT_OPERATION_TYPES_WITH_SCHEDULES.includes(
      blueprint?.operation_type
    );
    const default_unit = BlueprintTriggerIntervalUnit.HOURS;
    const default_slow_value = shouldBeScheduled ? 6 : 0;
    const default_intermediate_value = shouldBeScheduled ? 3 : 0;
    const default_fast_value = shouldBeScheduled ? 1 : 0;

    function generateSchedule(
      speed: BlueprintTriggerSpeed,
      value: number
    ): BlueprintTriggerSchedule {
      return {
        speed: speed,
        schedule_interval_value: value,
        schedule_interval_unit: default_unit,
      };
    }

    return {
      slow_trigger_schedule:
        schedule.slow_trigger_schedule == null
          ? generateSchedule(BlueprintTriggerSpeed.BLUEPRINT_SPEED_SLOW, default_slow_value)
          : schedule.slow_trigger_schedule,

      intermediate_trigger_schedule:
        schedule.intermediate_trigger_schedule == null
          ? generateSchedule(
              BlueprintTriggerSpeed.BLUEPRINT_SPEED_INTERMEDIATE,
              default_intermediate_value
            )
          : schedule.intermediate_trigger_schedule,

      fast_trigger_schedule:
        schedule.fast_trigger_schedule == null
          ? generateSchedule(BlueprintTriggerSpeed.BLUEPRINT_SPEED_FAST, default_fast_value)
          : schedule.fast_trigger_schedule,
    };
  };
  useEffect(() => {
    if (blueprint) {
      setStepRelationMap(initializeStepRelationMap(blueprint));
    }
  }, [blueprintState]);

  const setBlueprint = useCallback(
    (newBlueprint: Blueprint | undefined) => {
      if (newBlueprint && !isEqual(blueprint, newBlueprint)) {
        blueprintActions.set(newBlueprint);
      }
    },
    [blueprint, blueprintActions]
  );

  // Update selected step if changed
  useEffect(() => {
    if (selectedStep?.id && blueprint) {
      const currentStep = getBlueprintStepForStepID(blueprint, selectedStep.id);
      if (currentStep) {
        setSelectedSteps(undefined);
        if (!isEqual(currentStep, selectedStep)) {
          setSelectedStep(currentStep);
        }
      }
    }
  }, [blueprint, blueprint?.steps, selectedStep]);

  const selectedStepContextValue = useMemo(() => {
    return selectedStep;
  }, [selectedStep]);

  useEffect(() => {
    setSelectedStep(blueprint?.steps[0]);
    // intentionally do not want to update when steps update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprint?.version?.id]);

  useEffect(() => {
    // Both blueprints are undefined
    if (!blueprint && !originalBlueprint) {
      setDoesBlueprintHaveUnsavedChanges(false);
      return;
    }
    // One blueprint is undefined and the other isn't
    if (!blueprint || !originalBlueprint) {
      setDoesBlueprintHaveUnsavedChanges(true);
      return;
    }

    // Now we know that both blueprints are defined, let's
    // check their contents.

    // First, do the cheap XOR checks for one empty one non-empty
    const newBlueprintSteps = blueprint?.steps;
    const originalBlueprintSteps = originalBlueprint?.steps;
    const blueprintParameterSchema = blueprint?.parameter_schema;
    const originalBlueprintParameterSchema = originalBlueprint?.parameter_schema;
    if (!newBlueprintSteps !== !originalBlueprintSteps) {
      // One set of steps exists and the other doesn't,
      // so skip string dump and set to true.
      setDoesBlueprintHaveUnsavedChanges(true);
      return;
    }
    if (!blueprintParameterSchema !== !originalBlueprintParameterSchema) {
      // One has a parameter schema and the other doesn't,
      // so skip string dump and set to true.
      setDoesBlueprintHaveUnsavedChanges(true);
      return;
    }

    // Check for step changes
    if (JSON.stringify(blueprint.steps) !== JSON.stringify(originalBlueprint.steps)) {
      setDoesBlueprintHaveUnsavedChanges(true);
      return;
    }
    // Check for input parameter schema changes
    if (
      JSON.stringify(blueprintParameterSchema) !== JSON.stringify(originalBlueprintParameterSchema)
    ) {
      setDoesBlueprintHaveUnsavedChanges(true);
      return;
    }

    // All clear, no changes
    setDoesBlueprintHaveUnsavedChanges(false);
  }, [blueprint, JSON.stringify(blueprint?.steps), blueprint?.parameter_schema, originalBlueprint]);

  useEffect(() => {
    if (blueprint && blueprintTrigger) {
      setBlueprint(undefined);
    }

    if (blueprint?.version?.id !== blueprintVersionID) {
      fetchBlueprintVersionForIntegration({
        integrationID,
        blueprintVersionID,
        onSuccess: (response: BlueprintWithTrigger) => {
          const { schedule, ...blueprint } = response;
          setBlueprint(blueprint);
          setOriginalBlueprint(JSON.parse(JSON.stringify(blueprint)));
          setBlueprintTrigger(applyDefaultSchedules(blueprint, schedule));
        },
      });
    }
  }, [integrationID, blueprintVersionID, blueprint, blueprintTrigger, setBlueprint]);

  useEffect(() => {
    if (blueprint?.id) {
      fetchBlueprintVersions({
        blueprintID: blueprint?.id,
        onSuccess: (versions: BlueprintVersion[]) => setBlueprintVersions(versions),
      });
    }
  }, [blueprint]);

  useEffect(() => {
    fetchStepTemplates({
      integrationID,
      blueprintVersionID,
      onSuccess: (data) => {
        setStepTemplates(data);
      },
    });
    fetchGenericStepTemplates({
      integrationID,
      blueprintVersionID,
      onSuccess: (data) => {
        setGenericStepTemplates(data);
      },
    });
    fetchTemplateConfigs({
      integrationID,
      onSuccess: (data) => {
        setAvailableTemplateConfigs(data);
      },
    });
    fetchReportFilesByBlueprintVersionID({
      blueprintVersionID,
      onSuccess: (reportFiles: Array<ReportFile>) => setReportFiles(reportFiles),
    });
    fetchStaleParametersByBlueprintVersionID({
      blueprintVersionID,
      onSuccess: (response: BlueprintVersionStaleParametersInfo) => {
        if (Object.keys(response ?? {}).length > 0) {
          setStaleParameters(response);
        }
      },
      onError: () => {
        showErrorToast("Failed to fetch backend stale params");
      },
    });
  }, [integrationID, blueprintVersionID]);

  useEffect(() => {
    setBlueprintRunnerExecutionResponse(null);
  }, [blueprint]);

  function addStep<T extends BlueprintStepType>(
    stepTemplate: BlueprintStepTemplateBase<T, any>,
    newStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) {
    if (blueprint) {
      const response = addNewStepFromTemplate<T>(
        blueprint,
        stepTemplate,
        relatedStepID,
        newStepRelation,
        newStepTypePathKey
      );
      setBlueprint(JSON.parse(JSON.stringify(response.blueprint)));
      setSelectedStep(response.step);
    }
  }

  function addGenericStep(
    stepTemplate: BlueprintGenericStepTemplate,
    newStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) {
    if (blueprint) {
      const response = addNewGenericStepFromTemplate(
        blueprint,
        stepTemplate,
        relatedStepID,
        newStepRelation,
        newStepTypePathKey
      );
      setBlueprint(JSON.parse(JSON.stringify(response.blueprint)));
      setSelectedStep(response.step);
    }
  }

  function addCopiedStep(
    step: BlueprintStep,
    newStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) {
    if (blueprint) {
      const response = addNewCopiedStep(
        blueprint,
        step,
        relatedStepID,
        newStepRelation,
        newStepTypePathKey
      );

      setBlueprint(response.blueprint);
      setSelectedStep(response.step);
      onSetCopiedStep(response.step);
    }
  }

  function onSetCopiedStep(step: BlueprintStep | undefined) {
    if (step) {
      setCopiedStep(step);
      setCopiedSteps(undefined);
    }
  }
  function onSetCopiedSteps(steps: BlueprintStepOrGhostStepOrTriggerOrScraper[] | undefined) {
    if (steps && steps.length > 0) {
      setCopiedStep(undefined);
      setCopiedSteps(steps);
    }
  }

  async function addCopiedStepsFromClipboard(
    newStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) {
    const text = await navigator.clipboard.readText();
    try {
      const parsedText = JSON.parse(text);
      if (Array.isArray(parsedText)) {
        addCopiedSteps(
          parsedText as BlueprintStep[],
          newStepRelation,
          relatedStepID,
          newStepTypePathKey
        );
      } else {
        addCopiedStep(parsedText, newStepRelation, relatedStepID, newStepTypePathKey);
      }
    } catch (err) {
      showErrorToast(`Failed to load step(s) from clipboard: ${err}`);
    }
  }

  function addCopiedSteps(
    steps: BlueprintStep[],
    newStepRelation: AddStepRelation,
    relatedStepID: string,
    newStepTypePathKey?: string
  ) {
    if (blueprint && steps.length > 0) {
      let response = null;
      for (const [index, step] of steps.entries()) {
        if (index === 0) {
          response = addNewCopiedStep(
            blueprint,
            step,
            relatedStepID,
            newStepRelation,
            newStepTypePathKey
          );
        } else if (response) {
          response = addNewCopiedStep(
            response.blueprint,
            step,
            response.step.id,
            AddStepRelation.SIBLING_AFTER
          );
        }
      }
      if (response) {
        setBlueprint(response.blueprint);
        setSelectedStep(response.step);
      }
    }
  }

  function onDeleteStep(stepID: string) {
    if (blueprint != null) {
      setBlueprint(deleteStep(blueprint, stepID));
      setSelectedStep(blueprint.steps.length > 0 ? blueprint.steps[0] : undefined);
    }
  }

  function onDeleteSteps(steps: Array<BlueprintStep>) {
    if (blueprint != null) {
      setBlueprint(deleteSteps(blueprint, steps));
      setSelectedStep(blueprint.steps.length > 0 ? blueprint.steps[0] : undefined);
    }
  }

  function onDeleteIfElseStepAndMoveChildrenUp(step: BlueprintIfElseStep) {
    if (blueprint != null) {
      setBlueprint(deleteIfElseStepAndMoveChildrenUp(blueprint, step));
      setSelectedStep(blueprint.steps.length > 0 ? blueprint.steps[0] : undefined);
    }
  }

  function onCollapseSubsteps(stepID: string) {
    if (blueprint != null) {
      setBlueprint(collapseSubsteps(blueprint, stepID));
    }
  }

  const updateStepParameterValues = (
    step: BlueprintStep,
    parameterValues: { [key: string]: BlueprintParameterValue | null }
  ) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint, step: newStep } = updateBlueprintForNewParameterValues(
        blueprint,
        step,
        parameterValues
      );

      setBlueprint(newBlueprint);
      setSelectedStep(newStep);
    }
  };

  const updateStepParameterValue = (step: BlueprintStep, valueKey: string, value: any) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint, step: newStep } = updateBlueprintForNewParameterValue(
        blueprint,
        step,
        valueKey,
        value
      );

      setBlueprint(newBlueprint);
      setSelectedStep(newStep);
    }
  };

  const replaceStepTemplate = (
    step: BlueprintStep,
    newTemplate: BlueprintStepTemplate | BlueprintGenericStepTemplate
  ) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint, step: newStep } = updateBlueprintForNewStepTemplate(
        blueprint,
        step,
        newTemplate
      );

      setBlueprint(newBlueprint);
      setSelectedStep(newStep);
    }
  };

  const updateSwitchStepOption = (
    switchStep: BlueprintSwitchStep,
    optionKey: string,
    modificationType: ModifySwitchOptionType
  ) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint, step: newStep } = modifySwitchOption(
        blueprint,
        switchStep,
        optionKey,
        modificationType
      );
      setBlueprint(newBlueprint);
      setSelectedStep(newStep);
    }
  };

  const renameStep = (stepID: string, newName: string, onError?: () => void) => {
    if (blueprint != null) {
      const updatedBlueprintAndStep: {
        blueprint: Blueprint;
        step: BlueprintStep;
      } | null = renameBlueprintStep(blueprint, stepID, newName);

      if (updatedBlueprintAndStep) {
        setBlueprint(updatedBlueprintAndStep.blueprint);
        setSelectedStep(updatedBlueprintAndStep.step);
      } else if (onError && stepID !== newName) {
        showErrorToast("Error saving step! Step name already exists.");
        onError();
      }
    }
  };

  const setRunStepConcurrently = (stepId: string, runConcurrently: boolean) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint, step: newStep } = updateBlueprintStepConcurrency(
        blueprint,
        stepId,
        runConcurrently
      );

      setBlueprint(newBlueprint);
      setSelectedStep(newStep);
    }
  };

  const setStepUsesPaginationTimestamp = (stepId: string, usePaginationTimestamp: boolean) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint, step: newStep } = updateUsePaginationTimestamp(
        blueprint,
        stepId,
        usePaginationTimestamp
      );

      setBlueprint(newBlueprint);
      setSelectedStep(newStep);
    }
  };

  const setStepClosesPaginationSequence = (stepId: string, closesPaginationSequence: boolean) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint, step: newStep } = updateClosesPaginationSequence(
        blueprint,
        stepId,
        closesPaginationSequence
      );

      setBlueprint(newBlueprint);
      setSelectedStep(newStep);
    }
  };

  const updateStepMockResponseBody = (step: BlueprintStep, key: string, value: string) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint, step: newStep } = updateBlueprintForNewMockResponseBody(
        blueprint,
        step,
        key,
        value
      );

      setBlueprint(newBlueprint);
      setSelectedStep(newStep);
    }
  };

  const setBlueprintParameterSchema = (parameterSchema: JSONObjectSchema) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint } = setParameterSchemaForBlueprint(
        blueprint,
        parameterSchema
      );

      setBlueprint(newBlueprint);
    }
  };

  const setBlueprintReturnSchema = (returnSchema: JSONObjectSchema) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint } = setReturnSchemaForBlueprint(blueprint, returnSchema);

      setBlueprint(newBlueprint);
    }
  };

  const setBlueprintHumanName = (human_name: string) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint } = setHumanNameForBlueprint(blueprint, human_name);

      setBlueprint(newBlueprint);
    }
  };

  const setBlueprintStepNote = (stepID: string, text: string) => {
    if (blueprint != null) {
      const { blueprint: newBlueprint } = setStepNoteForBlueprint(blueprint, stepID, text);

      setBlueprint(newBlueprint);
    }
  };

  const keyMaps = {
    UNDO: "command+z",
    REDO: ["command+y", "command+shift+z"],
    SAVE: "command+s",
    COPY: "command+c",
    PASTE: "command+v",
    PASTE_CLIPBOARD: "command+shift+v",
    CUT: "command+x",
    UP: "up",
    DOWN: "down",
    LEFT: "left",
    RIGHT: "right",
    SHIFT_UP: "shift+up",
    SHIFT_DOWN: "shift+down",
  };

  return (
    <>
      <Route exact path={BLUEPRINT_EDITOR_PATH}>
        <Redirect to={match.url + "/main"} />
      </Route>
      {blueprint && originalBlueprint && blueprintName ? (
        <BlueprintContextProvider
          addStep={addStep}
          addGenericStep={addGenericStep}
          addCopiedStep={addCopiedStep}
          addCopiedSteps={addCopiedSteps}
          addCopiedStepsFromClipboard={addCopiedStepsFromClipboard}
          blueprint={blueprint}
          blueprintVersions={blueprintVersions}
          blueprintTrigger={blueprintTrigger}
          setBlueprintTrigger={setBlueprintTrigger}
          deleteStep={onDeleteStep}
          deleteSteps={onDeleteSteps}
          deleteIfElseStepAndMoveChildrenUp={onDeleteIfElseStepAndMoveChildrenUp}
          collapseSubsteps={onCollapseSubsteps}
          setBlueprint={setBlueprint}
          setOriginalBlueprint={setOriginalBlueprint}
          setSelectedStep={setSelectedStep}
          blueprintRunnerExecutionResponse={blueprintRunnerExecutionResponse}
          setBlueprintRunnerExecutionResponse={setBlueprintRunnerExecutionResponse}
          updateStepParameterValues={updateStepParameterValues}
          updateStepParameterValue={updateStepParameterValue}
          replaceStepTemplate={replaceStepTemplate}
          selectedStep={selectedStepContextValue}
          stepTemplates={stepTemplates}
          genericStepTemplates={genericStepTemplates}
          updateSwitchStepOption={updateSwitchStepOption}
          copiedStep={copiedStep}
          setCopiedStep={onSetCopiedStep}
          renameStep={renameStep}
          doesBlueprintHaveUnsavedChanges={doesBlueprintHaveUnsavedChanges}
          updateStepMockResponseBody={updateStepMockResponseBody}
          undoActions={blueprintActions}
          setBlueprintParameterSchema={setBlueprintParameterSchema}
          setReportFiles={setReportFiles}
          reportFiles={reportFiles}
          backendStaleParameters={staleParameters}
          setBackendStaleParameters={setStaleParameters}
          setBlueprintReturnSchema={setBlueprintReturnSchema}
          setRunStepConcurrently={setRunStepConcurrently}
          setStepUsesPaginationTimestamp={setStepUsesPaginationTimestamp}
          setStepClosesPaginationSequence={setStepClosesPaginationSequence}
          setBlueprintParameterSchemaValue={blueprintActions.setBlueprintParameterSchemaValue}
          setBlueprintReturnSchemaValue={blueprintActions.setBlueprintReturnSchemaValue}
          setRequiredFieldsForObject={blueprintActions.setRequiredFieldsForObject}
          setBlueprintHumanName={setBlueprintHumanName}
          setBlueprintStepNote={setBlueprintStepNote}
          availableTemplateConfigs={availableTemplateConfigs}
          setAvailableTemplateConfigs={setAvailableTemplateConfigs}
          stepRelationMap={stepRelationMap}
          setStepRelationMap={setStepRelationMap}
          selectedSteps={selectedSteps}
          setSelectedSteps={setSelectedSteps}
          copiedSteps={copiedSteps}
          setCopiedSteps={onSetCopiedSteps}
          setIsShowingStepCoverage={setIsShowingStepCoverage}
          isShowingStepCoverage={isShowingStepCoverage}
          selectedStepLog={selectedStepLog}
          setSelectedStepLog={setSelectedStepLog}
        >
          <GlobalHotKeys keyMap={keyMaps}>
            <EditorLeavingGuard
              currentLocation={getBlueprintEditorPath(integrationID, blueprintVersionID)}
              hasUnsavedChanges={doesBlueprintHaveUnsavedChanges}
            >
              <BlueprintEditorView
                integrationID={integrationID}
                match={match}
                parameterSchema={blueprint.parameter_schema}
                returnSchema={blueprint.return_schema}
                operationType={blueprint.operation_type}
                hasUnsavedChanges={doesBlueprintHaveUnsavedChanges}
              />
            </EditorLeavingGuard>
          </GlobalHotKeys>
        </BlueprintContextProvider>
      ) : (
        <EmptyStateWrapper isSpinner />
      )}
    </>
  );
}

export default BlueprintEditor;
