import { v4 as uuidv4 } from "uuid";
import {
  APIEndpointParameterMapping,
  ApiEndpointParameterMappings,
  ParameterMappingForOperatorDetails,
  SelectiveSyncFilterSchema,
  ValueTransformation,
  VersionedComponentInfo,
} from "../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { ConditionOperator } from "../types";
import { ReducerActions, VersionedComponentInstance } from "./useVersionedComponentReducer";

export const EMPTY_SELECTIVE_SYNC_FILTER_SCHEMA: SelectiveSyncFilterSchema = {
  filter_mapping_details: {},
  user_facing_filter_details: {
    filter_type_id: {
      id: "",
      common_model_class_id: "",
      filter_name: "",
      value_type: null,
      is_common_model_field: true,
    },
    remote_key_name: "",
    is_public_facing: true,
    filter_option_details: null,
  },
  deprecated_default_operator: null,
};

const selectiveSyncSchemaFilterReducerActions = (
  selectiveSyncFilterSchema: VersionedComponentInstance<SelectiveSyncFilterSchema> | undefined,
  reducerActions: ReducerActions<SelectiveSyncFilterSchema>
) => {
  const addFilterOperator = (newOperator: ConditionOperator) => {
    const currentParameterMappingDetails =
      selectiveSyncFilterSchema?.versionedComponentUnderConstruction?.filter_mapping_details ?? {};

    const defaultParameterMappingOperatorDetails: ParameterMappingForOperatorDetails = {
      api_endpoint_filter_mappings: [],
      allow_manual_filter_mapping_in_blueprints: false,
    };

    const newParameterMappingDetails = {
      ...currentParameterMappingDetails,
      [newOperator]: defaultParameterMappingOperatorDetails,
    };

    reducerActions.updateFieldValue(["filter_mapping_details"], newParameterMappingDetails);
  };

  const addOrUpdateAPIEndpointParameter = (
    newAPIEndpointParameter: VersionedComponentInfo,
    operator: string
  ) => {
    const currentAPIEndpointParameterMappings: ApiEndpointParameterMappings =
      selectiveSyncFilterSchema?.versionedComponentUnderConstruction?.filter_mapping_details?.[
        operator
      ]?.api_endpoint_filter_mappings ?? ({} as ApiEndpointParameterMappings);

    const newApiEndpointParameterMapping: APIEndpointParameterMapping = {
      api_endpoint_filter_id: newAPIEndpointParameter,
      id: uuidv4(),
      DEPRECATED__is_applied_implicitly: true,
      value_transformation: null,
    };

    const newParameterMappingDetails: ApiEndpointParameterMappings = [
      ...currentAPIEndpointParameterMappings.filter(
        (parameterMapping) =>
          parameterMapping?.api_endpoint_filter_id?.next_version?.id !=
          newAPIEndpointParameter?.next_version?.id
      ),
      newApiEndpointParameterMapping,
    ];

    reducerActions.updateFieldValue(
      ["filter_mapping_details", operator, "api_endpoint_filter_mappings"],
      newParameterMappingDetails
    );
  };

  const removeParameterMappingDetails = (parameterMappingDetailsID: string, operator: string) => {
    const currentAPIEndpointParameterMappings: ApiEndpointParameterMappings =
      selectiveSyncFilterSchema?.versionedComponentUnderConstruction?.filter_mapping_details?.[
        operator
      ]?.api_endpoint_filter_mappings ?? ({} as ApiEndpointParameterMappings);

    const newParameterMappingDetails: ApiEndpointParameterMappings = [
      ...currentAPIEndpointParameterMappings.filter(
        (parameterMapping) => parameterMapping?.id != parameterMappingDetailsID
      ),
    ];

    reducerActions.updateFieldValue(
      ["filter_mapping_details", operator, "api_endpoint_filter_mappings"],
      newParameterMappingDetails
    );
  };

  const removeFilterOperator = (operatorToDelete: ConditionOperator) => {
    const currentParameterMappingDetails =
      selectiveSyncFilterSchema?.versionedComponentUnderConstruction?.filter_mapping_details ?? {};

    if (!currentParameterMappingDetails) {
      return;
    }

    const newParameterMappingDetails = Object.keys(currentParameterMappingDetails).reduce(
      (acc, key) => {
        if (key !== operatorToDelete) {
          acc[key] = currentParameterMappingDetails[key];
        }
        return acc;
      },
      {} as typeof currentParameterMappingDetails
    );

    reducerActions.updateFieldValue(["filter_mapping_details"], newParameterMappingDetails);
    return;
  };

  const updateValueTransformation = (
    newValueTransformation: ValueTransformation | null,
    operator: string,
    index: number
  ) => {
    reducerActions.updateFieldValue(
      [
        "filter_mapping_details",
        operator,
        "api_endpoint_filter_mappings",
        index,
        "value_transformation",
      ],
      newValueTransformation
    );
    return;
  };

  const setFilterTypeID = (filterTypeID: string) => {
    reducerActions.updateFieldValue(
      ["user_facing_filter_details", "filter_type_id", "id"],
      filterTypeID
    );
    return;
  };

  const setRemoteKeyName = (remoteKeyName: string) => {
    reducerActions.updateFieldValue(
      ["user_facing_filter_details", "remote_key_name"],
      remoteKeyName
    );
    return;
  };

  const setAllowManualFilterMappingInBlueprints = (newValue: boolean, operator: string) => {
    reducerActions.updateFieldValue(
      ["filter_mapping_details", operator, "allow_manual_filter_mapping_in_blueprints"],
      newValue
    );
    return;
  };

  const updatePublicFacingStatus = (newPublicFacingStatus: boolean) => {
    reducerActions.updateFieldValue(
      ["user_facing_filter_details", "is_public_facing"],
      newPublicFacingStatus
    );
    return;
  };

  return {
    addFilterOperator,
    setFilterTypeID,
    setRemoteKeyName,
    removeFilterOperator,
    addOrUpdateAPIEndpointParameter,
    removeParameterMappingDetails,
    updateValueTransformation,
    setAllowManualFilterMappingInBlueprints,
    updatePublicFacingStatus,
  };
};

export default selectiveSyncSchemaFilterReducerActions;
