import { AdminAPICategory, Text } from "@merge-api/merge-javascript-shared";
import React from "react";
import { LinkedAccount } from "../../../models/Entities";
import useLoadLinkedAccounts from "../ai-search/hooks/useLoadLinkedAccounts";
import TypeaheadHeader from "./TypeaheadHeader";

interface Props {
  integrationID: string;
  category: AdminAPICategory;
  linkedAccountInfo: LinkedAccount | null;
  className?: string;
  setLinkedAccountInfo: React.Dispatch<React.SetStateAction<LinkedAccount | null>>;
}

const LinkedAccountChoiceRow = ({ linkedAccount }: { linkedAccount: LinkedAccount }) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        <Text variant="sm" className="mr-2">
          {linkedAccount?.end_user?.organization_name}
          {linkedAccount?.id}
        </Text>
      </div>
    </div>
  );
};

const LinkedAccountsTypeahead = ({
  integrationID,
  category,
  linkedAccountInfo,
  setLinkedAccountInfo,
  className = "",
}: Props) => {
  const { linkedAccounts } = useLoadLinkedAccounts({
    integrationID: integrationID,
    category,
  });

  const filteredLinkedAccounts = linkedAccounts.filter(
    (account) => account.id !== linkedAccountInfo?.id
  );

  return (
    <div>
      <TypeaheadHeader
        className={className}
        title="Linked account"
        onChange={(_: any, linkedAccount: any) => {
          if (linkedAccount != linkedAccountInfo) {
            setLinkedAccountInfo(linkedAccount);
          }
        }}
        options={filteredLinkedAccounts}
        value={linkedAccountInfo?.end_user?.organization_name ?? ("" as any)}
        renderOption={(linkedAccount) => <LinkedAccountChoiceRow linkedAccount={linkedAccount} />}
        filterOptions={(options: any, { inputValue }: any) => {
          return options.filter((option: LinkedAccount) => {
            const inputValueToLowerCase = inputValue.toLowerCase();
            const linkedAccountId = (option?.id ?? "").toLowerCase();
            const endUserName = (option?.end_user?.organization_name ?? "").toLowerCase();
            return (
              endUserName.includes(inputValueToLowerCase) ||
              linkedAccountId.includes(inputValueToLowerCase)
            );
          });
        }}
      />
    </div>
  );
};

export default LinkedAccountsTypeahead;
