import {
  DiffState,
  DiffStateField,
  DiffStateFieldTypeEnum,
} from "../../../../../models/DiffModels";
import { getAllKeysInBothCurrentAndNew, getDiffStatus } from "../helpers";
import { processDiffForPropertyDetails } from "./step-templates-property-details-diff";

/**
 * Generates diff state for a schema property (ie.: {"employee_id": {"type": "string", "description": "blah blah"}})
 * Handles diff for all types, including object, array, string, boolean, etc.
 * If different types between currentProperty and newProperty, generates 1 DiffStateField for each
 * @param {string} keyOfProperty - The key of the property being compared.
 * @param {Object} currentProperty - The current state of the property.
 * @param {Object} newProperty - The new state of the property.
 * @param {boolean} isRenderCurrentAsEmpty - Flag to render the current state as empty.
 * @param {boolean} isRenderNewAsEmpty - Flag to render the new state as empty.
 *
 * @returns {DiffState} - The generated diff state for the property.
 */
export const generateDiffStateForProperty = (
  keyOfProperty: string,
  currentProperty: { [key: string]: any },
  newProperty: { [key: string]: any },
  isRenderCurrentAsEmpty: boolean = false,
  isRenderNewAsEmpty: boolean = false
): DiffState => {
  const currentType = "type" in currentProperty ? currentProperty["type"] : "";
  const newType = "type" in newProperty ? newProperty["type"] : "";

  // If type is different, we create 2 DiffStateFields instead of 1.
  // This is because different types can have different ways of processing its children fields.
  // (ie.: object vs. array)
  if (
    Object.keys(currentProperty).length !== 0 &&
    Object.keys(newProperty).length !== 0 &&
    currentType !== newType
  ) {
    const diffStateForCurrentType: DiffState = generateDiffStateForProperty(
      keyOfProperty,
      currentProperty,
      {},
      false,
      true
    );
    const diffStateForNewType: DiffState = generateDiffStateForProperty(
      keyOfProperty,
      {},
      newProperty,
      true,
      false
    );
    return diffStateForCurrentType.concat(diffStateForNewType);
  }
  // If type is the same or we're comparing an added or deleted key
  else {
    const type = !!currentType ? currentType : newType;

    let currentValue: string = "";
    let newValue: string = "";

    // Describes the schema of child fields
    let childSchemaDiffStateFields: DiffStateField[] = [];
    // Describes the details of this field (ie.: "type", "description")
    let childDetailDiffStateFields: DiffStateField[] = [];

    /*------ PROCESS CHILD FIELDS ------*/

    // Object - These always have a key "properties" that is a dictionary of children fields' schemas
    if (type === "object") {
      const currentChildSchema =
        "properties" in currentProperty ? currentProperty["properties"] : {};
      const newChildSchema = "properties" in newProperty ? newProperty["properties"] : {};
      const allChildSchemaKeys = getAllKeysInBothCurrentAndNew(currentChildSchema, newChildSchema);
      allChildSchemaKeys.forEach((childSchemaKey) => {
        const currentChildPropertyForKey =
          childSchemaKey in currentChildSchema ? currentChildSchema[childSchemaKey] : {};
        const newChildPropertyForKey =
          childSchemaKey in newChildSchema ? newChildSchema[childSchemaKey] : {};
        childSchemaDiffStateFields = childSchemaDiffStateFields.concat(
          generateDiffStateForProperty(
            childSchemaKey,
            currentChildPropertyForKey,
            newChildPropertyForKey,
            isRenderCurrentAsEmpty,
            isRenderNewAsEmpty
          )
        );
      });
    }
    // Array - These always have a key "items" that is a property
    else if (type === "array") {
      const currentChildPropertyForItem =
        "items" in currentProperty ? currentProperty["items"] : {};
      const newChildPropertyForItem = "items" in newProperty ? newProperty["items"] : {};
      childSchemaDiffStateFields = generateDiffStateForProperty(
        "items",
        currentChildPropertyForItem,
        newChildPropertyForItem,
        isRenderCurrentAsEmpty,
        isRenderNewAsEmpty
      );
    }

    /*------ PROCESS DETAILS OF THIS FIELD ------*/

    let filteredCurrentProperty = { ...currentProperty };
    let filteredNewProperty = { ...newProperty };
    if (type === "object") {
      if ("properties" in filteredCurrentProperty) delete filteredCurrentProperty.properties;
      if ("properties" in filteredNewProperty) delete filteredNewProperty.properties;
    } else if (type === "array") {
      if ("items" in filteredCurrentProperty) delete filteredCurrentProperty.items;
      if ("items" in filteredNewProperty) delete filteredNewProperty.items;
    }

    [currentValue, newValue, childDetailDiffStateFields] = processDiffForPropertyDetails(
      filteredCurrentProperty,
      filteredNewProperty,
      isRenderCurrentAsEmpty,
      isRenderNewAsEmpty
    );

    /*------ PROCESS FIELD ITSELF -----*/

    const childDiffStateFields = childDetailDiffStateFields.concat(childSchemaDiffStateFields);
    const diffStateField: DiffStateField = {
      type: DiffStateFieldTypeEnum.SECTION,
      name: keyOfProperty,
      displayName: keyOfProperty,
      currentValue: currentValue,
      newValue: newValue,
      diffStatus: getDiffStatus(currentValue, newValue, childDiffStateFields),
      childDiffStateFields: childDetailDiffStateFields,
      childDiffStateNestedFields: childSchemaDiffStateFields,
      isRenderCurrentAsEmpty: isRenderCurrentAsEmpty,
      isRenderNewAsEmpty: isRenderNewAsEmpty,
    };
    return [diffStateField];
  }
};
