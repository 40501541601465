import { BlueprintGenericStepTemplate } from "../../../models/Blueprints";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import TypeaheadFormField from "./TypeaheadFormField";
import { HeaderPretitle } from "../../shared/text/MergeText";

interface Props {
  stepID: string;
  stepTemplate: BlueprintGenericStepTemplate;
}

const BlueprintEditorRightPanelGenericStepForm = ({ stepID, stepTemplate }: Props) => {
  return (
    <>
      <form>
        <HeaderPretitle className="mt-3">{"Parameter Values"}</HeaderPretitle>
        {Object.entries(stepTemplate.parameter_schema?.properties ?? {})
          .sort()
          .map(([key, parameter]) => {
            return (
              <TypeaheadFormField
                key={key}
                title={parameter.title}
                subtitle={parameter.description}
                valueKey={key}
                parameterType={parameter.type}
                choices_override={parameter?.enum}
                choiceNamesOverride={parameter?.enumNames}
              />
            );
          })}
      </form>
      <JSONSchemaTreeDiagram stepID={stepID} jsonSchema={stepTemplate.return_schema} />
    </>
  );
};

export default BlueprintEditorRightPanelGenericStepForm;
