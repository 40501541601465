import SelectHeader from "../../../shared/SelectHeader";
import {
  LinkChoiceStepOption,
  LinkChoiceStepOptionAccountTypeEnums,
} from "../../../utils/Entities";
import AccordionCard from "../../../shared/AccordionCard";

interface Props {
  indexStepOption: number;
  requestedLinkChoiceStepOption: LinkChoiceStepOption;
  onCallbackUpdateLinkChoiceStepOption: (index: number, keyValuePairs: Record<string, any>) => void;
}

const AdvancedOptionConfigurationFields = ({
  indexStepOption,
  requestedLinkChoiceStepOption,
  onCallbackUpdateLinkChoiceStepOption,
}: Props) => {
  return (
    <AccordionCard
      dataTestID={`accordion-option-card-advanced-${indexStepOption}`}
      title="Advanced"
      badgeNumber={1}
      variant="outline"
      titleVariant="h6"
      onChange={() => {}}
    >
      <div className="space-y-6">
        <SelectHeader
          dataTestID={`field-option-card-account-type-${indexStepOption}`}
          title="Account type"
          subtitle="By default, an option has account type of production. Set to sandbox if customers need a way to link a sandbox account with sandbox-specific authentication configuration overrides."
          learnMoreText="Adding a sandbox option prompts an initial Link screen that shows the option to select Sandbox vs Production. This Link screen will only show up if the customer's Organization has show_end_user_sandbox_config enabled. See iCIMS or Quickbooks as examples."
          options={Object.values(LinkChoiceStepOptionAccountTypeEnums)}
          value={requestedLinkChoiceStepOption.account_type}
          onChange={(_: any, selectedOption: LinkChoiceStepOptionAccountTypeEnums | null) =>
            onCallbackUpdateLinkChoiceStepOption(indexStepOption, { account_type: selectedOption })
          }
        />
      </div>
    </AccordionCard>
  );
};

export default AdvancedOptionConfigurationFields;
