import classNames from "classnames";

import useBlueprintContext from "../context/useBlueprintContext";
import { ScraperVersion } from "../../scraper/types";
import StepCard from "../../shared/StepCard";
interface Props {
  scraper: ScraperVersion;
}

const BlueprintScraperCard = ({ scraper }: Props) => {
  const { selectedStep, setSelectedStep } = useBlueprintContext();

  const isHighlighted = selectedStep === scraper;

  const cardClass = classNames(
    isHighlighted ? "step-card-highlighted" : "step-card-not-highlighted"
  );

  return (
    <StepCard
      cardClassName={cardClass}
      title="Scraper"
      subtitle={scraper.name}
      stepIcon="fe-chrome"
      onClick={() => setSelectedStep(scraper)}
    />
  );
};

export default BlueprintScraperCard;
