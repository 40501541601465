import clsx from "clsx";
import { ReactNode } from "react";

interface LineIndentProps {
  children: ReactNode;
  className?: string;
}

const LineIndent = ({ children, className }: LineIndentProps) => {
  return (
    <div className={clsx("grid grid-cols-[auto_1fr] gap-6", className)}>
      <div className="border-r-2 border-gray-10" />
      <div>{children}</div>
    </div>
  );
};

export default LineIndent;
