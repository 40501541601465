import { Button, Text } from "@merge-api/merge-javascript-shared";
import MergeTable from "../../../../shared/MergeTable";
import TableSkeletonLoader from "../../../../shared/TableSkeletonLoader";
import IntegrationBuilderHeader from "../../../shared/IntegrationBuilderHeader";
import AuthenticationConfigurationTableRow from "./AuthenticationConfigurationTableRow";
import { navigateToIntegrationBuilderCreateAuthenticationConfiguration } from "../../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { AuthConfigIntegrationBuilder } from "../../../utils/Entities";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import IntegrationBuilderEmptyState from "../../../shared/IntegrationBuilderEmptyState";
import AuthConfigExplainAlert from "../shared/AuthConfigExplainAlert";
import { Plus } from "lucide-react";

interface AuthenticationConfigurationTableProps {
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  isLoadingAuthConfig: boolean;
  integrationID: string;
}

const AUTHENTICATION_CONFIGURATION_SUBMIT_TEXT = "Save auth config";

const AuthenticationConfigurationTable = ({
  authConfigs,
  isLoadingAuthConfig,
  integrationID,
}: AuthenticationConfigurationTableProps) => {
  //hooks
  const history = useHistory();
  // context
  useIntegrationBuilderContext({
    submitButtonText: AUTHENTICATION_CONFIGURATION_SUBMIT_TEXT,
    canSubmit: false,
    shouldRenderSubmitButton: false,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
  });

  return (
    <div>
      <IntegrationBuilderHeader
        title="Authentication configurations"
        button={
          <Button
            size="md"
            leftIcon={<Plus size={16} />}
            onClick={() => {
              navigateToIntegrationBuilderCreateAuthenticationConfiguration(history, integrationID);
            }}
          >
            Configuration
          </Button>
        }
      />
      <AuthConfigExplainAlert className="mb-6" />
      <MergeTable
        hover={authConfigs && authConfigs.length > 0}
        hasMarginBottom={false}
        hasMorePaddingOnFirstElement
        header={
          <>
            <th scope="col">Name</th>
            <th scope="col">Auth type</th>
          </>
        }
        content={
          isLoadingAuthConfig ? (
            <TableSkeletonLoader cols={2} rows={5} size="large" />
          ) : (
            <>
              {authConfigs && authConfigs.length > 0 ? (
                <>
                  {authConfigs.map((config) => (
                    <AuthenticationConfigurationTableRow
                      key={config.id}
                      authConfig={config}
                      integrationID={integrationID}
                    />
                  ))}
                </>
              ) : (
                <IntegrationBuilderEmptyState
                  title="No authentication configurations"
                  subtitle={
                    <div>
                      Click <Text variant="title-sm">+ Configuration</Text> in the top right to
                      manually add one
                    </div>
                  }
                  numberOfColumns={2}
                />
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default AuthenticationConfigurationTable;
