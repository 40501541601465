import { useState } from "react";
// import styled from "styled-components";
import { Integration } from "../../../models/Entities";
import { IntegrationAvatar } from "../MergeAvatars";
import HeaderSection from "../layout/HeaderSection";
import IntegrationEditorNavbar, { IntegrationEditorNavbarSubtab } from "./IntegrationEditorNavbar";
import IntegrationEditorLeftPanelVerticalNavbar from "./IntegrationEditorLeftPanelVerticalNavbar";
import { LucideIcon } from "lucide-react";
import { Alert, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { Blueprint } from "../../../models/Blueprints";
import useBlueprintContext from "../../blueprint-editor/context/useBlueprintContext";
import DiffModal from "../diff-view/DiffModal";
import { DiffModalTypeEnum, DiffModelTypeEnum } from "../../../models/DiffModels";
import { viewBlueprintDiff } from "../../blueprint-editor/utils/BlueprintEditorUtils";

export type IntegrationEditorLeftPanelVerticalTab = {
  Icon: LucideIcon;
  title: string;
  href: string;
};

export enum IntegrationLeftPanelLogType {
  INFO,
  WARNING,
  SUCCESS,
  FAILURE,
}

export type IntegrationLeftPanelLog = {
  title: string;
  subtitle: string;
  icon?: JSX.Element;
  type: IntegrationLeftPanelLogType;
};

type Props = {
  integration: Integration;
  header: string;
  subheader: string;
  tabs: IntegrationEditorNavbarSubtab[] | null;
  verticalTabs?: IntegrationEditorLeftPanelVerticalTab[];
  hasUnsavedChanges?: boolean;
};

const IntegrationEditorLeftPanel = ({
  integration,
  header,
  subheader,
  tabs,
  verticalTabs,
  hasUnsavedChanges,
}: Props) => {
  const [selectedTabKey, setSelectedTabKey] = useState(tabs ? tabs[0].key : "");
  const [, setIsShowingVersionControlHistory] = useState(false);
  const [blueprintToCompare, setBlueprintToCompare] = useState<Blueprint>();
  const [isShowingDiffModal, setIsShowingDiffModal] = useState(false);
  const [isLoadingBlueprintToCompare, setIsLoadingBlueprintToCompare] = useState(false);
  const { blueprint, blueprintVersions } = useBlueprintContext();

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex items-center m-4">
          <div className="ml-3">
            <IntegrationAvatar integration={integration} />
          </div>
          <div className="ml-3">
            <HeaderSection
              header={header}
              subheader={subheader}
              hasUnsavedChanges={hasUnsavedChanges}
            />
          </div>
        </div>
        {hasUnsavedChanges && (
          <div className="flex-col pb-4 px-4 border-b border-gray-10">
            <Alert showWarningIcon>
              <div className="flex w-full flex-row items-center justify-between">
                <span>Unsaved changes</span>
                <Button
                  size="sm"
                  variant={ButtonVariant.TertiaryWhite}
                  onClick={() =>
                    viewBlueprintDiff(
                      () => setIsShowingVersionControlHistory(false),
                      setBlueprintToCompare,
                      setIsShowingDiffModal,
                      setIsLoadingBlueprintToCompare,
                      blueprint,
                      blueprintVersions[0].id
                    )
                  }
                >
                  Compare
                </Button>
              </div>
            </Alert>
          </div>
        )}
        {verticalTabs && verticalTabs.length > 1 && (
          <IntegrationEditorLeftPanelVerticalNavbar verticalTabs={verticalTabs} />
        )}
        {tabs && (
          <>
            <IntegrationEditorNavbar
              tabs={tabs}
              selectedTabKey={selectedTabKey}
              setSelectedTabKey={setSelectedTabKey}
              className="ml-3"
            />
            <div className="p-3 overflow-auto h-full">
              {tabs.find((tab) => tab.key === selectedTabKey)?.content}
            </div>
          </>
        )}
      </div>
      {isShowingDiffModal && (
        <DiffModal
          isModalOpen={isShowingDiffModal}
          setIsModalOpen={setIsShowingDiffModal}
          diffModalType={DiffModalTypeEnum.COMPARE}
          modelType={DiffModelTypeEnum.BLUEPRINT}
          isLoadingStates={isLoadingBlueprintToCompare}
          newState={blueprint}
          newStateTitle={`Unsaved Blueprint`}
          currentState={blueprintToCompare ?? {}}
          currentStateTitle={`Blueprint version - ${blueprintToCompare?.version.comment}`}
        />
      )}
    </>
  );
};

export default IntegrationEditorLeftPanel;
