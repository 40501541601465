import { Text } from "@merge-api/merge-javascript-shared";
import { BlueprintRunnerExecutionResponse } from "../../../../models/Blueprints";
import { useState } from "react";
import IntegrationEditorStepLogsTable from "../../../shared/integration-editor-base/IntegrationEditorStepLogsTable";
import LeftPanelSectionHeader from "../shared/LeftPanelSectionHeader";
import LeftPanelSelect from "../shared/LeftPanelSelect";
import StepIOLogsTable from "./step-io-logs/StepIOLogsTable";

interface Props {
  blueprintRunnerExecutionResponse: BlueprintRunnerExecutionResponse | null;
  setIsShowingStepCoverage: (x: boolean) => void;
  shouldLogStepIO: boolean;
}

// In the future, this will handle STEP_IO view as well
enum ConsoleResultLogViewOptionEnum {
  DEFAULT = "Step logs",
  STEP_COVERAGE = "Step logs + coverage",
  STEP_IO = "Step I/O",
}

/**
 * Renders the logs and log view options for a Blueprint run
 * If no Blueprint run results, does not render
 */
const BlueprintLeftPanelConsoleResultLogs = ({
  blueprintRunnerExecutionResponse,
  setIsShowingStepCoverage,
  shouldLogStepIO,
}: Props) => {
  const [selectedView, setSelectedView] = useState<ConsoleResultLogViewOptionEnum | null>(
    shouldLogStepIO
      ? ConsoleResultLogViewOptionEnum.STEP_IO
      : ConsoleResultLogViewOptionEnum.DEFAULT
  );

  if (!blueprintRunnerExecutionResponse) {
    return null;
  }

  const { exit_data, start_time } = blueprintRunnerExecutionResponse;

  // Handles what view to show
  // Soon, step I/O view will also be toggled here
  const handleOnChange = (value: ConsoleResultLogViewOptionEnum | null) => {
    setIsShowingStepCoverage(value === ConsoleResultLogViewOptionEnum.STEP_COVERAGE);
    setSelectedView(value);
  };

  const renderExitDataLogs = () => {
    if (exit_data) {
      // Render step IO
      if (selectedView === ConsoleResultLogViewOptionEnum.STEP_IO) {
        return (
          <div className="flex flex-col space-y-2">
            <Text variant="h6">Step I/O logs</Text>
            {exit_data.debugging_info?.step_io_logs ? (
              <StepIOLogsTable stepIOLogs={exit_data.debugging_info.step_io_logs} />
            ) : (
              <Text>No step I/O logs found</Text>
            )}
          </div>
        );
      }

      // Render step logs
      return (
        <div className="flex flex-col space-y-2">
          <Text variant="h6">Step logs</Text>
          {exit_data.step_logs ? (
            <IntegrationEditorStepLogsTable startTime={start_time} stepLogs={exit_data.step_logs} />
          ) : (
            <Text>No step logs found</Text>
          )}
        </div>
      );
    }

    return undefined;
  };

  return (
    <>
      <hr className="bg-gray-10 p-0 m-0" />
      <div className="flex flex-col space-y-4 py-4">
        <LeftPanelSectionHeader title="Logs" />
        <LeftPanelSelect
          title="Log view"
          placeholder="Default"
          onChange={(_, value) => handleOnChange(value)}
          value={selectedView}
          options={Object.values(ConsoleResultLogViewOptionEnum).filter((view) =>
            shouldLogStepIO ? true : view !== ConsoleResultLogViewOptionEnum.STEP_IO
          )}
          clearable={false}
        />
        {renderExitDataLogs()}
      </div>
    </>
  );
};

export default BlueprintLeftPanelConsoleResultLogs;
