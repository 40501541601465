import { useContext, useEffect, useState } from "react";
import MultiSelectHeader from "../../shared/MultiSelectHeader";
import {
  BREAK_VALUE_TYPE_OPTIONS,
  KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER,
  KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER,
  MAX_ITEMS_VALUE_LOCATION_OPTIONS,
  PAGINATION_CONFIG_END_OF_PAGINATION_OPTIONS,
} from "../constants";
import PaginationConfigurationContext from "../context/PaginationConfigurationContext";
import {
  BreakValueType,
  EndOfPaginationType,
  MaxItemsValueLocation,
} from "../../../../models/PaginationConfigurationModels";
import KeyPathFieldHeader from "../../shared/KeyPathFieldHeader";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { Accordion, Text } from "@merge-api/merge-javascript-shared";

const EndOfPaginationSetupOptions = () => {
  const {
    endOfPaginationType,
    setEndOfPaginationType,
    maxItemsValueLocation,
    setMaxItemsValueLocation,
    maxItemsValueKeyPath,
    setMaxItemsValueKeyPath,
    breakValueType,
    setBreakValueType,
    breakValueResponseBodyKeyPath,
    setBreakValueResponseBodyKeyPath,
    specificValueToBreakOn,
    setSpecificValueToBreakOn,
    overrideInfiniteLoopConditionKeyPath,
    setOverrideInfiniteLoopConditionKeyPath,
  } = useContext(PaginationConfigurationContext);

  const endOfPaginationOptions = PAGINATION_CONFIG_END_OF_PAGINATION_OPTIONS.map(
    ({ value, text }) => ({
      value,
      text,
      selected: endOfPaginationType === value,
      onClick: () => setEndOfPaginationType(value),
    })
  );

  const breakValueTypeOptions = BREAK_VALUE_TYPE_OPTIONS.map(({ value, text }) => ({
    value,
    text,
    selected: breakValueType === value,
    onClick: () => setBreakValueType(value),
  }));

  const [isLocationModified, setIsLocationModified] = useState<boolean>(false);
  const maxItemsLocationOptions = MAX_ITEMS_VALUE_LOCATION_OPTIONS.map(({ value, text }) => ({
    value,
    text,
    selected: maxItemsValueLocation === value,
    onClick: () => {
      if (value === maxItemsValueLocation) {
        setMaxItemsValueLocation(null);
      } else {
        setIsLocationModified(true);
        setMaxItemsValueLocation(value);
      }
    },
  }));

  useEffect(() => {
    if (isLocationModified) {
      setMaxItemsValueKeyPath([]);
    }
  }, [maxItemsValueLocation]);

  return (
    <>
      <MultiSelectHeader
        dataTestID="field-pagination-config-end-of-pagination-type"
        className="mt-6"
        title="When do we stop paginating?"
        subtitle="How do we know when we’ve reached the last page? Most requests would typically return an empty array, which we can use to detect that we’re on the last page."
        options={endOfPaginationOptions}
        required
      />
      {endOfPaginationType === EndOfPaginationType.MAX_ITEMS && (
        <div className="border-l-2 pl-8 mt-6 border-gray-10">
          <MultiSelectHeader
            className="mt-6"
            dataTestID="field-pagination-config-max-items-value-location"
            title="Location in response that indicates total number of items"
            subtitle="Where can we find the total number of items?"
            options={maxItemsLocationOptions}
            required
          />
          {/** TODO: https://app.asana.com/0/1205644398660644/1206923763851312/f Modify the key path field header component to have a max depth instead of using text field conditionally */}
          {maxItemsValueLocation === MaxItemsValueLocation.RESPONSE_HEADER && (
            <div className="border-l-2 pl-8 mt-6 border-gray-10">
              <TextFieldHeader
                className="mt-6"
                dataTestID="field-pagination-config-max-items-value-key"
                title="Key in response headers that indicates total number of items"
                subtitle="What is the key in the response header to determine the max items value?"
                hasSource={false}
                placeholder="Key"
                value={
                  maxItemsValueKeyPath && maxItemsValueKeyPath.length > 0
                    ? maxItemsValueKeyPath[0]
                    : ""
                }
                onChange={(e) => setMaxItemsValueKeyPath([e.target.value])}
                required
              />
            </div>
          )}
          {maxItemsValueLocation === MaxItemsValueLocation.RESPONSE_BODY && (
            <div className="border-l-2 pl-8 mt-6 border-gray-10">
              <KeyPathFieldHeader
                className="mt-6"
                dataTestID="field-pagination-config-max-items-value-key-path"
                title="Key path in response body that indicates total number of items"
                subtitle="What is the path to the specific value we should look at in the response body to determine the max items value?"
                placeholder={
                  maxItemsValueKeyPath?.length
                    ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                    : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
                }
                keyPath={maxItemsValueKeyPath}
                onKeyPathChange={setMaxItemsValueKeyPath}
                hasSource={false}
                required
              />
            </div>
          )}
        </div>
      )}
      {endOfPaginationType === EndOfPaginationType.BREAK_VALUE && (
        <div className="border-l-2 pl-8 mt-6 border-gray-10">
          <MultiSelectHeader
            className="mt-6"
            dataTestID="field-pagination-config-break-value-type"
            title="Type of break value"
            subtitle="Is it a bool value, where we keep paginating so long as the bool is True, or is there a specific value we should halt on?"
            options={breakValueTypeOptions}
            required
          />
          {breakValueType === BreakValueType.BREAK_WHEN_FALSE && (
            <div className="border-l-2 pl-8 mt-6 border-gray-10">
              <KeyPathFieldHeader
                dataTestID="field-pagination-config-break-value-response-body-key-path"
                title="Key path in response body that indicates last page"
                subtitle="Where can we find the the break value?"
                placeholder={
                  breakValueResponseBodyKeyPath?.length
                    ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                    : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
                }
                hasSource={false}
                keyPath={breakValueResponseBodyKeyPath}
                onKeyPathChange={setBreakValueResponseBodyKeyPath}
                required
              />
            </div>
          )}
          {breakValueType === BreakValueType.BREAK_WHEN_SPECIFIC_VALUE && (
            <div className="border-l-2 pl-8 mt-6 border-gray-10">
              <KeyPathFieldHeader
                dataTestID="field-pagination-config-break-value-response-body-key-path"
                title="Key path in response body that indicates last page"
                subtitle="Where can we find the the break value?"
                placeholder={
                  breakValueResponseBodyKeyPath?.length
                    ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                    : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
                }
                hasSource={false}
                keyPath={breakValueResponseBodyKeyPath}
                onKeyPathChange={setBreakValueResponseBodyKeyPath}
                required
              />
              <TextFieldHeader
                dataTestID="field-pagination-config-break-value-specific-value"
                className="mt-6"
                title="Value to indicate last page"
                subtitle="What is the value of the field located in the key path defined above, so that we stop paginating if the returned value matches the value defined here?"
                hasSource={false}
                value={specificValueToBreakOn}
                onChange={(e) => setSpecificValueToBreakOn(e.target.value)}
                placeholder="Value"
                required
              />
              <Accordion
                className="mt-6"
                chevronOrientation="right"
                chevronPlacement="end"
                chevronSize={12}
                onChange={function noRefCheck() {}}
                variant="outline"
                title={
                  <div data-testid="accordion-advanced-pagination-config-break-value-options">
                    <Text as="div" variant="h6">
                      Advanced
                    </Text>
                  </div>
                }
                titleClassName="px-5 py-3"
              >
                <div className="px-5 pb-4">
                  <hr className="text-gray-50 h-[0.5px] mt-0 mb-4 -mx-5" />
                  <KeyPathFieldHeader
                    dataTestID="field-pagination-config-break-value-override-infinite-loop-condition-key-path"
                    title="Key path to override infinite loop condition"
                    subtitle="If the pagination is stateful and returns the same pagination value each time, you might need a different way to detect if you’re cycling on the same API response besides checking for two equal pagination values in a row. Define a key path in the response body that the API request loop will track for detecting a cycle."
                    placeholder={
                      overrideInfiniteLoopConditionKeyPath?.length
                        ? KEY_PATH_FIELD_ADD_KEY_NAME_PLACEHOLDER
                        : KEY_PATH_FIELD_EXAMPLE_PLACEHOLDER
                    }
                    hasSource={false}
                    keyPath={overrideInfiniteLoopConditionKeyPath}
                    onKeyPathChange={setOverrideInfiniteLoopConditionKeyPath}
                  />
                </div>
              </Accordion>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EndOfPaginationSetupOptions;
