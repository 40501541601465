import { useContext, useEffect, useState } from "react";
import { getXMLRequestBodyParamSchema } from "../../../../integrations/utils/IntegrationsUtils";
import BadgeList from "../../../shared/BadgeList";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import APIEndpointContext from "../../context/APIEndpointContext";
import JSONSchemaFieldHeader from "../../../shared/JSONSchemaFieldHeader";
import GenshiTemplatingHelperAccordion from "./GenshiTemplatingHelperAccordion";

const SoapRequestConfigurationSetupOptions = () => {
  const {
    soapRequestBodyFormat: requestBodyFormat,
    setSoapRequestBodyFormat: setRequestBodyFormat,
    soapRequestBodyParamSchema: requestBodyParamSchema,
    setSoapRequestBodyParamSchema: setRequestBodyParamSchema,
    soapDoesRequestUseBodyTemplatingEngine: doesRequestUseBodyTemplatingEngine,
  } = useContext(APIEndpointContext);

  const [bodyParameters, setBodyParameters] = useState<string[]>([]);
  const [requestBodyParamSchemaString, setRequestBodyParamSchemaString] = useState<string>(
    JSON.stringify(requestBodyParamSchema)
  );

  // Automatically set the body param schema based on the body format if we not using the templating engine
  useEffect(() => {
    if (
      requestBodyFormat !== null &&
      requestBodyFormat !== undefined &&
      !doesRequestUseBodyTemplatingEngine
    ) {
      const XMLRequestBodyParamSchema = getXMLRequestBodyParamSchema(requestBodyFormat);
      setRequestBodyParamSchema(JSON.parse(XMLRequestBodyParamSchema));
    }
  }, [requestBodyFormat]);

  // The body parameters get displayed when the templating engine is not being used. Update those body parameters anytime the schema changes
  useEffect(() => {
    setBodyParameters(Object.keys((requestBodyParamSchema as any)?.properties || {}));
  }, [requestBodyParamSchema]);

  // We work off an open text field when the templating engine is being used.
  // When the text field changes and can parse to valid json, we update the object ONLY if the templating engine is being used
  // When the object changes, we update the text field ONLY if the templating engine is not being used

  // Update the request body param schema when the string is a valid json and the templating engine is being used
  useEffect(() => {
    try {
      const parsedJSON = JSON.parse(requestBodyParamSchemaString);
      if (doesRequestUseBodyTemplatingEngine && parsedJSON !== requestBodyParamSchema) {
        setRequestBodyParamSchema(parsedJSON);
      }
    } catch {
      // Do nothing
    }
  }, [requestBodyParamSchemaString]);

  // Update the request body param schema string when the object changes and the templating engine is not being used
  useEffect(() => {
    if (
      !doesRequestUseBodyTemplatingEngine &&
      requestBodyParamSchemaString !== JSON.stringify(requestBodyParamSchema)
    ) {
      setRequestBodyParamSchemaString(JSON.stringify(requestBodyParamSchema));
    }
  }, [requestBodyParamSchema]);

  return (
    <>
      {doesRequestUseBodyTemplatingEngine && <GenshiTemplatingHelperAccordion />}
      <TextFieldHeader
        dataTestID="field-api-endpoint-request-body-format"
        title="Request body format"
        subtitle="Request body string to format the SOAP Request. Use curly braces to denote any request body parameters. (i.e. {parameterName})"
        learnMoreText='If an example request body looked like "<b:LastName>like (Smith)</b:LastName>", enter "<b:LastName>like ({LastName})</b:LastName>" as the request body format.'
        placeholder="Example: <b:LastName>like ({LastName})</b:LastName>"
        value={requestBodyFormat}
        onChange={(e) => setRequestBodyFormat(e.target.value)}
        hasSource={false}
        autosize={true}
        className="my-6"
      />
      {doesRequestUseBodyTemplatingEngine ? (
        <JSONSchemaFieldHeader
          value={requestBodyParamSchemaString}
          setValue={setRequestBodyParamSchemaString}
          title="Request body parameter schema"
          subtitle="The schema for the request body parameters. This schema is used to parameterize the request body format."
          className="mt-6"
          hasSource={false}
        />
      ) : (
        <BadgeList
          className="mt-2"
          items={bodyParameters}
          title="Request body parameters"
          helpText={
            <>
              Request body parameters are variables in the body of the request that are replaced
              with actual values when making a request. To manually set these, select "(SOAP) Does
              request use body templating engine" in the Advanced section.
            </>
          }
        />
      )}
    </>
  );
};

export default SoapRequestConfigurationSetupOptions;
