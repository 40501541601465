import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelGetByIdForm = () => {
  return (
    <>
      <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
      <TypeaheadFormField
        key={"id"}
        title={"ID"}
        subtitle={"The ID of the matching object."}
        valueKey={"id"}
        parameterType={"uuid"}
      />
    </>
  );
};

export default BlueprintEditorRightPanelGetByIdForm;
