import { BlueprintStepTemplate } from "../../../models/Blueprints";
import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelTraverseTreeForm = (props: Props) => {
  return (
    <>
      <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
      <TypeaheadFormField
        key={"starting_nodes"}
        title={"Default Starting Nodes"}
        subtitle={
          "The list of drive & site IDs to start recursing into. The end user can also select the starting nodes via file picker; if they've done so, we'll use their selections instead of this value."
        }
        valueKey={"starting_nodes"}
        parameterType={"array"}
      />
      <HeaderPretitle className="mt-3">Common Model Fields</HeaderPretitle>
      <JSONSchemaTreeDiagram stepID={props.stepID} jsonSchema={props.stepTemplate.return_schema} />
    </>
  );
};

export default BlueprintEditorRightPanelTraverseTreeForm;
