import { BlueprintStepTemplate } from "../../../models/Blueprints";
import JSONSchemaTreeDiagram from "../../shared/JSONSchemaTreeDiagram";
import { HeaderPretitle } from "../../shared/text/MergeText";

interface Props {
  stepID: string;
  stepTemplate: BlueprintStepTemplate;
}

const BlueprintEditorRightPanelGetLinkedAccountFields = ({ stepID, stepTemplate }: Props) => {
  return (
    <>
      <HeaderPretitle className="mt-3">Available Fields</HeaderPretitle>
      <JSONSchemaTreeDiagram stepID={stepID} jsonSchema={stepTemplate.return_schema} />
    </>
  );
};

export default BlueprintEditorRightPanelGetLinkedAccountFields;
