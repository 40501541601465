import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import nightOwl from "react-syntax-highlighter/dist/cjs/styles/hljs/night-owl";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import python from "react-syntax-highlighter/dist/cjs/languages/hljs/python";
import ruby from "react-syntax-highlighter/dist/cjs/languages/hljs/ruby";
import bash from "react-syntax-highlighter/dist/cjs/languages/hljs/bash";
import elixir from "react-syntax-highlighter/dist/cjs/languages/hljs/elixir";
import go from "react-syntax-highlighter/dist/cjs/languages/hljs/go";
import { Row } from "react-bootstrap";

import MergeCopyToClipboard from "./MergeCopyToClipboard";
import React from "react";

SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("ruby", ruby);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("elixir", elixir);
SyntaxHighlighter.registerLanguage("go", go);

interface Props {
  children?: string | null;
  language?: string | null;
  showLineNumbers?: boolean | null;
  customStyle?: any;
  textToCopy?: string;
  shouldExcludeCopyOption?: boolean | null;
  shouldWrapLongLines?: boolean | null;
}

const MergeCodeBlock = ({
  children,
  language,
  showLineNumbers,
  customStyle,
  textToCopy,
  shouldExcludeCopyOption = false,
  shouldWrapLongLines = false,
}: Props) => {
  const CodeBlock = () => {
    return (
      <SyntaxHighlighter
        className="code-block block"
        language={language || "json"}
        showLineNumbers={showLineNumbers ? true : false}
        style={nightOwl}
        wrapLongLines={shouldWrapLongLines}
        customStyle={Object.assign({ padding: "5px 20px 20px" }, customStyle)}
        shouldExcludeCopyOption={shouldExcludeCopyOption}
      >
        {children}
      </SyntaxHighlighter>
    );
  };

  return (
    <div className="code-block-container">
      {!shouldExcludeCopyOption && (
        <Row
          className="align-items-center justify-content-end"
          style={{ padding: "0 10px", margin: 0 }}
        >
          <div className="d-flex align-items-center mt-1.5 float-right">
            <MergeCopyToClipboard textToCopy={textToCopy} />
          </div>
        </Row>
      )}
      <CodeBlock />
    </div>
  );
};

export default React.memo(MergeCodeBlock);
