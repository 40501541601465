import { Badge } from "react-bootstrap";
import { LucideIcon } from "lucide-react";
import styled from "styled-components";
import { palette } from "../../../styles/theme/colors";
import MergeText, { TextType } from "../text/MergeText";

export type IntegrationEditorNavbarSubtab = {
  key: string;
  navbarText: string;
  Icon: LucideIcon;
  content: React.ReactNode | (() => React.ReactNode);
  count?: number;
};

type Props = {
  tabs: IntegrationEditorNavbarSubtab[];
  selectedTabKey: string;
  setSelectedTabKey: (tabKey: string) => void;
  className?: string;
};

const Navbar = styled.div`
  display: flex;
  border-bottom: 1px solid #eaeef3;
`;

const NavbarItem = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: -1px;

  & ~ & {
    margin: 0 0 0 26px;
  }
`;

type NavbarContentProps = {
  isSelected: boolean;
};

const NavbarContent = styled.div<NavbarContentProps>`
  padding: 18px 2px;
  border-bottom: ${({ isSelected }) => (isSelected ? "1px solid #075FF7" : "")}};
  align-items: center;
  display: flex;
`;

const IntegrationEditorNavbar = ({ tabs, selectedTabKey, setSelectedTabKey, className }: Props) => {
  return (
    <Navbar className={className}>
      {tabs.map((tab) => {
        const { Icon, navbarText, key, count } = tab;
        const isSelected = key === selectedTabKey;

        return (
          <NavbarItem key={key} onClick={() => setSelectedTabKey(key)}>
            <NavbarContent isSelected={isSelected}>
              {
                <Icon
                  size="12px"
                  color={isSelected ? palette.blue : "#95AAC9"}
                  className="mr-1.5"
                />
              }
              <MergeText isBold size="13px" type={isSelected ? TextType.BLUE2 : TextType.MUTED}>
                {navbarText}
              </MergeText>
              {count !== undefined && (
                <Badge variant="soft-secondary" className="ml-1.5">
                  {" "}
                  {count}
                </Badge>
              )}
            </NavbarContent>
          </NavbarItem>
        );
      })}
    </Navbar>
  );
};

export default IntegrationEditorNavbar;
