import { SelectiveSyncFilterSchema } from "../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import {
  VersionedComponentAPIActions,
  VersionedComponentAPIComponentKeys,
  VersionedComponentsAPIRequestData,
} from "../../versioned-components/types";
import { makeVersionedComponentsAPIRequest } from "../VersionedComponentsAPIClient";
import { convertExpandedPydanticModelToNativePydanticModel } from "./utils";

interface CreateOrPatchSelectiveSyncFilterSchemaProps {
  selectiveSyncFilterSchema?: SelectiveSyncFilterSchema;
  integrationID: string;
  componentID?: string;
  successToastMessage?: string;
  onResponse: (data: any) => void;
  onError?: (data: any) => void;
}

const useCreateOrUpdateSelectiveSyncFilterSchema = () => {
  const SELECTIVE_SYNC_SCHEMA_CLASS = VersionedComponentAPIComponentKeys.SelectiveSyncFilterSchema;

  const component_key = SELECTIVE_SYNC_SCHEMA_CLASS;

  const createSelectiveSyncFilterSchema = ({
    selectiveSyncFilterSchema,
    integrationID,
    successToastMessage,
    onResponse,
  }: CreateOrPatchSelectiveSyncFilterSchemaProps) => {
    if (!selectiveSyncFilterSchema) return;

    const pydanticNativeSelectiveSyncFilterSchema = convertExpandedPydanticModelToNativePydanticModel(
      selectiveSyncFilterSchema,
      SELECTIVE_SYNC_SCHEMA_CLASS
    );
    const componentID = selectiveSyncFilterSchema.id;

    const request_body: VersionedComponentsAPIRequestData = {
      component_key,
      component_id: componentID,
      component: pydanticNativeSelectiveSyncFilterSchema,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action: VersionedComponentAPIActions.CREATE_NEW_COMPONENT,
      request_body,
      successToastMessage:
        successToastMessage ?? "Successfully created new Selective Sync filter draft",
      onResponse,
    });
  };

  const patchSelectiveSyncFilterSchema = ({
    selectiveSyncFilterSchema,
    integrationID,
    successToastMessage,
    onResponse,
  }: CreateOrPatchSelectiveSyncFilterSchemaProps) => {
    const pydanticNativeSelectiveSyncFilterSchema = convertExpandedPydanticModelToNativePydanticModel(
      selectiveSyncFilterSchema as SelectiveSyncFilterSchema,
      SELECTIVE_SYNC_SCHEMA_CLASS
    );
    const componentID = (selectiveSyncFilterSchema as SelectiveSyncFilterSchema).id;

    const request_body: VersionedComponentsAPIRequestData = {
      component_key,
      component_id: componentID,
      component: pydanticNativeSelectiveSyncFilterSchema,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action: VersionedComponentAPIActions.SAVE_DRAFT_COMPONENT,
      request_body,
      successToastMessage: successToastMessage ?? "Successfully saved Selective Sync filter draft",
      onResponse,
    });
  };

  const unstageSelectiveSyncFilterSchema = ({
    integrationID,
    componentID,
    successToastMessage,
    onResponse,
    onError,
  }: CreateOrPatchSelectiveSyncFilterSchemaProps) => {
    const request_body: VersionedComponentsAPIRequestData = {
      component_key,
      component_id: componentID,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action: VersionedComponentAPIActions.UNSTAGE_COMPONENT,
      request_body,
      successToastMessage: successToastMessage ?? "Successfully unstaged Selective Sync filter",
      onResponse,
      onError,
    });
  };

  const stageSelectiveSyncFilterSchema = ({
    integrationID,
    componentID,
    successToastMessage,
    onResponse,
  }: CreateOrPatchSelectiveSyncFilterSchemaProps) => {
    const request_body: VersionedComponentsAPIRequestData = {
      component_key,
      component_id: componentID,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action: VersionedComponentAPIActions.STAGE_COMPONENT,
      request_body,
      successToastMessage: successToastMessage ?? "Successfully staged Selective Sync filter",
      onResponse,
    });
  };

  const deleteSelectiveSyncFilterSchema = ({
    integrationID,
    componentID,
    successToastMessage,
    onResponse,
  }: CreateOrPatchSelectiveSyncFilterSchemaProps) => {
    const request_body: VersionedComponentsAPIRequestData = {
      component_key,
      component_id: componentID,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action: VersionedComponentAPIActions.DELETE_DRAFT_COMPONENT,
      request_body,
      successToastMessage: successToastMessage ?? "Successfully deleted Selective Sync filter",
      onResponse,
    });
  };

  return {
    createSelectiveSyncFilterSchema,
    patchSelectiveSyncFilterSchema,
    unstageSelectiveSyncFilterSchema,
    stageSelectiveSyncFilterSchema,
    deleteSelectiveSyncFilterSchema,
  };
};

export default useCreateOrUpdateSelectiveSyncFilterSchema;
