import { useEffect, useMemo, useState } from "react";
import IntegrationBuilderHeader from "../shared/IntegrationBuilderHeader";
import { IntegrationForIntegrationBuilder, LinkingFlowStepPathTypeEnums } from "../utils/Entities";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";
import useCreateOrPatchIntegration from "../hooks/useCreateOrPatchIntegration";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { Card } from "@merge-api/merge-javascript-shared";
import Callout from "../shared/Callout";
import CardHeader from "../shared/CardHeader";
import PostmanTableHeader from "../authentication/components/shared/PostmanTableHeader";
import { Row } from "../shared/postman-table/PostmanTableRow";
import { parseDictToPostmanTableRows, parsePostmanTableRowsToDict } from "../utils/helpers";
import { DiffModelTypeEnum } from "../../../models/DiffModels";
import EditorLeavingGuard from "../../shared/unsaved-changes/EditorLeavingGuard";
import useLoadLinkingFlowStepPathTableRows from "../linking-flow-steps/hooks/useLoadLinkingFlowStepPathTableRows";
import MultiSelectHeader from "../shared/MultiSelectHeader";
import { BOOLEAN_OPTIONS_DATA } from "../api-endpoint-setup/constants";

interface LinkingFlowStepPathsConfigurationProps {
  integrationID: string;
}

const LinkingFlowStepPathsConfiguration = ({
  integrationID,
}: LinkingFlowStepPathsConfigurationProps) => {
  /* SETUP REQUEST BODY FOR EDITING CONFIGURATION FIEDLS */
  const {
    setOnSubmit,
    integration,
    isLoading,
    setCurrentStateForDiff,
    setNewStateForDiff,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();

  // Load Merge Link steps to see if there's any active ones that are PARTNERSHIP
  const { linkingFlowStepPaths } = useLoadLinkingFlowStepPathTableRows({
    integrationID: integrationID,
  });
  const isPartnershipAuthSupported = useMemo(() => {
    if (linkingFlowStepPaths) {
      return linkingFlowStepPaths.some(
        (path) =>
          path.path_type === LinkingFlowStepPathTypeEnums.PATH_TYPE_PARTNERSHIP && path.is_active
      );
    }
    return false;
  }, [linkingFlowStepPaths]);

  /* STATES */
  const [requestBodyForIntegration, setRequestBodyForIntegration] = useState<
    IntegrationForIntegrationBuilder | undefined
  >();
  const { isLoadingCreateOrPatchIntegration, patchIntegration } = useCreateOrPatchIntegration({
    requestBodyForIntegration,
  });

  /* FORM FIELDS */
  const [showAdminNeedScreen, setShowAdminNeededScreen] = useState<boolean>(false);
  const showAdminNeededScreenOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: showAdminNeedScreen == value,
    onClick: setShowAdminNeededScreen,
  }));
  const [requiredOrganizationConfigFieldRows, setRequiredOrganizationConfigFieldRows] = useState<
    Row[]
  >([]);
  const [optionalOrganizationConfigFieldRows, setOptionalOrganizationConfigFieldRows] = useState<
    Row[]
  >([]);
  const [isRequiredFieldsCompleted, setIsRequiredFieldsCompleted] = useState<boolean>(false);

  /* SETUP BUTTON STATE */
  useIntegrationBuilderContext({
    submitButtonText: "Save configuration",
    canSubmit: isRequiredFieldsCompleted,
    isLoadingSubmit: isLoadingCreateOrPatchIntegration,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    modelTypeForDiff: DiffModelTypeEnum.MERGE_LINK_STEPS_CONFIGURATION,
    shouldHideDiffModal: false,
  });

  /* USEEFFECT */
  // Initialize states once integration is loaded
  /* This useEffect initializes the model state to be used in the diff modal.
   * When adding new fields/removing them, you'll have to add them to multiple places
   * to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    if (integration) {
      // Set initial Postman table data
      setRequiredOrganizationConfigFieldRows(
        parseDictToPostmanTableRows(integration.required_organization_config_fields ?? {})
      );
      setOptionalOrganizationConfigFieldRows(
        parseDictToPostmanTableRows(integration.optional_organization_config_fields ?? {})
      );
      setShowAdminNeededScreen(integration.show_admin_needed_screen ?? false);

      // Set initial diff and request body states
      const requestBody = {
        required_organization_config_fields: integration.required_organization_config_fields ?? {},
        optional_organization_config_fields: integration.optional_organization_config_fields ?? {},
        show_admin_needed_screen: integration.show_admin_needed_screen ?? false,
      };
      setRequestBodyForIntegration(requestBody);
      setCurrentStateForDiff(requestBody);
      setNewStateForDiff(requestBody);
    }
  }, [integration]);

  // Set submit button state based on current values
  useEffect(() => {
    setOnSubmit(() => patchIntegration(integrationID));
  }, [integrationID, requestBodyForIntegration]);

  /* ----- BEGIN - UPDATE REQUEST BODY ----- */
  // Update request body if required_organization_config_fields modified
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return {
        ...body,
        required_organization_config_fields: parsePostmanTableRowsToDict(
          requiredOrganizationConfigFieldRows
        ),
      };
    });
  }, [requiredOrganizationConfigFieldRows]);

  // Update request body if optional_organization_config_fields modified
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return {
        ...body,
        optional_organization_config_fields: parsePostmanTableRowsToDict(
          optionalOrganizationConfigFieldRows
        ),
      };
    });
  }, [optionalOrganizationConfigFieldRows]);

  // Update request body if show_admin_needed_screen modified
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return {
        ...body,
        show_admin_needed_screen: showAdminNeedScreen,
      };
    });
  }, [showAdminNeedScreen]);

  /* ----- END - UPDATE REQUEST BODY ----- */

  // Update required state
  useEffect(() => {
    setIsRequiredFieldsCompleted(
      !isPartnershipAuthSupported ||
        (isPartnershipAuthSupported &&
          requestBodyForIntegration?.required_organization_config_fields &&
          Object.keys(requestBodyForIntegration.required_organization_config_fields).length > 0)
        ? true
        : false
    );
  }, [requestBodyForIntegration, isPartnershipAuthSupported]);

  // Update new diff state
  useEffect(() => {
    setNewStateForDiff(requestBodyForIntegration);
  }, [requestBodyForIntegration]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div>
        <IntegrationBuilderHeader title="Merge Link step paths configuration" showRequired />
        {isLoading ? (
          <EmptyStateWrapper isSpinner />
        ) : (
          <div className="flex flex-column space-y-6">
            <Callout
              title="What are customer credential fields?"
              description="Customer credential fields are required for partnership authentication, so that customers can enter their own credentials. Very common are they OAuth fields, which usually include `oauth_client_id` `oauth_client_secret` & `redirect_uri`. This is necessary if there are any Merge Link steps that of path type PARTNERSHIP."
            />
            <Card className="pt-4 px-5 pb-5 bg-white mb-6 min-w-fit">
              <CardHeader title="Configuration" />
              <div className="space-y-6">
                <PostmanTableHeader
                  dataTestID="merge-link-step-configuration-required-organization-config-fields"
                  title="Required customer credential fields for partnership auth"
                  subtitle="Partnership auth requires customers to enter their own credentials. Very common are they OAuth fields, which usually include `oauth_client_id` `oauth_client_secret` & `redirect_uri`. This is necessary if there are any Merge Link steps that of path type PARTNERSHIP."
                  keyHeader="Field"
                  valueHeader="Description"
                  learnMoreText="Here's an example of key-description pairs: oauth_client_id: Your Google Drive app's OAuth Client ID.  oauth_redirect_uri: Your Google Drive app's Auth Redirect URI. Should be https://app.merge.dev/oauth/callback.  oauth_client_secret: Your Google Drive app's OAuth Client Secret."
                  rows={requiredOrganizationConfigFieldRows}
                  setRows={setRequiredOrganizationConfigFieldRows}
                  required={isPartnershipAuthSupported}
                />
                <PostmanTableHeader
                  dataTestID="merge-link-step-configuration-optional-organization-config-fields"
                  title="Optional customer credential fields for partnership auth"
                  subtitle="Partnership auth may have optional fields that customers can enter to unlock additional integration capabilities"
                  keyHeader="Field"
                  valueHeader="Description"
                  learnMoreText="Here's an example of key-description pairs: verifier_token: Token used to verify webhooks."
                  rows={optionalOrganizationConfigFieldRows}
                  setRows={setOptionalOrganizationConfigFieldRows}
                  required={false}
                />
                <MultiSelectHeader
                  dataTestID="merge-link-step-configuration-show-admin-needed-screen-field"
                  title="Should show admin needed Link screen?"
                  subtitle="Select 'Yes' if the integration requires an admin to complete the linking process (e.g., UKG Pro)"
                  required
                  options={showAdminNeededScreenOptions}
                />
              </div>
            </Card>
          </div>
        )}
      </div>
    </EditorLeavingGuard>
  );
};

export default LinkingFlowStepPathsConfiguration;
