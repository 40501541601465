import classNames from "classnames";
import { useState } from "react";
import { Accordion, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import MergeCodeBlock from "../MergeCodeBlock";
import { getShortenedText } from "../utils/SharedComponentUtils";

const CodeContainer = styled(Col)`
  width: 250px !important;
  padding: 0px;
`;

const CodeBlock = styled(Col)<ToggleProps>`
  width: 275px !important;
  border-radius: 5px;
  display: flex;
  background: rgb(1, 22, 39);
  color: #60819c;
  height: ${(props) => (props.isOpen ? "55px" : "28px")};
`;

interface ToggleProps {
  isOpen: boolean;
}

const ToggleBackground = styled.div<ToggleProps>`
  width: 15px !important;
  height: ${(props) => (props.isOpen ? "49px" : "22px")};
  background-color: rgba(244, 245, 246, 0.32);
  border-radius: 0.3rem !important;
  margin-top: 3px;
  margin-bottom: 3px;
  align-items: center;
  padding-top: ${(props) => (props.isOpen ? "10px" : "0px")} !important;
`;

const PaddedChevron = styled.i<{ $isUp: boolean }>`
  color: white;
  opacity: 1;
`;

const FieldStatusRow = (code: string, shouldWrapLongLines: boolean) => {
  return (
    <>
      <CodeContainer>
        <MergeCodeBlock
          textToCopy={code}
          language={"python"}
          shouldExcludeCopyOption={true}
          shouldWrapLongLines={shouldWrapLongLines}
          customStyle={{
            lineHeight: "15px",
            fontSize: "10px",
            padding: "5px 10px",
            flexWrap: "wrap",
          }}
        >
          {code}
        </MergeCodeBlock>
      </CodeContainer>
    </>
  );
};

type CollapsableSectionProps = {
  children: JSX.Element;
  code: string;
};

const ExpandableCodePreview = ({ code }: CollapsableSectionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CodeBlock isOpen={isOpen}>
      <Row>
        {isOpen ? (
          <CodeContainer>{FieldStatusRow(getShortenedText(code, 93)[0], true)}</CodeContainer>
        ) : (
          <CodeContainer>{FieldStatusRow(getShortenedText(code, 35)[0], false)}</CodeContainer>
        )}

        <ToggleBackground isOpen={isOpen}>
          <Accordion.Toggle
            onClick={() => (code.length > 35 ? setIsOpen(!isOpen) : null)}
            className={classNames(code.length > 28 && "clickable")}
            as={Card.Text}
            eventKey="0"
            style={{ padding: "0px" }}
          >
            <PaddedChevron
              className={classNames("fe", isOpen ? "fe fe-chevron-up" : "fe fe-chevron-down")}
              $isUp={true}
            />
          </Accordion.Toggle>
        </ToggleBackground>
      </Row>
    </CodeBlock>
  );
};

export default ExpandableCodePreview;
