import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { APIEndpointPaginationConfigurationTypes } from "../../../../models/APIEndpointModels";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import PaginationConfigurationContext from "../../pagination-configuration-setup/context/PaginationConfigurationContext";
import PaginationConfigurationContextProvider from "../../pagination-configuration-setup/context/PaginationConfigurationContextProvider";
import {
  APIEndpointIntegrationBuilder,
  APIEndpointPaginationConfigurationInformation,
  PaginationConfigurationIntegrationBuilder,
  RateLimitConfigurationIntegrationBuilder,
} from "../../utils/Entities";
import APIEndpointContext from "../context/APIEndpointContext";
import APIEndpointContextProvider from "../context/APIEndpointContextProvider";
import useCreateOrPatchAPIEndpoint from "../hooks/useCreateOrPatchAPIEndpoint";
import useDeleteAPIEndpoint from "../hooks/useDeleteAPIEndpoint";
import APIEndpointExplain from "./APIEndpointExplain";
import APIEndpointHeader from "./APIEndpointHeader";
import APIEndpointSetupOptions from "./APIEndpointSetupOptions";
import { DiffModelTypeEnum } from "../../../../models/DiffModels";
import { transformRateLimitConfigurationDataForDiffModal } from "../../rate-limits/utils/helpers";
import { API_ENDPOINT_TYPE_SELECT_OPTIONS } from "../constants";
import clsx from "clsx";
import EditorLeavingGuard from "../../../shared/unsaved-changes/EditorLeavingGuard";

const API_ENDPOINT_SAVE_TEXT = "Save endpoint";
const API_ENDPOINT_CREATE_TEXT = "Create endpoint";
const API_ENDPOINT_DELETE_TEXT = "Delete endpoint";

type RouteParams = {
  apiEndpointID: string | undefined;
};

export type RateLimitFormCallback = () => RateLimitConfigurationIntegrationBuilder | null;

interface APIEndpointSetupProps {
  integrationID: string;
  setAPIEndpoints: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder[] | undefined>
  >;
  isLoadingAPIEndpoints: boolean;
  apiEndpoints?: APIEndpointIntegrationBuilder[] | undefined;
  isNewAPIEndpoint?: boolean;
  selectedAPIEndpoint?: APIEndpointIntegrationBuilder | undefined;
  setSelectedAPIEndpoint?: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder | undefined>
  >;
  isAutoScrollToRateLimits?: boolean;
}

const BaseAPIEndpointSetup = ({
  integrationID,
  setAPIEndpoints,
  isLoadingAPIEndpoints,
  isNewAPIEndpoint,
  selectedAPIEndpoint,
  setSelectedAPIEndpoint,
  isAutoScrollToRateLimits = false,
}: APIEndpointSetupProps) => {
  // context
  const {
    isRightPanelOpen,
    setIsRightPanelOpen,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();
  const {
    formAPIEndpointData,
    canSubmitAPIEndpoint,
    paginationConfigType,
    setPaginationConfigurations,
    paginationConfigurations,
    rateLimitConfigurationsToDelete,
    setRateLimitConfigurationsToDelete,
    setRateLimitConfigurations,
    doesAllowTimestampFiltering,
  } = useContext(APIEndpointContext);
  const { formPaginationConfigurationData } = useContext(PaginationConfigurationContext);

  useEffect(() => {
    return () => setIsRightPanelOpen(false);
  }, []);

  // consts derived from state
  const apiEndpointSubmitText = isNewAPIEndpoint
    ? API_ENDPOINT_CREATE_TEXT
    : API_ENDPOINT_SAVE_TEXT;

  // hooks
  const { createAPIEndpoint, patchAPIEndpoint, isLoadingSubmit } = useCreateOrPatchAPIEndpoint({
    integrationID,
    setAPIEndpoints,
    setSelectedAPIEndpoint,
    setPaginationConfigurations,
    setRateLimitConfigurations,
  });
  const { deleteAPIEndpoint, isLoadingDeleteAPIEndpoint } = useDeleteAPIEndpoint({
    integrationID,
    setAPIEndpoints,
    apiEndpointID: selectedAPIEndpoint?.id,
  });

  // rate limit form registration functions to collect data to be updated
  const [registrationFunctions, setRegistrationFunctions] = useState<
    Map<string, RateLimitFormCallback>
  >(new Map());

  const registerForm = useCallback((formID: string, formFunction: RateLimitFormCallback) => {
    setRegistrationFunctions((prev) => new Map(prev).set(formID, formFunction));
  }, []);

  const unregisterForm = useCallback((formID: string) => {
    setRegistrationFunctions((prev) => {
      const newReg = new Map(prev);
      newReg.delete(formID);
      return newReg;
    });
  }, []);

  // integration builder specific context
  const { setOnSubmit, setOnDelete, setNewStateForDiff } = useIntegrationBuilderContext();
  useIntegrationBuilderContext({
    submitButtonText: apiEndpointSubmitText,
    canSubmit: canSubmitAPIEndpoint,
    isLoadingSubmit: isLoadingSubmit,
    isLoadingDelete: isLoadingDeleteAPIEndpoint,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: false,
    shouldRenderDeleteButton: !isNewAPIEndpoint,
    deleteButtonText: API_ENDPOINT_DELETE_TEXT,
    modelTypeForDiff: DiffModelTypeEnum.API_ENDPOINT,
    shouldHideDiffModal: isNewAPIEndpoint,
  });

  // This updateDiffState updates the model state to be used in the diff modal.
  const updateDiffState = (
    apiEndpointData: APIEndpointIntegrationBuilder,
    paginationConfigData: PaginationConfigurationIntegrationBuilder | undefined,
    allRateLimitConfigurationFormDataForDiff: { [key: string]: any }
  ) => {
    const displayablePaginationConfigurationInformation: APIEndpointPaginationConfigurationInformation | null = apiEndpointData.pagination_configuration_information
      ? paginationConfigData
        ? {
            ...apiEndpointData.pagination_configuration_information,
            pagination_configuration_id: paginationConfigData.name,
            pagination_response_body_array_key_path_override: null,
          }
        : {
            ...apiEndpointData.pagination_configuration_information,
            pagination_configuration_id:
              paginationConfigurations?.find(
                (config) =>
                  config.id ===
                  apiEndpointData.pagination_configuration_information?.pagination_configuration_id
              )?.name ?? "Use default",
          }
      : null;

    const apiEndpointDiffData = {
      ...apiEndpointData,
      request_configuration: {
        ...apiEndpointData.request_configuration,
        timestamp_filter_configuration: {
          ...apiEndpointData.request_configuration.timestamp_filter_configuration,
          does_allow_timestamp_filtering: doesAllowTimestampFiltering,
        },
      },
      endpoint_type: apiEndpointData.endpoint_type
        ? API_ENDPOINT_TYPE_SELECT_OPTIONS.find(
            (option) => option.value === apiEndpointData.endpoint_type
          )?.title ?? null
        : "Single Request",
      pagination_configuration_information: displayablePaginationConfigurationInformation,
    };

    const rateLimitConfigurationIDsToDelete = rateLimitConfigurationsToDelete.map(
      (config) => config.id
    );
    let remainingRateLimitConfigurationData = allRateLimitConfigurationFormDataForDiff.filter(
      (rateLimitConfiguration: { [key: string]: any }) => {
        if (rateLimitConfiguration && rateLimitConfiguration.id) {
          return !rateLimitConfigurationIDsToDelete.includes(rateLimitConfiguration?.id);
        }
        return true;
      }
    );

    setNewStateForDiff({
      api_endpoint: apiEndpointDiffData,
      pagination_configuration: paginationConfigData,
      rate_limit_configurations: remainingRateLimitConfigurationData,
    });
  };

  const updateSetOnSubmit = (
    apiEndpointData: APIEndpointIntegrationBuilder,
    paginationConfigData: PaginationConfigurationIntegrationBuilder | undefined,
    allRateLimitConfigurationFormData: RateLimitConfigurationIntegrationBuilder[]
  ) => {
    isNewAPIEndpoint
      ? setOnSubmit(() => createAPIEndpoint(apiEndpointData, paginationConfigData))
      : setOnSubmit(() =>
          patchAPIEndpoint(
            apiEndpointData,
            paginationConfigData,
            allRateLimitConfigurationFormData,
            rateLimitConfigurationsToDelete
          )
        );
  };

  // Retrieves updated values from forms
  const retrieveUpdatedValues = (): [
    APIEndpointIntegrationBuilder | undefined,
    PaginationConfigurationIntegrationBuilder | undefined,
    RateLimitConfigurationIntegrationBuilder[],
    { [key: string]: any }[]
  ] => {
    const apiEndpointData = formAPIEndpointData();
    if (!apiEndpointData) {
      return [undefined, undefined, [], []];
    }

    // Retrieves updated RateLimitConfigurations
    let allRateLimitConfigurationFormData: RateLimitConfigurationIntegrationBuilder[] = [];
    let allRateLimitConfigurationFormDataForDiff: { [key: string]: any }[] = [];

    registrationFunctions.forEach(
      (formRateLimitConfigurationFunction, rateLimitConfigurationIdentifier) => {
        let rateLimitConfig = formRateLimitConfigurationFunction();
        if (rateLimitConfig) {
          allRateLimitConfigurationFormData.push(rateLimitConfig);
          const transformedConfig = transformRateLimitConfigurationDataForDiffModal(
            rateLimitConfig
          );
          allRateLimitConfigurationFormDataForDiff.push({
            ...transformedConfig,
            rate_limit_identifier: rateLimitConfigurationIdentifier,
          });
        }
      }
    );

    // Retrieves updated PaginationConfiguration data, only if user is creating new one
    let paginationConfigData =
      paginationConfigType === APIEndpointPaginationConfigurationTypes.NEW
        ? formPaginationConfigurationData() || undefined
        : undefined;

    return [
      apiEndpointData,
      paginationConfigData,
      allRateLimitConfigurationFormData,
      allRateLimitConfigurationFormDataForDiff,
    ];
  };

  // Updates both setOnSubmit and diff states
  const updateStates = () => {
    const [
      apiEndpointData,
      paginationConfigData,
      allRateLimitConfigurationFormData,
      allRateLimitConfigurationFormDataForDiff,
    ] = retrieveUpdatedValues();

    // Updates states
    if (apiEndpointData) {
      updateSetOnSubmit(apiEndpointData, paginationConfigData, allRateLimitConfigurationFormData);
      updateDiffState(
        apiEndpointData,
        paginationConfigData,
        allRateLimitConfigurationFormDataForDiff
      );
    }

    // Clear list of rate limit configurations to delete
    setRateLimitConfigurationsToDelete([]);
  };

  // Sets up the onSubmit function for the API endpoint
  // dependencies on formAPIEndpointData and formPaginationConfigurationData so we have the most up to date info
  // Also updates diff state
  useEffect(updateStates, [
    formAPIEndpointData,
    formPaginationConfigurationData,
    registrationFunctions,
    setRateLimitConfigurationsToDelete,
  ]);

  // Sets up the onDelete function for the API endpoint
  // dependency on selectedAPIEndpoint so we know what endpoint to delete
  useEffect(() => {
    setOnDelete(deleteAPIEndpoint);
  }, [setOnDelete, selectedAPIEndpoint]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div className={clsx(isRightPanelOpen && "my-10 ml-10", "pb-4")}>
        <APIEndpointHeader isNewAPIEndpoint={!!isNewAPIEndpoint} integrationID={integrationID} />
        <APIEndpointExplain />
        {!isLoadingAPIEndpoints && (
          <APIEndpointSetupOptions
            selectedAPIEndpoint={selectedAPIEndpoint}
            registerForm={registerForm}
            unregisterForm={unregisterForm}
            isAutoScrollToRateLimits={isAutoScrollToRateLimits}
          />
        )}
      </div>
    </EditorLeavingGuard>
  );
};

const APIEndpointSetup = ({
  integrationID,
  apiEndpoints,
  setAPIEndpoints,
  isLoadingAPIEndpoints,
}: APIEndpointSetupProps) => {
  const { apiEndpointID } = useParams<RouteParams>();

  const [selectedAPIEndpoint, setSelectedAPIEndpoint] = useState(
    apiEndpoints?.find((apiEndpoint) => apiEndpoint.id === apiEndpointID?.split("?")[0])
  );
  const [isAutoScrollToRateLimits, setIsAutoScrollToRateLimits] = useState<boolean>(false);

  useEffect(() => {
    setSelectedAPIEndpoint(
      apiEndpoints?.find((apiEndpoint) => apiEndpoint.id === apiEndpointID?.split("?")[0])
    );
  }, [apiEndpointID, apiEndpoints]);

  const location = useLocation();

  // Retrieve this page's query param for auto-scrolling, if it exists
  useEffect(() => {
    if (selectedAPIEndpoint) {
      const params = new URLSearchParams(location.search);
      const scrollTo = params.get("scrollTo");
      setIsAutoScrollToRateLimits(scrollTo === "rate-limit-configurations");
    }
  }, [selectedAPIEndpoint, location]);

  return (
    <PaginationConfigurationContextProvider
      integrationID={integrationID}
      selectedPaginationConfiguration={undefined}
    >
      <APIEndpointContextProvider
        integrationID={integrationID}
        selectedAPIEndpoint={selectedAPIEndpoint}
      >
        <BaseAPIEndpointSetup
          integrationID={integrationID}
          apiEndpoints={apiEndpoints}
          setAPIEndpoints={setAPIEndpoints}
          isLoadingAPIEndpoints={isLoadingAPIEndpoints}
          isNewAPIEndpoint={!!!apiEndpointID}
          selectedAPIEndpoint={selectedAPIEndpoint}
          setSelectedAPIEndpoint={setSelectedAPIEndpoint}
          isAutoScrollToRateLimits={isAutoScrollToRateLimits}
        />
      </APIEndpointContextProvider>
    </PaginationConfigurationContextProvider>
  );
};

export default APIEndpointSetup;
