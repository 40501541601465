import MergeModal from "../../../shared/MergeModal";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useState } from "react";
import { MappingTestRequestMock } from "../../../../models/MappingTests";
import { useParams } from "react-router-dom";
import { HTTPMethod } from "../../../../models/HTTPMethods";
import { Button, TextField, Select, ButtonVariant } from "@merge-api/merge-javascript-shared";
import Box from "@mui/material/Box";
import { Plus } from "lucide-react";
import { isValidXML } from "../../../../utils";

type Props = {
  show?: boolean;
  onHide: () => void;
  onConfirm?: () => void;
  setShowAddRequestMockModal: (showAddRequestMockModal: boolean) => void;
  onSubmit: (requestMock: MappingTestRequestMock) => void;
  requestMock?: MappingTestRequestMock;
};

interface FormData {
  name: string;
  url: string;
  response_headers: string;
  request_body: any | null;
  response_body: string | null;
  request_headers: string;
  response_code: number;
}

export default function AddMappingTestRequestMockModal({
  onHide,
  setShowAddRequestMockModal,
  onSubmit,
  requestMock,
}: Props) {
  const { testID } = useParams<{ testID: string }>();
  const { register, handleSubmit, errors } = useForm();
  const [isRequestHeaderJsonValid, setIsRequestHeaderJsonValid] = useState(true);
  const [isRequestHeaderXMLValid, setIsRequestHeaderXMLValid] = useState(true);
  const [isRequestBodyJsonValid, setIsRequestBodyJsonValid] = useState(true);
  const [isRequestBodyXMLValid, setIsRequestBodyXMLValid] = useState(true);
  const [isResponseHeaderJsonValid, setIsResponseHeaderJsonValid] = useState(true);
  const [isResponseHeaderXMLValid, setIsResponseHeaderXMLValid] = useState(true);
  const [isResponseBodyJsonValid, setIsResponseBodyJsonValid] = useState(true);
  const [isResponseBodyXMLValid, setIsResponseBodyXMLValid] = useState(true);
  const [method, setMethod] = useState<HTTPMethod | null>(requestMock?.method ?? HTTPMethod.GET);

  const createRequestMock = (formData: FormData) => {
    const newRequestMockInfo = {
      ...formData,
      name: formData?.name ?? requestMock?.name,
      is_outgoing: true,
      edited_at: new Date().toISOString(),
      blueprint: null,
      method: method ? method : HTTPMethod.GET,
      request_body_type: formData?.request_body ? "STRING" : null,
      response_body_type: formData?.response_body ? "STRING" : null,
      request_body: formData?.request_body ? formData?.request_body : null,
      request_headers: formData?.request_headers ? JSON.parse(formData?.request_headers) : {},
      response_headers: formData?.response_headers ? JSON.parse(formData?.response_headers) : {},
      original_response_body: "",
      response_body_file: null,
      id: requestMock?.id ?? null,
      response_code: formData?.response_code ?? 200,
      test_version: testID,
      download_link: null,
      file_size: null,
    };

    onSubmit(newRequestMockInfo);
    setShowAddRequestMockModal(false);
  };

  const validateJson = (jsonString: any) => {
    if ([null, undefined].includes(jsonString)) return true;
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  };

  const HTTP_METHOD_OPTIONS = [...Object.values(HTTPMethod)];

  return (
    <MergeModal show={true} onHide={onHide}>
      <Box
        component="form"
        onSubmit={handleSubmit(createRequestMock)}
        className="flex flex-col gap-y-6"
        autoComplete="off"
      >
        <TextField
          name="name"
          placeholder="Request Mock Name"
          type="text"
          ref={register({ required: true, minLength: 1, maxLength: 128 })}
          error={!!errors.name}
          errorText="Please enter a name."
          variant="bordered"
          defaultValue={requestMock?.name || undefined}
        />
        <div className="flex flex-row items-start gap-x-3">
          <div className="w-1/4">
            <Select
              name="method"
              placeholder="Method"
              clearable={false}
              value={method}
              options={HTTP_METHOD_OPTIONS}
              getOptionLabel={(option: HTTPMethod) => {
                return option;
              }}
              onChange={(_: any, methodPicked: HTTPMethod | null) => {
                setMethod(methodPicked);
              }}
            />
          </div>
          <div className="w-3/4">
            <TextField
              placeholder="URL"
              name="url"
              type="text"
              ref={register({ required: true, minLength: 1, maxLength: 1000 })}
              error={!!errors.url}
              errorText="Please enter a valid url."
              variant="bordered"
              defaultValue={requestMock?.url || undefined}
            />
          </div>
        </div>
        <TextField
          label="Request Headers"
          name="request_headers"
          multiline
          rows={2}
          onChange={(e) => {
            setIsRequestHeaderJsonValid(validateJson(e.target.value));
            setIsRequestHeaderXMLValid(isValidXML(e.target.value));
          }}
          className={classNames({ "is-invalid": !isRequestHeaderJsonValid })}
          ref={register({ required: false, minLength: 1 })}
          error={!isRequestHeaderJsonValid && !isRequestHeaderXMLValid}
          errorText="Please enter a valid JSON or XML."
          variant="bordered-code"
          defaultValue={JSON.stringify(requestMock?.request_headers) || undefined}
        />
        <TextField
          label="Request Body"
          name="request_body"
          multiline
          rows={2}
          onChange={(e) => {
            setIsRequestBodyJsonValid(validateJson(e.target.value));
            setIsRequestBodyXMLValid(isValidXML(e.target.value));
          }}
          className={classNames({ "is-invalid": !isRequestBodyJsonValid })}
          ref={register({ required: false, minLength: 1 })}
          error={!isRequestBodyJsonValid && !isRequestBodyXMLValid}
          errorText="Please enter a valid JSON or XML."
          variant="bordered-code"
          defaultValue={requestMock?.request_body || undefined}
        />
        <TextField
          label="Response Headers"
          name="response_headers"
          multiline
          rows={2}
          onChange={(e) => {
            setIsResponseHeaderJsonValid(validateJson(e.target.value));
            setIsResponseHeaderXMLValid(isValidXML(e.target.value));
          }}
          className={classNames({ "is-invalid": !isResponseHeaderJsonValid })}
          ref={register({ required: true, minLength: 1 })}
          error={!isResponseHeaderJsonValid && !isResponseHeaderXMLValid}
          errorText="Please enter a valid JSON or XML."
          variant="bordered-code"
          defaultValue={
            JSON.stringify(requestMock?.response_headers) ||
            JSON.stringify({ "Content-Type": "application/json; charset=utf-8" })
          }
        />
        <TextField
          label="Response Body"
          name="response_body"
          multiline
          rows={8}
          onChange={(e) => {
            setIsResponseBodyJsonValid(validateJson(e.target.value));
            setIsResponseBodyXMLValid(isValidXML(e.target.value));
          }}
          className={classNames({ "is-invalid": !isResponseBodyJsonValid })}
          ref={register({ required: true, minLength: 1 })}
          error={!isResponseBodyJsonValid && !isResponseBodyXMLValid}
          errorText="Please enter a valid JSON or XML."
          variant="bordered-code"
          defaultValue={requestMock?.response_body || undefined}
        />
        <TextField
          label="Response Code"
          name="response_code"
          ref={register({ required: false, minLength: 3, maxLength: 3 })}
          error={!!errors.response_code}
          errorText="Please enter a valid response code."
          variant="bordered-code"
          defaultValue={requestMock?.response_code || 200}
        />
        <div className="flex flex-row gap-x-1">
          {requestMock ? (
            <Button type="submit" size="md">
              Save
            </Button>
          ) : (
            <Button type="submit" size="md" leftIcon={<Plus size={16} />}>
              Request Mock
            </Button>
          )}
          <Button
            size="md"
            variant={ButtonVariant.TextBlack}
            className="border-0"
            onClick={() => setShowAddRequestMockModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </MergeModal>
  );
}
