import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { APIEndpointMethod } from "../../../models/Entities";
import { showErrorToast, showSuccessToast } from "../../shared/Toasts";
import { navigateToIntegrationBuilderPostIntegrationInitialization } from "../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";
import { IntegrationForIntegrationBuilder, IntegrationLogos } from "../utils/Entities";

interface IntegrationForIntegrationBuilderParams {
  requestBodyForIntegration: IntegrationForIntegrationBuilder | undefined;
  requestBodyForLogos?: IntegrationLogos;
}

const useCreateOrPatchIntegration = ({
  requestBodyForIntegration,
  requestBodyForLogos,
}: IntegrationForIntegrationBuilderParams) => {
  // loading states
  const [isLoadingCreateOrPatchIntegration, setIsLoadingCreateOrPatchIntegration] = useState(false);
  const [isLoadingPatchFields, setIsLoadingPatchFields] = useState(false);
  const [isLoadingPatchLogos, setIsLoadingPatchLogos] = useState(false);

  // context
  const { integration, setIntegration, resetDiffStates } = useIntegrationBuilderContext();
  const history = useHistory();

  // update loading state, triggered when updating logos
  useEffect(() => {
    setIsLoadingCreateOrPatchIntegration(isLoadingPatchFields || isLoadingPatchLogos);
  }, [isLoadingPatchFields, isLoadingPatchLogos]);

  // API requests
  const patchFields = (integrationID: string, setIsLoading: (value: boolean) => void) => {
    setIsLoading(true);
    requestBodyForIntegration = preProcessRequestBodyForIntegration(requestBodyForIntegration);
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}`,
      method: APIEndpointMethod.PATCH,
      body: requestBodyForIntegration,
      onResponse: (responseData: IntegrationForIntegrationBuilder) => {
        showSuccessToast("Integration successfully updated!");
        setIntegration(responseData);
        setIsLoading(false);
      },
      onError: () => {
        showErrorToast("Failed to update your integration.");
        setIsLoading(false);
      },
    });
  };

  const patchLogos = (integrationID: string) => {
    setIsLoadingPatchLogos(true);
    fetchWithAuth({
      path: `/integrations/integration-builder/integration/${integrationID}/upload-images`,
      method: APIEndpointMethod.PATCH,
      headers: {
        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary",
      },
      body: requestBodyForLogos,
      onResponse: (responseData: IntegrationForIntegrationBuilder) => {
        showSuccessToast("Logos successfully updated!");
        setIntegration(responseData);
        setIsLoadingPatchLogos(false);
      },
      onError: () => {
        showErrorToast("Failed to update logos.");
        setIsLoadingPatchLogos(false);
      },
    });
  };

  // pre-format request body
  const preProcessRequestBodyForIntegration = (
    requestBody: IntegrationForIntegrationBuilder | undefined
  ) => {
    if (requestBody?.color) {
      if (requestBody.color.length <= 1) {
        requestBody.color = "#080808";
      }
    }
    return requestBody;
  };

  const createIntegration = () => {
    setIsLoadingCreateOrPatchIntegration(true);
    requestBodyForIntegration = preProcessRequestBodyForIntegration(requestBodyForIntegration);
    fetchWithAuth({
      path: "/integrations/integration-builder/integration",
      method: APIEndpointMethod.POST,
      body: requestBodyForIntegration,
      onResponse: (responseData: IntegrationForIntegrationBuilder) => {
        showSuccessToast("Integration successfully created!");
        setIntegration(responseData);
        if (responseData.id) {
          // reset diff states
          resetDiffStates();
          navigateToIntegrationBuilderPostIntegrationInitialization(history, responseData.id);
        }
        setIsLoadingCreateOrPatchIntegration(false);
      },
      onError: () => {
        showErrorToast("Failed to create your integration.");
        setIsLoadingCreateOrPatchIntegration(false);
      },
    });
  };

  const patchIntegration = (integrationID: string) => {
    // Update logos and fields
    if (requestBodyForLogos && Object.keys(requestBodyForLogos).length > 0) {
      setIsLoadingCreateOrPatchIntegration(true);
      setIsLoadingPatchFields(true);
      setIsLoadingPatchLogos(true);
      patchFields(integrationID, setIsLoadingPatchFields);
      patchLogos(integrationID);
    }
    // Update fields only
    else {
      patchFields(integrationID, setIsLoadingCreateOrPatchIntegration);
    }
  };

  return { createIntegration, patchIntegration, isLoadingCreateOrPatchIntegration, integration };
};

export default useCreateOrPatchIntegration;
