import {
  AddStepRelation,
  BlueprintRunnerStepLogs,
  BlueprintVersionPublishState,
  JSONObjectSchema,
} from "../../models/Blueprints";

export enum ScraperStepType {
  CLICK = "SCRAPER_STEP_TYPE_CLICK",
  INPUT = "SCRAPER_STEP_TYPE_INPUT",
  GOTO = "SCRAPER_STEP_TYPE_GOTO",
  READ_AND_SAVE = "SCRAPER_STEP_TYPE_READ_AND_SAVE",
  READ_FROM_CLIPBOARD = "SCRAPER_STEP_TYPE_READ_FROM_CLIPBOARD",
  WAIT = "SCRAPER_STEP_TYPE_WAIT",
  PRESS_KEY = "SCRAPER_STEP_TYPE_PRESS_KEY",
  READ_STORAGE_STATE = "SCRAPER_STEP_TYPE_READ_STORAGE_STATE",
  CONDITIONAL_ACTION = "SCRAPER_STEP_TYPE_CONDITIONAL_ACTION",
  RAISE_EXCEPTION = "SCRAPER_STEP_TYPE_RAISE_EXCEPTION",
  GET_USER_INPUT = "SCRAPER_STEP_TYPE_GET_USER_INPUT",
  ARRAY_LOOP = "SCRAPER_STEP_TYPE_ARRAY_LOOP",
  QUERY_AND_EVAL = "SCRAPER_STEP_TYPE_QUERY_AND_EVAL",
  QUERY_SELECTOR_LOOP = "SCRAPER_STEP_TYPE_QUERY_SELECTOR_LOOP",
  SET_VARIABLE = "SCRAPER_STEP_TYPE_SET_VARIABLE",
  SET_KEY_VALUE = "SCRAPER_STEP_TYPE_SET_KEY_VALUE",
  APPEND_TO_ARRAY = "SCRAPER_STEP_TYPE_APPEND_TO_ARRAY",
  CONCATENATE_STRINGS = "SCRAPER_STEP_TYPE_CONCATENATE_STRINGS",
  REGEX = "SCRAPER_STEP_TYPE_REGEX",
  SAVE_CURRENT_URL = "SCRAPER_STEP_TYPE_SAVE_CURRENT_URL",
  CLICK_AND_DOWNLOAD = "SCRAPER_STEP_TYPE_CLICK_AND_DOWNLOAD",
  CLICK_AND_DOWNLOAD_CSV = "SCRAPER_STEP_TYPE_CLICK_AND_DOWNLOAD_CSV",
  EXPECT_DOWNLOAD_CSV = "SCRAPER_STEP_TYPE_EXPECT_DOWNLOAD_CSV",
  SAVE_NETWORK_RESPONSE = "SCRAPER_STEP_TYPE_SAVE_NETWORK_RESPONSE",
  SELECT_OPTION = "SCRAPER_STEP_TYPE_SELECT_OPTION",
  WAIT_FOR_SELECTOR = "SCRAPER_STEP_TYPE_WAIT_FOR_SELECTOR",
  IF_ELSE = "SCRAPER_STEP_TYPE_IF_ELSE",
  CUSTOM_FUNCTION = "SCRAPER_STEP_TYPE_CUSTOM_FUNCTION",
  TAKE_SCREENSHOT = "SCRAPER_STEP_TYPE_TAKE_SCREENSHOT",
  SAVE_MFA_SECRET_KEY = "SCRAPER_STEP_TYPE_SAVE_MFA_SECRET_KEY",
  GENERATE_MFA_CODE_FROM_SECRET_KEY = "SCRAPER_STEP_TYPE_GENERATE_MFA_CODE_FROM_SECRET_KEY",
}

export enum ScraperValueType {
  NONE = "SCRAPER_VALUE_TYPE_NONE",
  CONSTANT = "SCRAPER_VALUE_TYPE_CONSTANT",
  ELEMENT_SELECTOR = "SCRAPER_VALUE_TYPE_ELEMENT_SELECTOR",
  GLOBAL_VARIABLE = "SCRAPER_VALUE_TYPE_GLOBAL_VARIABLE",
  VARIABLE = "SCRAPER_VALUE_TYPE_VARIABLE",
  NESTED_PARAMETER_VALUES = "SCRAPER_VALUE_TYPE_NESTED_PARAMETER_VALUES",
  RETURN_VALUE = "SCRAPER_VALUE_TYPE_RETURN_VALUE",
}

export enum ScraperElementSelectorType {
  TEXT_MATCH = "SCRAPER_ELEMENT_SELECTOR_TYPE_TEXT_MATCH",
  CSS_MATCH = "SCRAPER_ELEMENT_SELECTOR_TYPE_CSS_MATCH",
  MATCHES_ALL_SELECTORS = "SCRAPER_ELEMENT_SELECTOR_TYPE_MATCHES_ALL_SELECTORS",
  MATCHES_ANY_SELECTOR = "SCRAPER_ELEMENT_SELECTOR_TYPE_MATCHES_ANY_SELECTOR",
}

export interface ScraperConstantValue extends ScraperValueBase<ScraperValueType.CONSTANT> {
  constant: string | boolean;
}

export interface ScraperNoneValue extends ScraperValueBase<ScraperValueType.NONE> {}
export interface ScraperElementSelectorValue
  extends ScraperValueBase<ScraperValueType.ELEMENT_SELECTOR> {
  tag: string;
  text?: string;
  outerHTML: string;
  selector?: string;
}

export interface ScraperGlobalVariableValue
  extends ScraperValueBase<ScraperValueType.GLOBAL_VARIABLE> {
  global_variable_key?: string;
  traversal_path?: Array<string>;
  return_schema_path?: Array<string | number>;
  request_return_value_path?: Array<string | number>;
}

export interface ScraperVariableValue extends ScraperValueBase<ScraperValueType.VARIABLE> {
  key: string;
}

export interface ScraperNestedParameterValues
  extends ScraperValueBase<ScraperValueType.NESTED_PARAMETER_VALUES> {
  nested_parameter_values: {
    [key: string]: ScraperValue;
  };
}

export interface ScraperReturnValueValue extends ScraperValueBase<ScraperValueType.RETURN_VALUE> {
  step_id: string;
  traversal_path: Array<string | number>;
  return_schema_path: Array<string | number>;
}

export interface ScraperValueBase<V extends ScraperValueType> {
  value_type: V;
}

export type ScraperValue =
  | ScraperConstantValue
  | ScraperElementSelectorValue
  | ScraperGlobalVariableValue
  | ScraperReturnValueValue
  | ScraperVariableValue
  | ScraperNestedParameterValues
  | ScraperNoneValue;

export type ScraperStepPath = Array<ScraperStep>;
export type ScraperStepPaths<P extends string> = Record<P, ScraperStepPath>;

export interface ScraperStepBase<
  T extends ScraperStepType,
  V extends Record<string, ScraperValue>,
  P extends string | null
> {
  id: string;
  hasCollapsedSubsteps?: boolean;
  isCollapsed?: boolean;
  step_type: T;
  parameter_values: V;
  paths?: P extends string ? ScraperStepPaths<P> : undefined;
  step_note?: string | null;
}

export type ScraperClickStep = ScraperStepBase<
  ScraperStepType.CLICK,
  {
    element_selector: ScraperElementSelectorValue;
    should_click_all: ScraperConstantValue;
  },
  null
>;
export type ScraperInputStep = ScraperStepBase<
  ScraperStepType.INPUT,
  {
    input_value: ScraperValue;
    should_clear_before_inputting: ScraperConstantValue;
    should_input_immediately: ScraperConstantValue;
    element_selector: ScraperElementSelectorValue;
  },
  null
>;
export type ScraperGotoStep = ScraperStepBase<
  ScraperStepType.GOTO,
  { url: ScraperConstantValue },
  null
>;
export type ScraperReadAndSaveStep = ScraperStepBase<
  ScraperStepType.READ_AND_SAVE,
  { element_selector: ScraperElementSelectorValue },
  null
>;
export type ScraperReadFromClipboardStep = ScraperStepBase<
  ScraperStepType.READ_FROM_CLIPBOARD,
  {},
  null
>;
export type ScraperWaitStep = ScraperStepBase<
  ScraperStepType.WAIT,
  { timeout: ScraperConstantValue },
  null
>;
export type ScraperPressKeyStep = ScraperStepBase<
  ScraperStepType.PRESS_KEY,
  { key: ScraperConstantValue },
  null
>;
export type ScraperReadStorageStateStep = ScraperStepBase<
  ScraperStepType.READ_STORAGE_STATE,
  {},
  null
>;
export type ScraperConditionalActionStep = ScraperStepBase<
  ScraperStepType.CONDITIONAL_ACTION,
  {},
  null
>;
export type ScraperRaiseExceptionStep = ScraperStepBase<
  ScraperStepType.RAISE_EXCEPTION,
  {
    arguments: ScraperNestedParameterValues;
    exception_text: ScraperConstantValue;
    should_retry: ScraperConstantValue;
    exception_type: ScraperConstantValue;
  },
  null
>;
export type ScraperGetUserInputStep = ScraperStepBase<
  ScraperStepType.GET_USER_INPUT,
  {
    integration_setup_step_type: ScraperConstantValue;
    condition_for_success_value1: ScraperConstantValue;
    condition_for_success_comparator: ScraperConstantValue;
    condition_for_success_value2: ScraperConstantValue;
    store_input_as_var: ScraperConstantValue;
    should_store_session_state: ScraperConstantValue;
    timeout_ms: ScraperConstantValue;
    failure_action: ScraperConstantValue;
    max_retries: ScraperConstantValue;
    prompt: ScraperConstantValue;
  },
  "true"
>;
export type ScraperQueryAndEvalStep = ScraperStepBase<
  ScraperStepType.QUERY_AND_EVAL,
  {
    element_selector: ScraperElementSelectorValue;
    should_fail_if_not_found: ScraperConstantValue;
    should_query_all: ScraperConstantValue;
    eval_expression: ScraperConstantValue;
  },
  null
>;
export type ScraperArrayLoopStep = ScraperStepBase<
  ScraperStepType.ARRAY_LOOP,
  { array: ScraperValue },
  "true"
>;
export type ScraperSetVariableStep = ScraperStepBase<
  ScraperStepType.SET_VARIABLE,
  {
    key: ScraperConstantValue;
    value_type: ScraperConstantValue;
    value: ScraperValue;
  },
  null
>;

export type ScraperSetKeyValueStep = ScraperStepBase<
  ScraperStepType.SET_KEY_VALUE,
  {
    object: ScraperVariableValue;
    value_key: ScraperConstantValue;
    value: ScraperValue;
  },
  null
>;

export type ScraperAppendToArrayStep = ScraperStepBase<
  ScraperStepType.APPEND_TO_ARRAY,
  { array: ScraperVariableValue; value: ScraperValue },
  null
>;

export type ScraperConcatenateStringsStep = ScraperStepBase<
  ScraperStepType.CONCATENATE_STRINGS,
  Record<string, ScraperValue>,
  null
>;

export type ScraperRegexStep = ScraperStepBase<
  ScraperStepType.REGEX,
  { string: ScraperValue; pattern: ScraperValue },
  null
>;

export type ScraperQuerySelectorLoopStep = ScraperStepBase<
  ScraperStepType.QUERY_SELECTOR_LOOP,
  {
    element_selector: ScraperElementSelectorValue;
    action: ScraperConstantValue;
  },
  "true"
>;

export type ScraperClickandDownloadStep = ScraperStepBase<
  ScraperStepType.CLICK_AND_DOWNLOAD,
  {
    element_selector: ScraperElementSelectorValue;
    action: ScraperConstantValue;
  },
  null
>;

export type ScraperClickandDownloadCSVStep = ScraperStepBase<
  ScraperStepType.CLICK_AND_DOWNLOAD_CSV,
  {
    element_selector: ScraperElementSelectorValue;
    action: ScraperConstantValue;
    response_json_schema: ScraperConstantValue;
  },
  null
>;

export type ScraperExpectDownloadCSVStep = ScraperStepBase<
  ScraperStepType.EXPECT_DOWNLOAD_CSV,
  {
    response_json_schema: ScraperConstantValue;
    timeout_override: ScraperConstantValue;
  },
  null
>;

export type ScraperSaveNetworkResponseStep = ScraperStepBase<
  ScraperStepType.SAVE_NETWORK_RESPONSE,
  {
    response_json_schema: ScraperConstantValue;
    should_save_all_matches_as_list: ScraperConstantValue;
    json_schema: ScraperConstantValue;
    should_log_sentry_error_if_no_match: ScraperConstantValue;
  },
  null
>;

export type ScraperSaveCurrentURLStep = ScraperStepBase<ScraperStepType.SAVE_CURRENT_URL, {}, null>;

export type ScraperTakeScreenshotStep = ScraperStepBase<ScraperStepType.TAKE_SCREENSHOT, {}, null>;

export type ScraperSelectOptionStep = ScraperStepBase<
  ScraperStepType.SELECT_OPTION,
  {
    select_element_selector: ScraperElementSelectorValue;
    option: ScraperValue;
  },
  null
>;

export type ScraperWaitForSelectorStep = ScraperStepBase<
  ScraperStepType.WAIT_FOR_SELECTOR,
  {
    element_selector: ScraperElementSelectorValue;
    should_continue_after_timeout_if_not_found: ScraperConstantValue;
  },
  null
>;

export type ScraperIfElseStep = ScraperStepBase<
  ScraperStepType.IF_ELSE,
  { value1: ScraperValue; comparator: ScraperConstantValue; value2: ScraperValue },
  "true" | "false"
>;

export type ScraperCustomFunctionStep = ScraperStepBase<
  ScraperStepType.CUSTOM_FUNCTION,
  {
    code: ScraperConstantValue;
    arguments: ScraperNestedParameterValues;
  },
  null
>;

export type ScraperSaveMFASecretKeyStep = ScraperStepBase<
  ScraperStepType.SAVE_MFA_SECRET_KEY,
  {
    mfa_secret_key: ScraperValue;
  },
  null
>;

export type ScraperGenerateMFACodeFromSecretKeyStep = ScraperStepBase<
  ScraperStepType.GENERATE_MFA_CODE_FROM_SECRET_KEY,
  {},
  null
>;

export type ScraperStep =
  | ScraperClickStep
  | ScraperInputStep
  | ScraperGotoStep
  | ScraperReadAndSaveStep
  | ScraperWaitStep
  | ScraperPressKeyStep
  | ScraperReadFromClipboardStep
  | ScraperReadStorageStateStep
  | ScraperConditionalActionStep
  | ScraperRaiseExceptionStep
  | ScraperGetUserInputStep
  | ScraperQueryAndEvalStep
  | ScraperArrayLoopStep
  | ScraperSetVariableStep
  | ScraperSetKeyValueStep
  | ScraperAppendToArrayStep
  | ScraperConcatenateStringsStep
  | ScraperRegexStep
  | ScraperQuerySelectorLoopStep
  | ScraperSaveCurrentURLStep
  | ScraperClickandDownloadStep
  | ScraperClickandDownloadCSVStep
  | ScraperExpectDownloadCSVStep
  | ScraperSaveNetworkResponseStep
  | ScraperSelectOptionStep
  | ScraperWaitForSelectorStep
  | ScraperIfElseStep
  | ScraperCustomFunctionStep
  | ScraperTakeScreenshotStep
  | ScraperSaveMFASecretKeyStep
  | ScraperGenerateMFACodeFromSecretKeyStep;

export interface ScraperGhostStep {
  newStepRelation: AddStepRelation;
  relatedStepID: string;
  pathKey?: string;
  template: "ghost";
  id: string;
}

export type ScraperVersion = {
  id: string;
  version_id: string;
  name: string;
  global_var_json_schema?: JSONObjectSchema;
  data_type: string;
  steps: Array<ScraperStep>;
  // To satisfy BPE typescript
  template?: never;
  max_timeout: string;
  most_recent_blueprint_version_id: string;
  should_run_in_headless_mode: boolean;
  should_log_non_errors: boolean;
  should_solve_captchas: boolean;
  is_draft: boolean;
  is_staged: boolean;
  created_at: string;
  publish_state: BlueprintVersionPublishState;
  is_published: boolean;
};

export type Scraper = {
  id: string;
  name: string;
  global_var_json_schema?: JSONObjectSchema;
  data_type: string;
  steps: Array<ScraperStep>;
  // To satisfy BPE typescript
  template?: never;
  max_timeout: string;
  most_recent_blueprint_version_id: string;
  most_recent_scraper_version_id: string;
  should_run_in_headless_mode: boolean;
  should_log_non_errors: boolean;
  should_solve_captchas: boolean;
};

export enum ScraperRequestEventType {
  CLICK = "click",
  CHANGE = "change",
  KEYPRESS = "keypress",
}

export type ScraperRequest = {
  eventType: ScraperRequestEventType;
  keyCode?: number;
  eventTarget: {
    attributes: Array<{ name: string; value: string }>;
    innerHTML: string;
    outerHTML: string;
    innerText: string;
    outerText: string;
    localName: string;
    classList: Array<string>;
    className: string;
    selector: string;
    value: string;
    name: string;
    title: string;
  };
};

export type ScraperExecutionLogs = {
  step_logs: BlueprintRunnerStepLogs;
};

export enum ScraperExecutionStatus {
  RUNNING = "RUNNING",
  WAITING_ON_INPUT = "WAITING_ON_INPUT",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}

export enum ScraperDataType {
  AUTH_DATA = "AUTH_DATA",
  COMMON_MODEL_DATA = "COMMON_MODEL_DATA",
}

export enum ScraperConditionalAction {
  GET_MFA_CODE = "GET_MFA_CODE",
  RAISE_EXCEPTION = "RAISE_EXCEPTION",
  RAISE_ADMIN_PERMISSION_EXCEPTION = "RAISE_ADMIN_PERMISSION_EXCEPTION",
  EXIT_WITH_200 = "EXIT_WITH_200",
}

export enum ScraperExceptionType {
  BAD_REQUEST = "BAD_REQUEST", // 400
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR", // 500
  REQUIRES_PASSWORD_RESET = "REQUIRES_PASSWORD_RESET", // 702
  MISSING_PERMISSIONS = "MISSING_PERMISSIONS", // 419
  NEEDS_MFA_CODE = "NEEDS_MFA_CODE", // 418
}

export enum ScraperUserInputFailedAction {
  EXIT_WITH_200 = "EXIT_WITH_200",
  RAISE_EXCEPTION = "RAISE_EXCEPTION",
  RAISE_MFA_CODE_EXCEPTION = "RAISE_MFA_CODE_EXCEPTION",
}

export enum ScraperGetUserInputIntegrationSetupStepType {
  STEP_TYPE_MFA_CODE = "STEP_TYPE_MFA_CODE",
  STEP_TYPE_SECURITY_QUESTION = "STEP_TYPE_SECURITY_QUESTION",
}

export interface ScraperTestResults {
  exit_code?: number;
  scraper_results?: {
    exit_data: ScraperExecutionLogs;
  };
  blueprint_results?: {
    exit_data: object;
  };
}

export interface SecurityQuestion {
  question: string;
  answer?: string;
}
export interface ScraperExecutionWithIO {
  id: string;
  is_io_enabled: boolean;
  created_at: string;
  finished_at: string | undefined;
  is_test_run: boolean;
  status: ScraperExecutionStatus;
  integration_setup_step_type?: ScraperGetUserInputIntegrationSetupStepType;
  prompt?: string;
  results?: ScraperTestResults;
}

export interface ScraperExecution {
  end_time: string;
  start_time: string;
  exit_code: string;
  linked_account: string;
  id: string;
}

export interface ScraperExecutionPreview {
  id: string;
  linked_account: string;
  created_at?: string;
  modified_at?: string;
  start_time?: string;
  end_time?: string;
  exit_code?: string;
  is_io_enabled?: string;
  is_test_run?: boolean;
  trigger_type?: string;
  queue_name?: string;
  scraper?: string;
  integration_issue?: string;
  task_id?: string;
  celery_worker?: string;
  browser?: string;
}
