import classNames from "classnames";
import { Card, Col, Row } from "react-bootstrap";
import { BlueprintParameterValue, BlueprintStepType } from "../../models/Blueprints";
import StepCodePreview from "./stepcard/StepCodePreview";
import { getShortenedText } from "./utils/SharedComponentUtils";
import DeprecatedH4 from "../deprecated/DeprecatedH4";
import { Badge, Tooltip, Text } from "@merge-api/merge-javascript-shared";
import { camelCaseToSentenceCase } from "../../utils";

const MAX_PARAM_LENGTH = 36;
const MAX_TITLE_LENGTH = 32;

interface Props {
  cardClassName?: string;
  title: string;
  subtitle: string;
  stepIcon?: string;
  stepParameterValues?: Record<string, BlueprintParameterValue>;
  stepImg?: string;
  stepType?: BlueprintStepType | string;
  onClick?: () => void;
  onContextMenu?: (e: any) => void;
  id?: string;
  additionalCardInfo?: Record<string, string | [string]>;
}

const StepCard = ({
  cardClassName,
  title,
  subtitle,
  stepIcon,
  stepParameterValues,
  stepImg,
  stepType,
  onClick,
  onContextMenu,
  id,
  additionalCardInfo,
}: Props) => {
  const cardClass = classNames("card clickable", "m-0", "step-card", cardClassName);
  const [shortenedTitle, isTitleShortened] = getShortenedText(title, MAX_PARAM_LENGTH);
  const [shortenedStepName, isStepNameShortened] = getShortenedText(subtitle, MAX_TITLE_LENGTH);

  const shouldShowPreview = (
    stepParameterValues: Record<string, BlueprintParameterValue> | undefined
  ) =>
    stepParameterValues &&
    (stepParameterValues.array ||
      stepParameterValues.value ||
      stepType === BlueprintStepType.IfElse ||
      stepType === BlueprintStepType.SetVariable ||
      stepType === BlueprintStepType.DataTransform ||
      stepType === BlueprintStepType.CustomFunction);

  const showAdditionalCardInfo = (additionalCardInfo?: Record<string, string | string[]>) => {
    const response = [];

    if (additionalCardInfo) {
      for (const key in additionalCardInfo) {
        const value = additionalCardInfo[key];
        if (value) {
          if (Array.isArray(value) && value.length) {
            response.push(
              <Text className="small text-muted text-sm space-y-1 space-x-1">
                {camelCaseToSentenceCase(key)}:{" "}
                {value.map((val) => (
                  <Badge size="sm"> {val} </Badge>
                ))}
              </Text>
            );
          } else if (typeof value == "string") {
            response.push(
              <Text className="small text-muted text-sm space-y-1 space-x-1">
                {camelCaseToSentenceCase(key)}: {value}
              </Text>
            );
          }
        }
      }
    }

    return response;
  };

  return (
    <button className={cardClass} onClick={onClick} onContextMenu={onContextMenu} id={id}>
      <Card.Body className="d-flex" style={{ padding: "0.01rem .1rem", width: "315px" }}>
        <Row className="flex-nowrap no-gutters items-center" style={{ minHeight: "65px" }}>
          <Col className="col-auto self-center">
            {stepImg ? (
              <img src={stepImg} className="card-image" alt="" />
            ) : (
              <div className="card-image align-items-center justify-content-center trigger-card-image">
                <span
                  className={classNames("fe text-white", stepIcon)}
                  style={{ fontSize: "24px" }}
                />
              </div>
            )}
          </Col>

          <Col className="ml-3 text-left align-self-center" style={{ paddingLeft: "12px" }}>
            {stepParameterValues && shouldShowPreview(stepParameterValues) ? (
              <StepCodePreview
                title={title}
                subtitle={subtitle}
                shortenedTitle={shortenedTitle}
                step={stepParameterValues}
                stepType={stepType}
              />
            ) : (
              <div>
                <Row>
                  {!isStepNameShortened ? (
                    <p className="mb-1.5 mt-0" style={{ fontSize: "15px", fontWeight: 500 }}>
                      {subtitle}
                    </p>
                  ) : (
                    <Tooltip title={subtitle}>
                      <p className="mb-1.5 mt-0" style={{ fontSize: "15px", fontWeight: 500 }}>
                        {shortenedStepName}
                      </p>
                    </Tooltip>
                  )}
                </Row>
                <Row>
                  {!isTitleShortened ? (
                    <DeprecatedH4 className="small text-muted text-truncate text-sm">
                      {shortenedTitle}
                    </DeprecatedH4>
                  ) : (
                    <Tooltip title={title}>
                      <DeprecatedH4 className="small text-muted text-truncate mb-0 text-sm">
                        {shortenedTitle}
                      </DeprecatedH4>
                    </Tooltip>
                  )}
                </Row>
                <Row>{showAdditionalCardInfo(additionalCardInfo)}</Row>
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </button>
  );
};

export default StepCard;
