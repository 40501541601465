import { useEffect, useState } from "react";
import IntegrationBuilderHeader from "../shared/IntegrationBuilderHeader";
import { IntegrationForIntegrationBuilder } from "../utils/Entities";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";
import useCreateOrPatchIntegration from "../hooks/useCreateOrPatchIntegration";
import EmptyStateWrapper from "../../shared/layout/EmptyStateWrapper";
import { Card } from "@merge-api/merge-javascript-shared";
import CardHeader from "../shared/CardHeader";
import MultiSelectHeader from "../shared/MultiSelectHeader";
import { BOOLEAN_OPTIONS_DATA } from "../utils/constants";
import APIEndpointExplain from "../api-endpoint-setup/components/APIEndpointExplain";
import AccordionCard from "../shared/AccordionCard";
import TextFieldHeader from "../shared/TextFieldHeader";
import { DiffModelTypeEnum } from "../../../models/DiffModels";
import EditorLeavingGuard from "../../shared/unsaved-changes/EditorLeavingGuard";

interface APIEndpointsConfigurationProps {
  integrationID: string;
}

const APIEndpointsConfiguration = ({ integrationID }: APIEndpointsConfigurationProps) => {
  /* SETUP REQUEST BODY FOR EDITING CONFIGURATION FIEDLS */
  const {
    setOnSubmit,
    integration,
    isLoading,
    setCurrentStateForDiff,
    setNewStateForDiff,
    computeHasUnsavedChanges,
  } = useIntegrationBuilderContext();
  const [requestBodyForIntegration, setRequestBodyForIntegration] = useState<
    IntegrationForIntegrationBuilder | undefined
  >();
  const { isLoadingCreateOrPatchIntegration, patchIntegration } = useCreateOrPatchIntegration({
    requestBodyForIntegration,
  });
  const [isRequiredFieldsCompleted, setIsRequiredFieldsCompleted] = useState<boolean>(false);

  /* SETUP FIELDS AND THEIR STATES */
  const [
    isRetainAuthorizationHeaderOnRedirect,
    setIsRetainAuthorizationHeaderOnRedirect,
  ] = useState<boolean>(true);
  const isRetainAuthorizationHeaderOnRedirectOptions = BOOLEAN_OPTIONS_DATA.map(
    ({ value, text }) => ({
      value,
      text,
      selected: value === isRetainAuthorizationHeaderOnRedirect,
      onClick: () => setIsRetainAuthorizationHeaderOnRedirect(value),
    })
  );
  const [isRequireSslCertificate, setIsRequireSslCertificate] = useState<boolean>(false);
  const isRequireSslCertificateOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isRequireSslCertificate,
    onClick: () => setIsRequireSslCertificate(value),
  }));
  const [isConvertBodyDataToString, setIsConvertBodyDataToString] = useState<boolean>(false);
  const isConvertBodyDataToStringOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isConvertBodyDataToString,
    onClick: () => setIsConvertBodyDataToString(value),
  }));
  const [isConvertStringToNumbers, setIsConvertStringToNumbers] = useState<boolean>(true);
  const isConvertStringToNumbersOptions = BOOLEAN_OPTIONS_DATA.map(({ value, text }) => ({
    value,
    text,
    selected: value === isConvertStringToNumbers,
    onClick: () => setIsConvertStringToNumbers(value),
  }));

  /* SETUP BUTTON STATE */
  useIntegrationBuilderContext({
    submitButtonText: "Save configuration",
    canSubmit: isRequiredFieldsCompleted,
    isLoadingSubmit: isLoadingCreateOrPatchIntegration,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
    modelTypeForDiff: DiffModelTypeEnum.API_ENDPOINT_CONFIGURATION,
    shouldHideDiffModal: false,
  });

  /* Initialize states once integration is loaded
   * When adding/removing fields, you must update helpers-api-endpoint-config-diff.ts
   * Otherwise diff modal will display outdated fields
   */
  useEffect(() => {
    if (integration) {
      const requestBody = {
        timestamp_format: integration.timestamp_format,
        retain_authorization_header_on_redirect:
          integration.retain_authorization_header_on_redirect,
        override_request_timeout_seconds: integration.override_request_timeout_seconds,
        api_request_retry_delay_seconds: integration.api_request_retry_delay_seconds,
        require_ssl_certificates: integration.require_ssl_certificates,
        convert_body_data_to_string: integration.convert_body_data_to_string,
        convert_strings_to_numbers: integration.convert_strings_to_numbers,
      };
      setRequestBodyForIntegration(requestBody);
      setIsRetainAuthorizationHeaderOnRedirect(
        integration.retain_authorization_header_on_redirect ?? false
      );
      setIsRequireSslCertificate(integration.require_ssl_certificates ?? false);
      setIsConvertBodyDataToString(integration.convert_body_data_to_string ?? false);
      setIsConvertStringToNumbers(integration.convert_strings_to_numbers ?? false);
      setCurrentStateForDiff(requestBody);
      setNewStateForDiff(requestBody);
    }
  }, [integration]);

  // Set submit button state based on current values
  useEffect(() => {
    setOnSubmit(() => patchIntegration(integrationID));
  }, [integrationID, requestBodyForIntegration]);

  // Update request body for boolean state changes
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return {
        ...body,
        retain_authorization_header_on_redirect: isRetainAuthorizationHeaderOnRedirect,
      };
    });
  }, [isRetainAuthorizationHeaderOnRedirect]);
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return { ...body, require_ssl_certificates: isRequireSslCertificate };
    });
  }, [isRequireSslCertificate]);
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return { ...body, convert_body_data_to_string: isConvertBodyDataToString };
    });
  }, [isConvertBodyDataToString]);
  useEffect(() => {
    setRequestBodyForIntegration((body) => {
      return { ...body, convert_strings_to_numbers: isConvertStringToNumbers };
    });
  }, [isConvertStringToNumbers]);

  // Update required state for request body changes
  useEffect(() => {
    setIsRequiredFieldsCompleted(
      requestBodyForIntegration?.timestamp_format !== null &&
        requestBodyForIntegration?.timestamp_format !== ""
    );
  }, [requestBodyForIntegration]);

  // Update diff state for diff modal
  useEffect(() => {
    setNewStateForDiff(requestBodyForIntegration);
  }, [requestBodyForIntegration]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <div>
        <IntegrationBuilderHeader title="API endpoints configuration" showRequired />
        {isLoading ? (
          <EmptyStateWrapper isSpinner />
        ) : (
          <div className="flex flex-column space-y-6">
            <APIEndpointExplain />
            <Card className="pt-4 px-5 pb-5 bg-white mb-6 min-w-fit">
              <CardHeader title="Configuration" />
              <div className="space-y-6">
                <TextFieldHeader
                  dataTestID="field-api-endpoint-configuration-timestamp-format"
                  title="Timestamp format"
                  subtitle="How to format timestamps taken in/returned by integration. If the integration uses ISO formatting, put ISO_FORMAT. If the integration uses timestamp in milliseconds, put MILLISECONDS. Otherwise, put in string that can be passed into strftime.

                    Example: Cake HR uses YYYY-MM-DD, so this should be %Y-%m-%d."
                  placeholder="Example: %Y-%m-%d"
                  value={requestBodyForIntegration?.timestamp_format ?? ""}
                  onChange={(e) => {
                    setRequestBodyForIntegration((body) => {
                      return { ...body, timestamp_format: e.target.value };
                    });
                  }}
                  required
                />
                <AccordionCard
                  title="Advanced"
                  titleVariant="h6"
                  variant="outline"
                  dataTestID="accordion-api-endpoint-configuration-advanced"
                >
                  <div className="space-y-6">
                    <TextFieldHeader
                      dataTestID="field-api-endpoint-configuration-override-request-timeout"
                      title="Override request timeout"
                      subtitle="Overrides the default request timeout when making API request for this Integration in the BlueprintRunner. In seconds."
                      type="number"
                      placeholder="Default: 10 seconds"
                      value={requestBodyForIntegration?.override_request_timeout_seconds?.toString()}
                      onChange={(e) => {
                        setRequestBodyForIntegration((body) => {
                          return {
                            ...body,
                            override_request_timeout_seconds: parseInt(e.target.value),
                          };
                        });
                      }}
                    />
                    <TextFieldHeader
                      dataTestID="field-api-endpoint-configuration-api-request-retry-delay"
                      title="API request retry delay"
                      subtitle="Overrides the default base retry delay (1 second) for failed outbound API requests. In seconds."
                      type="number"
                      placeholder="Default: 1 seconds"
                      value={requestBodyForIntegration?.api_request_retry_delay_seconds}
                      onChange={(e) => {
                        setRequestBodyForIntegration((body) => {
                          return {
                            ...body,
                            api_request_retry_delay_seconds: parseInt(e.target.value),
                          };
                        });
                      }}
                    />
                    <MultiSelectHeader
                      dataTestID="field-api-endpoint-configuration-retain-authorization-header-on-redirect"
                      title="Should we retain authorization header on redirect?"
                      subtitle="Indicates whether this integration should retain the authorization header when a redirect happens to a different hostname"
                      options={isRetainAuthorizationHeaderOnRedirectOptions}
                    />
                    <MultiSelectHeader
                      dataTestID="field-api-endpoint-configuration-require-ssl-certificate"
                      title="Is SSL certificate required?"
                      subtitle="Indicates whether to include uploaded SSL certificate and key in requests"
                      options={isRequireSslCertificateOptions}
                    />
                    <MultiSelectHeader
                      dataTestID="field-api-endpoint-configuration-convert-body-data-to-string"
                      title="Should we convert API request body data to string?"
                      subtitle="Indicates whether to convert body data to string in requests"
                      options={isConvertBodyDataToStringOptions}
                    />
                    <MultiSelectHeader
                      dataTestID="field-api-endpoint-configuration-convert-string-to-numbers"
                      title="Should we convert string to numbers?"
                      subtitle="Indicates whether to convert string variables to numbers when constructing requests (usually true)"
                      options={isConvertStringToNumbersOptions}
                    />
                  </div>
                </AccordionCard>
              </div>
            </Card>
          </div>
        )}
      </div>
    </EditorLeavingGuard>
  );
};

export default APIEndpointsConfiguration;
