import { Accordion, Badge, Text } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import useIntegrationBuilderContext from "../context/useIntegrationBuilderContext";

interface AccordionCardProps {
  className?: string;
  disabled?: boolean;
  title: string;
  titleIcon?: React.ReactNode;
  children: React.ReactNode;
  titleClassName?: string;
  titleVariant?:
    | "hero"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "title-sm"
    | "pre-title"
    | "lg"
    | "md"
    | "sm"
    | "caption"
    | "lg-mono"
    | "md-mono"
    | "sm-mono"
    | "inherit";
  defaultExpanded?: boolean;
  expanded?: boolean;
  setExpanded?: (value: boolean) => void;
  chevronSize?: number;
  chevronOrientation?: "down" | "right";
  chevronPlacement?: "right" | "end" | "left";
  chevronColor?: "black" | "gray" | "darkGray" | "lightGray";
  badgeNumber?: number;
  variant?: "none" | "outline" | "shadow" | undefined;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  dataTestID?: string;
  required?: boolean;
  nested?: boolean; // if AccordionCard is nested inside another one, we want to change the title style
}

const AccordionCard = ({
  className,
  title,
  children,
  titleIcon,
  titleClassName,
  titleVariant,
  defaultExpanded,
  expanded,
  setExpanded,
  chevronSize,
  chevronOrientation,
  chevronPlacement,
  chevronColor,
  badgeNumber,
  variant,
  dataTestID,
  onChange,
  required = false,
  disabled = false,
  nested = false,
}: AccordionCardProps) => {
  const { hasPermissionToEdit } = useIntegrationBuilderContext();

  const isDisabled = !hasPermissionToEdit || disabled;

  return (
    <Accordion
      className={className || "my-6"}
      title={
        <div data-testid={dataTestID} className="flex flex-row items-center">
          {titleIcon && <div className="mr-3">{titleIcon}</div>}
          {nested ? (
            <Text variant="md" className="font-semibold">
              {title}
            </Text>
          ) : (
            <Text variant={titleVariant || "h5"}>{title}</Text>
          )}
          {badgeNumber !== undefined && (
            <div className="min-w-fit min-h-fit ml-2">
              <Badge className="align-middle" color="blue">
                {badgeNumber}
              </Badge>
            </div>
          )}
          {required && (
            <Text variant="md" className="text-red-50 ml-1">
              *
            </Text>
          )}
        </div>
      }
      titleClassName={clsx(
        titleClassName ?? "px-5 py-4",
        disabled ? "cursor-auto" : "cursor-pointer"
      )}
      defaultExpanded={(nested && isDisabled) || defaultExpanded}
      expanded={expanded}
      setExpanded={setExpanded}
      chevronSize={disabled ? 0 : chevronSize}
      chevronOrientation={chevronOrientation ?? "right"}
      chevronPlacement={chevronPlacement}
      chevronColor={chevronColor}
      variant={variant}
      onChange={onChange}
    >
      {!disabled && (
        <div className="px-5 pb-5 ">
          <hr className="text-gray-20 h-[0.5px] mt-0 mb-5 -mx-5" />
          {children}
        </div>
      )}
    </Accordion>
  );
};

export default AccordionCard;
