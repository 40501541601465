import { useState } from "react";
import Editor from "react-simple-code-editor";

import { ScraperConstantValue, ScraperValueType } from "./types";
import FormField from "../blueprint-editor/right-panel/FormField";

const { highlight, languages } = require("prismjs");

interface Props {
  updateValue: (newValue: ScraperConstantValue) => void;
  value: ScraperConstantValue;
  valueKey: string;
  description?: string | JSX.Element;
}
const ScraperTextField = ({ description, updateValue, value, valueKey }: Props) => {
  const [unsavedValueText, setUnsavedValueText] = useState(`${value.constant}`);

  const updateValueFromText = (newText: string) => {
    updateValue({
      constant: newText,
      value_type: ScraperValueType.CONSTANT,
    });
  };

  return (
    <FormField title={valueKey} subtitle={description} valueType="any">
      <div>
        <Editor
          highlight={(text) => highlight(text, languages.markup, "markup")}
          value={unsavedValueText}
          onBlur={() => {
            updateValueFromText(unsavedValueText);
          }}
          onValueChange={(newText) => {
            setUnsavedValueText(newText);
          }}
          padding={10}
          style={{
            border: "1px solid #d2ddec",
            borderRadius: 8,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            minHeight: "100px",
            overflow: "auto",
          }}
        />
      </div>
    </FormField>
  );
};

export default ScraperTextField;
