import { Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";
import {
  CommonModelInfo,
  LinkedAccount,
  LinkedAccountStatus,
  mapDisplayableLinkedAccountStatus,
} from "../../../../models/Entities";
import LeftPanelTypeahead from "../shared/LeftPanelTypeahead";

interface Props {
  selectedTestLinkedAccount: undefined | string;
  setSelectedTestLinkedAccount: (s: string | undefined) => void;
  setReportFileID: (x: string | undefined) => void;
  isLoadingTestLinkedAccounts: boolean;
  testLinkedAccounts: LinkedAccount[];
  selectedTestCommonModel: undefined | string;
  setSelectedTestCommonModel: (s: string | undefined) => void;
  testCommonModels: undefined | CommonModelInfo[];
}

const mapLinkedAccountStatusToBadgeColor: Record<LinkedAccountStatus, BaseColor> = {
  [LinkedAccountStatus.COMPLETE]: "blue",
  [LinkedAccountStatus.INCOMPLETE]: "orange",
  [LinkedAccountStatus.RELINK_NEEDED]: "red",
};

/**
 * Renders Select fields for configuring a Blueprint test run
 */
const BlueprintLeftPanelConsoleSelect = ({
  selectedTestLinkedAccount,
  setSelectedTestLinkedAccount,
  setReportFileID,
  isLoadingTestLinkedAccounts,
  testLinkedAccounts,
  selectedTestCommonModel,
  setSelectedTestCommonModel,
  testCommonModels,
}: Props) => {
  return (
    <div className="flex flex-col space-y-4">
      <LeftPanelTypeahead
        loading={isLoadingTestLinkedAccounts}
        title="Linked Account"
        placeholder="Select Linked Account"
        onChange={(_, option: LinkedAccount | undefined) => {
          setSelectedTestLinkedAccount(option?.id ?? undefined);
          setReportFileID(undefined);
        }}
        value={testLinkedAccounts.find(
          (linkedAccount) => linkedAccount?.id === selectedTestLinkedAccount
        )}
        getOptionLabel={(option: LinkedAccount) => {
          return option.end_user?.organization_name;
        }}
        renderOption={(option: LinkedAccount) => (
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-col items-start w-2/3 truncate">
              <Text variant="sm" className="text-gray-70 w-full truncate">
                Created on {new Date(option.created_at).toLocaleDateString()}
              </Text>
              <Tooltip title={option.end_user.organization_name} className="w-full truncate">
                <Text className="w-full truncate">{option.end_user.organization_name}</Text>
              </Tooltip>
              <Text variant="sm" className="text-gray-70 w-full truncate">
                {option.id}
              </Text>
            </div>
            <div className="flex flex-row space-x-2">
              {option.status && (
                <Badge size="sm" color={mapLinkedAccountStatusToBadgeColor[option.status]}>
                  {mapDisplayableLinkedAccountStatus[option.status]}
                </Badge>
              )}
              {option.is_sandbox_account && <Badge size="sm">Sandbox</Badge>}
            </div>
          </div>
        )}
        filterOptions={(options: LinkedAccount[], state: any) => {
          return options.filter((option: LinkedAccount) => {
            const inputValueToLowerCase = (state.inputValue as string).toLowerCase();
            const linkedAccountName = option?.end_user.organization_name.toLowerCase();
            const linkedAccountID = option?.id;
            return (
              linkedAccountName?.includes(inputValueToLowerCase) ||
              linkedAccountID?.startsWith(inputValueToLowerCase)
            );
          });
        }}
        options={testLinkedAccounts}
      />
      {testCommonModels !== undefined && (
        <LeftPanelTypeahead
          title={`Common Model ${testCommonModels?.[0]?.name}`}
          placeholder={`Select ${testCommonModels?.[0]?.name ?? "Common Model"}`}
          onChange={(_, option) => {
            setSelectedTestCommonModel(option ?? undefined);
          }}
          value={selectedTestCommonModel}
          options={testCommonModels.map(({ id }) => id)}
        />
      )}
    </div>
  );
};

export default BlueprintLeftPanelConsoleSelect;
