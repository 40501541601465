import isEqual from "lodash/isEqual";
import { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import { Row, Col, Card, Button, Dropdown, Form } from "react-bootstrap";
import Dot from "../../shared/Dot";
import { BlueprintOperationType, BlueprintStatus } from "../../../models/Blueprints";
import { BlueprintMeta, CommonModelMappingStatus } from "../../../models/Entities";
import {
  MappingTestBlock,
  MappingTestBlockBlueprintsMeta,
  MappingTestRequestMock,
} from "../../../models/MappingTests";
import { BlueprintStatusBadge, CommonModelBadge } from "../../shared/MergeBadges";
import MergeText, { TextType } from "../../shared/text/MergeText";
import { firstLetterUpperCase } from "../../../utils";
import { ScraperDataType } from "../../scraper/types";
import { AvatarSize, UserAvatar } from "../../shared/MergeAvatars";
import { getCapitalizedTimeFromNow } from "../../shared/utils/SharedComponentUtils";
import styled from "styled-components";
import MappingTestBlockRequestMockRow from "../central-panel/MappingTestBlockRequestMockRow";
import useMappingTestContext from "../context/useMappingTestContext";
import { navigateToBlueprintEditor } from "../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@merge-api/merge-javascript-shared";

import clsx from "clsx";

const DRAFT_BLUEPRINT_WARNING_TEXT =
  "This blueprint needs at least one Staged or Published version in order to run as part of the mapping test";

const BlueprintCard = styled(Card)`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 0px; !important
`;

interface AddRequestMockDropdownProps {
  requestNames: Array<string>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const AddRequestMockDropdownToggle = styled(Dropdown.Toggle)`
  background: #f2f4f7;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  width: 100%;
  height: 36px;
  color: #737982;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 0.5px rgba(220, 226, 234, 0.2), 0px 3px 12px -3px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  border-color: #d7d9de;
  padding-top: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
`;

const BlueprintButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddRequestMockDropDownContainer = styled.div`
  height: 48px;
`;

const RequestMockOption = styled(Dropdown.Item)`
  width: 100%;
  height: 48px;
`;

const DisableFilterByDateCheckBox = styled(Form.Check)`
  margin-top: 12px;
`;

const DisableFilterByDateLabel = styled.div`
  font-size: 12px;
`;

const OverrideLastRunAtTitle = styled.div`
  font-size: 12px;
  margin-top: 12px;
`;

const OverrideLastRunAtForm = styled(Form.Control)`
  padding: 4px;
  font-size: 12px;
  margin-top: 0px;
  height: 25px;
`;

type Props = {
  mappingTestBlock: MappingTestBlock;
  blockBlueprintMeta: MappingTestBlockBlueprintsMeta;
  blueprint: BlueprintMeta;
  requestsMap: { [id: string]: MappingTestRequestMock };
  removeBlueprintFromMappingTestBlock: (mappingTestBlockID: string, blueprintID: string) => void;
  addRequestMockToMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  removeRequestMockFromMappingTestBlock: (
    mappingTestBlockID: string,
    blueprintID: string,
    requestMock: MappingTestRequestMock
  ) => void;
  setOverrideLastRunAtValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    overrideLastRunAtValue?: string | null
  ) => void;
  setDisableFilterByDateValue: (
    mappingTestBlockID: string,
    blueprintID: string,
    disableFilterByDateValue?: boolean
  ) => void;
};

const MAX_COMMENT_LENGTH = 30;

const MappingTestBlockBlueprintCard = ({
  mappingTestBlock,
  blueprint,
  removeBlueprintFromMappingTestBlock,
  blockBlueprintMeta,
  requestsMap,
  addRequestMockToMappingTestBlock,
  removeRequestMockFromMappingTestBlock,
  setOverrideLastRunAtValue,
  setDisableFilterByDateValue,
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const deleteOptionRef = useRef<HTMLDivElement>(null);
  const { integrationID } = useMappingTestContext();
  const history = useHistory();

  const commonModelBadges = () => {
    return blueprint?.operation_type === BlueprintOperationType.FETCH
      ? blueprint?.mapping_statuses
          .sort((a, b) => (a.common_model > b.common_model ? 1 : -1))
          .map((commonModel: CommonModelMappingStatus) => (
            <CommonModelBadge commonModel={commonModel.common_model} />
          ))
      : blueprint?.written_common_model && (
          <CommonModelBadge commonModel={blueprint?.written_common_model} />
        );
  };

  const handleOptionsClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setShowOptions((prevShowDeleteOption) => !prevShowDeleteOption);
  };

  const removeBlueprintOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    removeBlueprintFromMappingTestBlock(mappingTestBlock.id, blueprint?.blueprint_id);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (deleteOptionRef.current && !deleteOptionRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };

  const optionsTooltip = () => (
    <div className="d-flex flex-column">
      <div className={`fe fe-more-horizontal`} onClick={handleOptionsClick} />
      {showOptions && (
        <div ref={deleteOptionRef} className="position-absolute">
          <Button
            onClick={(event: React.MouseEvent<HTMLDivElement>) => removeBlueprintOnClick(event)}
            variant="white"
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );

  const AddRequestMockDropDown: React.FC<AddRequestMockDropdownProps> = ({ requestNames }) => {
    return (
      <AddRequestMockDropDownContainer>
        <Dropdown>
          <AddRequestMockDropdownToggle variant="light">
            Select request mock
          </AddRequestMockDropdownToggle>
          <Dropdown.Menu>
            {requestNames
              .filter(
                (name) =>
                  !mappingTestBlock.blueprints_meta[blueprint?.blueprint_id].request_mocks.includes(
                    name
                  )
              )
              .map((name) => (
                <RequestMockOption
                  onClick={() =>
                    addRequestMockToMappingTestBlock(
                      mappingTestBlock.id,
                      blueprint?.blueprint_id,
                      requestsMap[name]
                    )
                  }
                >
                  {name}
                </RequestMockOption>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </AddRequestMockDropDownContainer>
    );
  };

  useEffect(() => {
    const handleOutsideClickEvent = (event: MouseEvent) => {
      handleOutsideClick(event);
    };

    document.addEventListener("click", handleOutsideClickEvent);

    return () => {
      document.removeEventListener("click", handleOutsideClickEvent);
    };
  }, []);

  const CardBody = () => (
    <Card.Body>
      <div className="flex flex-row">
        <Col md={6}>
          <div className="flex flex-row">
            <Col>
              <MergeText isBold size="16px">
                {blueprint?.human_name && blueprint?.human_name != ""
                  ? blueprint?.human_name
                  : blueprint?.name}
              </MergeText>
            </Col>
          </div>
          <Row>
            <Col>
              <BlueprintStatusBadge
                status={
                  blueprint?.status === BlueprintStatus.Archived
                    ? blueprint?.status
                    : blueprint?.blueprint_overview_publish_state
                }
              />
              <Dot />
              <MergeText isBold type={TextType.MUTED}>
                {firstLetterUpperCase(blueprint?.operation_type ?? "")}
              </MergeText>
              <Dot />
              <MergeText isBold type={TextType.MUTED}>
                {blueprint?.has_scraper
                  ? blueprint?.scraper_data_type === ScraperDataType.AUTH_DATA
                    ? "Auth Scraper"
                    : "Common Model Scraper"
                  : "API"}
              </MergeText>
              <Dot />

              <Tooltip title={blueprint?.user?.name}>
                <span className="mr-2">
                  <UserAvatar user={blueprint?.user ?? undefined} size={AvatarSize.xxs} />
                </span>
              </Tooltip>
              <Tooltip title={blueprint?.comment}>
                <MergeText isBold type={TextType.MUTED}>
                  {blueprint?.comment.length > MAX_COMMENT_LENGTH
                    ? blueprint?.comment.substring(0, MAX_COMMENT_LENGTH) + "..."
                    : blueprint?.comment}
                </MergeText>
              </Tooltip>
              <MergeText type={TextType.MUTED2} className="ml-2">
                {getCapitalizedTimeFromNow(blueprint?.created_at)}
              </MergeText>
            </Col>
          </Row>
          {commonModelBadges()}
        </Col>
        <Col>
          <strong>Runner Settings</strong>
          <DisableFilterByDateCheckBox
            type="checkbox"
            label={<DisableFilterByDateLabel>Disable filter by date </DisableFilterByDateLabel>}
            defaultChecked={blockBlueprintMeta?.disable_filter_by_date ?? false}
            onChange={() =>
              setDisableFilterByDateValue(
                mappingTestBlock.id,
                blueprint?.blueprint_id,
                !(blockBlueprintMeta?.disable_filter_by_date ?? false)
              )
            }
          />
          <div>
            <OverrideLastRunAtTitle>Override Last Run At</OverrideLastRunAtTitle>
            <OverrideLastRunAtForm
              id="override_last_run_at"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setOverrideLastRunAtValue(
                  mappingTestBlock.id,
                  blueprint?.blueprint_id,
                  e.target.value != "" ? e.target.value : null
                )
              }
              placeholder=""
              value={blockBlueprintMeta["override_last_run_at"] ?? ""}
            />
          </div>
        </Col>
        <Col>
          <BlueprintButtonContainer>
            <Button
              onClick={(e: any) => {
                e.stopPropagation();
                navigateToBlueprintEditor(history, integrationID, blueprint?.id, true);
              }}
              className="ml-3 mr-1.5 p-1"
              size="sm"
              variant="secondary"
            >
              Open Blueprint
            </Button>
          </BlueprintButtonContainer>
        </Col>
        <Col className="col-auto d-flex align-items-center">{optionsTooltip()}</Col>
      </div>
    </Card.Body>
  );

  return (
    <Container>
      <BlueprintCard
        className={clsx(
          "clickable",
          blueprint.blueprint_overview_publish_state == "DRAFT"
            ? "border border-red-50 bg-red-0"
            : "border border-gray-20"
        )}
        onClick={(_: any) => {}}
      >
        {blueprint.blueprint_overview_publish_state == "DRAFT" ? (
          <Tooltip title={DRAFT_BLUEPRINT_WARNING_TEXT}>{CardBody()}</Tooltip>
        ) : (
          CardBody()
        )}
      </BlueprintCard>
      {blockBlueprintMeta["request_mocks"].map((name) => (
        <MappingTestBlockRequestMockRow
          requestMock={requestsMap[name]}
          deletedRequestMockFallbackName={name}
          mappingTestBlock={mappingTestBlock}
          blueprint={blueprint}
          removeRequestMockFromMappingTestBlock={removeRequestMockFromMappingTestBlock}
        />
      ))}
      <AddRequestMockDropDown
        requestNames={Object.entries(requestsMap)
          .filter(([_, request]) => {
            if (!request.blueprint || request.blueprint == blueprint.blueprint_id) {
              return true;
            } else return false;
          })
          .map((entry) => entry[0])}
      />
    </Container>
  );
};

export default memo(MappingTestBlockBlueprintCard, isEqual);
