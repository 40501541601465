import {
  IntegrationChecklistData,
  FIELD_NAME_TO_WARNING_TEXT,
  ChecklistStatusValue,
  supportedIntegrationChecklistData,
} from "../../constants";
import { isAnyConfigNotImplemented } from "../../utils";
import { AlertTriangle, MinusCircle } from "lucide-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import styled from "styled-components";
import { useState } from "react";
import MarkFieldUnsupportedModal from "./MarkFieldUnsupportedModal";
import { useParams } from "react-router-dom";

// Styled component for MissingConfigsContainer
const MissingConfigsContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--lm-yellow-10, #fbe8ab);
  background: var(--lm-yellow-0, #fff9e6);
`;

// Styled component for ChildComponent
const MissingConfigRow = styled.div`
  display: flex;
  padding: 6px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: rgba(243, 235, 208, 0.4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  margin-bottom: 8px;
`;

const RowsContainer = styled.div`
  padding-right: 20px;
  padding-left: 40px;
  width: 100%;
`;

const WarningText = styled.div`
  margin-bottom: 16px;
  display: flex; /* Add flex display */
  align-items: center; /* Vertically center items */
  padding-left: 20px;
`;

const AlertIcon = styled(AlertTriangle)`
  margin-right: 20px;
`;

const MissingIntegrationConfigurationsComponent = ({
  checklistData,
}: {
  checklistData: IntegrationChecklistData;
}) => {
  const params = useParams<{ integrationID: string; subtab: string }>();
  const [showModal, setShowModal] = useState(false);
  const [fieldName, setFieldName] = useState<keyof IntegrationChecklistData | null>(null);
  // If we're not missing configs, return nothing! nothing at all!
  if (!isAnyConfigNotImplemented(checklistData)) return <></>;

  const handleCircleSlashedClick = (fieldName: keyof IntegrationChecklistData) => {
    setFieldName(fieldName);
    setShowModal(true);
  };

  return (
    <MissingConfigsContainerWrapper className="mt-6">
      {showModal && fieldName && (
        <MarkFieldUnsupportedModal
          fieldName={fieldName}
          onHide={() => setShowModal(false)}
          show={showModal}
          integrationID={params.integrationID}
        />
      )}
      <WarningText>
        <AlertIcon color="#CA8F00" size={16} />
        <b>Missing configurations&nbsp;</b> Resolve the configurations below before publishing your
        integration.
      </WarningText>
      <RowsContainer>
        {Object.entries(checklistData)
          .filter(
            ([field_name, value]) =>
              supportedIntegrationChecklistData.includes(field_name) &&
              value == ChecklistStatusValue.NOT_IMPLEMENTED
          )
          .map(([field_name, _]) => {
            return (
              <MissingConfigRow>
                {/* @ts-ignore it does not understand I am mapping keys of IntegrationChecklistData for some reason */}
                <b>{FIELD_NAME_TO_WARNING_TEXT[field_name]}</b>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-${field_name}`}>Mark as unsupported</Tooltip>}
                >
                  <MinusCircle
                    size={16}
                    onClick={() =>
                      handleCircleSlashedClick(field_name as keyof IntegrationChecklistData)
                    }
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
              </MissingConfigRow>
            );
          })}
      </RowsContainer>
    </MissingConfigsContainerWrapper>
  );
};

export default MissingIntegrationConfigurationsComponent;
