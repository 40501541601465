import { MappingTestExecutionResult } from "../../../models/MappingTests";
import MergeText, { TextType } from "../text/MergeText";

type Props = {
  header: string;
  subheader: string;
  testResult?: MappingTestExecutionResult | undefined;
  hasUnsavedChanges?: boolean;
};

const HeaderSection = ({ header, subheader, testResult, hasUnsavedChanges }: Props) => {
  const textColor =
    testResult === MappingTestExecutionResult.SUCCESS
      ? "#00B187"
      : testResult === MappingTestExecutionResult.FAILURE
      ? "#F00424"
      : "#080808";

  return (
    <div>
      <MergeText color={textColor} isBold size={"20px"}>
        {header}
      </MergeText>
      <div className="d-flex align-items-center">
        <MergeText color={textColor} type={TextType.MUTED} size={"16px"}>
          {subheader}
        </MergeText>
        {hasUnsavedChanges && (
          <span className="badge badge-soft-danger ml-1.5">Unsaved changes</span>
        )}
      </div>
    </div>
  );
};

export default HeaderSection;
