import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchWithAuth, PaginatedAPIResponse, useQuery } from "../../../api-client/api_client";
import { ScraperExecutionPreview } from "../../scraper/types";
import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import { showErrorToast } from "../../shared/Toasts";
import ScraperHistoryView from "./ScraperHistoryView";

const ScraperHistoryRoot = () => {
  const { scraperID, integrationID } = useParams<{
    integrationID: string;
    scraperID: string;
  }>();
  const query = useQuery();
  const [executions, setExecutions] = useState<ScraperExecutionPreview[] | undefined>(undefined);
  const [nextPageURL, setNextPageURL] = useState<string | null>(null);
  const [previousPageURL, setPreviousPageURL] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const linkedAccountID: string | null = query.get("linkedAccountID");
  useEffect(() => {
    fetchExecutions();
  }, [linkedAccountID]);

  const fetchExecutions = (cursorURL?: string) => {
    const linkedAccountParam = linkedAccountID ? `&linked_account_id=${linkedAccountID}` : "";
    setIsLoading(true);
    fetchWithAuth({
      path: cursorURL || `/scrapers/executions?scraper_id=${scraperID}${linkedAccountParam}`,
      method: "GET",
      onResponse: (data: PaginatedAPIResponse<ScraperExecutionPreview>) => {
        setExecutions(data.results);
        setNextPageURL(data.next);
        setPreviousPageURL(data.previous);
        setIsLoading(false);
      },
      onError: () => {
        showErrorToast("Failed to fetch scraper executions.");
        setIsLoading(false);
      },
    });
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <ScraperHistoryView
      integrationID={integrationID}
      scraperID={scraperID}
      executions={executions ?? undefined}
      nextPageURL={nextPageURL}
      previousPageURL={previousPageURL}
      fetchExecutions={fetchExecutions}
    />
  );
};

export default ScraperHistoryRoot;
