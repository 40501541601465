import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ChevronsLeftRight } from "lucide-react";
import { getIntegrationBuilderAPIEndpointPathForId } from "../../../../router/RouterUtils";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import IntegrationBuilderHeader from "../../shared/IntegrationBuilderHeader";

interface APIEndpointHeaderProps {
  integrationID: string;
  isNewAPIEndpoint: boolean;
}

const APIEndpointHeader = ({ integrationID, isNewAPIEndpoint }: APIEndpointHeaderProps) => {
  const { setIsRightPanelOpen } = useIntegrationBuilderContext();

  return (
    <IntegrationBuilderHeader
      title={isNewAPIEndpoint ? "Add endpoint" : "Edit endpoint"}
      navButtonLink={getIntegrationBuilderAPIEndpointPathForId(integrationID)}
      navButtonText="API endpoints"
      showRequired
      button={
        <div className="flex flex-row items-center flex-nowrap ml-2 space-x-3">
          <Button
            variant={ButtonVariant.SecondaryBlue}
            size="md"
            leftIcon={<ChevronsLeftRight size={16} />}
            onClick={() => setIsRightPanelOpen(true)}
          >
            API tester
          </Button>
        </div>
      }
    />
  );
};

export default APIEndpointHeader;
