import { Link, Text } from "@merge-api/merge-javascript-shared";
import { ListTransformationInformation } from "../../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import TextFieldHeader from "../../../../../shared/TextFieldHeader";
import TransformValuesModalCheckboxes from "./TransformValuesModalCheckboxes";

type Props = {
  isListOperator: boolean;

  formatString: string;
  prefix: string;
  suffix: string;
  listTransformationInformation: ListTransformationInformation;
  datetimeFormat: string;

  setFormatString: (newVal: string) => void;
  setPrefix: (newVal: string) => void;
  setSuffix: (newVal: string) => void;
  setListTransformationInformation: (newVal: ListTransformationInformation) => void;
  setDatetimeFormat: (newVal: string) => void;
};

const PYTHON_FORMAT_STRINGS_DOC_URL = "https://docs.python.org/3/library/string.html#formatstrings";

const TransformValuesModalForm = ({
  isListOperator,
  listTransformationInformation,
  setListTransformationInformation,
  formatString,
  setFormatString,
  prefix,
  setPrefix,
  suffix,
  setSuffix,
}: Props) => {
  return (
    <div className="flex flex-col">
      <>
        {isListOperator && (
          <TransformValuesModalCheckboxes
            listTransformationInformation={listTransformationInformation}
            setListTransformationInformation={setListTransformationInformation}
          />
        )}
        {/* S2TODO: update placholder text - https://app.asana.com/0/1205644398660644/1207534868357516 */}
        <TextFieldHeader
          className="mb-6"
          title="Format string"
          // subtitle="Specify what string transformation (if any) should be applied to the value we input in our filter
          // Reference for available transformations: https://docs.python.org/3/library/string.html#formatstrings"
          subtitleComponent={
            <Text as="span" className="text-gray-70" variant="sm">
              Specify what string transformation (if any) should be applied to the value we input in
              our filter <br /> Available transformations:{" "}
              <Link target="_blank" href={PYTHON_FORMAT_STRINGS_DOC_URL}>
                {PYTHON_FORMAT_STRINGS_DOC_URL}
              </Link>
            </Text>
          }
          value={formatString ?? ""}
          onChange={(event) => {
            const value = event.target.value;
            setFormatString(value);
          }}
        />
        {/* S2TODO: update placholder text - https://app.asana.com/0/1205644398660644/1207534868357516 */}
        <TextFieldHeader
          className="mb-6"
          title="Filter Prefix"
          subtitle="The string prefix that will be prepended to our filter value"
          value={prefix}
          onChange={(event) => {
            const value = event.target.value;
            setPrefix(value);
          }}
        />
        {/* S2TODO: update placholder text - https://app.asana.com/0/1205644398660644/1207534868357516 */}
        <TextFieldHeader
          className="mb-6"
          title="Filter suffix"
          subtitle="The string suffix that will be appended after our filter value"
          value={suffix}
          onChange={(event) => {
            const value = event.target.value;
            setSuffix(value);
          }}
        />
      </>
    </div>
  );
};

export default TransformValuesModalForm;
