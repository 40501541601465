import React from "react";
import { Row } from "../../shared/postman-table/PostmanTableRow";
import { ResponseBodyAdditionalParsing } from "../../../../models/Entities";
import {
  APIEndpointBodyParsingConfiguration,
  APIEndpointIntegrationBuilder,
  PaginationConfigurationIntegrationBuilder,
  RateLimitConfigurationIntegrationBuilder,
} from "../../utils/Entities";
import { APIEndpointPaginationConfigurationTypes } from "../../../../models/APIEndpointModels";

export interface APIEndpointContextState {
  integrationID: string;
  name: string;
  setName: (value: string) => void;
  protocol: string;
  setProtocol: (value: string) => void;
  endpointType: string;
  setEndpointType: (value: string) => void;
  method: string;
  setMethod: (value: string) => void;
  path: string;
  setPath: (value: string) => void;
  baseURLOverride: string;
  setBaseURLOverride: (value: string) => void;
  pathParamSchema: Object;
  setPathParamSchema: (value: Object) => void;
  queryParamSchema: Object;
  setQueryParamSchema: (value: Object) => void;
  queryParamTableRows: Row[];
  setQueryParamTableRows: React.Dispatch<React.SetStateAction<Row[]>>;
  doesAllowTimestampFiltering: boolean;
  setDoesAllowTimestampFiltering: (value: boolean) => void;
  timestampFilterParamKey: string;
  setTimestampFilterParamKey: (value: string) => void;
  timestampFilterParamLocation: string | null;
  setTimestampFilterParamLocation: (value: string | null) => void;
  timestampFilterFormatOverride: string;
  setTimestampFilterFormatOverride: (value: string) => void;
  timestampFilterParamPrefix: string;
  setTimestampFilterParamPrefix: (value: string) => void;
  timestampFilterParamSuffix: string;
  setTimestampFilterParamSuffix: (value: string) => void;
  timestampFilterBufferHours: number | null;
  setTimestampFilterBufferHours: (value: number | null) => void;
  timestampFilterDefaultOverride: string;
  setTimestampFilterDefaultOverride: (value: string) => void;
  headerSchema: Object;
  setHeaderSchema: (value: Object) => void;
  headerSchemaTableRows: Row[];
  setHeaderSchemaTableRows: React.Dispatch<React.SetStateAction<Row[]>>;
  contentType: string;
  setContentType: (value: string) => void;
  bodySchema: Object;
  setBodySchema: (value: Object) => void;
  soapRequestBodyFormat: string;
  setSoapRequestBodyFormat: (value: string) => void;
  soapRequestBodyParamSchema: Object;
  setSoapRequestBodyParamSchema: (value: Object) => void;
  soapDoesRequestUseBodyTemplatingEngine: boolean;
  setSoapDoesRequestUseBodyTemplatingEngine: (value: boolean) => void;
  graphqlRequestBodyBaseConfig: string;
  setGraphqlRequestBodyBaseConfig: (value: string) => void;
  graphqlRequestBodyParamSchema: Object;
  setGraphqlRequestBodyParamSchema: (value: Object) => void;
  requestType: string;
  setRequestType: (value: string) => void;
  fileSchema: Object;
  setFileSchema: (value: Object) => void;
  urlEncodedQueryParamsFormat: Object;
  setUrlEncodedQueryParamsFormat: (value: Object) => void;
  urlEncodedQueryParamSchema: Object;
  setUrlEncodedQueryParamSchema: (value: Object) => void;
  syncCursorKeyPath: string[] | null;
  setSyncCursorKeyPath: (value: string[] | null) => void;
  shouldFilterEmptyValuesFromBodyData: boolean;
  setShouldFilterEmptyValuesFromBodyData: (value: boolean) => void;
  shouldAcceptMergeWithJsonRequestBody: boolean;
  setShouldAcceptMergeWithJsonRequestBody: (value: boolean) => void;
  shouldAcceptRawJsonRequestBody: boolean;
  setShouldAcceptRawJsonRequestBody: (value: boolean) => void;
  shouldOverrideIntegrationConvertStringsToNumbers: boolean;
  setShouldOverrideIntegrationConvertStringsToNumbers: (value: boolean) => void;
  shouldOverrideIntegrationConverBodyDataToString: boolean;
  setShouldOverrideIntegrationConverBodyDataToString: (value: boolean) => void;
  responseType: string;
  setResponseType: (value: string) => void;
  responseSchema: string;
  setResponseSchema: (value: string) => void;
  allowedErrorCodes: string[] | null;
  setAllowedErrorCodes: React.Dispatch<React.SetStateAction<string[] | null>>;
  additionalResponseBodyParsing: APIEndpointBodyParsingConfiguration[] | null;
  setAdditionalResponseBodyParsing: React.Dispatch<APIEndpointBodyParsingConfiguration[] | null>;
  responseBodyParsingRules: ResponseBodyAdditionalParsing;
  setResponseBodyParsingRules: (data: ResponseBodyAdditionalParsing) => void;
  isEndpointModificationDisabled: boolean;
  setIsEndpointModificationDisabled: (value: boolean) => void;
  paginationConfigID: string | null;
  setPaginationConfigID: (value: string | null) => void;
  paginationResponseBodyArrayKeyPathOverride: string[] | null;
  setPaginationResponseBodyArrayKeyPathOverride: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
  paginationConfigType: APIEndpointPaginationConfigurationTypes;
  setPaginationConfigType: (value: APIEndpointPaginationConfigurationTypes) => void;
  paginationConfigurations: PaginationConfigurationIntegrationBuilder[] | undefined;
  setPaginationConfigurations: React.Dispatch<
    React.SetStateAction<PaginationConfigurationIntegrationBuilder[] | undefined>
  >;
  defaultPaginationConfiguration: PaginationConfigurationIntegrationBuilder | null;
  // RATE LIMIT CONFIGURATIONS
  rateLimitConfigurations: RateLimitConfigurationIntegrationBuilder[] | undefined;
  setRateLimitConfigurations: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[] | undefined>
  >;
  rateLimitConfigurationsToDelete: RateLimitConfigurationIntegrationBuilder[];
  setRateLimitConfigurationsToDelete: React.Dispatch<
    React.SetStateAction<RateLimitConfigurationIntegrationBuilder[]>
  >;
  canSubmitRateLimitConfigurations: boolean;
  setCanSubmitRateLimitConfigurations: (value: boolean) => void;
  formAPIEndpointData: () => APIEndpointIntegrationBuilder | null;
  canSubmitAPIEndpoint: boolean;
}

const APIEndpointContext = React.createContext<APIEndpointContextState>({
  integrationID: "",
  name: "",
  setName: () => {},
  protocol: "",
  setProtocol: () => {},
  endpointType: "",
  setEndpointType: () => {},
  method: "",
  setMethod: () => {},
  path: "",
  setPath: () => {},
  baseURLOverride: "",
  setBaseURLOverride: () => {},
  pathParamSchema: {},
  setPathParamSchema: () => {},
  queryParamSchema: {},
  setQueryParamSchema: () => {},
  queryParamTableRows: [],
  setQueryParamTableRows: () => {},
  doesAllowTimestampFiltering: false,
  setDoesAllowTimestampFiltering: () => {},
  timestampFilterParamKey: "",
  setTimestampFilterParamKey: () => {},
  timestampFilterParamLocation: null,
  setTimestampFilterParamLocation: () => {},
  timestampFilterFormatOverride: "",
  setTimestampFilterFormatOverride: () => {},
  timestampFilterParamPrefix: "",
  setTimestampFilterParamPrefix: () => {},
  timestampFilterParamSuffix: "",
  setTimestampFilterParamSuffix: () => {},
  timestampFilterBufferHours: null,
  setTimestampFilterBufferHours: () => {},
  timestampFilterDefaultOverride: "",
  setTimestampFilterDefaultOverride: () => {},
  headerSchema: {},
  setHeaderSchema: () => {},
  headerSchemaTableRows: [],
  setHeaderSchemaTableRows: () => {},
  contentType: "",
  setContentType: () => {},
  bodySchema: {},
  setBodySchema: () => {},
  soapRequestBodyFormat: "",
  setSoapRequestBodyFormat: () => {},
  soapRequestBodyParamSchema: {},
  setSoapRequestBodyParamSchema: () => {},
  soapDoesRequestUseBodyTemplatingEngine: false,
  setSoapDoesRequestUseBodyTemplatingEngine: () => {},
  graphqlRequestBodyBaseConfig: "",
  setGraphqlRequestBodyBaseConfig: () => {},
  graphqlRequestBodyParamSchema: {},
  setGraphqlRequestBodyParamSchema: () => {},
  requestType: "",
  setRequestType: () => {},
  fileSchema: "",
  setFileSchema: () => {},
  urlEncodedQueryParamsFormat: "",
  setUrlEncodedQueryParamsFormat: () => {},
  urlEncodedQueryParamSchema: "",
  setUrlEncodedQueryParamSchema: () => {},
  syncCursorKeyPath: null,
  setSyncCursorKeyPath: () => {},
  shouldFilterEmptyValuesFromBodyData: false,
  setShouldFilterEmptyValuesFromBodyData: () => {},
  shouldAcceptMergeWithJsonRequestBody: false,
  setShouldAcceptMergeWithJsonRequestBody: () => {},
  shouldAcceptRawJsonRequestBody: false,
  setShouldAcceptRawJsonRequestBody: () => {},
  shouldOverrideIntegrationConvertStringsToNumbers: false,
  setShouldOverrideIntegrationConvertStringsToNumbers: () => {},
  shouldOverrideIntegrationConverBodyDataToString: false,
  setShouldOverrideIntegrationConverBodyDataToString: () => {},
  responseType: "",
  setResponseType: () => {},
  responseSchema: "",
  setResponseSchema: () => {},
  allowedErrorCodes: [],
  setAllowedErrorCodes: () => {},
  additionalResponseBodyParsing: null,
  setAdditionalResponseBodyParsing: () => {},
  responseBodyParsingRules: [],
  setResponseBodyParsingRules: () => {},
  isEndpointModificationDisabled: false,
  setIsEndpointModificationDisabled: () => {},
  paginationConfigID: null,
  setPaginationConfigID: () => {},
  paginationResponseBodyArrayKeyPathOverride: null,
  setPaginationResponseBodyArrayKeyPathOverride: () => null,
  paginationConfigType: APIEndpointPaginationConfigurationTypes.DEFAULT,
  setPaginationConfigType: () => {},
  paginationConfigurations: undefined,
  setPaginationConfigurations: () => null,
  rateLimitConfigurations: undefined,
  setRateLimitConfigurations: () => null,
  rateLimitConfigurationsToDelete: [],
  setRateLimitConfigurationsToDelete: () => [],
  canSubmitRateLimitConfigurations: true,
  setCanSubmitRateLimitConfigurations: () => {},
  defaultPaginationConfiguration: null,
  formAPIEndpointData: () => null,
  canSubmitAPIEndpoint: false,
});

export default APIEndpointContext;
