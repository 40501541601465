import { Badge, Tab, TabPanel, Tabs, TabsList } from "@merge-api/merge-javascript-shared";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PostmanTableHeader from "../../../authentication/components/shared/PostmanTableHeader";
import { parsePropertiesDictToPostmanTableRows } from "../../../utils/helpers";
import APIEndpointContext from "../../context/APIEndpointContext";
import { APIProtocols } from "../../../../../models/APIEndpointModels";

const PARAM_INPUT_TABLE_SUBTITLE = "Nested parameter keys will be listed as parent.child";

interface APIRequestTesterParamInputsProps {
  setParameterValues: React.Dispatch<React.SetStateAction<{}>>;
}

const APIRequestTesterParamInputs = ({ setParameterValues }: APIRequestTesterParamInputsProps) => {
  const {
    protocol,
    headerSchema,
    queryParamSchema,
    pathParamSchema,
    bodySchema,
    soapRequestBodyParamSchema,
    graphqlRequestBodyParamSchema,
  } = useContext(APIEndpointContext);

  // Construct table row states from schemas
  const [headerParamTableRows, setHeaderParamTableRows] = useState(
    parsePropertiesDictToPostmanTableRows(headerSchema, false, false)
  );

  const [queryParamTableRows, setQueryParamTableRows] = useState(
    parsePropertiesDictToPostmanTableRows(queryParamSchema, false, false)
  );

  const [pathParamTableRows, setPathParamTableRows] = useState(
    parsePropertiesDictToPostmanTableRows(pathParamSchema, false, false)
  );

  const setupBodyParamTableRows = useCallback(() => {
    if (protocol === APIProtocols.SOAP) {
      return parsePropertiesDictToPostmanTableRows(soapRequestBodyParamSchema, false, false);
    } else if (protocol === APIProtocols.GRAPHQL) {
      return parsePropertiesDictToPostmanTableRows(graphqlRequestBodyParamSchema, false, false);
    } else {
      return parsePropertiesDictToPostmanTableRows(bodySchema, false, false);
    }
  }, [protocol, bodySchema, soapRequestBodyParamSchema, graphqlRequestBodyParamSchema]);
  const initialBodyParamTableRows = setupBodyParamTableRows();
  const [bodyParamTableRows, setBodyParamTableRows] = useState(initialBodyParamTableRows);

  // Update parameter input tables when schemas change
  useEffect(() => {
    setHeaderParamTableRows(parsePropertiesDictToPostmanTableRows(headerSchema, false, false));
  }, [headerSchema]);

  useEffect(() => {
    setQueryParamTableRows(parsePropertiesDictToPostmanTableRows(queryParamSchema, false, false));
  }, [queryParamSchema]);

  useEffect(() => {
    setPathParamTableRows(parsePropertiesDictToPostmanTableRows(pathParamSchema, false, false));
  }, [pathParamSchema]);

  useEffect(() => {
    const tableRows = setupBodyParamTableRows();
    setBodyParamTableRows(tableRows);
  }, [protocol, bodySchema, soapRequestBodyParamSchema]);

  // Update parameter values being sent in request tester when tables change
  useEffect(() => {
    setParameterValues((prev) => ({
      ...prev,
      headers: headerParamTableRows.reduce((acc, row) => {
        return row.active ? { ...acc, [row.key]: row.value } : { ...acc };
      }, {}),
    }));
  }, [headerParamTableRows]);

  useEffect(() => {
    setParameterValues((prev) => ({
      ...prev,
      query_params: queryParamTableRows.reduce((acc, row) => {
        return row.active ? { ...acc, [row.key]: row.value } : { ...acc };
      }, {}),
    }));
  }, [queryParamTableRows]);

  useEffect(() => {
    setParameterValues((prev) => ({
      ...prev,
      path_params: pathParamTableRows.reduce((acc, row) => {
        return row.active ? { ...acc, [row.key]: row.value } : { ...acc };
      }, {}),
    }));
  }, [pathParamTableRows]);

  useEffect(() => {
    setParameterValues((prev) => ({
      ...prev,
      body_params: bodyParamTableRows.reduce((acc, row) => {
        return row.active ? { ...acc, [row.key]: row.value } : { ...acc };
      }, {}),
    }));
  }, [bodyParamTableRows]);

  const isUserInputRequired = useMemo(
    () =>
      headerParamTableRows.length ||
      pathParamTableRows.length ||
      queryParamTableRows.length ||
      bodyParamTableRows.length,
    [headerParamTableRows, pathParamTableRows, queryParamTableRows, bodyParamTableRows]
  );

  if (!isUserInputRequired) {
    return null;
  }

  return (
    <Tabs className="mt-6">
      <Tabs>
        <TabsList>
          {headerParamTableRows.length > 0 && (
            <Tab>
              Headers <Badge className="ml-1">{headerParamTableRows.length}</Badge>
            </Tab>
          )}
          {pathParamTableRows.length > 0 && (
            <Tab>
              Path Params <Badge className="ml-1">{pathParamTableRows.length}</Badge>
            </Tab>
          )}
          {queryParamTableRows.length > 0 && (
            <Tab>
              Query Params <Badge className="ml-1">{queryParamTableRows.length}</Badge>
            </Tab>
          )}
          {bodyParamTableRows.length > 0 && (
            <Tab>
              Body Params <Badge className="ml-1">{bodyParamTableRows.length}</Badge>
            </Tab>
          )}
        </TabsList>
        {headerParamTableRows.length > 0 && (
          <TabPanel>
            <PostmanTableHeader
              className="mt-4"
              rows={headerParamTableRows}
              subtitle={PARAM_INPUT_TABLE_SUBTITLE}
              setRows={setHeaderParamTableRows}
              title=" "
              hasSource={false}
              keysDisabled={true}
              userInputDisabled={false}
              isUserInput={false}
              addRowDisabled={true}
            />
          </TabPanel>
        )}
        {pathParamTableRows.length > 0 && (
          <TabPanel>
            <PostmanTableHeader
              className="mt-4"
              rows={pathParamTableRows}
              subtitle={PARAM_INPUT_TABLE_SUBTITLE}
              setRows={setPathParamTableRows}
              title=" "
              hasSource={false}
              keysDisabled={true}
              userInputDisabled={false}
              isUserInput={false}
              addRowDisabled={true}
            />
          </TabPanel>
        )}
        {queryParamTableRows.length > 0 && (
          <TabPanel>
            <PostmanTableHeader
              className="mt-4"
              rows={queryParamTableRows}
              subtitle={PARAM_INPUT_TABLE_SUBTITLE}
              setRows={setQueryParamTableRows}
              title=" "
              hasSource={false}
              keysDisabled={true}
              userInputDisabled={false}
              isUserInput={false}
              addRowDisabled={true}
            />
          </TabPanel>
        )}
        {bodyParamTableRows.length > 0 && (
          <TabPanel>
            <PostmanTableHeader
              className="mt-4"
              rows={bodyParamTableRows}
              subtitle={PARAM_INPUT_TABLE_SUBTITLE}
              setRows={setBodyParamTableRows}
              title=" "
              hasSource={false}
              keysDisabled={true}
              userInputDisabled={false}
              isUserInput={false}
              addRowDisabled={true}
            />
          </TabPanel>
        )}
      </Tabs>
    </Tabs>
  );
};

export default APIRequestTesterParamInputs;
