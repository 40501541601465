import TypeaheadFormField from "./TypeaheadFormField";
import useBlueprintContext from "../context/useBlueprintContext";
import { HeaderPretitle } from "../../shared/text/MergeText";

const BlueprintEditorRightPanelReturnValuesToParentBPForm = () => {
  const { blueprint } = useBlueprintContext();
  const returnSchema = blueprint.return_schema;
  const returnValueParameters = Object.entries(returnSchema?.properties ?? {});
  return (
    <>
      <form>
        <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
        {Object.entries(returnValueParameters).map(([_, [key, parameter]]) => {
          return (
            <TypeaheadFormField
              key={key}
              title={key}
              valueKey={key}
              parameterType={parameter.type}
            />
          );
        })}
      </form>
      <hr />
    </>
  );
};

export default BlueprintEditorRightPanelReturnValuesToParentBPForm;
