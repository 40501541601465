import { Text } from "@merge-api/merge-javascript-shared";
import { BlueprintAvailableParameter } from "../../utils/BlueprintEditorUtils";

interface Props {
  availableParameterValue: BlueprintAvailableParameter;
}

const PrettifiedStepParameterValue = ({ availableParameterValue }: Props) => {
  return (
    <div className="p-2 min-w-[350px] w-fit">
      {availableParameterValue.labelKey.split(".").map((value, index) => (
        <div key={index} className="w-full">
          <Text
            variant={index === 0 ? "lg-mono" : "md-mono"}
            className={index === 0 ? "font-extrabold" : "font-bold"}
          >
            {value}
          </Text>
        </div>
      ))}
    </div>
  );
};

export default PrettifiedStepParameterValue;
