import { Button, Text } from "@merge-api/merge-javascript-shared";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { HTTPMethod } from "../../../../models/HTTPMethods";
import { navigateToIntegrationBuilderCreateAPIEndpoint } from "../../../../router/RouterUtils";
import MergeFlagWrapper from "../../../shared/MergeFlagWrapper";
import MergeTable from "../../../shared/MergeTable";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import { MergeFlagFeature } from "../../../shared/hooks/useMergeFlag";
import useIntegrationBuilderContext from "../../context/useIntegrationBuilderContext";
import IntegrationBuilderEmptyState from "../../shared/IntegrationBuilderEmptyState";
import IntegrationBuilderHeader from "../../shared/IntegrationBuilderHeader";
import {
  APIEndpointIntegrationBuilder,
  APIEndpointPreviewIntegrationBuilder,
  APIEndpointPreviewStatus,
  AutogenerationRunNotificationStatus,
  AutogenerationRunStatus,
} from "../../utils/Entities";
import usePollAPIEndpointPreviews from "../hooks/usePollAPIEndpointPreviews";
import APIEndpointExplain from "./APIEndpointExplain";
import APIEndpointPreviewGeneratingTableRow from "./APIEndpointPreviewGeneratingTableRow";
import APIEndpointTableRow from "./APIEndpointTableRow";
import APIEndpointsGenerator from "./APIEndpointsGenerator";
import HTTPMethodColumnHeader from "./HTTPMethodColumnHeader";
import usePollAPIEndpoints from "../hooks/usePollAPIEndpoints";
import { Plus } from "lucide-react";
import AccordionCard from "../../shared/AccordionCard";
import { ChecklistStatusValue } from "../../../../constants";
import APIEndpointsGeneratorPreview from "./APIEndpointsGeneratorPreview";

interface APIEndpointsTableProps {
  integrationID: string;
  apiEndpoints: APIEndpointIntegrationBuilder[] | undefined;
  setAPIEndpoints: React.Dispatch<
    React.SetStateAction<APIEndpointIntegrationBuilder[] | undefined>
  >;
  isLoadingAPIEndpoints: boolean;
  setIsLoadingAPIEndpoints: React.Dispatch<React.SetStateAction<boolean>>;
}

const API_ENDPOINT_SUBMIT_TEXT = "Save API endpoint";

const APIEndpointsTable = ({
  integrationID,
  apiEndpoints,
  setAPIEndpoints,
  isLoadingAPIEndpoints,
  setIsLoadingAPIEndpoints,
}: APIEndpointsTableProps) => {
  //hooks
  const history = useHistory();
  // context
  const { integration, aiSearchConfigurationStatus } = useIntegrationBuilderContext({
    submitButtonText: API_ENDPOINT_SUBMIT_TEXT,
    canSubmit: false,
    shouldRenderSubmitButton: false,
    shouldRenderNavigationButtons: true,
    shouldRenderDeleteButton: false,
  });

  // state
  const [filteredAPIEndpoints, setFilteredAPIEndpoints] = useState<
    APIEndpointIntegrationBuilder[] | undefined
  >(apiEndpoints);
  const [methodFilter, setMethodFilter] = useState<HTTPMethod | null>(null);
  const [isGeneratingListExpanded, setIsGeneratingListExpanded] = useState<boolean>(true);

  const shouldDisplayAIGenerator = useMemo(() => integration?.is_soap_integration === false, [
    integration,
  ]);

  // Set up the main table of API endpoints
  useEffect(() => {
    if (apiEndpoints) {
      if (methodFilter) {
        setFilteredAPIEndpoints(
          apiEndpoints.filter(
            (apiEndpoint) => apiEndpoint.request_configuration.method === methodFilter
          )
        );
      } else {
        setFilteredAPIEndpoints(apiEndpoints);
      }
    }
  }, [methodFilter, apiEndpoints]);

  const [apiEndpointPreviews, setAPIEndpointPreviews] = useState<
    APIEndpointPreviewIntegrationBuilder[] | undefined
  >([]);
  const [pendingAPIEndpointPreviews, setPendingAPIEndpointPreviews] = useState<
    APIEndpointPreviewIntegrationBuilder[]
  >([]);
  const [generatingAPIEndpointPreviews, setGeneratingAPIEndpointPreviews] = useState<
    APIEndpointPreviewIntegrationBuilder[]
  >([]);
  const {
    pollAPIEndpointPreviews,
    stopPollingAPIEndpointPreviews,
    fetchAPIEndpointPreviews,
  } = usePollAPIEndpointPreviews({
    setAPIEndpointPreviews,
  });
  const { pollAPIEndpoints, stopPollingAPIEndpoints } = usePollAPIEndpoints({
    setAPIEndpoints,
    setIsLoadingAPIEndpoints,
  });

  // sets up and polls the API endpoints on mount
  // stops the polling on unmount
  useEffect(() => {
    pollAPIEndpoints(integrationID);
    return () => {
      stopPollingAPIEndpoints();
    };
  }, [pollAPIEndpoints, integrationID]);

  // sets up and polls the API endpoint previews on mount
  // stops the polling on unmount
  useEffect(() => {
    pollAPIEndpointPreviews(integrationID);
    return () => {
      stopPollingAPIEndpointPreviews();
    };
  }, [pollAPIEndpointPreviews, integrationID]);

  // Sets up endpoint previews that are pending and generating
  useEffect(() => {
    if (apiEndpointPreviews) {
      /**
       * API Endpoint Previews get filtered into two tables:
       * - Pending
       * - Generating
       *
       * Endpoint previews will get placed into the pending table on the following conditions:
       * - If the endpoint preview has a pending status, it appears in the pending table
       * - If the endpoint preview has autogen runs, it appears in the pending table if the most recent run has a dismissed notification status and a status that is not a successful model creation
       *
       * Endpoint previews will get placed into the generating table on the following conditions:
       * - If the endpoint preview has a processing status, it appears in the generating table
       * - If the endopint is not pending, it will appear in the generating table if there are no autogen runs or the most recent run has a pending notification status
       *
       * Other endpoint previews will not appear in either table. These are endpoint previews that have been successfully generated and then dismissed.
       */

      setPendingAPIEndpointPreviews(
        apiEndpointPreviews.filter(
          (apiEndpointPreview) =>
            apiEndpointPreview.status === APIEndpointPreviewStatus.PENDING ||
            (apiEndpointPreview.autogen_runs &&
              apiEndpointPreview.autogen_runs?.length > 0 &&
              apiEndpointPreview.autogen_runs[0].notification_status ===
                AutogenerationRunNotificationStatus.DISMISSED &&
              apiEndpointPreview.autogen_runs[0].status !==
                AutogenerationRunStatus.SUCCESS_MODEL_CREATED)
        )
      );

      setGeneratingAPIEndpointPreviews(
        apiEndpointPreviews.filter(
          (apiEndpointPreview) =>
            !(
              apiEndpointPreview?.autogen_runs?.length &&
              apiEndpointPreview.autogen_runs[0].notification_status ===
                AutogenerationRunNotificationStatus.DISMISSED
            ) &&
            (apiEndpointPreview.status === APIEndpointPreviewStatus.PROCESSING ||
              (apiEndpointPreview.status !== APIEndpointPreviewStatus.PENDING &&
                (!!!apiEndpointPreview.autogen_runs?.length ||
                  (apiEndpointPreview.autogen_runs &&
                    apiEndpointPreview.autogen_runs?.length > 0 &&
                    apiEndpointPreview.autogen_runs[0].notification_status ===
                      AutogenerationRunNotificationStatus.PENDING))))
        )
      );
    }
  }, [apiEndpointPreviews]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <IntegrationBuilderHeader
          title="API endpoints"
          button={
            <Button
              size="md"
              leftIcon={<Plus size={16} />}
              onClick={() => {
                navigateToIntegrationBuilderCreateAPIEndpoint(history, integrationID);
              }}
            >
              Endpoint
            </Button>
          }
        />
        <APIEndpointExplain className="mb-6" />
        {generatingAPIEndpointPreviews && generatingAPIEndpointPreviews.length > 0 && (
          <AccordionCard
            title={"Generating endpoints with AI"}
            titleVariant="h6"
            defaultExpanded={true}
            expanded={isGeneratingListExpanded}
            setExpanded={setIsGeneratingListExpanded}
          >
            <div className="space-y-4">
              {generatingAPIEndpointPreviews.map((apiEndpointPreview) => (
                <APIEndpointPreviewGeneratingTableRow
                  apiEndpointPreview={apiEndpointPreview}
                  setGeneratingAPIEndpointPreviews={setGeneratingAPIEndpointPreviews}
                />
              ))}
            </div>
          </AccordionCard>
        )}
        <MergeTable
          hover={!isLoadingAPIEndpoints && filteredAPIEndpoints && filteredAPIEndpoints.length > 0}
          hasMarginBottom={false}
          hasMorePaddingOnFirstElement
          className="mb-8" // gives space for the sticky button
          header={
            <>
              <th scope="col">Name</th>
              <th scope="col">
                <HTTPMethodColumnHeader
                  methodFilter={methodFilter}
                  setMethodFilter={setMethodFilter}
                />
              </th>
              <th scope="col">Path</th>
              <th></th>
            </>
          }
          content={
            isLoadingAPIEndpoints ? (
              <TableSkeletonLoader cols={4} rows={3} size="large" />
            ) : (
              <>
                {filteredAPIEndpoints && filteredAPIEndpoints.length > 0 ? (
                  filteredAPIEndpoints.map((apiEndpoint, index) => (
                    <APIEndpointTableRow
                      key={index}
                      apiEndpoint={apiEndpoint}
                      integrationID={integrationID}
                      hasButtonHeader={true}
                      setAPIEndpoints={setAPIEndpoints}
                      canDuplicate={true}
                    />
                  ))
                ) : (
                  <IntegrationBuilderEmptyState
                    title="No API endpoints"
                    subtitle={
                      <div>
                        Click <Text variant="title-sm">+ Endpoint</Text> in the top right to
                        manually add one or <Text variant="title-sm">autogenerate </Text>
                        endpoints below with AI
                      </div>
                    }
                    numberOfColumns={3}
                  />
                )}
              </>
            )
          }
        />
      </div>
      {shouldDisplayAIGenerator ? (
        <MergeFlagWrapper
          feature={MergeFlagFeature.MERGE_FLAG_AI_ENDPOINTS}
          isEnabledForUser={true}
        >
          <div className="bottom-4 sticky -mx-[20px]">
            {aiSearchConfigurationStatus == ChecklistStatusValue.IMPLEMENTED ? (
              <APIEndpointsGenerator
                pendingAPIEndpointPreviews={pendingAPIEndpointPreviews}
                setAPIEndpointPreviews={setAPIEndpointPreviews}
                integrationID={integrationID}
                fetchAPIEndpointPreviews={fetchAPIEndpointPreviews}
                setIsGeneratingListExpanded={setIsGeneratingListExpanded}
              />
            ) : (
              <APIEndpointsGeneratorPreview
                integrationID={integrationID}
                aiSearchConfigurationStatus={aiSearchConfigurationStatus}
              />
            )}
          </div>
        </MergeFlagWrapper>
      ) : null}
    </div>
  );
};

export default APIEndpointsTable;
