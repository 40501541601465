import { HeaderPretitle } from "../../shared/text/MergeText";
import TypeaheadFormField from "./TypeaheadFormField";

const BlueprintEditorRightPanelMetaAddEnumChoicesToFieldForm = () => {
  return (
    <form>
      <HeaderPretitle className="mt-3">Parameters</HeaderPretitle>
      <TypeaheadFormField
        title={"Enum Location"}
        subtitle={
          "The input parameter of the field that should be updated. Use The 'Meta: Access Parent BP Params' step. Either Enum Location or Enum linked_account_param Location is required."
        }
        includeUniqueIdentifierCheck={false}
        valueKey={"enum_location"}
        parameterType={"field_reference"}
      />
      <TypeaheadFormField
        title={"Enum linked_account_param Location"}
        subtitle={
          "The linked_account_params input parameter that this field should be added to. Use The 'Meta: Access Parent BP Params' step. Either Enum Location or Enum linked_account_param Location is required."
        }
        includeUniqueIdentifierCheck={false}
        valueKey={"enum_linked_account_param_location"}
        parameterType={"field_reference"}
      />
      <TypeaheadFormField
        title={"Enum linked_account_param Key"}
        subtitle={
          "The key to refer the linked account param by. Required if Enum linked_account_param Location is filled in."
        }
        includeUniqueIdentifierCheck={false}
        valueKey={"enum_linked_account_param_key"}
        parameterType={"string"}
      />
      <TypeaheadFormField
        title={"Enum Value"}
        subtitle={"The value of the enum choice."}
        includeUniqueIdentifierCheck={false}
        valueKey={"enum_value"}
        parameterType={"string"}
      />
      <TypeaheadFormField
        title={"Enum description"}
        subtitle={"The description of the enum choice, if available."}
        includeUniqueIdentifierCheck={false}
        valueKey={"enum_description"}
        parameterType={"string"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelMetaAddEnumChoicesToFieldForm;
